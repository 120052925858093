import { Component, OnInit, AfterViewInit, ViewEncapsulation, ElementRef, TemplateRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AccordionConfig } from 'ngx-bootstrap/accordion';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { DomSanitizer } from '@angular/platform-browser'; // for google
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { UntypedFormControl } from '@angular/forms';
import { CommonValidationService } from 'src/app/common/service/common-validation.service';
import { OrderFromPipe } from 'src/app/izomcc/order-form/order-form.pipe';
export function getAccordionConfig(): AccordionConfig {
    return Object.assign(new AccordionConfig(), { closeOthers: true });
}

import { IzomccScpService } from 'src/app/izomcc-scp/izomcc-scp.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { DataSharedSvc } from 'src/app/data-shared-svc';
import { OrderFormService } from 'src/app/izomcc/order-form/order-form.service';
@Component({
    selector: 'app-scp-order-review',
    templateUrl: './scp-order-review.component.html',
    styleUrls: ['./scp-order-review.component.css'],
    providers: [{ provide: AccordionConfig, useFactory: getAccordionConfig }, OrderFromPipe],
    encapsulation: ViewEncapsulation.None
})
export class ScpOrderReviewComponent implements OnInit {
    html = '<span class="infoicon">!</span> <b>Verify content</b><br>Please verify the content ' +
        'in each section to be able to place your order';
    deployedNAT = 'Yes';
    showingTab = 'solution'; // for initial value for which tab should show
    quoteObj = JSON.parse(this.dataSharedSvc.getQuoteObjFromSession());
    customer = JSON.parse(this.dataSharedSvc.getCustomerFromSession());
    siteObj: any = {
    };
    clicked: boolean = false;
    showloader = false;
    isCommercialUser: boolean;
    mailDropSettings = {
        text: "Please select name",
        singleSelection: true,
        enableSearchFilter: true,
        classes: "mail-class",
        showCheckbox: false
    }
    today = new Date()
    quoteInfo: any = { "quoteId": 67957, "quoteleId": 68203 };
    reviewAccept: any = {
        'is_solution_visited': true,
        'is_charges_visited': false,
        'is_billing_visited': false,
        'is_Configuration_Visited': false,
        'is_contractingInfo_visited': false,
        'is_terms_visited': false,
        'enablePlaceOrder': false,
        'enableCheck': false,
        'delegateRights': false,
        'selectedOffering': 'All',
        'billing': [],
        'solutionDtails': [],
        'siteInfo': [],
        'image': [],
        'location': [],
        'contractingInfo': {
            'customer': {
                'billingAddress': []
            },
            'supplier': []
        },
        'sites': [],
        'solutions': {},
        'listOfOfferings': [],
        'offerings': [],
        'showSite': false,
        'cpeData': [],
        'pageReady': false,
        'electSign': true,
        'termsCondition': false,
        'validation': {},
        'currentDocument': '',
        'documentsConfirm': {},
        'disableDocumentFlag': true,
        'documents': [],
        'showMSA': false,
        'showSS': false,
        'serviceVariant': [], // for msa document conditions
        'productInfo': {}, // for terms and conditions,
        'contractPeriod': [],
        'listOfBillingAddress': [], //for billing address
        'deployNAT': 'Yes',
        'currencyType': [],
        'totalTcv': '',
        'burstableCharges': 0,
        'mastPrice': 0,
        'publicIp': '',
        'selectedUser': '',
        salesLogin: false,
        listOfUser: [],
        docusignFlag: false,
        impersonateUser: {},
        placeOrder: 'Place Order',
        psamUserLogin: false,
        canPlaceOrder: false
    };
    loggedInUserType: any;
    loggedInUserActions: any;
    loggedInUserModules: any;
    creditCheck: any = {
        creditCheckStatus: '',
        creditCheckError: '',
        creditCheckStatusFlag: false
    }
    userCategoryModule: any;
    isPoMandatoryOrder: any;
    modalRef: BsModalRef;
    productName: any;
    serviceVariantList: any;
    quoteFullObj: any;
    isECOInternetVariant: boolean = false;
    flag: any = {
        showConfirmationDialog: false
    };
    quoteDetails: any;
    quoteSolutionData: any;
    cloudConnections: any = [];
    edgeConnectivity: any = [];
    edgeDevice: any = [];
    componentList: any = [];
    finalList: any = [];
    solutionObj: any;
    configurationList: any = [];
    serviceTime: any;
    refOrderId: any;
    accessRequired: any;
    accessType: any;

    unSelectedUserPresent: boolean;
    contactid: any;
    billAddress: any;
    listOfSites_Contract: any[];
    quoteLeId: any;
    showFirstSigner: boolean = true;
    showSecondSigner: boolean;
    showThirdSigner: boolean;
    showFirstReviewer: boolean;
    showSecondReviewer: boolean;
    showCarbonCopy: boolean;
    firstReviewer = new UntypedFormControl();
    secondReviewer = new UntypedFormControl();
    secondSigner = new UntypedFormControl();
    thirdSigner = new UntypedFormControl();
    /**common modal */
    messageModalSettings: any = {};
    dflag: boolean = false;
    solutionsArr: any = [];
    configurations: any = [];
    edgeflag: any = [];
    cloudflag: any = [];
    connectflag: any = [];
    currency: any;
    quoteComponents: any;
    newCloudArray: any;
    enableCheckBox: boolean = false;
    enableSubmitBtn: boolean = false;
    newEdgeConnectivityArray: any;
    newEdgeDeviceArray: any;
    newEdgeConnectivityArrayAtt: any;
    newEdgeDeviceArrayAtt: any[];
    regionName: any;
    newEdgeDevArry: any = [];
    singleCloudArr: any = [];
    MulCloudArr: any = []
    sCloud: boolean = false;
    contractTerm: any;
    cTermText: string;
    locName: any;
    connection_alias_name: any;
    openMessageModal(data) {
        this.messageModalSettings.open(data);
    }
    payloadStage = {
        stage: 'Order Form'
    }
    selectedUser = new UntypedFormControl();
    customerCC = new UntypedFormControl();
    listOfActions = this.dataSharedSvc.getListOfActions();
    @ViewChild('MSA') msa: TemplateRef<any>;
    @ViewChild('pop') pop: PopoverDirective;
    @ViewChild('delgationTemplate') delgationTemplate: TemplateRef<any>;
    @ViewChild('deployOrderTemplate') deployOrderTemplate: TemplateRef<any>;
    @ViewChild('MailConfirmation') mailConfirmationModal: TemplateRef<any>;
    @ViewChild('DocuSignForSalesLogin') docuSignForSalesLoginModal: TemplateRef<any>;
    @ViewChild('confirmOrderModal') confirmOrderModal: TemplateRef<any>;
    constructor(private _snackBar: MatSnackBar, public dataSharedSvc: DataSharedSvc, private router: Router, public activatedroute: ActivatedRoute,
        public sanitizer: DomSanitizer, public elementRef: ElementRef,
        public modalService: BsModalService, private _scp_izomcc_service: IzomccScpService) { }

    ngOnInit(userCategoryModule?) {
        this.quoteInfo = JSON.parse(this.dataSharedSvc.getQuoteObjFromSession());
        this.activatedroute.params.subscribe(params => {
            //console.log(params)
            if (params && params['quoteId']) {
                this.dflag = true
                this.quoteObj = params
                this.dataSharedSvc.setQuoteObjInSession(JSON.stringify(this.quoteObj));
            } else {
                this.dflag = false
            }
        })

        let dataForCredit = this.dataSharedSvc.getCreditStatus();

        if (dataForCredit) {
            this.creditCheck.creditCheckStatus = (dataForCredit.creditCheckStatus == 'Positive' && dataForCredit.preapprovedFlag) ? dataForCredit.creditCheckStatus : '';
            this.creditCheck.creditCheckStatusFlag = dataForCredit.creditCheckStatusFlag;
            this.creditCheck.creditCheckError = dataForCredit.creditCheckError;

            if (this.creditCheck.creditCheckStatus) this.setCreditMessage(this.creditCheck.creditCheckStatus);
            if (this.creditCheck.creditCheckError) this.setCreditErrorMessage(this.creditCheck.creditCheckError);
        }


        if (!dataForCredit) {
        this.refreshCreditCheck()

        }


        if (this.quoteObj.quoteleId == null || this.quoteObj.quoteleId == undefined) {
            this.quoteLeId = this.quoteObj.legalEntities[0].quoteleId;

        }
        else {
            this.quoteLeId = this.quoteObj.quoteleId;
        }


        if (this.dflag == true) {

            this.getLoginProfile();
            setTimeout(() => {                           // <<<---using ()=> syntax
                this.getReviewDetails();
            }, 3000);
            // this.updateStage()
        } if (this.dflag == false) {

            this.getLoginProfile();
            setTimeout(() => {                           // <<<---using ()=> syntax
                this.getReviewDetails();
            }, 3000);
            // this.updateStage()
        }


    }

    getLoginProfile() {
        this.dataSharedSvc.sharedData.subscribe(data => {
            console.log(data)
            const isCustomerUser = data['userType'] == 'CUSTOMER';

            if (data['actions'].includes("OPT_L2O_CUSTOMER_ORDER_CREATE")) {
                this.reviewAccept.salesLogin = true;

            }
            if (data['actions'] && data['actions'].indexOf('OPT_VIEW_COMMERCIAL') > -1) {

                this.isCommercialUser = true;
            }
            if (data['modules'].includes("OPT_PARTNER_USER_PSAM")) {
                this.reviewAccept.psamUserLogin = true;
            }
            if ((!this.reviewAccept.salesLogin && !isCustomerUser) || (isCustomerUser && data['actions'].includes("OPT_L2O_CUSTOMER_PLACE_ORDER"))) {

                this.reviewAccept.canPlaceOrder = true;
            }
            this.loggedInUserType = data['userType'];
            this.loggedInUserActions = data['actions'];
            this.loggedInUserModules = data['modules'];
            if (this.loggedInUserModules === 'partner') {
                this.isCommercialUser = true;
            }
        });

    }
    routeBack() {
        this.router.navigate(['/checkout'], {
            relativeTo: this.activatedroute,
            skipLocationChange: false
        });
    }

    checkPlaceOrderEnabled(visitingTab) {
        this.showingTab = visitingTab;

        if (this.showingTab === 'solution') {
            this.reviewAccept.is_solution_visited = true;
        }

        if (this.showingTab === 'charges') {
            this.reviewAccept.is_charges_visited = true;
        }

        if (this.showingTab === 'payments') {
            this.reviewAccept.is_billing_visited = true;
        }

        if (this.showingTab === 'customer') {
            this.reviewAccept.is_contractingInfo_visited = true;
        }
        if (this.showingTab === 'terms') {
            this.reviewAccept.is_terms_visited = true;
        }

        if (this.reviewAccept.is_solution_visited &&
            this.reviewAccept.is_charges_visited && this.reviewAccept.is_billing_visited && this.reviewAccept.is_contractingInfo_visited &&
            this.reviewAccept.is_terms_visited) {
            this.reviewAccept.enableCheck = true;

            if (this.reviewAccept.psamUserLogin) {
                this.reviewAccept.enablePlaceOrder = true;
            }
        }

    }

    openLoaderModal(data) {
        // this.dataSharedSvc.open(data);
    }

    getSelectedCOFInfo(data) {
        this.reviewAccept.deployNAT = data;
    }


    validateImpInfo(val) {
        // if (this.creditCheck.creditCheckStatusFlag) {
        //     return;
        // }
        // this.service.validateCofDataIzomcc(this.quoteObj.quoteId, this.quoteLeId, response => {

        //     if (val == 'downloadCof') {
        //         this.generatCOF_PDF();
        //     } if (val == 'docusign') {
        //         this.confirmSentMail('docusign');
        //     }

        // }, error => {

        // });

    }

    openModal(template: TemplateRef<any>, type) {
        this.modalRef = this.modalService.show(
            template,
            Object.assign({}, { class: 'modal-dialog-centered ' + type, ignoreBackdropClick: true })
        );
    }

    openDocument(index) {

        this.openModal(this.msa, '');
        // document.querySelector("iframe").src = index.url;
        document.getElementById('scpiframeid').setAttribute('src', index.url);

    }
    public downloadServiceScheduleExhibits(): void {
        this.serviceVariantList.forEach(serviceVariant => {
            this.downloadServiceSchedule(serviceVariant);
        });
    }

    public downloadServiceSchedule(serviceVariant: string): void {
        this.productName = 'IZOMCC';
        switch (serviceVariant) {

        }

    }

   /**checking whether terrms and conditions checkbox is checked or not */
    checkTermsAndConditions() {

    }

    getReviewDetails(): void {


        // this.service.getReviewDetails(this.quoteObj.quoteId, (res) => {

        //     this.quoteDetails = res.data;
        //     this.updateStage()
        //     this.quoteSolutionData = this.quoteDetails.legalEntities[0].productFamilies[0].solutions;


        //     this.reviewAccept.legalEntity = res.data.legalEntities;

        //     this.reviewAccept.customerLegalEntityId = this.quoteDetails.legalEntities[0].customerLegalEntityId
        //     this.reviewAccept.legalAttributes = this.reviewAccept.legalEntity[0].legalAttributes;
        //     this.reviewAccept.isDocusign = this.quoteDetails.isDocusign;
        //     this.reviewAccept.isDelegated = this.quoteDetails.isDelegated ? this.quoteDetails.isDelegated : false;
        //     this.reviewAccept.customerId = this.quoteDetails.customerId
        //     this.currency = this.quoteDetails.legalEntities[0].currency
        //     this.isPoMandatoryOrder = this.quoteDetails.poRequired
        //     this.quoteComponents = res.data.legalEntities[0].productFamilies[0].solutions[0].configurations[0].components


        //     this.regionName = this.reviewAccept ?.legalEntity[0] ?.legalAttributes.filter(function (el) {
        //         return el.displayValue == "REGION";
        //     });
        //     this.connection_alias_name = this.reviewAccept ?.legalEntity[0] ?.legalAttributes.filter(function (el) {
        //         return el.displayValue == "SOLUTION ALIAS NAME";
        //     });

        //     let cloudArr = this.quoteComponents.filter(function (el) {
        //         return el.name === "Cloud";
        //     });
        //     console.log('quoteComponents', cloudArr)


        //     cloudArr[0] ?.attributes.forEach((el) => {

        //         if (el['name'] == 'Secondary Service Key') {

        //             this.sCloud = true;
        //         }
        //     });
        //     if (this.sCloud == true) {

        //         cloudArr[0] ?.attributes.forEach((el) => {

        //             if (
        //                 el['name'] == 'Cloud Provider'
        //                 || el['name'] == 'Interconnect Location'
        //                 || el['name'] == 'Cloud Bandwidth'
        //                 || el['name'] == 'Link Type'
        //                 || el['name'] == 'Cloud Alias Name'

        //                 || el['name'] == 'Primary Ip Address'
        //                 || el['name'] == 'Primary Subnet'
        //                 || el['name'] == 'Primary Bgp Key'
        //                 || el['name'] == 'Primary Neighbour Ip'
        //                 || el['name'] == 'Primary Remote As Number'

        //                 || el['name'] == 'Primary Service Key'
        //                 || el['name'] == 'Primary Peer Type'
        //                 || el['name'] == 'Primary vlan'
        //                 || el['name'] == 'Secondary Service Key'
        //                 || el['name'] == 'Secondary Peer Type'
        //                 || el['name'] == 'Secondary Ip Address'
        //                 || el['name'] == 'Secondary Subnet'
        //                 || el['name'] == 'Secondary Bgp Key'
        //                 || el['name'] == 'Secondary Neighbour Ip'
        //                 || el['name'] == 'Secondary vlan'
        //                 || el['name'] == 'Secondary Remote As Number'

        //             ) {
        //                 this.singleCloudArr.push(el)
        //             }
        //         });

        //     } else {

        //         cloudArr[0] ?.attributes.forEach((el) => {

        //             if (
        //                 el['name'] == 'Cloud Provider'
        //                 || el['name'] == 'Interconnect Location'
        //                 || el['name'] == 'Cloud Bandwidth'
        //                 || el['name'] == 'Link Type'
        //                 || el['name'] == 'Cloud Alias Name'
        //                 || el['name'] == 'Primary Account Id'
        //                 || el['name'] == 'Primary Ip Address'
        //                 || el['name'] == 'Primary Subnet'
        //                 || el['name'] == 'Primary Bgp Key'
        //                 || el['name'] == 'Primary Neighbour Ip'
        //                 || el['name'] == 'Primary Remote As Number'

        //             ) {
        //                 this.singleCloudArr.push(el)
        //             }
        //         });
        //     }

        //     cloudArr[1] ?.attributes.forEach((el) => {

        //         if (
        //             el['name'] == 'Cloud Provider'
        //             || el['name'] == 'Interconnect Location'
        //             || el['name'] == 'Cloud Bandwidth'
        //             || el['name'] == 'Link Type'
        //             || el['name'] == 'Cloud Alias Name'
        //             // || el['name'] == 'Primary Account Id'
        //             || el['name'] == 'Primary Ip Address'
        //             || el['name'] == 'Primary Subnet'
        //             || el['name'] == 'Primary Bgp Key'
        //             || el['name'] == 'Primary Neighbour Ip'
        //             || el['name'] == 'Primary Remote As Number'

        //             || el['name'] == 'Primary Service Key'
        //             || el['name'] == 'Primary Peer Type'
        //             || el['name'] == 'Primary vlan'
        //             || el['name'] == 'Secondary Service Key'
        //             || el['name'] == 'Secondary Peer Type'
        //             || el['name'] == 'Secondary Ip Address'
        //             || el['name'] == 'Secondary Subnet'
        //             || el['name'] == 'Secondary Bgp Key'
        //             || el['name'] == 'Secondary Neighbour Ip'
        //             || el['name'] == 'Secondary vlan'
        //             || el['name'] == 'Secondary Remote As Number') {
        //             this.MulCloudArr.push(el)
        //         }
        //     });

        //     console.log('MulCloudArr========', this.MulCloudArr)

        //     let VNFDeatils = this.quoteComponents.filter(function (el) {
        //         return el.name === "Edge Device";
        //     });


        //     VNFDeatils[0].attributes.forEach((el) => {

        //         if (el['name'] == 'Device Model' || el['name'] == 'Vnf Core Size' || el['name'] == 'Memory'
        //             || el['name'] == 'Device Alias Name' || el['name'] == 'Local As Number' || el['name'] == 'Link Type') {
        //             this.newEdgeDevArry.push(el)
        //         }
        //     });

        //     console.log('quoteComponents', this.newEdgeDevArry)


        //     this.quoteDetails.legalEntities[0].legalAttributes.forEach(row => {
        //         //console.log(row)
        //         if (row['displayValue'] == 'BILLING_CONTACT_ID') {
        //             this.contactid = row['attributeValue']
        //         }
        //     })

        //     let edgeDevice = []
        //     this.quoteSolutionData.forEach(element => {
        //         this.solutionsArr.push(element)
        //     });

        //     for (let i = 0; i < this.solutionsArr.length; i++) {
        //         this.configurations.push(this.solutionsArr[i].configurations[0])
        //     }

        //     this.configurations.forEach(element => {
        //         this.edgeflag = element.components.some(ele => ele.name == "Edge Device")
        //     });

        //     this.configurations.forEach(element => {
        //         this.cloudflag = element.components.some(ele => ele.name == "Cloud")
        //     });
        //     this.configurations.forEach(element => {
        //         this.connectflag = element.components.some(ele => ele.name == "Edge Connectivity")
        //     });
        //     console.log('this.connectflag', this.connectflag)
        //     this.contractTerm = this.reviewAccept ?.legalEntity[0] ?.termInMonths
        //     if (this.contractTerm == '1 Month') {
        //         this.cTermText = 'Non-committed term – Daily Rate plan'
        //     } else {
        //         this.cTermText = this.contractTerm
        //     }
        //     this.locName = this.reviewAccept ?.legalEntity[0] ?.productFamilies[0] ?.solutions[0] ?.configurations[0] ?.locationName
        //     this.quoteSolutionData
        //     this.getBilling();
        //     this.getDocList();
        //     this.billingAddress();
        //     this.getContractingInfo()

        // }, (error) => {
        //     this.updateStage()
        // })

    }

    getData(obj, index) {

        let configurationList = []
        this.quoteSolutionData.forEach(element => {

            let arr1 = []
            let arr2 = []
            let arr3 = []
            if (element['productSolutionId'] == obj['productSolutionId']) {
                configurationList.push(element)
                configurationList.forEach(el => {

                    el['configurations'].forEach(item => {
                        item['components'].forEach(row => {
                            if (row['name'] == 'Cloud') {
                                arr1.push(row)
                                this.cloudConnections = arr1

                            }
                            if (row['name'] == 'Edge Device') {
                                arr2.push(row)
                                this.edgeDevice = arr2

                            }
                            if (row['name'] == 'Edge Connectivity') {
                                arr3.push(row)
                                this.edgeConnectivity = arr3

                            }
                        })
                    })
                })

            }
        });
    }

    salesFn(category) {
        if (category == 'docusign') {
            this.validateCOFData('docusign');
        } else if (category == 'delegate') {
            this.validateCOFData('delegate');
        } else {
            this.generatCOF_PDF();
        }
    }



    validateCOFData(val) {
        if (this.creditCheck.creditCheckStatusFlag) {
            return;
        }
        if (val == 'docusign') {

            this.validateCOF('docusign');
        } else if (val == 'delegate') {
            this.delegateChange();
        } else if (val == 'Place Order') {
            this.openModal(this.confirmOrderModal, 'modal-sm');
        }

    }

    generatCOF_PDF() {
        this.notify();
        let nat = this.reviewAccept.deployNAT == '' || this.reviewAccept.deployNAT == 'No' ? false : true;

        const data = { 'message': 'Please wait ...' };
        this.dataSharedSvc.open(data);
        this._scp_izomcc_service.generateIzomccPDF((successData) => {
            const blob = successData;
            const binaryData = [];
            binaryData.push(blob);
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(new Blob(binaryData));
            /** Quote Pdf format change */
            link.download = 'Customer-Order-Form - ' + this.quoteDetails.quoteCode + '.pdf';

            document.body.appendChild(link);
            link.click();
            link.remove();
            setTimeout(() => {
                this.dataSharedSvc.close();
            }, 1000);
        }, (errorData) => {
            setTimeout(() => {
                this.dataSharedSvc.close();
            }, 1000);
        }, this.quoteObj.quoteId, this.quoteLeId, nat);
    }

    confirmSentMail(name) {
        if (name == 'docusign') {
            this.reviewAccept.docusignFlag = true;
            this.reviewAccept.docusignSelectedUser = '';
            this.reviewAccept.selectedUser = '';
        }
        // this.service.getLeContactInfo(response => {
        //     var response = response.data;
        //     this.reviewAccept.userList = response.contacts;

        //     this.reviewAccept.userList.forEach(ul => ul['isSelected'] = false);
        //     this.unSelectedUserPresent = this.reviewAccept.userList.length > 1;

        // }, (error) => { }, this.reviewAccept.customerLegalEntityId)
        this.reviewAccept.mailConfirmationModalRef = this.modalService.show(this.docuSignForSalesLoginModal, { class: "modal-md" })
    }

    delegateChange() {
        // this.service.getCOFUserList(this.reviewAccept.customerId, this.quoteObj.quoteId, (successData) => {
        //     this.reviewAccept.listOfUser = successData.data;
        //     this.reviewAccept.listOfUser.forEach(user => {
        //         user.itemName = user.username;
        //     });
        // }, (err) => { });
        this.openModal(this.delgationTemplate, 'modal-sm');

    }

    resetFormValues() { }


    getBilling() {
        // this.service.getBilling(this.reviewAccept.customerLegalEntityId, (successData) => {
        //     this.reviewAccept.billing = successData.data;
        //     this.reviewAccept.legalAttributes;
        //     /** Payment current as per quote currency selection  */
        //     this.reviewAccept.legalAttributes.map(eachattribute => {
        //         this.reviewAccept.billing.map(eachBill => {
        //             if (eachBill.attributeName === eachattribute.displayValue) {
        //                 eachBill.attributeValue = eachattribute.attributeValue
        //             }
        //         })
        //     })
        // }, (err) => { });
    }

    getDocList() {
        // this.service.getDocuments(this.reviewAccept.customerLegalEntityId, (successData) => {
        //     this.reviewAccept.documents = successData.data;
        //     this.reviewAccept.documents.forEach(row => {

        //         this.getDocs(row)
        //     })

        // }, (err) => { });
    }


    billingAddress() {
        // this.service.getbillingAddress(this.reviewAccept.customerLegalEntityId, this.contactid, (res) => {

        //     this.billAddress = res['data']['billAddr']
        // }, (error) => {

        // })
    }
    getContractingInfo() {
        const params = {
            customerLegalEntityId: this.reviewAccept.customerLegalEntityId,
            supplierLegalEntityId: this.reviewAccept.legalEntity[0].supplierLegalEntityId
        };
        // this.service.getContractingInfo(params, (contractingData) => {
        //     this.reviewAccept.contractingInfo = contractingData.data;
        //     this.reviewAccept.signing = contractingData.data.supplierContractingInfo.signingDetails;

        //     this.reviewAccept.legalAttributes.forEach(entity => {
        //         if (entity.displayValue === 'SUPPLIER_LE_OWNER') {
        //             this.reviewAccept.contractingInfo.supplierName = entity.attributeValue;
        //         } else if (entity.displayValue === 'SUPPLIER_LE_EMAIL') {
        //             this.reviewAccept.contractingInfo.supplierEmail = entity.attributeValue;
        //         } else if (entity.displayValue === 'Le Contact') {
        //             this.reviewAccept.contractingInfo.supplierMobile = entity.attributeValue;
        //         } else if (entity.displayValue === 'CONTACTNAME') {
        //             this.reviewAccept.contractingInfo.customerName = entity.attributeValue;
        //         } else if (entity.displayValue === 'DESIGNATION') {
        //             this.reviewAccept.contractingInfo.customerDesignation = entity.attributeValue;
        //         } else if (entity.displayValue === 'CONTACTEMAIL') {
        //             this.reviewAccept.contractingInfo.customerEmail = entity.attributeValue;
        //         } else if (entity.displayValue === 'CONTACTNO') {
        //             this.reviewAccept.contractingInfo.customerMobile = entity.attributeValue;
        //         } else if (entity.displayValue === 'LeStateGstNumber') {
        //             this.reviewAccept.contractingInfo.gstNumber = entity.attributeValue;
        //         } else if (entity.displayValue === 'PO_NUMBER') {
        //             this.reviewAccept.contractingInfo.ponumber = entity.attributeValue;
        //         } else if (entity.displayValue === 'PO_DATE') {
        //             this.reviewAccept.contractingInfo.podate = entity.attributeValue;
        //         } else if (entity.displayValue === 'VAT Number') {
        //             this.reviewAccept.contractingInfo.vatNumber = entity.attributeValue;
        //         }
        //     });
        //     this.listOfSites_Contract = [];
        //     for (let i = 0; i < this.reviewAccept.contractingInfo.entityAdress.length; i++) {
        //         if (this.reviewAccept.contractingInfo.entityAdress[i].name === 'Customer Contracting Entity') {
        //             this.listOfSites_Contract.push(this.reviewAccept.contractingInfo.entityAdress[i].value);
        //             this.reviewAccept.contractingInfo.customerLocationId = this.reviewAccept.contractingInfo.entityAdress[i].value
        //         }
        //     }

        //     for (let i = 0; i < this.reviewAccept.contractingInfo.supplierContractingInfo.contractingEntityId.length; i++) {
        //         if (this.reviewAccept.contractingInfo.supplierContractingInfo.contractingEntityId[i].name === 'Supplier Contracting Entity') {
        //             this.listOfSites_Contract.push(this.reviewAccept.contractingInfo.supplierContractingInfo.contractingEntityId[i].value);
        //             this.reviewAccept.contractingInfo.supplierLocationId = this.reviewAccept.contractingInfo.supplierContractingInfo.contractingEntityId[i].value
        //         }
        //         else if (this.reviewAccept.contractingInfo.supplierContractingInfo.contractingEntityId[i].name === 'Supplier Contact Title/Designation') {
        //             this.reviewAccept.contractingInfo.supplierDesignation = this.reviewAccept.contractingInfo.
        //                 supplierContractingInfo.contractingEntityId[i].value;
        //         }
        //         else if (this.reviewAccept.contractingInfo.supplierContractingInfo.contractingEntityId[i].name === 'GST_Number') {
        //             this.reviewAccept.contractingInfo.supplierGSTNumber = this.reviewAccept.contractingInfo.
        //                 supplierContractingInfo.contractingEntityId[i].value;
        //         }
        //         else if (this.reviewAccept.contractingInfo.supplierContractingInfo.contractingEntityId[i].name === 'Supplier LandlineNumber') {
        //             this.reviewAccept.contractingInfo.supplierLandline = this.reviewAccept.contractingInfo.
        //                 supplierContractingInfo.contractingEntityId[i].value;
        //         }
        //     }
        //     if (this.quoteObj.quoteType != 'MACD') {
        //         this.postListOfSites();
        //     }

        // }, (errorData) => { });
    }



    postListOfSites() {
        // this.service.postListOfSites(this.listOfSites_Contract, (addressData) => {
        //     addressData.data.forEach(e => {
        //         if (e.locationId === Number(this.reviewAccept.contractingInfo.customerLocationId)) {
        //             this.reviewAccept.contractingInfo.customerAddress = e.userAddress
        //         }
        //         else if (e.locationId === Number(this.reviewAccept.contractingInfo.supplierLocationId)) {
        //             this.reviewAccept.contractingInfo.supplierAddress = e.userAddress
        //         }
        //     });
        // }, (errorData) => { });
    }



    notify() {
        // this.service.cofNotificationForIzomcc(this.quoteObj.quoteId, this.quoteLeId, (res) => {
        // }, (error) => {

        // })

    }

    setCreditMessage(message) {
        if (message.includes('Pending Credit Action') || message.includes('Reserved')) {
            this.creditCheck.creditCheckStatus = this.reviewAccept.salesLogin ? 'Credit Evaluation is in Process.' :
                `Credit Evaluation is in progress. Kindly contact your account manager.`;
        }
        else if (message.includes('Positive')) {

            this.creditCheck.creditCheckStatus = 'Credit Evaluation is Completed. Please Place the Order.';
        }
        else if (message.includes('Negative') || (message == '0XOBL001')) {

            this.creditCheck.creditCheckStatus = 'Credit Evaluation is Completed. Please get in touch with your respective AGMR.';
        } else {

            this.creditCheck.creditCheckStatus = this.reviewAccept.salesLogin ? 'Credit Evaluation is in Process.' :
                `Credit Evaluation is in progress. Kindly contact your account manager.`;
        }
    }

    setCreditErrorMessage(message) {

        if (message == '0XOBL001') {
            this.creditCheck.creditCheckError = "This account has been blacklisted"
        } else if (message == '0XOCR001') {
            this.creditCheck.creditCheckError = "Error in getting credit check status"
        } else if (message == '0XOCP001') {
            this.creditCheck.creditCheckError = "Error in getting credit preapproved flag"
        } else if (message == '0XOCV001') {
            this.creditCheck.creditCheckError = "Error in getting preapproved values"
        }

    }

    displayCustomerName(name, id) {
        return name === 'select' ? 'select' : name + ' ( ' + id + ' )';
    }
    docusignListSelect(data) {
        this.reviewAccept.selectedUser = data;
        this.reviewAccept.docusignSelectedUser = this.displayCustomerName(data.customerLeContactName, data.customerLeContactEmailid);
    }
    onClickAddSigner(): void {
        !this.showSecondSigner ? this.showSecondSigner = true : this.showThirdSigner = true;

    }
    onClickAddReviewer(): void {
        !this.showFirstReviewer ? this.showFirstReviewer = true : this.showSecondReviewer = true;
    }

    onSelectUser(user: object, selectedBy: string): void {
        this.reviewAccept.userList.forEach(ul => {
            if (ul['isSelected'] === selectedBy) {
                ul['isSelected'] = false;
            }
            if (ul['customerLeContactEmailid'] === user['customerLeContactEmailid']) {
                ul['isSelected'] = selectedBy;
            }
        });
        this.unSelectedUserPresent = this.reviewAccept.userList.filter(ul => !ul['isSelected']).length > 0;
        this.checkDataForReviewer();
    }

    checkDataForReviewer() {
        if (this.reviewAccept.userList.filter(ul => !ul['isSelected']).length === 0) {
            if (this.showFirstReviewer && !this.firstReviewer.value) {
                if (this.showSecondReviewer && !this.secondReviewer.value) {
                    this.onClickTrashIcon('secondReviewer');
                    this.onClickTrashIcon('firstReviewer');
                } else if (this.showSecondReviewer && this.secondReviewer.value) {
                    this.firstReviewer.setValue(this.secondReviewer.value);
                    this.firstReviewer.value['isSelected'] = 'firstReviewer';
                    this.secondReviewer.setValue(null);
                    this.showSecondReviewer = false;
                } else {
                    this.onClickTrashIcon('firstReviewer');
                }
            } else if (this.showSecondReviewer && !this.secondReviewer.value) {
                this.onClickTrashIcon('secondReviewer');
            }
            if (this.showFirstSigner && !this.selectedUser.value) {
                if (this.showSecondSigner && !this.secondSigner.value) {
                    if (this.showThirdSigner && !this.thirdSigner.value) {
                        this.onClickTrashIcon('thirdSigner');
                        this.onClickTrashIcon('secondSigner');
                        this.onClickTrashIcon('selectedUser');
                    }
                    else if (this.showThirdSigner && this.thirdSigner.value) {
                        this.secondSigner.setValue(this.thirdSigner.value);
                        this.secondSigner.value['isSelected'] = 'secondSigner';
                        this.thirdSigner.setValue(null);
                        this.showThirdSigner = false;
                    }
                    else {
                        this.onClickTrashIcon('selectedUser');
                    }
                }
                else if (this.showSecondSigner && this.secondSigner.value) {
                    this.selectedUser.setValue(this.secondSigner.value);
                    this.selectedUser.value['isSelected'] = 'firstSigner';
                    this.secondSigner.setValue(null);
                    this.showSecondSigner = false;

                }
                else {
                    this.onClickTrashIcon('secondSigner');
                }

            }
            else if (this.showThirdSigner && !this.thirdSigner.value) {
                this.onClickTrashIcon('thirdSigner');
            }
            if (this.showCarbonCopy && (!this.customerCC.value || this.customerCC.value.length === 0)) {
                this.onClickTrashIcon('carbonCopy');
            }
        }
    }

    onClickTrashIcon(targetField: string, clearAllData?: boolean): void {
        switch (targetField) {
            case 'carbonCopy':
                if (this.customerCC.value && this.customerCC.value.length > 0) {
                    this.reviewAccept.userList.forEach(ul => {
                        this.customerCC.value.forEach(cl => {
                            if (cl['customerLeContactEmailid'] === ul['customerLeContactEmailid']) {
                                ul['isSelected'] = false;
                            }
                        });
                    });
                }
                this.customerCC.setValue(null);
                this.showCarbonCopy = false;
                break;
            case 'firstReviewer':
                if (this.firstReviewer.value) {
                    this.reviewAccept.userList.forEach(ul => {
                        if (ul['customerLeContactEmailid'] === this.firstReviewer.value['customerLeContactEmailid']) {
                            ul['isSelected'] = false;
                        }
                    });
                }
                if (this.showSecondReviewer && !clearAllData) {
                    this.firstReviewer.setValue(this.secondReviewer.value);
                    this.secondReviewer.setValue(null);
                    this.firstReviewer.value['isSelected'] = 'firstReviewer';
                    this.showSecondReviewer = false;
                } else {
                    this.showFirstReviewer = false;
                    this.firstReviewer.setValue(null);
                }
                break;
            case 'secondReviewer':
                if (this.secondReviewer.value) {
                    this.reviewAccept.userList.forEach(ul => {
                        if (ul['customerLeContactEmailid'] === this.secondReviewer.value['customerLeContactEmailid']) {
                            ul['isSelected'] = false;
                        }
                    });
                }
                this.showSecondReviewer = false;
                this.secondReviewer.setValue(null);
                break;
            case 'secondSigner':
                if (this.secondSigner.value) {
                    this.reviewAccept.userList.forEach(ul => {
                        if (ul['customerLeContactEmailid'] === this.secondSigner.value['customerLeContactEmailid']) {
                            ul['isSelected'] = false;
                        }
                    });
                }
                if (this.showThirdSigner && !clearAllData) {
                    this.secondSigner.setValue(this.thirdSigner.value);
                    this.thirdSigner.setValue(null);
                    this.secondSigner.value['isSelected'] = 'secondSigner';
                    this.showThirdSigner = false;
                } else {
                    this.showSecondSigner = false;
                    this.secondSigner.setValue(null);
                }
                break;
            case 'thirdSigner':
                if (this.thirdSigner.value) {
                    this.reviewAccept.userList.forEach(ul => {
                        if (ul['customerLeContactEmailid'] === this.thirdSigner.value['customerLeContactEmailid']) {
                            ul['isSelected'] = false;
                        }
                    });
                }
                this.showThirdSigner = false;
                this.thirdSigner.setValue(null);
                break;
            case 'selectedUser':
                if (this.selectedUser.value) {
                    this.reviewAccept.userList.forEach(ul => {
                        if (ul['customerLeContactEmailid'] === this.selectedUser.value['customerLeContactEmailid']) {
                            ul['isSelected'] = false;
                        }
                    });
                }
                if (this.showSecondSigner && !clearAllData) {
                    this.selectedUser.setValue(this.secondReviewer.value);
                    this.secondReviewer.setValue(null);
                    this.selectedUser.value['isSelected'] = 'firstSigner';
                    this.showSecondSigner = false;
                } else {
                    this.showFirstSigner = false;
                    this.selectedUser.setValue(null);
                }
                break;
        }
        this.unSelectedUserPresent = this.reviewAccept.userList.filter(ul => !ul['isSelected']).length > 0;
    }

    proceedNext() {
        /**Sending Cof data to oms service */
        var str = JSON.stringify(this.customizeCOFData());
        const cofParams = { 'cofPayload': window.btoa(unescape(encodeURIComponent(str))).toString(), 'refUuid': this.quoteObj.quoteId };


        /**Create order  */
        this.reviewAccept.enablePlaceOrder = false;
        let params = { quoteId: this.quoteObj.quoteId, checkList: this.reviewAccept.deployNAT };
        setTimeout(() => {
            this.openLoaderModal({ message: "Loading. please wait..." });
        });
        // this.service.postOnAgree(this.quoteObj.quoteId, params, (res) => {
        //     setTimeout(() => {
        //         this.dataSharedSvc.close();
        //     });
        //     if (res.data.orderId) {
        //         this.commonValidationService.removeQuoteLocalStorage();
        //         this.dataSharedSvc.setOrderObjInSession(JSON.stringify(res.data));
        //         this.router.navigateByUrl('/izomcc/orderForm/congratulations');

        //     }
        // }, (error) => {
        //     setTimeout(() => {
        //         this.dataSharedSvc.close();
        //     });
        // })
    }

    customizeCOFData() {
        /** for customizing terms and conditions based on selection */
        const temp = document.getElementById('termsandconditions');
        const cloneElement = temp.cloneNode(true) as HTMLDivElement;
        let removeElement;
        if (this.reviewAccept.deployNAT !== '' && cloneElement) {
            this.reviewAccept.deployNAT === 'Yes' ? removeElement = cloneElement.getElementsByClassName('deployedNATNo') : removeElement = cloneElement.getElementsByClassName('deployedNATYes');;
            if (removeElement.length) {
                removeElement[0].parentNode.removeChild(removeElement[0]);
                const removeRadio = cloneElement.getElementsByClassName('removeRadio');
                removeRadio[0].parentNode.removeChild(removeRadio[0]);
                removeRadio[0].parentNode.removeChild(removeRadio[0]);
            }
        }

        const params = {
            'moduleName': 'abc',
            'dataJson': [{
                'title': 'Solution And Configuration Details',
                'billing': this.reviewAccept.billing,
                'solution': this.reviewAccept.offerings,
                'cpe': this.reviewAccept.cpeData,
                'contractingInfo': this.reviewAccept.contractingInfo,
                'tandc': cloneElement.innerHTML,

                'contractPeriod': this.reviewAccept.contractPeriod ? this.reviewAccept.contractPeriod : '',
                'currencyType': this.reviewAccept.currencyType ? this.reviewAccept.currencyType : '',

                'totalAmount': this.reviewAccept.totalAmount,
                'burstableCharges': this.reviewAccept.burstableCharges,

                'billingAddress': this.reviewAccept.billingAddr,
                'quoteCode': this.quoteObj.quoteId,
                'customerIP': this.reviewAccept.publicIp
            }]
        };
        return params;
    }



    onClickSubmit(): void {
        this.reviewAccept.docuFlag = false;
        let nat = this.reviewAccept.deployNAT == '' || this.reviewAccept.deployNAT == 'No' ? false : true;
        this.reviewAccept.docuFlag = true;
        let customerSigners = []
        let ccEmails = []
        let approvers = []
        let primeSigner;
        if (this.customerCC.value && this.customerCC.value.length > 0) {
            this.customerCC.value.forEach(val => {
                const obj = {
                    name: val.customerLeContactName,
                    email: val.customerLeContactEmailid
                };
                ccEmails.push(obj);
            });
        }
        if (this.selectedUser.value && !this.secondSigner.value && !this.thirdSigner.value) {
            primeSigner = this.selectedUser.value;
        } else if (this.selectedUser.value && this.secondSigner.value && !this.thirdSigner.value) {
            primeSigner = this.secondSigner.value;
            const obj = {
                name: this.selectedUser.value.customerLeContactName,
                email: this.selectedUser.value.customerLeContactEmailid
            }
            customerSigners.push(obj);
        } else if (this.selectedUser.value && this.secondSigner.value && this.thirdSigner.value) {
            primeSigner = this.thirdSigner.value;
            const obj1 = {
                name: this.selectedUser.value.customerLeContactName,
                email: this.selectedUser.value.customerLeContactEmailid
            }
            const obj2 = {
                name: this.secondSigner.value.customerLeContactName,
                email: this.secondSigner.value.customerLeContactEmailid
            }
            customerSigners.push(obj1, obj2);
        }


        if (this.firstReviewer.value) {
            const obj = {
                name: this.firstReviewer.value.customerLeContactName,
                email: this.firstReviewer.value.customerLeContactEmailid
            }
            approvers.push(obj);
        }
        if (this.secondReviewer.value) {
            const obj = {
                name: this.secondReviewer.value.customerLeContactName,
                email: this.secondReviewer.value.customerLeContactEmailid
            }
            approvers.push(obj);
        }
        customerSigners = customerSigners.length > 0 ? customerSigners : undefined;
        ccEmails = ccEmails.length > 0 ? ccEmails : undefined;
        approvers = approvers.length > 0 ? approvers : undefined;
        const params = {
            customerSigners: customerSigners,
            approvers: approvers,
            ccEmails: ccEmails
        }
        // this.service.getDocusign(response => {
        //     this.reviewAccept.mailConfirmationModalRef.hide();
        //     this.openMessageModal({ message: 'successfuly send !' });
        //     setTimeout(() => {

        //         window.open(window.location.origin + '/optimus/products', "_blank");
        //     }, 1000);
        // }, (error) => {
        //     if (error.error.responseCode == '500' && error.error.message == 'macd.already.initiated.for.a.circuit.in.this.quote') {
        //         this.openMessageModal({ message: 'Macd already initiated for a circuit in this quote' });
        //     } else {
        //         this.openMessageModal({ message: 'DocuSign already initiated' });
        //     }
        // },
        //     this.quoteObj.quoteId, this.quoteObj.quoteleId, nat,
        //     primeSigner.customerLeContactEmailid, primeSigner.customerLeContactName, params);
    }

    /**send delegation name to backend to trigger notigication api and static data will be replaced once OMS ready*/
    submitDelegation() {

        if (this.reviewAccept.selectedDelegatePerson.length) {
            let params = {
                'emailId': this.reviewAccept.selectedDelegatePerson[0].emailId,
                'quoteId': this.quoteObj.quoteId,
                'quoteToLeId': this.quoteObj.quoteleId,
            };
            // this.service.postDelegates(this.quoteObj.quoteId, this.quoteLeId, params, (successData) => {
            //     this.reviewAccept.postResponse = successData.data;
            //     this.openMessageModal({ message: 'successfuly sent !' });
            //     setTimeout(() => {
            //         // this.router.navigate(['/']);
            //         window.open(window.location.origin + '/optimus/products', "_self");
            //     }, 1000);
            // }, (errorData) => {
            //     alert('Failed To Sent !')

            // })

        }
    }

    updateStage() {
        // this.service.updateQuoteStage(this.quoteObj.quoteId, this.quoteLeId, this.payloadStage, (res) => {

        // }, (error) => {

        // })
    }

    showedge(configs) {
        return configs.components.some(item => item['name'] == 'Edge Device')
    }

    showcloud(configs) {
        return configs.components.some(item => item['name'] == 'Cloud')
    }

    showconnect(configs) {
        return configs.components.some(item => item['name'] == 'Edge Connectivity')
    }

    getDocs(obj) {
        //console.log(obj)
        // this.service.getAttachment(obj.id, this.reviewAccept.customerLegalEntityId, (res) => {
        //     //console.log(res)
        //     if (res && res.data) {
        //         this.service.getCOFPdf(res.data, (sucessData) => {
        //             this.downloadAttachments(sucessData, obj)
        //         }, (error) => {

        //         })
        //     } else {
        //         this.downloadAttachments(res, obj)
        //     }
        // }, (error) => {

        // })
    }


    downloadAttachments(attachmentData, element): void {
        const blob = attachmentData;
        const binaryData = [];
        binaryData.push(blob);
        element.url = window.URL.createObjectURL(new Blob(binaryData, { type: "application/pdf" }));
    }

    validateCOF(val) {
        if (this.creditCheck.creditCheckStatusFlag) {
            return;
        }
        // this.service.validateCofDataIzomcc(this.quoteObj.quoteId, this.quoteObj.quoteleId, response => {
        //     if (response.data.status) {
        //         if (val == 'docusign') {
        //             this.confirmSentMail('docusign');
        //         } else if (val == 'delegate') {
        //             this.delegateChange();
        //         } else if (val == 'Place Order') {
        //             this.openModal(this.confirmOrderModal, 'modal-sm');
        //         }
        //     } else {
        //         this.openMessageModal({ message: response.data.validationMessage });
        //     }
        // }, error => { });
    }
    postGenerateOrderForm() {
        this.openModal(this.deployOrderTemplate, 'modal-sm');
    }

    submit(){
        this.router.navigateByUrl('/thankyou');
    }
    submitDepolyOrder() {
        this.openLoaderModal({ message: "Loading. please wait..." });
        this._scp_izomcc_service.postGenerateOrderForm((success) => {
            this.dataSharedSvc.setOrderObjInSession(JSON.stringify(success.data));
            this.dataSharedSvc.close();
            this.router.navigateByUrl('/thankyou');
        }, (error) => {
            this.dataSharedSvc.close();
            this.openSnackBar("Internal server error, Try again later");
            this.clicked = false;
        });
    }

    openSnackBar(msg) {
        this._snackBar.open(msg, 'OK', {
            panelClass: ['warning-snackbar'],
            verticalPosition: 'bottom',
            horizontalPosition: 'center',
        });
    }

    saleSelectionChanged(value) {
        //console.log(value);
        this.reviewAccept.placeOrder = value;
        //this.reviewAccept.electSign = false;
        this.checkTermsAndConditions();
    }

        // Checking NAT is selected or not in terms and conditions
        checkNATSelected(template) {
            if (this.reviewAccept.deployNAT === "") {
                this.messageModalSettings.open({ message: "Please read Terms & Conditons and Select appropriate NAT solution details" })
                return;
            }
            this.openModal(template, 'modal-sm');
        }

        refreshCreditCheck() {
            this.openLoaderModal({ message: "Loading. please wait..." });
                // this.service.postnewCreditCheckStatus(this.quoteObj.quoteId, this.quoteObj.quoteleId, (creditData) => {

                //     this.dataSharedSvc.close();
                //     this.creditCheck.creditCheckStatus = (creditData.data[0].creditCheckStatus == 'Positive' && creditData.data[0].preapprovedOpportunityFlag) ? 'Positive' : creditData.data[0].creditCheckStatus;

                //     this.creditCheck.creditCheckStatus != 'Positive' ? this.creditCheck.creditCheckStatusFlag = true : this.creditCheck.creditCheckStatusFlag = false;
                //     if (this.creditCheck.creditCheckStatus) this.setCreditMessage(this.creditCheck.creditCheckStatus)
                // }, (errorData) => {
                //     this.dataSharedSvc.close();
                //     this.creditCheck.creditCheckStatusFlag = true;
                //     if (this.reviewAccept.salesLogin) {
                //         this.setCreditErrorMessage(errorData.error.message)
                //     } else {
                //         this.creditCheck.creditCheckError = "Credit Evaluation is in Process."
                //     }

                // });

        }

}
