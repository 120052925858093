import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd, RoutesRecognized } from '@angular/router';
import { IzoWidgetLabels } from '../step-widget/izo-widget-labels.constant';

@Component({
    selector: 'app-stepwidget',
    templateUrl: './step-widget.component.html'
})
export class StepWidgetComponent implements OnInit {
    @Input('showWidget') showWidget: any;
    componentLabels = [];
    activeIndex: number;
    activateClass: number;
    isMulticircuit = sessionStorage.getItem("multi-circuit");

    constructor(private router: Router, private activatedRoute: ActivatedRoute, ) { }

    ngOnInit(): void {
        this.router.events.subscribe(data => {
            //console.log(data)
            if (data instanceof NavigationEnd) {
                //console.log(data.url)
                if (data.url.includes('selectconfiguration')) {
                    this.componentLabels = IzoWidgetLabels;
                }else if(data.url.includes('reviewConfig')){
                    this.componentLabels = IzoWidgetLabels;
                }else if(data.url.includes('reviewaccept')){
                    this.componentLabels = IzoWidgetLabels;
                }else if(data.url.includes('salesorder')){
                    this.componentLabels = IzoWidgetLabels;
                }else if(data.url.includes('checkout')){
                    this.componentLabels = IzoWidgetLabels;
                }else if(data.url.includes('congratulations')){
                    this.componentLabels = IzoWidgetLabels;
                }else if(data.url.includes('solutiondetailsoms')){
                    this.componentLabels = IzoWidgetLabels; 
                }else if(data.url.includes('solutiondetails')){
                    this.componentLabels = IzoWidgetLabels;
                }else if(data.url.includes('congratulations')){
                    this.componentLabels = IzoWidgetLabels;
                }
                
                this.activeIndex = undefined;
                this.activateClass = undefined;
                const stage = this.getStageName(this.activatedRoute.snapshot);
                for (let i = 0; i < this.componentLabels.length; i++) {
                    //console.log(this.componentLabels[i].SubCategory)
                    if (this.componentLabels[i].Name.indexOf(stage) > -1) {
                        this.activeIndex = i;
                        //console.log(this.activeIndex)
                    }
                    //console.log(this.componentLabels[i].SubCategory.indexOf(stage))
                    if (this.componentLabels[i].SubCategory.indexOf(stage) > -1) {
                      
                        //console.log(this.componentLabels[i].SubCategory.indexOf(stage))
                        this.activateClass = this.componentLabels[i].SubCategory.indexOf(stage);
                        this.activeIndex = i;
                    }
                }
            }
        })
    }
    getStageName(snapshot) {
     
        if (snapshot.data && snapshot.data['stage']) {
            
            return snapshot.data['stage'];
            
        } else if (snapshot && snapshot.children && snapshot.children.length > 0) {
            //console.log(this.getStageName(snapshot.children[0]))
            return this.getStageName(snapshot.children[0]);
        } else {
            //console.log('null')
            return null;
        }
    }

}


