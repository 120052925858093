<ng-template #messageModal>
    <div class="service-details-modal" id="" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="" role="document">
            <div class="modal-content">
                <div class="modal-header d-flex justify-content-between align-items-center flex-row">
                    <h5 class="modal-title">Message</h5>
                    <button type="button"  *ngIf="!msgModalData.isDisableClose" (click)="closeModal()" class="close" data-dismiss="modal" aria-label="Close">
          <i class="icon-close" ></i>
        </button>
                </div>
                <div class="modal-body">

					<!-- <h4>{{msgModalData.message}}</h4> -->
					<h4 [innerHTML]="msgModalData.message"></h4>

                </div>
                <div class="modal-footer">
                    <button type="button" (click)="closeModal()" class="close" data-dismiss="modal" class="btn active-btn">Close</button>
                </div>
            </div>
        </div>
    </div>
</ng-template>