// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {

    production: true,
    nodeUrl: "http://10.133.232.102" ,
    devUrl: "http://10.133.232.102",
    isO2CSplitEnabled: true,
    quoteService: '/optimus-quote',
    htmlPdfService: '/optimus',
    omsService: '/optimus-oms',
    wholesaleService: '/optimus-oms-wholesale',
    customerService: '/optimus-customer',
    locationService: '/optimus-location',
    notificationService: '/optimus-notification',
    quoteUrl: '/optimus-quote',
    nodeOptimus: '/optimus-pdf',
    solutionUrl: '/optimus-oms',
    location: '/optimus-location',
    audit: '/optimus-audit',
    configserviceUrl: '/optimus-oms',
    omsProduct: '/optimus-product',
    optimusService: '/optimus-service',
    serviceInventory: '/optimus-serviceinventory',
    userManagement: '/usermanagement',
    // keycloakRootUrl: 'https://10.133.208.101' + '/auth/',
    //keycloakRootUrl: window.location.origin + '/auth/',
    keycloakRootUrl: 'https://optimus-dev.tatacommunications.com' + '/auth/',
    //keycloakRootUrl: window.location.origin + '/auth/',
    userManagementService: '/authentication',
    ticket: '/optimus-ticketing',
    billing: '/optimus-billing',
    reports: '/optimus-pm',
    serviceFullfillment: '/optimus-servicefulfillment',
    prepareFulfillment: '/optimus-preparefulfillment',
    l2oworkflow: '/optimus-l2oworkflow',
    serviceActivation: '/optimus-serviceactivation',
    serviceHandOver: '/optimus-servicehandover',
    swiftApi: "/",
    objectStorageEnabled: true,
    mapApiKey: 'AIzaSyABsK46nzZQTt5N_zEM6vRONgpwYZypdhA',
    isO2CEnabled: true,
    isO2CEnabledForGVPN: false,
    nsUrl: '/optimus-oetui/',
    O2CFlags: { 'IAS': true, 'GVPN': true, 'NPL': true, 'GSIP': true, 'UCAAS': true, 'IZOPC': true, 'Microsoft Cloud Solutions': true, 'NDE': true },
    networkRoutingViewUrl: 'http://uswv1vpap016b:8084/static/NetworkRoutingView0.0.0.6/index.html?objectName=',
    netScopeUrl: 'http://netscopeuat/',
    mapUrl: 'https://optimus-uat.tatacommunications.com/3dmaps',
    analyticalReportRoutingUrl: 'https://14.142.245.153/',
    ipcCloudRoutingUrl: 'https://ipcloud.tatacommunications.com/catalyst-v4/#/',
    ipcStgCloudRoutingUrl: 'https://stg.opstic.tatacommunications.com/catalyst-v4/#/',
    pmsReportRoutingUrl: 'https://voiceperformancemanagement.tatacommunications.com/vizportal/api/saml?authSetting=SAML&embedded=false&idp=https%3A%2F%2Fvoiceperformancemanagement.tatacommunications.com%2Fsamlservice%2Fpublic%2Fidp%2Fmetadata&sendPodInfo=false&siteLuid=61e6bb6b-099e-4291-ad5e-edfa8c434451&target_site=Otimus_1&dest=%2F',
    redirectFAQ: 'http://inp44vkms/kb/',
    testUrl: '',
    olioUrl: window.location.origin + '/optimus-olio-ui/',
    nectarRoutingURL: `${window.location.origin}/auth/realms/master/protocol/saml/clients/nector_sso`,
    inventoryOlioUrl: window.location.origin + '/optimus-olio-ui/manage-sdwan-service',
    sdwanOmsService: '/optimus-sdwanoms',
    smartVoiceUrl: window.location.origin + "/auth/",
    fortilizerRoutingURL: `${window.location.origin}/auth/realms/master/protocol/saml/clients/https://115.112.163.221/saml/?acs`,
    omsmwieProduct: '/optimus-mwieoms/api/v1',
    base_path: '/optimus'

};
