import { Injectable, Injector } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { environment } from '../environments/environment';
import { Router } from '@angular/router';

@Injectable()
export class DataSharedSvc {
    // Helper property to resolve the service dependency.
    private get router() { return this._injector.get(Router); }

    primaryProfileInfo: any;
    constructor(
        // private router: Router
        private _injector: Injector
    ) { }

    open: any;
    close: any;
    navMenuOpenStatus: any;

    private dataSource = new BehaviorSubject<object>({});
    private customerList = new BehaviorSubject<object>({});
    private tempCustomerEntity = new BehaviorSubject<object>({});
    private sdwanSiteList = new BehaviorSubject<object>({});
    private sdwanCpeList = new BehaviorSubject<object>({});
    private sdwanSiteCpeCount = new BehaviorSubject<object>({});
    sharedData = this.dataSource.asObservable();
    private invoiceFilter = new BehaviorSubject<any>('');
    private incidentStatus = new BehaviorSubject<any>('');
    customerChangeSubject = new BehaviorSubject<any>({});
    customerChangeobj = new BehaviorSubject<any>({});
    messageSubject = new Subject();
    messageSubcription = this.messageSubject.asObservable();
    taskHelpDesk = new BehaviorSubject<any>(false);
    pendingTask = new BehaviorSubject<any>(false);
    //related to task module
    postData = new BehaviorSubject<any>(false);
    downGradeOrder = new BehaviorSubject<any>('');

    //Ends here
    browserCheck: boolean = false;
    selectedVoiceService: string = '';
    selectedUcaasService = '';
    creditStatus: any = '';
    gvpnStageForVoice: string = '';
    selectedtab = '';
    listOfActions: any;
    taskStatus: string = '';
    selectedFiter;
    selectedFiterValue;
    selecteMplsValue;
    selectedServiceIds: Array<any> = [];

    hasNavigatedBackToSalesOrderFromReviewAccept = false;
    departmentBillingDetailsObj = new BehaviorSubject<any>(null);

    changeData(obj) {
        this.dataSource.next(obj.data);
    }
    setBrowserAccess(flag) {
        this.browserCheck = flag;
    }
    getBrowserAccess() {
        return this.browserCheck;
    }
    /**for voice service */
    setSelectedVoiceService(service) {
        this.selectedVoiceService = service;
    }
    setSelectedUcaasService(service) {
        this.selectedUcaasService = service;
    }

    getSelectedVoiceService() {
        return this.selectedVoiceService;
    }

    setCreditStatus(data) {
        this.creditStatus = data;
    }

    getCreditStatus() {
        return this.creditStatus;
    }

    setGvpnStageForVoice(stage) {
        this.gvpnStageForVoice = stage;
    }

    getPreservedGvpnStage() {
        return this.gvpnStageForVoice;
    }

    setListOfActions(actions: Array<any>) {
        this.listOfActions = actions;
    }

    getListOfActions() {
        return this.listOfActions;
    }
    /** voice service ends here*/

    /** dashboard to component filters starts here */
    /**** Recent Invoice widget to billing  ****/
    getInvoiceFilter(): Observable<any> {
        return this.invoiceFilter.asObservable();
    }
    setInvoiceFilter(invoice) {
        this.invoiceFilter.next(invoice)
    }
    /**** Recent Invoice widget to billing  ****/
    /** dashboard to component filters ends here */
    setSelectedTab(tabname) {
        this.selectedtab = tabname;
    }
    getSelectedTab() {
        return this.selectedtab;
    }
    setFilterValue(fiterType, filterValue) {
        this.selectedFiter = fiterType;
        this.selectedFiterValue = filterValue;
    }
    clearValue() {

        this.selectedFiter = ''
        this.selectedFiterValue = ''
    }
    getFilterValue() {
        var result = [this.selectedFiter, this.selectedFiterValue]
        return result;
    }
    groupName;
    setGroupName(value) {
        this.groupName = value
    }
    getGroupName() {
        return this.groupName
    }

    mainPageAdjustToMenu() {
        var elem = document.querySelector('main');
        if (elem && this.navMenuOpenStatus) {
            elem.classList.add('togglemain');
        } else if (elem) {
            elem.classList.remove('togglemain');
        }
        // return true;
    }

    setStatus(status) {
        this.taskStatus = status;
    }

    getStatus() {
        return this.taskStatus;
    }

    setServiceIDs(serviceIds) {
        this.selectedServiceIds = serviceIds
    }

    getServiceIDs() {
        return this.selectedServiceIds;
    }

    /**** sessionStorage starts ****/

    getSessionKey(key) {
        if (key in sessionStorage) {
            return sessionStorage[key];
        }
        return null;
    }

    // customer
    setCustomerInSession(value) {
        sessionStorage.setItem('customer', value);
    }
    setCustomerLeInSession(value) {
        sessionStorage.setItem('customerLe', value);
    }
    setProductForPartnerInSession(value) {
        sessionStorage.setItem('isTeamsDRPartner', value);
    }

    setSecsInSession(value) {
        let customerObj = JSON.parse(sessionStorage.getItem('customer'));
        customerObj.secs = value
        sessionStorage.setItem('customer', JSON.stringify(customerObj));
    }

    setCustomerLeCountry(value) {
        let customerObj = JSON.parse(sessionStorage.getItem('customer'));
        customerObj.country = value;
        sessionStorage.setItem('customer', JSON.stringify(customerObj));
    }

    setDepartmentBillingObjInSession(value) {
        sessionStorage.setItem('departmentBillingConfigByService', JSON.stringify(value));
    }

    getCustomerFromSession() {
        return this.getSessionKey('customer');
    }
    getCustomerLeFromSession() {
        return this.getSessionKey('customerLe');
    }
    getSecsFromSession() {
        let customerObj = JSON.parse(sessionStorage.getItem('customer'))
        return customerObj.secs;
    }

    getDepartmentBillingObjFromSession() {
        return this.getSessionKey('departmentBillingConfigByService');
    }

    removeCustomerFromSession() {
        sessionStorage.removeItem('customer');
    }

    removeUcaasFromSession() {
        sessionStorage.removeItem('ucassObj');
    }

    removeDepartmentBillingObjFromSession() {
        sessionStorage.removeItem('departmentBillingConfigByService');
    }

    // quoteObj
    setQuoteObjInSession(value) {
        sessionStorage.setItem('quoteObj', value);
    }

    getQuoteObjFromSession() {
        return this.getSessionKey('quoteObj');
    }

    removeQuoteObjFromSession() {
        sessionStorage.removeItem('quoteObj');
    }

    // orderObj
    setOrderObjInSession(value) {
        sessionStorage.setItem('orderObj', value);
    }

    getOrderObjFromSession() {
        return this.getSessionKey('orderObj');
    }

    removeOrderObjFromSession() {
        sessionStorage.removeItem('orderObj');
    }

    // partner
    setPartnerInSession(value) {
        sessionStorage.setItem('partner', value);
    }

    getPartnerFromSession() {
        return this.getSessionKey('partner');
    }

    // VOLUME_ESTIMATION
    setVolumeInSession(value) {
        sessionStorage.setItem('VOLUME_ESTIMATION', value);
    }

    getVolumeFromSession() {
        return this.getSessionKey('VOLUME_ESTIMATION');
    }

    // ORDER_OBJ
    setOrder_ObjInSession(value) {
        sessionStorage.setItem('ORDER_OBJ', value);
    }

    getOrder_ObjFromSession() {
        return this.getSessionKey('ORDER_OBJ');
    }

    // QUOTE_OBJ
    setQuote_ObjInSession(value) {
        sessionStorage.setItem('QUOTE_OBJ', value);
    }

    getQuote_ObjFromSession() {
        return this.getSessionKey('QUOTE_OBJ');
    }

    //entityId
    setEntityIdInSession(value) {
        sessionStorage.setItem('entityId', value);
    }

    getEntityIdFromSession() {
        return this.getSessionKey('entityId');
    }

    //engagementOptyId
    setEngagementOptyIdInSession(value) {
        sessionStorage.setItem('engagementOptyId', value);
    }

    getEngagementOptyIdFromSession() {
        return this.getSessionKey('engagementOptyId');
    }

    //opportunityCode
    setOpportunityCodeInSession(value) {
        sessionStorage.setItem('opportunityCode', value);
    }

    getOpportunityCodeFromSession() {
        return this.getSessionKey('opportunityCode');
    }

    //quoteType
    setquoteTypeInSession(value) {
        sessionStorage.setItem('QuoteType', value);
    }

    getquoteTypeFromSession() {
        return this.getSessionKey('QuoteType');
    }

    //opportunityId
    setOpportunityIdInSession(value) {
        sessionStorage.setItem('opportunityId', value);
    }

    getOpportunityIdFromSession() {
        return this.getSessionKey('opportunityId');
    }

    //serviceMatrix
    setTeamsDrPartnerServiceMatrixInSession(value) {
        sessionStorage.setItem('TeamsDrPartnerServiceMatrix', value);
    }

    getTeamsDrPartnerServiceMatrixFromSession() {
        return this.getSessionKey('TeamsDrPartnerServiceMatrix');
    }

    //classification
    setClassificationInSession(value) {
        sessionStorage.setItem('classification', value);
    }

    getClassificationFromSession() {
        return this.getSessionKey('classification');
    }

    // impersonateUser
    setImpersonateUserInSession(value) {
        sessionStorage.setItem('impersonateUser', value);
    }

    getImpersonateUserFromSession() {
        return this.getSessionKey('impersonateUser');
    }

    // currentGVPNStatus
    setCurrentGVPNStatusInSession(value) {
        sessionStorage.setItem('currentGVPNStatus', value);
    }

    getCurrentGVPNStatusFromSession() {
        return this.getSessionKey('currentGVPNStatus');
    }

    removeCurrentGVPNStatusFromSession() {
        sessionStorage.removeItem('currentGVPNStatus');
    }

    //QUOTE_OBJECT
    setQuote_ObjectInSession(value) {
        sessionStorage.setItem('QUOTE_OBJECT', value);
    }

    getQuote_ObjectFromSession() {
        return this.getSessionKey('QUOTE_OBJECT');
    }

    removeQuote_ObjectFromSession() {
        sessionStorage.removeItem('QUOTE_OBJECT');
    }

    //CUSTOMER_OBJECT
    getCustomer_ObjectFromSession() {
        return this.getSessionKey('CUSTOMER_OBJECT');
    }

    //serviceSelected
    getServiceSelectedFromSession() {
        return this.getSessionKey('serviceSelected');
    }

    removeServiceSelectedFromSession() {
        sessionStorage.removeItem('serviceSelected');
    }

    //interServerError
    getInterServerErrorFromSession() {
        return this.getSessionKey('interServerError');
    }

    //currencyType
    setCurrencyTypeInSession(value) {
        sessionStorage.setItem('currencyType', value);
    }

    getCurrencyTypeStatusFromSession() {
        return this.getSessionKey('currencyType');
    }

    //multi-circuit - bulk update type
    setBulkUpdateType(value) {
        sessionStorage.setItem('bulkUpdateType', value);
    }

    getBulkUpdateType() {
        return this.getSessionKey('bulkUpdateType');
    }

    // PartnerLeId
    setPartnerLeIdInSession(value) {
        sessionStorage.setItem('partnerLeId', value);
    }

    getPartnerLeIdFromSession() {
        return this.getSessionKey('partnerLeId');
    }

    /**** sessionStorage ends ****/


    /*********For Macd - Select-configuration */


    setProfileInfo(data) {
        this.primaryProfileInfo = data;
    }

    getProfileInfo() {
        return this.primaryProfileInfo;
    }
    setMplsAttributeValue(value, profile) {
        this.selecteMplsValue = { concurrentValue: value, profileName: profile }
    }
    getMplsAttributeValue() {
        return this.selecteMplsValue
    }

    setSdwanCustomerLeInSession(value) {
        sessionStorage.setItem('sdwanCustomerLe', value);
    }

    getSdwanCustomerLeFromSession() {
        return this.getSessionKey('sdwanCustomerLe');
    }

    setSdwanCustomerInSession(value) {
        sessionStorage.setItem('sdwanCustomer', value);
    }

    getSdwanCustomerFromSession() {
        return this.getSessionKey('sdwanCustomer');
    }
    getCustomerList(): Observable<any> {
        return this.customerList.asObservable();
    }

    setCustomerList(data) {
        this.customerList.next(data);
    }

    gettempCustomerEntity(): Observable<any> {
        return this.tempCustomerEntity.asObservable();
    }

    settempCustomerEntity(data) {
        this.tempCustomerEntity.next(data);
    }

    getDownGradeOrder(): Observable<any> {
        return this.downGradeOrder.asObservable();
    }

    setDownGradeOrder(data) {
        this.downGradeOrder.next(data);
    }


    /*********Ends here */

    removeVolumeEstimate() {
        sessionStorage.removeItem('volumeEstimated');
    }
    /*****Task Routes related work */

    routeToTaskModule(url) {
        if (environment.isO2CSplitEnabled) {
            window.location.href = environment.devUrl + '/optimus-o2c' + url
        } else {
            this.router.navigateByUrl(url);
        }
    }

    setServiceDetailResponseInSession(value) {
        sessionStorage.setItem('serviceDetailApiResponse', value);
    }

    getServiceDetailResponseFromSession() {
        return this.getSessionKey('serviceDetailApiResponse');
    }

    setSitePerformanceDetailsInSession(value) {
        sessionStorage.setItem('sitePerformanceDetails', value);
    }

    getSitePerformanceDetailsFromSession() {
        return this.getSessionKey('sitePerformanceDetails');
    }

    getSdwanSiteList(): Observable<any> {
        return this.sdwanSiteList.asObservable();
    }

    setSdwanSiteList(data) {
        this.sdwanSiteList.next(data);
    }

    getSdwanCpeList(): Observable<any> {
        return this.sdwanCpeList.asObservable();
    }

    setSdwanCpeList(data) {
        this.sdwanCpeList.next(data);
    }

    getSdwanSiteCpeCount(): Observable<any> {
        return this.sdwanSiteCpeCount.asObservable();
    }

    setSdwanSiteCpeCount(data) {
        this.sdwanSiteCpeCount.next(data);
    }

    // productStats
    setProductStatsInSession(value) {
        sessionStorage.setItem('productStats', JSON.stringify(value))
    }

    getProductStatsFromSession() {
        return this.getSessionKey('productStats');
    }

    removeProductStatsFromSession() {
        sessionStorage.removeItem('productStats');
    }

    //location
    setLocationInSession(value) {
        sessionStorage.setItem('location', value);
    }

    getLocationInSession() {
        return this.getSessionKey('location');
    }

    removeLocationInSession() {
        sessionStorage.removeItem('location');
    }
    removeCustomerLeFromSession(){
        sessionStorage.removeItem('customerLe');
    }
}
