<!-- main container -->
<main>
  <section
    *ngIf="showSalesOrder"
    style="margin: 0px 51px; color: black !important"
  >
    <section class="container-header">
      <div class="row">
        <div class="col-md-9 align-self-start">
          <h3 style="color: white">
            Please provide input to generate your contract documents
            <a href="#" class="back-arrow" [routerLink]="['/getquote']">
              <i class="icon icon-back"></i>
            </a>
          </h3>
          <p style="color: #ffffff">
            Please use this section to verify and update the contracting
            information.
          </p>
        </div>

        <div class="col-md-3 align-self-end text-right generate-docs">
          <!-- izo-ne validate and remove -->
          <button
            type="button"
            (click)="proceedToNextPartnerCheck(template)"
            tourAnchor="tour-8"
            style="width: 160px"
            class="btn active-btn modal-slider"
          >
            GENERATE ORDER FORM
          </button>
          <span></span>
        </div>
      </div>
    </section>
    <section>
      <div class="container-header">
        <div class="card custom-card">
          <div class="card-header">Customer Contracting Entity</div>
          <div class="card-body">
            <div class="card shadow-card">
              <div class="card-body">
                <div class="row entityrow">
                  <div class="col-md-12">
                    <h4 class="mb-4 gmfont">
                      Select number of legal entities for this order
                    </h4>
                    <div class="row" tourAnchor="tour-1">
                      <div class="col-md-6">
                        <label class="radio-box small-font">
                          <input
                            type="radio"
                            name="entitynumber"
                            value="single"
                            [(ngModel)]="salesOrderParams.numberOfEntity"
                            [disabled]="isDocusign"
                          />
                          <span class="checkmark"></span>
                          <span>Single</span>
                        </label>
                      </div>
                      <div class="col-md-6">
                        <label class="radio-box small-font">
                          <input
                            type="radio"
                            name="entitynumber"
                            value="mulitple"
                            [(ngModel)]="salesOrderParams.numberOfEntity"
                            (click)="openModal(multipledocs, 'modal-sm')"
                            [disabled]="isDocusign"
                          />
                          <span class="checkmark"></span>
                          <span>Multiple</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- OPT-660 Le owner page revamp New Template changes -->
            <div class="card shadow-card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <h4 class="mb-3">Contracting Legal Entity Information</h4>
                  </div>
                </div>
                <div class="row mb-2">
                  <div class="col-12">
                    <label class="userEdit col-form-label gmfont"
                      >Name
                      <a
                        class="pointer"
                        tooltip="Please contact customer support through Help section, if you don't find your desired legal entity in the list"
                      >
                        <span class="icon icon-info"></span>
                      </a>
                    </label>

                    <p
                      class="gbfont"
                      *ngIf="commonProductName === 'IZOMCC'; else parnterLeName"
                    >
                      {{ salesOrderParams.entityName }}
                    </p>

                    <ng-template #parnterLeName>
                      <p>{{ partnerGVPNOppIdDetails?.data.customerLeName }}</p>
                    </ng-template>
                  </div>
                </div>

                <div
                  class="row mb-3"
                  *ngIf="
                    commonProductName === 'IZO SDWAN' && isTaxDocumentRequired
                  "
                >
                  <div class="col-md-12">
                    <span
                      class="mb-3"
                      style="font-size: 12px; font-family: 'Gotham-Medium'"
                      >Upload approval document
                      <a
                        class="pointer"
                        tooltip="Only .pdf files up to 20mb can be uploaded"
                      >
                        <span class="icon icon-info"></span>
                      </a>
                    </span>
                    <span
                      class="download-file gotham-book"
                      (click)="downloadTaxFile()"
                      *ngIf="salesOrderData.uploadSuccess"
                    >
                      <a>
                        <b>Download</b>
                        <i class="icon-download ml-1" style="padding: 5px"></i>
                      </a>
                    </span>
                    <div class="row upload-files">
                      <div class="upload-file col-md-8">
                        <label
                          class="ct-input-file row"
                          style="border-bottom: 1px solid lightgray"
                        >
                          <div class="col-md-9 gotham-book">Browse</div>
                          <input
                            id="uploadExcelInput"
                            type="file"
                            name=""
                            (change)="onChangeUploadTemplate($event)"
                          />
                          <i
                            class="icon-upload col-md-2"
                            style="padding-left: 14%"
                          ></i>
                        </label>
                        <span style="font-family: gotham-book">{{
                          uploadDetails.selectedFile?.fileName
                        }}</span>
                      </div>
                      <div class="col-md-4" style="text-align: right">
                        <span
                          *ngIf="uploadDetails.uploadSpinner"
                          class="upload-spin"
                          [optimusSpinner]="{ faSize: 'fa-lg' }"
                        ></span>
                        <button
                          type="button"
                          class="btn active-btn"
                          style="float: right"
                          [disabled]="uploadDetails.uploadSpinner"
                          (click)="onClickUpload()"
                        >
                          Upload File
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row mb-3" *ngIf="commonProductName !== 'IZO SDWAN'">
                  <div class="col-md-12 form-group">
                    <label class="userEdit col-form-label gmfont"
                      >Address</label
                    >
                    <div class="row">
                      <div class="col dropright" dropdown>
                        <div
                          class="form-control select small-font"
                          id="dropdownMenuButton"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <input
                            tourAnchor="tour-2"
                            dropdownToggle
                            type="text"
                            (click)="searchTextObj.contractingAddress = ''"
                            placeholder="Please select Contracting Address"
                            [(ngModel)]="selectedCustomerContractingAddress"
                            class="legatentityClass"
                            data-matomo-unmask
                            readonly
                          />
                        </div>
                        <div
                          *dropdownMenu
                          class="dropdown-menu radiobtn-list-dp"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div class="row">
                            <div class="col">
                              <input
                                type="text"
                                class="le-search"
                                placeholder="Search contracting address"
                                (click)="onSearchClick($event)"
                                [(ngModel)]="searchTextObj.contractingAddress"
                                data-matomo-unmask
                                autocomplete="off"
                              />
                              <i
                                class="fa fa-search le-search-icon"
                                aria-hidden="true"
                              ></i>
                              <ul
                                class="radiobtn-list mCustomScrollbar"
                                style="width: 370px"
                              >
                                <li
                                  *ngFor="
                                    let contractingAddress of customerContractingAddressList
                                      | filter
                                        : searchTextObj.contractingAddress
                                        : 'add'
                                        : true;
                                    let i = index
                                  "
                                >
                                  <label class="radio-box small-font">
                                    <input
                                      type="radio"
                                      name="address"
                                      [(ngModel)]="
                                        selectedCustomerContractingAddress
                                      "
                                      [value]="contractingAddress.add"
                                      (ngModelChange)="
                                        onSelectOfContractingAddress(
                                          contractingAddress
                                        )
                                      "
                                      checked
                                      readonly
                                    />
                                    <span class="checkmark"></span>
                                    <span>{{ contractingAddress.add }}</span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="row mb-3">
                  <div class="col-12">
                    <h4 class="mb-2">Contact Details</h4>
                  </div>
                  <div class="col-6">
                    <label class="userEdit col-form-label gmfont"
                      >Email ID</label
                    >
                    <div class="row">
                      <div class="col-12 form-group dropright" dropdown>
                        <div
                          class="form-control select small-font"
                          id="dropdownMenuButton"
                          aria-haspopup="true"
                          aria-expanded="false"
                          (click)="searchTextObj.contactEmail = ''"
                        >
                          <input
                            tourAnchor="tour-3"
                            dropdownToggle
                            type="text"
                            value="demo@tatacommunications.com"
                            class="legatentityClass"
                            data-matomo-unmask
                            readonly
                          />
                        </div>
                        <div
                          *dropdownMenu
                          class="dropdown-menu radiobtn-list-dp bill-contact-email-drpdwn"
                          aria-labelledby="dropdownMenuButton"
                        >
                          <div class="row">
                            <div class="col">
                              <input
                                type="text"
                                class="le-search"
                                placeholder="Search"
                                (click)="onSearchClick($event)"
                                [(ngModel)]="searchTextObj.contactEmail"
                                data-matomo-unmask
                                autocomplete="off"
                              />
                              <i
                                class="fa fa-search le-search-icon"
                                aria-hidden="true"
                              ></i>
                              <ul
                                class="radiobtn-list mCustomScrollbar"
                                style="width: 370px"
                              >
                                <li
                                  *ngFor="
                                    let contact of userDetail.customerContactList
                                      | filter
                                        : searchTextObj.contactEmail
                                        : 'customerLeContactEmailid'
                                        : true;
                                    let i = index
                                  "
                                >
                                  <label class="radio-box small-font">
                                    <input
                                      type="radio"
                                      name="address"
                                      [(ngModel)]="userDetail.contactId"
                                      (ngModelChange)="getCustomerContact()"
                                      [value]="contact.contactId"
                                      checked
                                      readonly
                                    />
                                    <span class="checkmark"></span>
                                    <span>{{
                                      contact.customerLeContactEmailid
                                    }}</span>
                                  </label>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="row">
                  <div class="col-md-4 form-group">
                    <label class="userEdit col-form-label gmfont">Name</label>
                    <p tourAnchor="tour-4" class="form-control-plaintext">
                      Demo Customer
                    </p>
                  </div>
                  <div class="col-md-4 form-group">
                    <label class="userEdit col-form-label gmfont"
                      >Contact Number</label
                    >
                    <p tourAnchor="tour-5" class="form-control-plaintext">
                      +1-(357) 4589123
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="card shadow-card">
              <div class="card-body">
                <div class="row">
                  <div class="col-12">
                    <h4 class="d-inline-block">
                      Billing Contact Details
                      <a
                        class="pointer"
                        tooltip="Please select Address in Billing Address if you don't find your desired Email in the list"
                      >
                        <span class="icon icon-info"></span>
                      </a>
                    </h4>
                  </div>
                </div>
                <ng-container
                  *ngFor="
                    let billingDetails of userDetail.billingContactDetails
                  "
                >
                  <div class="row mb-2">
                    <!-- Email ID section -->
                    <div class="col-md-6 form-group">
                      <label class="userEdit col-form-label"
                        >Email Address</label
                      >
                      <div class="row align-items-center">
                        <div class="col-12" dropdown>
                          <div
                            dropdownToggle
                            class="form-control select small-font drop-height"
                            id="dropdownMenuButton"
                            aria-haspopup="true"
                            aria-expanded="false"
                            [class.disabled]="isDocusign"
                            (click)="searchTextObj.billingEmail = ''"
                          >
                            <span *ngIf="!salesOrderParams.legalEntityId"
                              >Please select your Email Address
                            </span>
                            <span
                              *ngIf="salesOrderParams.legalEntityId"
                              style="color: black"
                            >
                              {{ billingDetails.emailId }}
                            </span>
                          </div>
                          <div
                            *dropdownMenu
                            class="dropdown-menu radiobtn-list-dp bill-contact-email-drpdwn"
                            aria-labelledby="dropdownMenuButton"
                          >
                            <input
                              type="text"
                              class="le-search"
                              placeholder="Search"
                              (click)="onSearchClick($event)"
                              [(ngModel)]="selectedBillingAddress"
                              data-matomo-unmask
                              autocomplete="off"
                            />
                            <i
                              class="fa fa-search le-search-icon"
                              aria-hidden="true"
                            ></i>
                            <ul
                              class="radiobtn-list mCustomScrollbar"
                              style="width: 370px"
                            >
                              <li
                                *ngFor="
                                  let billingAddress of salesOrderData.uniqueEmailsFromBillingAddresses
                                    | filter
                                      : searchTextObj.billingEmail
                                      : 'emailId'
                                      : true;
                                  let i = index
                                "
                              >
                                <label class="radio-box small-font">
                                  <input
                                    type="radio"
                                    name="contact-details"
                                    [(ngModel)]="salesOrderData.billingInfoid"
                                    [value]="billingAddress.billingInfoid"
                                    (change)="
                                      getBilling(i, billingAddress, 'email')
                                    "
                                    checked
                                  />
                                  <span class="checkmark"></span>
                                  <span>{{ billingAddress.emailId }}</span>
                                </label>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" *ngIf="flag.salesLogin">
                      <span
                        *ngIf="
                          salesOrderParams.legalEntityId &&
                          !isGVPNInternational &&
                          !isCrossConnect &&
                          (commonProductName == 'IAS' ||
                            commonProductName == 'GVPN' ||
                            commonProductName == 'NPL' ||
                            commonProductName == 'NDE')
                        "
                        class="billingEdit buttEdit"
                        style="
                          margin-top: 0px !important;
                          margin-right: 2px !important;
                        "
                      >
                        <button
                          class="btn secondary-outline-btn modal-slider"
                          [disabled]="!isSiteWiseBillingEnabled"
                          (click)="onClickSitewiseBilling()"
                        >
                          Sitewise Billing
                        </button>
                      </span>
                    </div>
                  </div>
                  <div class="row mb-2">
                    <!-- First Name section -->
                    <div class="col-md-4 form-group">
                      <label class="userEdit col-form-label">First Name</label>
                      <p class="form-control-plaintext">
                        {{ billingDetails.fname }}
                      </p>
                    </div>
                    <!-- Last name section -->
                    <div class="col-md-4 form-group">
                      <label class="userEdit col-form-label">Last Name</label>
                      <p class="form-control-plaintext">
                        {{ billingDetails.lname }}
                      </p>
                    </div>
                    <!-- contact section -->
                    <div class="col-md-4 form-group">
                      <label class="userEdit col-form-label"
                        >Contact Number</label
                      >
                      <p class="form-control-plaintext">
                        {{ billingDetails.contactNumber }}
                      </p>
                    </div>
                  </div>
                </ng-container>
                <div class="row">
                  <!-- Address section -->
                  <div class="col-md-12 form-group">
                    <label class="userEdit col-form-label">Address</label>
                    <a
                      class="pointer pl-1"
                      tooltip="Please select Email Address in Billing Contact Details if you don't find your desired address in the list"
                    >
                      <span class="icon icon-info"></span>
                    </a>

                    <div class="row">
                      <input
                        tourAnchor="tour-6"
                        type="text"
                        class="form-control p-10"
                        name="billingAddress"
                        [(ngModel)]="selectedBillingAddress"
                        placeholder="select billing address"
                        disabled
                        style="font-weight: unset"
                      />
                    </div>
                    <br />
                  </div>
                </div>
              </div>
            </div>

            <div class="card shadow-card">
              <div class="card-body">
                <div class="row entityrow">
                  <div class="col-md-10">
                    <h4 class="mb-3">
                      Corresponding TATA Communications Legal Entity
                    </h4>
                    <div class="row">
                      <div class="">
                        <div class="small-font">
                          <!-- <div [hidden]="commonProductName === 'PARTNERIAS' || commonProductName === 'PARTNERGVPN'" class="row"> -->
                          <div
                            class="col dropright"
                            dropdown
                            *ngIf="commonProductName == 'IZO SDWAN'"
                            [ngClass]="{
                              'disable-selection':
                                salesOrderData.uploadSuccess == false
                            }"
                          >
                            <div
                              class="form-control select small-font"
                              id="dropdownMenuButton"
                              aria-haspopup="true"
                              aria-expanded="false"
                              [ngClass]="{
                                'disable-selection':
                                  salesOrderData.uploadSuccess == false
                              }"
                            >
                              <input
                                dropdownToggle
                                type="text"
                                [ngClass]="{
                                  'disable-selection':
                                    salesOrderData.uploadSuccess == false
                                }"
                                placeholder="Please select your supplier legal entity"
                                [readOnly]="showDropdown"
                                [(ngModel)]="
                                  salesOrderData.selectedSpleDetails
                                    .selectedSupplier
                                "
                                class="legatentityClass"
                                (ngModelChange)="
                                  filterSupplierItem(
                                    salesOrderData.selectedSpleDetails
                                      .selectedSupplier
                                  )
                                "
                                (click)="
                                  showDropdown = !showDropdown;
                                  $event.stopPropagation()
                                "
                                [disabled]="
                                  isDocusign || !salesOrderData.uploadSuccess
                                "
                                data-matomo-unmask
                              />

                              <!--<span *ngIf="!salesOrderParams.legalEntityId">Please select your legal entity </span> -->
                              <!-- <span *ngIf="salesOrderParams.legalEntityId" style="color:black">{{salesOrderParams.entityName}}</span> -->
                            </div>
                            <div
                              *dropdownMenu
                              class="dropdown-menu radiobtn-list-dp"
                              aria-labelledby="dropdownMenuButton"
                              style="margin-left: -15.8rem !important"
                              (click)="$event.stopPropagation()"
                            >
                              <div class="row">
                                <div class="col">
                                  <!-- <label class="search-text">Enter a name:</label>
                                                                                    <span class="search-value" style="margin-left: 20px;"> -->
                                  <input
                                    type="text"
                                    class="le-search"
                                    placeholder="Search legal entity"
                                    [(ngModel)]="
                                      salesOrderData.filteredSupplier
                                    "
                                    (ngModelChange)="
                                      filterSupplierItem(
                                        salesOrderData.filteredSupplier
                                      )
                                    "
                                    data-matomo-unmask
                                  />
                                  <i
                                    class="fa fa-search le-search-icon"
                                    aria-hidden="true"
                                  ></i>
                                  <!-- </span> -->
                                  <ul
                                    class="radiobtn-list mCustomScrollbar"
                                    style="width: 370px"
                                  >
                                    <li
                                      *ngFor="
                                        let entity of salesOrderData.supplierLegalDetails;
                                        let i = index
                                      "
                                    >
                                      <label class="radio-box small-font">
                                        <input
                                          type="radio"
                                          name="entityname"
                                          [(ngModel)]="
                                            salesOrderData.selectedSpleDetails
                                              .selectedSupplier
                                          "
                                          [value]="entity.supplierName"
                                          (change)="
                                            onClickSupplierLegalEntity(
                                              entity.supplierId
                                            )
                                          "
                                          checked
                                        />

                                        <span class="checkmark"></span>
                                        <span>{{ entity.supplierName }}</span>
                                      </label>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- </div> -->
                          <p
                            *ngIf="
                              salesOrderParams.legalEntityId &&
                              commonProductName === 'IZOMCC'
                            "
                            style="padding-left: 16px"
                          >
                            {{ salesOrderParams.correspondingLegalEntityName }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-2" *ngIf="salesOrderParams.legalEntityId">
                    <h4 class="mb-3">Currency</h4>
                    <div class="row">
                      <div class="col" style="padding: 0px 1.429rem">
                        <div class="small-font">
                          <span
                            *ngIf="
                              salesOrderParams.legalEntityId &&
                              commonProductName != 'IZO SDWAN'
                            "
                            >{{ salesOrderParams.currency }}</span
                          >
                          <div
                            *ngIf="commonProductName == 'IZO SDWAN'"
                            class="select"
                          >
                            <select
                              [disabled]="
                                isDocusign || !salesOrderData.uploadSuccess
                              "
                              class="form-control"
                              [(ngModel)]="
                                salesOrderData.selectedSpleDetails
                                  .selectedCurrency
                              "
                              (change)="
                                salesOrderParams.currency =
                                  salesOrderData.selectedSpleDetails.selectedCurrency;
                                updatePaymentCurrency()
                              "
                            >
                              <option
                                *ngFor="
                                  let details of salesOrderData
                                    .selectedSpleDetails?.currency
                                "
                              >
                                {{ details }}
                              </option>
                            </select>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card shadow-card" style="display: none">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-4">
                    <label class="po-field" style="margin-right: 20px"
                      >PO Number</label
                    >
                    <mat-form-field class="example-full-width">
                      <input
                        autocomplete="off"
                        matInput
                        placeholder="Enter PO Number"
                        [(ngModel)]="salesOrderParams.po_number"
                      />
                    </mat-form-field>
                  </div>
                  <div class="form-group col-4">
                    <label class="po-field" style="margin-right: 20px"
                      >PO Date</label
                    >
                    <mat-form-field>
                      <input
                        matInput
                        [matDatepicker]="pickerNew"
                        placeholder="Choose a date"
                        autocomplete="off"
                        [(ngModel)]="salesOrderParams.po_date"
                      />
                      <mat-datepicker-toggle
                        matSuffix
                        [for]="pickerNew"
                        style="color: #ea7926"
                      ></mat-datepicker-toggle>
                      <mat-datepicker #pickerNew></mat-datepicker>
                    </mat-form-field>
                  </div>
                </div>
              </div>
            </div>
            <div class="card shadow-card" *ngIf="isGSTForIndia">
              <div class="card-body">
                <div class="row">
                  <div class="form-group col-7">
                    <label class="po-field">GST No.</label>

                    <div>
                      <div
                        class="select"
                        *ngIf="gstnInfoList && !gstnInfoList.length"
                      >
                        <select
                          class="form-control"
                          [(ngModel)]="salesOrderParams.GSTNumber"
                          (change)="
                            salesOrderParams.GSTNumber = 'No Registered GST'
                          "
                        >
                          <option value=" "></option>
                          <option value="No Registered GST">
                            No Registered GST
                          </option>
                        </select>
                      </div>
                      <p
                        *ngIf="gstnInfoList && gstnInfoList.length === 1"
                        class="select pt-3 pb-1"
                        id="mastergst"
                        style="border-bottom: 1px solid #d8d8d8; color: #333333"
                      >
                        {{
                          gstnInfoList[0].gstn
                            ? gstnInfoList[0].gstn
                            : "Please select Master GST"
                        }}
                      </p>
                      <div
                        *ngIf="gstnInfoList && gstnInfoList.length > 1"
                        class="row"
                      >
                        <div class="col dropright" dropdown>
                          <div
                            class="select"
                            id="dropdownMenuButtonTwo"
                            aria-haspopup="true"
                            aria-expanded="false"
                          >
                            <p
                              dropdownToggle
                              class="pt-3 pb-1"
                              id="mastersubgst"
                              style="
                                border-bottom: 1px solid #d8d8d8;
                                color: #333333;
                              "
                              data-matomo-unmask
                            >
                              {{
                                gstnInfoList[0].gstn
                                  ? gstnInfoList[0].gstn
                                  : "Please select Master GST"
                              }}
                            </p>
                          </div>
                          <div
                            *dropdownMenu
                            class="dropdown-menu radiobtn-list-dp"
                            aria-labelledby="dropdownMenuButtonTwo"
                            (click)="$event.stopPropagation()"
                            style="left: -150px"
                          >
                            <div class="row">
                              <div
                                class="col pl-0 pr-0"
                                style="background-color: #f2f2f2; width: 500px"
                              >
                                <div class="col-md-12">
                                  <div class="row mt-3">
                                    <p
                                      class="col-md-6 font-bold text-center"
                                      style="
                                        font-family: 'Gotham-Book';
                                        font-size: 12px;
                                      "
                                    >
                                      GST Number
                                    </p>
                                    <p
                                      class="col-md-6 font-bold text-center"
                                      style="
                                        font-family: 'Gotham-Book';
                                        font-size: 12px;
                                      "
                                    >
                                      Address
                                    </p>
                                  </div>
                                </div>
                                <div
                                  class="radiobtn-list mCustomScrollbar col-md-12"
                                  style="
                                    background-color: #fff;
                                    max-height: 250px;
                                    overflow-y: scroll;
                                    width: 500px;
                                  "
                                >
                                  <div
                                    class="row mt-3 p-2"
                                    *ngFor="
                                      let entity of gstnInfoList;
                                      let i = index
                                    "
                                  >
                                    <div class="col-md-6">
                                      <label class="radio-box small-font">
                                        <input
                                          type="radio"
                                          name="entityname"
                                          (change)="
                                            onChangeGSTSelection(
                                              entity,
                                              'clicked'
                                            )
                                          "
                                          [checked]="entity['isSelected']"
                                        />
                                        <span class="checkmark"></span>
                                        <span
                                          style="
                                            font-family: 'Gotham-Book';
                                            font-size: 12px;
                                          "
                                          >{{ entity.gstn }}</span
                                        >
                                      </label>
                                    </div>
                                    <div class="col-md-6">
                                      <p
                                        style="
                                          font-family: 'Gotham-Book';
                                          font-size: 12px;
                                        "
                                      >
                                        {{ entity.address }}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!--End of billing add changes-->
            <div
              class="card shadow-card"
              *ngIf="!offeringName || offeringName !== 'MMR Cross Connect'"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <h4 class="mb-4">Need Tax Exemption ?</h4>
                    <div class="row" tourAnchor="tour-7">
                      <div class="col-md-2">
                        <label class="radio-box small-font">
                          <input
                            type="radio"
                            name="taxexcemp"
                            value="yes"
                            [(ngModel)]="salesOrderParams.isTaxExemption"
                          />

                          <span class="checkmark"></span>
                          <span>Yes</span>
                        </label>
                      </div>
                      <div class="col-md-2">
                        <label class="radio-box small-font">
                          <input
                            type="radio"
                            name="taxexcemp"
                            value="no"
                            [(ngModel)]="salesOrderParams.isTaxExemption"
                          />
                          <span class="checkmark"></span>
                          <span>No</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card shadow-card siteaddresses small-font"
              [ngClass]="{ 'd-none': salesOrderParams.isTaxExemption == 'no' }"
              *ngIf="selectedAddress.length > 0"
            >
              <div class="card-body">
                <div class="row">
                  <div class="col-md-12">
                    <h4>Sites selected for Tax Exemption</h4>
                    <div class="row">
                      <div
                        class="col-md-6"
                        *ngFor="let site of selectedAddress; let i = index"
                      >
                        <p class="taxsite">
                          <span class="d-block">Address - {{ i + 1 }}</span>
                          <span class="d-block"
                            >{{
                              site.userAddress.addressLineOne +
                                " " +
                                site.userAddress.city +
                                " " +
                                site.userAddress.state +
                                " " +
                                site.userAddress.country
                            }}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
  <section class="col-md-12 showRediriectionMessage" *ngIf="!showSalesOrder">
    <h4>
      Notification successfully sent to RM.Please wait untill you get a response
    </h4>
    <div class="m-t-30">
      <div class="row">
        <div class="col-md-6">
          <a class="btn btn-primary" href="#"> GOTO LANDING PAGE</a>
        </div>
        <div class="col-md-6">
          <a
            class="btn btn-primary"
            (click)="
              salesOrderParams.numberOfEntity = 'single';
              showSalesOrder = !showSalesOrder
            "
          >
            GO BACK</a
          >
        </div>
      </div>
    </div>
  </section>
</main>
<!-- Multiple Docs Modal -->
<ng-template #multipledocs>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">
      Do you want this order to be placed by multiple legal entities?
    </h5>
    <button
      type="button"
      class="close"
      (click)="salesOrderParams.numberOfEntity = 'single'; modalRef.hide()"
      aria-label="Close"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Our support team will contact you soon to complete the order forms showing
      the respective legal entities involved.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      (click)="salesOrderParams.numberOfEntity = 'single'; modalRef.hide()"
      class="btn secondary-dark-outline-btn editButton"
    >
      CANCEL
    </button>
    <button
      type="button"
      (click)="modalRef.hide(); triggerMail()"
      class="btn active-btn"
    >
      OK
    </button>
  </div>
</ng-template>
<ng-template #taximplications>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">Confirm you understand the tax implications</h5>
    <button
      type="button"
      class="close"
      (click)="modalRef.hide(); cancelTaxExemption()"
      aria-label="Close"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Choosing a legal entity outside of the country where we deliver the
      service may have tax implications. If you fully understand them, click
      ‘OK’.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      data-dismiss="modal"
      class="btn active-btn"
      (click)="modalRef.hide()"
    >
      OK
    </button>
  </div>
</ng-template>

<!-- Tax excemption Modal -->
<ng-template #taxexcemption>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">
      Select the site(s) for which tax exemption applies
    </h5>
  </div>
  <div
    class="modal-body no-padding"
    [ngClass]="{
      'modal-scroll mCustomScrollbar': commonProductName == 'IZO SDWAN'
    }"
  >
    <div class="item">
      <div class="add-location-list">
        <div
          class="row"
          *ngIf="flag.isUSLocation"
          style="
            padding: 7px;
            border: 1px solid #213b58;
            background: #213b58;
            color: #ffffff;
            margin-right: 0px;
            margin-left: 0px;
            margin-bottom: 7px;
          "
        >
          <span class="col-md-1"
            ><i class="fa fa-exclamation-circle" aria-hidden="true"></i
          ></span>
          <span class="col-md-9"
            >Account manager will contact for further processes.
          </span>
        </div>
        <ul>
          <li *ngFor="let site of salesOrderData.listOfSites; let i = index">
            <label class="check-box check-box-left">
              <input
                type="checkbox"
                *ngIf="
                  this.commonProductName != 'GVPN' &&
                  this.commonProductName != 'IAS'
                "
                [(ngModel)]="site.addressStatus"
              />
              <input
                type="checkbox"
                *ngIf="
                  this.commonProductName === 'GVPN' ||
                  this.commonProductName == 'IAS'
                "
                [(ngModel)]="site.addressStatus"
                (change)="checkStatus(site)"
              />
              <span class="checkmark"></span>
              <span class="d-block">{{
                site.userAddress.addressLineOne +
                  " " +
                  site.userAddress.city +
                  " " +
                  site.userAddress.state +
                  " " +
                  site.userAddress.country
              }}</span>
            </label>
          </li>
        </ul>
        <p class="error" *ngIf="salesOrderData.showAddressError">
          {{ "Please select address" }}
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      (click)="cancelTaxExemption(); modalRef.hide()"
      class="btn secondary-dark-outline-btn editButton"
    >
      CANCEL
    </button>
    <button
      type="button"
      class="btn active-btn add-address"
      [disabled]="
        !salesOrderParams.customerLegalEntityId ||
        !salesOrderParams.isTaxExemption ||
        !salesOrderParams.numberOfEntity
      "
      (click)="getAddress(); sendMail()"
    >
      SUBMIT
    </button>
  </div>
</ng-template>

<!-- Multiple Docs Modal -->
<ng-template #errorHandle>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">Error!!</h5>
    <button
      type="button"
      class="close"
      (click)="modalRef.hide()"
      aria-label="Close"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <p>Unhandled network exception</p>
  </div>
  <div class="modal-footer">
    <button type="button" (click)="modalRef.hide()" class="btn active-btn">
      ok
    </button>
  </div>
</ng-template>
<ng-template #mailSent>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">Error</h5>
    <button
      type="button"
      class="close"
      (click)="modalRef.hide()"
      aria-label="Close"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <p
      *ngIf="
        this.commonProductName === 'IZOPC' ||
        this.commonProductName === 'GVPN' ||
        this.commonProductName === 'IAS' ||
        this.commonProductName === 'IZO Internet WAN'
      "
    >
      Your Account manager will contact you for further process.
    </p>
    <p
      *ngIf="
        (this.commonProductName !== 'IZOPC' &&
          this.commonProductName !== 'GVPN') ||
        this.commonProductName !== 'IAS' ||
        this.commonProductName !== 'IZO Internet WAN'
      "
    >
      Legal entity is not available.
    </p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      (click)="mailTriger(); modalRef.hide()"
      class="btn active-btn"
    >
      ok
    </button>
  </div>
</ng-template>

<ng-template #template>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">Information</h5>
    <button
      type="button"
      class="close"
      (click)="modalRef.hide()"
      aria-label="Close"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <p>Please select the Master GST.</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      (click)="modalRef.hide()"
      class="btn active-btn buttonMargin"
    >
      CLOSE
    </button>
  </div>
</ng-template>

<ng-template #sitewiseBilling>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">Sitewise Billing</h5>
    <button
      type="button"
      class="close"
      (click)="modalRef.hide(); isSitewiseBillingbtnClicked = false"
      aria-label="Close"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <mat-card class="pb-2 pt-2">
      <div class="row">
        <div class="form-group col-md-2">
          <label class="po-field">State</label>
          <div class="select">
            <select
              class="form-control"
              [(ngModel)]="siteWiseBilling.selectedState"
            >
              <option *ngFor="let state of stateList" [ngValue]="state">
                {{ state }}
              </option>
            </select>
          </div>
        </div>
      </div>
    </mat-card>
    <div class="row sitewisebilling-heading mt-3 mb-3">
      <div class="col-md-1">SL:NO</div>
      <div
        class="col-md-1"
        *ngIf="commonProductName == 'NPL' || commonProductName == 'NDE'"
      >
        SITE TYPE
      </div>
      <div
        class="col-md-4"
        [ngClass]="
          commonProductName == 'IAS' || commonProductName == 'GVPN'
            ? 'pl-5'
            : ''
        "
      >
        SITE ADDRESS
      </div>
      <div
        [ngClass]="
          commonProductName == 'NPL' || commonProductName == 'NDE'
            ? 'col-md-3 pl-1'
            : 'col-md-4 pl-5'
        "
      >
        BILLING ADDRESS
      </div>
      <div class="col-md-3">GST NUMBER</div>
    </div>
    <div
      *ngIf="commonProductName == 'IAS' || commonProductName == 'GVPN'"
      class="billing-scroll"
    >
      <mat-card
        *ngFor="
          let billing of billingAttr
            | sitewiseBillingStatePipe
              : siteWiseBilling.selectedState
              : commonProductName;
          let i = index
        "
        class="mb-3"
      >
        <div class="row" style="font-family: 'Gotham-Book'">
          <div class="col-md-1">{{ i + 1 }}</div>
          <div class="form-group col-4">
            <p>
              {{ billing.addressInfo.userAddress.addressLineOne }}
              {{ billing.addressInfo.userAddress.state }}
              {{ billing.addressInfo.userAddress.city }}
              {{ billing.addressInfo.userAddress.country }}
            </p>
          </div>

          <div class="col-md-4">
            <div class="row">
              <div class="col dropright" dropdown>
                <div
                  class="form-control select small-font"
                  id="dropdownMenuButton"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <input
                    dropdownToggle
                    type="text"
                    placeholder="Please select billing address"
                    [readOnly]="showDropdown"
                    [(ngModel)]="billing.selectedBillingAddress.billAddr"
                    class="legatentityClass"
                    (click)="
                      showDropdown = !showDropdown; $event.stopPropagation()
                    "
                    [disabled]="isDocusign"
                    data-matomo-unmask
                  />
                </div>
                <div
                  *dropdownMenu
                  class="dropdown-menu radiobtn-list-dp"
                  style="margin-left: -70%"
                  aria-labelledby="dropdownMenuButton"
                  (click)="$event.stopPropagation()"
                >
                  <div class="row">
                    <div class="col">
                      <input
                        type="text"
                        class="le-search"
                        placeholder="Search billing address"
                        [(ngModel)]="searchBillingAddress"
                        (ngModelChange)="
                          filterBillingAddress(searchBillingAddress)
                        "
                        data-matomo-unmask
                      />
                      <i
                        class="fa fa-search le-search-icon"
                        aria-hidden="true"
                      ></i>
                      <!-- </span> -->
                      <ul
                        class="radiobtn-list mCustomScrollbar"
                        style="width: 370px"
                      >
                        <li
                          *ngFor="
                            let entity of billingAddressList;
                            let i = index
                          "
                        >
                          <label class="radio-box small-font">
                            <input
                              type="radio"
                              name="billing address{{ i }}"
                              [(ngModel)]="billing.selectedBillingAddress"
                              [value]="entity"
                              checked
                            />

                            <span class="checkmark"></span>
                            <span>{{ entity.billAddr }}</span>
                          </label>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
            <div class="form-group">
              <div class="select" style="width: 80%">
                <select
                  class="form-control"
                  [(ngModel)]="billing.selectedGST"
                  (change)="onChangeGST(billing)"
                >
                  <option
                    *ngFor="let data of billing.gstDetails"
                    [value]="data.gstNo"
                  >
                    {{ data.gstNo }}
                  </option>
                </select>
              </div>
              <p
                class="mt-3"
                *ngIf="billing.gstAddress && billing.gstAddress.length > 0"
              >
                {{ billing.gstAddress }}
              </p>
              <p
                class="mt-3"
                *ngIf="billing.selectedGST == 'No Registered GST'"
              >
                ---
              </p>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
    <div
      *ngIf="commonProductName == 'NPL' || commonProductName == 'NDE'"
      class="billing-scroll"
    >
      <mat-card
        *ngFor="
          let billing of billingAttr
            | sitewiseBillingStatePipe
              : siteWiseBilling.selectedState
              : commonProductName;
          let i = index
        "
        class="mb-3"
      >
        <div class="row" style="font-family: 'Gotham-Book'">
          <div
            class="col-md-12 row"
            *ngFor="let site of billing.sites; let j = index"
          >
            <div class="col-md-1" [hidden]="j != 0">{{ i + 1 }}</div>
            <div class="col-md-1" [hidden]="j != 1"></div>
            <div class="col-md-1">{{ site.siteType }}</div>

            <div class="form-group col-4">
              <p>
                {{ site.addressInfo.userAddress.addressLineOne }}
                {{ site.addressInfo.userAddress.state }}
                {{ site.addressInfo.userAddress.city }}
                {{ site.addressInfo.userAddress.country }}
              </p>
            </div>

            <div class="col-md-3">
              <div class="row">
                <div class="col dropright" dropdown>
                  <div
                    class="form-control select small-font"
                    id="dropdownMenuButton"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    <input
                      dropdownToggle
                      type="text"
                      placeholder="Please select billing address"
                      [readOnly]="showDropdown"
                      [(ngModel)]="site.selectedBillingAddress.billAddr"
                      class="legatentityClass"
                      (click)="
                        showDropdown = !showDropdown; $event.stopPropagation()
                      "
                      [disabled]="isDocusign"
                      data-matomo-unmask
                    />
                  </div>
                  <div
                    *dropdownMenu
                    class="dropdown-menu radiobtn-list-dp"
                    style="margin-left: -70%"
                    aria-labelledby="dropdownMenuButton"
                    (click)="$event.stopPropagation()"
                  >
                    <div class="row">
                      <div class="col">
                        <input
                          type="text"
                          class="le-search"
                          placeholder="Search billing address"
                          [(ngModel)]="searchBillingAddress"
                          (ngModelChange)="
                            filterBillingAddress(searchBillingAddress)
                          "
                          data-matomo-unmask
                        />
                        <i
                          class="fa fa-search le-search-icon"
                          aria-hidden="true"
                        ></i>

                        <ul
                          class="radiobtn-list mCustomScrollbar"
                          style="width: 370px"
                        >
                          <li
                            *ngFor="
                              let entity of billingAddressList;
                              let i = index
                            "
                          >
                            <label class="radio-box small-font">
                              <input
                                type="radio"
                                name="billing address{{ i }}"
                                [(ngModel)]="site.selectedBillingAddress"
                                [value]="entity"
                                checked
                              />
                              <span class="checkmark"></span>
                              <span>{{ entity.billAddr }}</span>
                            </label>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3">
              <div class="form-group">
                <div class="select" style="width: 80%">
                  <select
                    class="form-control"
                    (change)="onChangeGST(site)"
                    [(ngModel)]="site.selectedGST"
                    name="gstnumber{{ site.siteId }}"
                  >
                    <option
                      *ngFor="let data of site.gstDetails"
                      [value]="data.gstNo"
                    >
                      {{ data.gstNo }}
                    </option>
                  </select>
                </div>
                <p class="mt-3">{{ site.gstAddress }}</p>
              </div>
            </div>
          </div>
        </div>
      </mat-card>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      (click)="onClickSaveSitewiseBilling()"
      class="btn active-btn buttonMargin"
      [disabled]="!validateBillingFields()"
    >
      SAVE
    </button>
  </div>
</ng-template>

<ng-template #deleteConfirmation>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">Delete Confirmation</h5>
  </div>
  <div class="modal-body">
    <div>
      <p>{{ popUpMessage }}</p>
    </div>
    <p>Are you sure you want to Delete?</p>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn active-btn buttonMargin"
      (click)="modalRef.hide(); OnclickCancel()"
    >
      No
    </button>
    <button
      type="button"
      class="btn active-btn buttonMargin"
      (click)="onClickDeleteSiteWiseBilling()"
    >
      Yes
    </button>
  </div>
</ng-template>
<tour-step-template>
  <ng-template let-step="step">
    <div class="tour-pointer"></div>
    <div class="tour">
      <p class="ngxp-title">{{ step?.title }}</p>
      <p class="ngxp-content">{{ step?.content }}</p>
      <div class="tour-step-navigation">
        <button
          [disabled]="!tourService.hasPrev(step)"
          class="ngxp-btn"
          (click)="tourService.prev()"
        >
          « prev
        </button>

        <button
          class="ngxp-btn"
          (click)="tourService.next(); setValuesOnNext(step?.anchorId)"
        >
          next »
        </button>
        <!-- <button class="ngxp-btn" (click)="tourService.end();setValuesOnNext(step?.anchorId)">end</button> -->
      </div>
    </div>
  </ng-template>
</tour-step-template>
