import { Component, ElementRef, OnInit, AfterViewInit } from '@angular/core';
import * as joint from 'jointjs';
import * as _ from 'underscore';
declare var $: any; // For global jQuery

@Component({
  selector: 'app-visual',
  templateUrl: './visual.component.html',
  styleUrls: ['./visual.component.scss']
})
export class VisualComponent implements OnInit, AfterViewInit {

  private paper!: joint.dia.Paper;
  graph = new joint.dia.Graph();
  constructor(private el: ElementRef) {
    // var adjustGraphVertices = _.partial(this.adjustVertices, this.graph);

// adjust vertices when a cell is removed or its source/target was changed
// this.graph.on('add remove change:source change:target', adjustGraphVertices);

// adjust vertices when the user stops interacting with an element
// this.paper.on('cell:pointerup', adjustGraphVertices);
  }

  ngAfterViewInit() {
    this.setupIsometricDrawing();
    this.paper.on('element:pointerdown', (elementView: joint.dia.ElementView) => {
      this.onElementClick(elementView);
    });

    this.paper.on('element:mouseover', (cellView, evt) => {
      if (cellView.model.isLink()) return; // Skip links

      const tooltipText = cellView.model.prop('attrs/tooltip');
      if (tooltipText && tooltipText.tooltip) {
        console.log(cellView, evt, cellView.model.prop('attrs/tooltip'))
        this.showCustomTooltip(tooltipText.tooltip, evt);

      }
    });

    this.paper.on('element:mouseout', (cellView) => {
      // Remove tooltip when mouse leaves
      document.getElementById('tooltip')?.remove();
    });
  }

  ngOnInit(): void {

  }

  private setupIsometricDrawing() {

    this.paper = new joint.dia.Paper({
      el: this.el.nativeElement.querySelector('#isometricDrawingContainer'),
      model: this.graph,
      width: "100%",
      height: "400px",
      gridSize: 10,
      async: true,
      frozen: true,
      sorting: joint.dia.Paper.sorting.APPROX,
      background: { color: "#FFF" },
      interactive: { labelMove: true },
      linkPinning: false,
      clickThreshold: 10,
      defaultAnchor: {
        name: 'modelCenter',
    },
    defaultConnectionPoint: {
        name: 'boundary',
    },
    defaultConnector: {
        name: 'rounded',
    },
    defaultRouter: {
      name: "manhattan",
      args: {
        step: 10,
        endDirections: ["bottom"],
        startDirections: ["top"],
        padding: { bottom: 20 }
      }
    },
    preventDefaultViewAction: false,

    });

    const color = "#ff4468";

    this.paper.svg.prepend(
      joint.V.createSVGStyle(`
        .joint-element .selection {
          stroke: ${color};
        }
        .joint-link .selection {
          stroke: ${color};
          stroke-dasharray: 5;
          stroke-dashoffset: 10;
          animation: dash 0.5s infinite linear;
        }
        @keyframes dash {
          to {
            stroke-dashoffset: 0;
          }
        }
      `)
    );

    const el1 = this.imageElement(
      720,
      0,
      './assets/joint/azure.png',
      'Azure\nRegion:APEast-1',
      110, 100,
      `<p>Primary IP: 192.168.5.1</p><p>Primary Subnet: /30</p><p>BGP Key: Password</p>`,
  );

    const el2 = this.imageElement(
      720,
      260,
      './assets/joint/aws.png',
      'AWS\nRegion:APEast-1',
      110, 100,
      `<p>Primary IP: 192.168.1.1</p><p>Primary Subnet: /30</p><p>BGP Key: Password</p>`
  );



  const el3 = this.imageElement(
      350,
      130,
      './assets/joint/vnf.png',
      '',
      140, 120,
      `<p>IntNum:xx -> IntNum:yy -> 100Mbps</p><br/><p>IntNum:xx -> IntNum:yy -> 100Mbps</p>`,
  );

  const el7 = this.imageElement(
    -10,
    130,
    './assets/joint/building.png',
    'Cx Netherland',
    110, 100,
);
const el8 = this.imageElement(
  200,
  -30,
  './assets/joint/mcc.png',
  '',
  300, 100,
);
const el9 = this.imageElement(
  370,
  80,
  './assets/joint/arrow.png',
  '',
  110, 55,
);
const el10 = this.imageElement(
  70,
  200,
  './assets/joint/mplsint.png',
  '',
  180, 170,
);
  const el6 = this.element(
    390,
    140,
    180,
    120,
    'transparent'
);
    // const el4 = this.imageElement(70, 170, './assets/joint/pe.png', 'PE Router');
    // const el5 = this.imageElement(70, 0, './assets/joint/tunnel.jpg', 'Via IP sec tunnel/GVPN/IWAN');

    this.linkWPortazure(el6, el1,'port1','port0', '100Mbps\nSingle Link'); // Adjust dx and dy values
    this.linkWPortaws(el6, el2, 'port2','port0', '100Mbps\nSingle Link'); // Adjust dx and dy values
    this.link(el7,el6);

    // this.link(el6, el4);
    // // this.linkWPort(el6, el4,'port2','port0', '', 'right', 'left');
    // this.link(el4, el5, '100Mbps', 'bottom', 'center');


    this.text(430,270, 'IZO Multi Cloud\n Connect Node');
    this.text(200,220, 'TATA Communications\nEnterprise Fabric');
    this.text(720,185, 'Cloud Fabric');
    // this.text(500,220, 'OTHER-MPLS')

    this.paper.unfreeze();
  }

  check(){
    console.log(this.paper, this.paper.svg, this.graph.toJSON());

  }

  text(x:number, y:number, text:string){
   const el =  new joint.shapes.basic.Text({
      position: { x, y },
      size: { width: 120, height: 30 },
      attrs: {
        text: { text, fill: '#000' },
      },
    });
    this.graph.addCell(el);
    return el;
  }

  element(x: number, y: number, width: number, height: number, backgroundColor: string = 'blue'): joint.dia.Element {
    const el = new joint.shapes.standard.Rectangle({
      position: { x, y },
      size: { width, height },
      attrs: {
        body: {
          fill: backgroundColor,
          stroke: '#6e6b6b',
          strokeWidth: '1px'
        },
      },
      ports: {
        groups: {
          rightPorts: {
            position: {
              name: 'absolute',
              args: { x: 210, y: 50 }
            },
            attrs: {
              circle: {
                r: 10,
                fill: '#9797e0',
                stroke: '#9797e0',
                magnet: true
              }
            }
          }
        }
      },
      z: 2
    });
    for (let i = 0; i < 1; i++) {
      el.addPort({ group: 'rightPorts', id: `port${i}` });
    }
    this.graph.addCell(el);
    return el;
}

  imageElement(x: number, y: number, imageUrl: string, label:string='', w:number = 150, h:number = 80, tooltip:string='', inPorts:number=0, outPorts:number=0): joint.dia.Element {
    const { shapes, highlighters } = joint;
    const el = new joint.shapes.standard.Image({
      position: { x, y },
      size: { width: w, height: h },
      attrs: {
        image: {
          'xlink:href': imageUrl,
          width: 40, // Adjust width as needed
          height: 40, // Adjust height as needed
          refX: '50%', // Center the image horizontally
          refY: 5 // Place the image 5 units from the top
        },
        label: {
          text: label,
          fontFamily: 'sans-serif',
          fontSize: 12,
          refX: '100%', // Center the text horizontally
          refY: 100, // Place the text 50 units from the top
          textAnchor: 'middle', // Center-align the text
          fill: 'black' // Color of the text
        },
        tooltip: { tooltip }
      },
      z: 2
    });
    this.graph.addCell(el);
    return el;
  }

  imageElementWPort(x: number, y: number, imageUrl: string, label: string = '', tooltip: string = '', inPorts: number = 0, outPorts: number = 0): joint.dia.Element {
    const { shapes, highlighters } = joint;
    const el = new joint.shapes.standard.Image({
      position: { x, y },
      size: { width: 150, height: 80 },
      attrs: {
        image: {
          'xlink:href': imageUrl,
          width: 40, // Adjust width as needed
          height: 40, // Adjust height as needed
          refX: '0%', // Align the right edge of the image
          refY: 5 // Place the image 5 units from the top
        },
        label: {
          text: label ? label : `Node ${this.graph.getElements().length + 1}`,
          fontFamily: 'sans-serif',
          fontSize: 12,
          refX: '50%', // Center the text horizontally
          refY: 70, // Place the text 50 units from the top
          textAnchor: 'middle', // Center-align the text
          fill: 'black' // Color of the text
        },
        tooltip: { tooltip }
      },
      ports: {
        groups: {
          leftPorts: {
            position: {
              name: 'left',

            },
            attrs: {
              circle: {
                r: 5,
                fill: '#9797e0',
                stroke: '#9797e0',
                magnet: false
              }
            }
          }
        }
      },
      z: 2
    });
    for (let i = 1; i < 3; i++) {
      el.addPort({ group: 'rightPorts', id: `port${i}` });
    }
    this.graph.addCell(el);
    return el;
}


  generatePorts(count: number, side: string, color: string): any {
    const ports = {};

    for (let i = 0; i < count; i++) {
      ports[`port${i}`] = {
        position: { name: side },
        attrs: {
          circle: { r: 5, magnet: true, stroke: 'black', fill: color }
        }
      };
    }

    return ports;
  }

  link(target: joint.dia.Element, source: joint.dia.Element, label:string='', px:string='center', py:string='center'): joint.dia.Link {
    let marker = {
      markup: joint.util.svg`
              <path d="M 0 0 L 12 -4 L 5 0 L 12 4 z"
                    fill="#000"
                    stroke-width="2"
              />
          `
    };
    const l = new joint.shapes.standard.Link({
      source: {
        id: source.id,
        selector: 'body',
        anchor: {
          name: px,
      }
      },
      target: {
        id: target.id,
        selector: 'body',
        anchor: {
          name: py,

      }
      },
      labels: [
        { position: 0.5, attrs: { text: { text: label } } } // Set the label text and position (0.5 for middle)
      ],
   // Configure Manhattan style routing
      attrs: {
        line: {
          stroke: 'blue',
          strokeWidth: 1,
          sourceMarker: {
              'type': 'path',
              'stroke': 'black',
              'fill': '#6e6b6b',
              'd': 'M 10 -5 0 0 10 5 Z'
          },
          targetMarker: {
              'type': 'path',
              'stroke': 'black',
              'fill': '#6e6b6b',
              'd': 'M 10 -5 0 0 10 5 Z'
          }
      },
      wrapper: {
        pointerEvents: 'none' // Allows interaction with underlying shapes
    },
      }

    });
    l.set('draggable', true);
    this.graph.addCell(l);
    return l;
  }

  linkWPortaws(target: joint.dia.Element, source: joint.dia.Element, p1:any, p2:any, label:string='', px:string='center', py:string='center'): joint.dia.Link {
    let marker = {
      markup: joint.util.svg`
              <path d="M 0 0 L 12 -4 L 5 0 L 12 4 z"
                    fill="#000"
                    stroke-width="2"
              />
          `
    };
    const l = new joint.shapes.standard.Link({
      source: {
        id: source.id,
        port: p1,
        selector: 'body',
        anchor: {
          name: px,
          args: { connectionPoint: { name: 'boundary', args: { padding: 40 } }, start: '100' }
      },

      },
      target: {
        id: target.id,
        port: p2,
        selector: 'body',
        anchor: {
          name: py,
          args: { connectionPoint: { name: 'boundary', args: { padding: 40 } } }

      },
      },
      labels: [
        { position: 0.5, attrs: {
          rect: {
          fill: 'lightblue', // Background color
          stroke: 'black',   // Border color
          strokeWidth: 0,    // Border width
          width: 100,        // Width of the background
          height: 20,        // Height of the background
          rx: 5,             // Border radius (optional)
          ry: 5              // Border radius (optional)
      }, text: { text: label, refX: 35,
        refY: 0.5, } } } // Set the label text and position (0.5 for middle)
      ],
   // Configure Manhattan style routing
      attrs: {
        line: {
          stroke: 'blue',
          strokeWidth: 1,
          sourceMarker: {
              'type': 'path',
              'stroke': 'black',
              'fill': '#6e6b6b',
              'd': 'M 10 -5 0 0 10 5 Z'
          },
          targetMarker: {
              'type': 'path',
              'stroke': 'black',
              'fill': '#6e6b6b',
              'd': 'M 10 -5 0 0 10 5 Z'
          }
      },
      wrapper: {
        pointerEvents: 'none' // Allows interaction with underlying shapes
    },
      },
      connector: { name: 'rounded' }, // Example connector, adjust as needed
    router: { name: 'orthogonal' }, // Example router, adjust as needed
    anchor: {
        name: 'center', // Use 'perpendicular' anchor to connect to the side of the target element
        args: { start: 200, end: '50%+10' } // Adjust the start and end positions as needed
    },

    });
    l.set('draggable', true);
    this.graph.addCell(l);
    return l;
  }

  linkWPortazure(target: joint.dia.Element, source: joint.dia.Element, p1:any, p2:any, label:string='', px:string='center', py:string='center'): joint.dia.Link {
    let marker = {
      markup: joint.util.svg`
              <path d="M 0 0 L 12 -4 L 5 0 L 12 4 z"
                    fill="#000"
                    stroke-width="2"
              />
          `
    };
    const l = new joint.shapes.standard.Link({
      source: {
        id: source.id,
        port: p1,
        selector: 'body',
        anchor: {
          name: px,
          args: { connectionPoint: { name: 'boundary', args: { padding: 40 } }, start: '100' }
      },

      },
      target: {
        id: target.id,
        port: p2,
        selector: 'body',
        anchor: {
          name: py,
          args: { connectionPoint: { name: 'boundary', args: { padding: 40 } } }

      },
      },
      labels: [
        { position: 0.5, attrs: {
          rect: {
          fill: 'lightblue', // Background color
          stroke: 'black',   // Border color
          strokeWidth: 0,    // Border width
          width: 120,        // Width of the background
          height: 20,        // Height of the background
          rx: 5,             // Border radius (optional)
          ry: 5              // Border radius (optional)
      }, text: { text: label, refX: 25,
        refY: 0.5 } } } // Set the label text and position (0.5 for middle)
      ],
   // Configure Manhattan style routing
      attrs: {
        line: {
          stroke: 'blue',
          strokeWidth: 1,
          sourceMarker: {
              'type': 'path',
              'stroke': 'black',
              'fill': '#6e6b6b',
              'd': 'M 10 -5 0 0 10 5 Z'
          },
          targetMarker: {
              'type': 'path',
              'stroke': 'black',
              'fill': '#6e6b6b',
              'd': 'M 10 -5 0 0 10 5 Z'
          }
      },
      wrapper: {
        pointerEvents: 'none' // Allows interaction with underlying shapes
    },
      },
      connector: { name: 'rounded' }, // Example connector, adjust as needed
    router: { name: 'orthogonal' }, // Example router, adjust as needed
    anchor: {
        name: 'center', // Use 'perpendicular' anchor to connect to the side of the target element
        args: { start: 200, end: '50%+10' } // Adjust the start and end positions as needed
    },

    });
    l.set('draggable', true);
    this.graph.addCell(l);
    return l;
  }


  getElementPredecessorLinks(el: joint.dia.Element): joint.dia.Link[] {
    return this.graph
      .getSubgraph([el, ...this.graph.getPredecessors(el)])
      .filter((cell: { isLink: () => any; }) => cell.isLink()) as joint.dia.Link[];
  }

  highlightCell(cell: joint.dia.Cell): void {
    joint.highlighters.addClass.add(
      cell.findView(this.paper),
      cell.isElement() ? "body" : "line",
      "selection",
      { className: "selection" }
    );
  }

  unhighlightCell(cell: joint.dia.Cell): void {
    joint.highlighters.addClass.remove(cell.findView(this.paper), "selection");
  }

  selection!: joint.dia.Element;

  selectElement(el: joint.dia.Element | null): void {
    if (this.selection === el) return;
    if (this.selection) {
      this.unhighlightCell(this.selection);
      this.graph.getLinks().forEach((link: joint.dia.Cell<joint.dia.Cell.Attributes, joint.dia.ModelSetOptions>) => this.unhighlightCell(link));
    }
    if (el) {
      this.highlightCell(el);
      this.getElementPredecessorLinks(el).forEach((link) => this.highlightCell(link));
      this.selection = el;
    } else {
      // this.selection = '';
    }
  }

  onElementClick(elementView: joint.dia.ElementView): void {
    this.selectElement(elementView.model);
  }

  onBlankClick(): void {
    this.selectElement(null);
  }



private showCustomTooltip(text: string, event: joint.dia.Event) {
  console.log(text, event)
  // Create a div element to act as the custom tooltip
  const tooltipDiv = document.createElement('div');
  tooltipDiv.innerHTML = text;
  tooltipDiv.style.position = 'absolute';
  tooltipDiv.style.background = 'lightyellow';
  tooltipDiv.style.padding = '5px';
  tooltipDiv.style.border = '1px solid #ccc';
  tooltipDiv.style.left = event.clientX + 'px';
  tooltipDiv.style.top = event.clientY + 'px';
  tooltipDiv.setAttribute('id', 'tooltip');

  // Attach the tooltip div to the body
  document.body.appendChild(tooltipDiv);


}

}
