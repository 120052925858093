<div class="container-fluid">
  <div class="row" style="height: 100%">
    <div class="col-sm-8 left d-none d-sm-block">
      <div id="particles-js">
        <canvas
          class="particles-js-canvas-el"
          width="1843"
          height="463"
          style="width: 100%; height: 100%"
        ></canvas>
      </div>
      <div class="left-wrapper">
        <h2 class="font-weight-bold">
          Unmatched Control, Visibility And Ease Of Managing Your Business
        </h2>
        <h6 class="pt-1 pb-4">Simple. Efficient. Transparent</h6>
        <div class="products">
          <!-- <img
            src="assets/login/images/TATA icons_Network.svg"
            class="icon-img"
          /> -->
          Network
        </div>
        <div class="products">
          <!-- <img src="assets/login/images/tata_cloud.svg" class="icon-img" /> -->
          Cloud Solutions
        </div>
        <div class="products">
          <!-- <img src="assets/login/images/tata_security.svg" class="icon-img" /> -->
          Security
        </div>
        <div class="products">
          <!-- <img
            src="assets/login/images/unified_communication.svg"
            class="icon-img"
          /> -->
          Unified Communication
        </div>
      </div>
    </div>
    <div class="col-sm-4 right right-content">
      <div class="row">
        <div class="col">
          <!-- <img
            src="assets/login/images/XMLID_18133_.svg"
            class="float-right d-flex pt-2"
          /> -->
        </div>
      </div>
      <div class="row">
        <div class="col">
          <img
            src="https://customersandbox.tatacommunications.com/optimus-login/assets/login/images/tata-right-content.svg"
            class="d-flex mx-auto mt-2"
            style="height: 100px"
          />
        </div>
      </div>
      <div class="text-center">
        <div class="login-cp">Sign in to customer portal</div>
      </div>
      <div class="text-center">
        <div data-toggle="buttons" class="btn-group btn-group-toggle">
          <label class="btn btn-outline-toggle active"
            ><input
              type="radio"
              name="options"
              class="ng-untouched ng-pristine ng-valid"
            />
            USE PASSWORD </label
          ><label class="btn btn-outline-toggle"
            ><input
              type="radio"
              name="options"
              class="ng-untouched ng-pristine ng-valid"
            />
            USE OTP
          </label>
        </div>
      </div>
      <div class="row right-wrapper">
        <div class="col">
          <div class="login-box mt-2 overlay">
            <div class="login-box-content mt-2">
              <form
                novalidate=""
                autocomplete="off"
                class="ng-dirty ng-valid ng-touched"
              >
                <div id="kc-form" class="mt-2">
                  <div id="kc-form-wrapper">
                    <div id="email-block" class="form-group mt-2">
                      <label for="username">Email ID</label
                      ><input
                        id="username"
                        name="email"
                        type="text"
                        placeholder=""
                        autofocus=""
                        required=""
                        value="demo@tatacommunications.com"
                        autocomplete="none"
                        class="form-input ng-dirty ng-valid ng-touched"
                      /><!---->
                    </div>
                    <!----><!----><!----><!---->
                    <div id="pwd-block" class="form-group mt-2">
                      <label for="password">Password</label
                      ><input
                        id="password"
                        name="password"
                        type="password"
                        placeholder=""
                        autocomplete="off"
                        required=""
                        value="qwertyuiop"
                        class="form-input ng-dirty ng-valid ng-touched"
                      /><!---->
                    </div>
                    <!----><!----><!----><!----><!---->
                    <div id="login-block">
                      <div class="text-center mt-4">
                        <button
                          id="login-btn"
                          name="login"
                          type="submit"
                          (click)="navigatetoproduct()"
                          class="btn btn-sm active-btn btn-block"
                          tourAnchor="tour-1"
                        >
                          Sign in</button
                        ><!----><!----><!----><!---->
                      </div>
                      <br />
                      <div class="text-center">
                        <a id="forgot-pwd" class="pointer">Forgot Password ?</a>
                      </div>
                      <!---->
                    </div>
                    <!----><!---->
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <!---->
      <footer class="footer-position">
        <div class="col login-cp footer-wrapper">
          <a
            target="_blank"
            href="https://www.tatacommunications.com/policies/overview"
            class="text-muted"
            >Policies</a
          ><span class="text-muted px-1">|</span
          ><a
            target="_blank"
            href="https://www.tatacommunications.com/legal"
            class="text-muted"
            >Legal</a
          ><span class="text-muted px-1">|</span
          ><a
            target="_blank"
            href="https://www.tatacommunications.com/sitemap"
            class="text-muted"
            >Sitemap</a
          ><span class="text-muted px-1">|</span
          ><a
            target="blank"
            href="https://www.tatacommunications.com/contact/"
            class="text-muted"
            >Contact Us</a
          ><span class="text-muted px-1">|</span
          ><a
            target="_blank"
            href="https://customer.tatacommunications.com/optimus-deliverystatus/faq"
            class="text-muted"
            >Help</a
          >
        </div>
      </footer>
    </div>
  </div>
</div>

<tour-step-template>
  <ng-template let-step="step">
    <div class="tour-pointer"></div>
    <div class="tour">
      <p class="ngxp-title">{{ step?.title }}</p>
      <p class="ngxp-content">{{ step?.content }}</p>
      <div class="tour-step-navigation">
        <!-- <button
          [disabled]="!tourService.hasPrev(step)"
          class="ngxp-btn"
          (click)="tourService.prev()"
        >
          « prev
        </button> -->

        <button class="ngxp-btn" (click)="handleNextButtonClick(step)">
          next »
        </button>
        <!-- <button class="ngxp-btn" (click)="tourService.end();setValuesOnNext(step?.anchorId)">end</button> -->
      </div>
    </div>
  </ng-template>
</tour-step-template>
