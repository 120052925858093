import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-variantselect',
  templateUrl: './variantselect.component.html',
  styleUrls: ['./variantselect.component.css']
})
export class VariantselectComponent implements OnInit {

  constructor(private router:Router, private tourService: TourService) { }

  ngOnInit(): void {
    const tourSteps:any = [
      {
        anchorId: 'tour-1', // Solution Alias Name
        content: 'Please select IZO MCC Variant', // Updated content
        title: 'IZO MCC Variants', // Updated title
        placement: 'top'
    },
    {
      anchorId: 'tour-2', // Solution Alias Name
      content: 'Click Here to design your solution', // Updated content
      title: 'Solution Design', // Updated title
      placement: 'top'
  }
    ]

    this.tourService.initialize(tourSteps, {
      enableBackdrop: false,
      backdropConfig: {
        //@ts-ignore
        offset: 10,
        closeOnBackdropClick: false,
      },
    });

    setTimeout(()=>{
      this.tourService.start();
    },200)
  }

  setValuesOnNext(step:any){

    if(step==='tour-1'){
      document.getElementById('configurationss').click()
    }
    if(step==='tour-2'){
      this.router.navigate(['/landingPage'])
    }
  }

  handleNextButtonClick(step: any) {
    if (this.tourService.hasNext(step)) {
        this.tourService.next();
        this.setValuesOnNext(step?.anchorId);
    } else {
        this.tourService.end();
        this.setValuesOnNext(step?.anchorId);
    }
}


  navigatetolanding(){
    this.router.navigate(['/landingPage'])
  }

}
