import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-topology',
  templateUrl: './topology.component.html',
  styleUrls: ['./topology.component.css']
})
export class TopologyComponent implements OnInit {


ngOnInit() {

}
}
