
import { TokenInterceptor } from './../app.interceptor';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';

import { NgxPermissionsModule } from 'ngx-permissions';
import { GscNameChangePipe } from '../common/pipes/gscNameChange.pipe'

import {
    MatDatepickerModule,
} from '@angular/material/datepicker';
import { MatBadgeModule } from '@angular/material/badge';
import { MatIconModule } from '@angular/material/icon';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatGridListModule } from '@angular/material/grid-list';
// import {  } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSliderModule } from '@angular/material/slider';
import { MatCardModule } from '@angular/material/card';
import { MatListModule } from '@angular/material/list';
import { MatChipsModule } from '@angular/material/chips';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatCheckboxModule } from '@angular/material/checkbox';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatRadioModule } from '@angular/material/radio';
import { MatStepperModule } from '@angular/material/stepper';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatRippleModule } from '@angular/material/core';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MatPaginatorModule } from '@angular/material/paginator'
// import {
//     AccordionModule, TooltipModule, ModalModule, TabsModule, AlertModule,
//     CollapseModule, BsDropdownModule, PopoverModule, TypeaheadModule
// } from 'ngx-bootstrap';
import {  } from 'ngx-bootstrap/timepicker';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AlertModule } from 'ngx-bootstrap/alert';
import { CollapseModule } from 'ngx-bootstrap/collapse';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';

import { ErrorPageComponent } from './component/error-page/error-page.component';
import 'hammerjs';
import { MessageModalComponent } from './component/message-modal/message-modal.component';
import { ConfirmModalComponent } from './component/confirm-modal/confirm-modal.component';
import { LoaderModalComponent } from "./component/loader-modal/loader-modal.component";
import { CommonValidationService } from './service/common-validation.service';
import { SpinnerComponent } from './component/spinner/spinner.component';
import { attrOrderPipe, customOrderPipe, filterPipe, KeysPipe, TitlePipe, TitleCasePipe, MinuteSecondsPipe, filterPipeNew } from './pipes/attr-order-pipe';

// import { ChartArea } from './component/chart/chart.component';



import { UploadDocumentsService } from './service/upload-documents/upload-documents.service';

/**Highchart */
// import { HighchartsChartModule } from 'highcharts-angular';

/**Ngx Bootstrap Pagination */
import { PaginationModule } from 'ngx-bootstrap/pagination';
// import { ChannelCalculatorModalComponent } from './component/channel-calculator-modal/channel-calculator-modal.component';
//data time picker
import { MaterialModule } from './material.module';
// import { MatDialogComponent } from './component/generic-form-fields/components/mat-dialog/mat-dialog.component';
// import { TimerComponent } from './component/generic-form-fields/components/timer/timer.component';
// import { InputComponent } from './component/generic-form-fields/components/input/input.component';
// import { SelectComponent } from './component/generic-form-fields/components/select/select.component';
// import { RadioComponent } from './component/generic-form-fields/components/radio/radio.component';
// import { PasswordComponent } from './component/generic-form-fields/components/password/password.component';
// import { CheckboxComponent } from './component/generic-form-fields/components/checkbox/checkbox.component';
// import { DateComponent } from './component/generic-form-fields/components/date/date.component';
// import { FileuploadComponent } from './component/generic-form-fields/components/fileupload/fileupload.component';
// import { TextAreaComponent } from './component/generic-form-fields/components/text-area/text-area.component';
// import { NumberComponent } from './component/generic-form-fields/components/number/number.component';
// import { GoogleMapComponent } from './component/generic-form-fields/components/google-map/google-map.component';
// import { ButtonComponent } from './component/generic-form-fields/components/button/button.component';
// import { GenericFormComponent } from './component/generic-form-fields/generic-form/generic-form.component';
// import { GenericHeaderDetailsComponent } from './component/generic-form-fields/generic-header-form/generic-header-details.component';
// import { GenericFormFieldsDirective } from './component/generic-form-fields/directives/generic-form-fields.directive';
// import { GenericFormFieldsService } from './component/generic-form-fields/services/generic-form-fields.service';
// import { GenericFormValidationsService } from './component/generic-form-fields/services/generic-form-validations.service';
import { Angular2CsvModule } from 'angular2-csv';
// import { AdminTaskHeaderComponent } from './component/admin-task-header/admin-task-header.component';
// import { AssignTaskComponent } from './component/assign-task/assign-task.component';
import { LoadingWidgetComponent } from './component/loading-widget/loading-widget.component';
import { NavigationService } from './service/navigation-service';import { CommaSeperatorPipe } from './pipes/comma-seperator.pipe';
import { SitewiseBillingStatePipe } from './pipes/sitewise-billing-state-pipe';

import { DndModule } from 'ngx-drag-drop';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { QRCodeModule } from 'angularx-qrcode';
// import { IzoNeModule } from '../izo-ne/izo-ne.module';
import { StepWidgetComponent } from './component/step-widget/step-widget.component';
import { CommonSalesOrderComponent } from './component/sales-order/sales-order.component';

import { OrderFormService } from '../izomcc/order-form/order-form.service';
// import { OrderFormService } from './../izo-ne/order-form/order-form.service'
@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        ReactiveFormsModule,
        AngularMultiSelectModule,
        TooltipModule.forRoot(),
        ModalModule.forRoot(),
        TabsModule.forRoot(),
        AlertModule.forRoot(),
        CollapseModule.forRoot(),
        BsDropdownModule.forRoot(),
        PopoverModule.forRoot(),
        AccordionModule.forRoot(),
        TypeaheadModule.forRoot(),
        TimepickerModule.forRoot(),
        // HighchartsChartModule,
        PaginationModule.forRoot(),
        MaterialModule,
        Angular2CsvModule,
        NgxPermissionsModule,
        NgxPermissionsModule.forChild(),
        MatRippleModule,
        MatSnackBarModule,
        MatProgressSpinnerModule,
        DndModule,
        InfiniteScrollModule,
        MatPaginatorModule,
        QRCodeModule,
        // IzoMCCModule
        // IzoNeModule
    ],
    declarations: [


        ErrorPageComponent,
        MessageModalComponent,
        LoaderModalComponent,
        ConfirmModalComponent,

        SpinnerComponent,
        attrOrderPipe,
        filterPipe,
        customOrderPipe,
        KeysPipe,
        TitlePipe,
        MinuteSecondsPipe,
        filterPipeNew,
        TitleCasePipe,
        // ChartArea,
        // ChannelCalculatorModalComponent,
        LoadingWidgetComponent,
        CommonSalesOrderComponent,
        // GenericFormComponent,
        // GenericHeaderDetailsComponent,
        // InputComponent,
        // SelectComponent,
        // RadioComponent,
        // PasswordComponent,
        // CheckboxComponent,
        // DateComponent,
        // FileuploadComponent,
        // TextAreaComponent,
        // NumberComponent,
        // GoogleMapComponent,
        // ButtonComponent,
        // TimerComponent,
        // MatDialogComponent,
        // AdminTaskHeaderComponent,
        // AssignTaskCommercialComponent,
        // AssignTaskComponent,
        // GenericFormFieldsDirective,
        CommaSeperatorPipe,
        GscNameChangePipe,
        SitewiseBillingStatePipe,
        //FeedbackComponent,
        // RevisitTaskComponent,

        StepWidgetComponent,

    ],
    providers: [
    ],
    exports: [
        CommonModule,
        FormsModule,
        HttpClientModule,
        RouterModule,
        ReactiveFormsModule,


        TooltipModule,
        ModalModule,
        TabsModule,
        AlertModule,
        CollapseModule,
        BsDropdownModule,
        AccordionModule,
        AngularMultiSelectModule,
        TypeaheadModule,
        MessageModalComponent,
        LoaderModalComponent,
        ConfirmModalComponent,
        SpinnerComponent,
        attrOrderPipe,
        filterPipe,
        customOrderPipe,
        KeysPipe,
        TitlePipe,
        MinuteSecondsPipe,
        filterPipeNew,
        TitleCasePipe,
        // ChartArea,

        // HighchartsChartModule,
        PaginationModule,
        // ChannelCalculatorModalComponent,
        MaterialModule,
        // GenericFormComponent,
        // GenericHeaderDetailsComponent,
        PopoverModule,
        Angular2CsvModule,
        LoadingWidgetComponent,
        // AdminTaskHeaderComponent,
        // AssignTaskComponent.
        // AssignTaskCommercialComponent,
        // GenericFormFieldsDirective,
        NgxPermissionsModule,
        MatRippleModule,
        MatSnackBarModule,
        CommaSeperatorPipe,
        GscNameChangePipe,
        NgxPermissionsModule,
        MatProgressSpinnerModule,
        // RevisitTaskComponent,
        // DndModule,
        DndModule,
        // InfiniteScrollModule,

        MatPaginatorModule,
        SitewiseBillingStatePipe,

        QRCodeModule,
        CommonSalesOrderComponent,
    ]
})

export class CommonModules { }
