<ng-template #loaderModal>
    <div class="service-details-modal height loader-modal-ex" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="" role="document">
            <div class="modal-content">
                <div class="modal-body">
                    <!-- <span class="spinner-center"><i class="fa fa-circle-o-notch fa-spin fa-3x fa-fw spinner-style" aria-hidden="true"></i></span> -->
                    <div class="block">
                        <!-- <div class="loadme-circleBounce">
                            <div class="loadme-circleBounce1"></div>
                            <div class="loadme-circleBounce2"></div>
                        </div> -->
                        <div class="spinner">
                            <div class="bounce1"></div>
                            <div class="bounce2"></div>
                            <div class="bounce3"></div>
                        </div>
                    </div>
                    <div class="message">
                        <h4>{{msgModalData.message}}</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>