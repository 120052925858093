<mat-drawer-container
  _ngcontent-mdp-c441=""
  class="mat-drawer-container example-container mat-drawer-container-explicit-backdrop"
  ng-reflect-has-backdrop="true"
  ><div class="mat-drawer-backdrop ng-star-inserted"></div>
  <!--bindings={
    "ng-reflect-ng-if": "true"
  }-->
  <div
    class="cdk-visually-hidden cdk-focus-trap-anchor"
    aria-hidden="true"
  ></div>
  <!--mat-drawer-anchor-->
  <div
    class="cdk-visually-hidden cdk-focus-trap-anchor"
    aria-hidden="true"
  ></div>
  <mat-drawer-content _ngcontent-mdp-c441="" class="mat-drawer-content"
    ><main _ngcontent-mdp-c441="">
      <div _ngcontent-mdp-c441="" class="landing-container">
        <h4 _ngcontent-mdp-c441="" class="SERVICE-OFFERINGS">
          Service Offerings
        </h4>
        <div _ngcontent-mdp-c441="" class="landing-menu">
          <div _ngcontent-mdp-c441="" class="menu-container">
            <ul _ngcontent-mdp-c441="" class="list-inline">
              <li
                _ngcontent-mdp-c441=""
                class="list-inline-item menu-item pointer ng-star-inserted"
              >
                <span _ngcontent-mdp-c441="" class="menu-icon"
                  ><img
                    _ngcontent-mdp-c441=""
                    alt=""
                    src="assets/images/All_Selected.svg" /></span
                >&nbsp;
                <label
                  _ngcontent-mdp-c441=""
                  for=""
                  class="pointer menu-selected"
                  ng-reflect-ng-class="[object Object]"
                  >All</label
                >
              </li>
              <li
                _ngcontent-mdp-c441=""
                class="list-inline-item menu-item pointer ng-star-inserted"
              >
                <span _ngcontent-mdp-c441="" class="menu-icon"
                  ><img
                    _ngcontent-mdp-c441=""
                    alt=""
                    src="assets/images/Network.svg" /></span
                >&nbsp;
                <label
                  _ngcontent-mdp-c441=""
                  for=""
                  class="pointer"
                  ng-reflect-ng-class="[object Object]"
                  >Network</label
                >
              </li>
              <li
                _ngcontent-mdp-c441=""
                class="list-inline-item menu-item pointer ng-star-inserted"
              >
                <span _ngcontent-mdp-c441="" class="menu-icon"
                  ><img
                    _ngcontent-mdp-c441=""
                    alt=""
                    src="assets/images/Cloud Solutions.svg" /></span
                >&nbsp;
                <label
                  _ngcontent-mdp-c441=""
                  for=""
                  class="pointer"
                  ng-reflect-ng-class="[object Object]"
                  >Cloud Solutions</label
                >
              </li>
              <li
                _ngcontent-mdp-c441=""
                class="list-inline-item menu-item pointer ng-star-inserted"
              >
                <span _ngcontent-mdp-c441="" class="menu-icon"
                  ><img
                    _ngcontent-mdp-c441=""
                    alt=""
                    src="assets/images/Security.svg" /></span
                >&nbsp;
                <label
                  _ngcontent-mdp-c441=""
                  for=""
                  class="pointer"
                  ng-reflect-ng-class="[object Object]"
                  >Security</label
                >
              </li>
              <li
                _ngcontent-mdp-c441=""
                class="list-inline-item menu-item pointer ng-star-inserted"
              >
                <span _ngcontent-mdp-c441="" class="menu-icon"
                  ><img
                    _ngcontent-mdp-c441=""
                    alt=""
                    src="assets/images/Unified Communications.svg" /></span
                >&nbsp;
                <label
                  _ngcontent-mdp-c441=""
                  for=""
                  class="pointer"
                  ng-reflect-ng-class="[object Object]"
                  >Unified Communications</label
                >
              </li>
              <!--bindings={
    "ng-reflect-ng-for-of": "[object Object],[object Object"
  }-->
            </ul>
          </div>
        </div>
        <div _ngcontent-mdp-c441="" class="landing-content">
          <div _ngcontent-mdp-c441="" class="row mb-2 ng-star-inserted">
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  Internet Access Service
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Connect your business to Internet through Tata Communications.
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><button
                  _ngcontent-mdp-c441=""
                  class="btn btn-class secondary-outline-btn purchase-btn ng-star-inserted"
                >
                  <strong _ngcontent-mdp-c441="">BUY</strong></button
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }--><!--bindings={
    "ng-reflect-ng-if": "false"
  }-->
              </div>
            </div>
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  IZO Internet
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Internet broadband service created in partnership with ISPs
                  for Indian Enterprise.
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><!--bindings={
    "ng-reflect-ng-if": "false"
  }--><a _ngcontent-mdp-c441="" class="purchase not-allowed ng-star-inserted"
                  ><strong _ngcontent-mdp-c441="">Coming Soon</strong></a
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }-->
              </div>
            </div>
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  IZO SDWAN
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Maximise performance with cutting edge software-defined and
                  virtualised network technology.
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><!--bindings={
    "ng-reflect-ng-if": "false"
  }--><a _ngcontent-mdp-c441="" class="purchase not-allowed ng-star-inserted"
                  ><strong _ngcontent-mdp-c441="">Coming Soon</strong></a
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }-->
              </div>
            </div>
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  Private WAN
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Extend your enterprise globally with secure and scalable
                  applications.
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><button
                  _ngcontent-mdp-c441=""
                  class="btn btn-class secondary-outline-btn purchase-btn ng-star-inserted"
                >
                  <strong _ngcontent-mdp-c441="">BUY</strong></button
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }--><!--bindings={
    "ng-reflect-ng-if": "false"
  }-->
              </div>
            </div>
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  IZO Private Cloud
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Integrated cloud platform, having global dimensions with
                  security and multi-cloud environment.
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><button
                  _ngcontent-mdp-c441=""
                  class="btn btn-class secondary-outline-btn purchase-btn ng-star-inserted"
                >
                  <strong _ngcontent-mdp-c441="">BUY</strong></button
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }--><!--bindings={
    "ng-reflect-ng-if": "false"
  }-->
              </div>
            </div>
            <!--bindings={
    "ng-reflect-ng-for-of": "[object Object],[object Object"
  }--><!--bindings={
    "ng-reflect-ng-if": "false"
  }-->
          </div>
          <div _ngcontent-mdp-c441="" class="row mb-2 ng-star-inserted">
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  Managed Security Service
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Multi layered Managed security services to minimize business
                  risk.
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><!--bindings={
    "ng-reflect-ng-if": "false"
  }--><a _ngcontent-mdp-c441="" class="purchase not-allowed ng-star-inserted"
                  ><strong _ngcontent-mdp-c441="">Coming Soon</strong></a
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }-->
              </div>
            </div>
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  Voice Services
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Energize your UC strategy with our Global SIP Connect Network
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><button
                  _ngcontent-mdp-c441=""
                  class="btn btn-class secondary-outline-btn purchase-btn ng-star-inserted"
                >
                  <strong _ngcontent-mdp-c441="">BUY</strong></button
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }--><!--bindings={
    "ng-reflect-ng-if": "false"
  }-->
              </div>
            </div>
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  Voice Services Beta Version
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Energize your UC strategy with our Global SIP Connect Network
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><button
                  _ngcontent-mdp-c441=""
                  class="btn btn-class secondary-outline-btn purchase-btn ng-star-inserted"
                >
                  <strong _ngcontent-mdp-c441="">BUY</strong></button
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }--><!--bindings={
    "ng-reflect-ng-if": "false"
  }-->
              </div>
            </div>
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  GlobalRapide
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Bring everything &amp; every one together with best-in-class
                  collaboration services.
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><button
                  _ngcontent-mdp-c441=""
                  class="btn btn-class secondary-outline-btn purchase-btn ng-star-inserted"
                >
                  <strong _ngcontent-mdp-c441="">BUY</strong></button
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }--><!--bindings={
    "ng-reflect-ng-if": "false"
  }-->
              </div>
            </div>
            <div
              tourAnchor="tour-1"
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  IZO Multi Cloud Connect
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Deliver high performance and secure cloud connectivity across
                  your business network through various connections.
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><button
                  _ngcontent-mdp-c441=""
                  class="btn btn-class secondary-outline-btn purchase-btn ng-star-inserted"
                  (click)="navigatetovariant()"
                >
                  <strong _ngcontent-mdp-c441="">BUY</strong></button
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }--><!--bindings={
    "ng-reflect-ng-if": "false"
  }-->
              </div>
            </div>
            <!--bindings={
    "ng-reflect-ng-for-of": "[object Object],[object Object"
  }--><!--bindings={
    "ng-reflect-ng-if": "false"
  }-->
          </div>
          <div _ngcontent-mdp-c441="" class="row mb-2 ng-star-inserted">
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">WAN</h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Extend your enterprise connectivity globally with secure and
                  scalable network solutions.
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><!--bindings={
    "ng-reflect-ng-if": "false"
  }--><a _ngcontent-mdp-c441="" class="purchase not-allowed ng-star-inserted"
                  ><strong _ngcontent-mdp-c441="">Coming Soon</strong></a
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }-->
              </div>
            </div>
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  Private Line
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  Highly scalable, secure, resilient, and agile connectivity to
                  enable your journey towards digital transformation
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><button
                  _ngcontent-mdp-c441=""
                  class="btn btn-class secondary-outline-btn purchase-btn ng-star-inserted"
                >
                  <strong _ngcontent-mdp-c441="">BUY</strong></button
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }--><!--bindings={
    "ng-reflect-ng-if": "false"
  }-->
              </div>
            </div>
            <div
              _ngcontent-mdp-c441=""
              class="col ml-2 product-tile ng-star-inserted"
            >
              <div _ngcontent-mdp-c441="" class="product-box">
                <h4 _ngcontent-mdp-c441="" class="product-title pb-3">
                  Dedicated Internet Access
                </h4>
                <p _ngcontent-mdp-c441="" class="product-short">
                  DIA service is designed especially for enterprise requiring
                  dedicated access to the Internet....
                </p>
                <a
                  _ngcontent-mdp-c441=""
                  containerclass="popCustomClass"
                  class="explore pointer"
                  ><strong _ngcontent-mdp-c441="">Explore</strong></a
                ><!--bindings={
    "ng-reflect-ng-if": "false"
  }--><a _ngcontent-mdp-c441="" class="purchase not-allowed ng-star-inserted"
                  ><strong _ngcontent-mdp-c441="">Coming Soon</strong></a
                ><!--bindings={
    "ng-reflect-ng-if": "true"
  }-->
              </div>
            </div>
            <!--bindings={
    "ng-reflect-ng-for-of": "[object Object],[object Object"
  }-->
            <div _ngcontent-mdp-c441="" class="col ng-star-inserted"></div>
            <div _ngcontent-mdp-c441="" class="col ng-star-inserted"></div>
            <!--bindings={
    "ng-reflect-ng-for-of": "1,1"
  }--><!--ng-container--><!--bindings={
    "ng-reflect-ng-if": "true"
  }-->
          </div>
          <!--bindings={
    "ng-reflect-ng-for-of": "[object Object],[object Object"
  }-->
        </div>
      </div>
    </main></mat-drawer-content
  ><!--bindings={
    "ng-reflect-ng-if": "false"
  }--><mat-drawer
    _ngcontent-mdp-c441=""
    tabindex="-1"
    position="end"
    class="mat-drawer ng-tns-c158-0 ng-trigger ng-trigger-transform mat-drawer-end mat-drawer-over ng-star-inserted"
    ng-reflect-position="end"
    ng-reflect-mode="over"
    style="box-shadow: none; visibility: hidden"
    ><div cdkscrollable="" class="mat-drawer-inner-container ng-tns-c158-0">
      <div _ngcontent-mdp-c441="" class="ng-star-inserted" style="">
        <div _ngcontent-mdp-c441="" class="p-2">
          <button
            _ngcontent-mdp-c441=""
            type="button"
            aria-label="Close"
            class="close float-right pointer"
          >
            <i
              _ngcontent-mdp-c441=""
              aria-hidden="true"
              class="fa fa-times-circle"
            ></i>
          </button>
        </div>
        <h4 _ngcontent-mdp-c441="" class="explore-title"></h4>
        <p _ngcontent-mdp-c441="" class="explore-short ng-star-inserted"></p>
        <!--bindings={
    "ng-reflect-ng-if": "true"
  }--><!--bindings={
    "ng-reflect-ng-if": "false"
  }-->
        <h4 _ngcontent-mdp-c441="" class="explore-sub-title"></h4>
        <ul _ngcontent-mdp-c441="" class="card-text">
          <!--bindings={}-->
        </ul>
        <!--bindings={}-->
      </div>
      <!--bindings={
    "ng-reflect-ng-template-outlet": "[object Object]"
  }-->
    </div></mat-drawer
  ></mat-drawer-container
>

<tour-step-template>
  <ng-template let-step="step">
    <div class="tour-pointer"></div>
    <div class="tour">
      <p class="ngxp-title">{{ step?.title }}</p>
      <p class="ngxp-content">{{ step?.content }}</p>
      <div class="tour-step-navigation">
        <!-- <button
          [disabled]="!tourService.hasPrev(step)"
          class="ngxp-btn"
          (click)="tourService.prev()"
        >
          « prev
        </button> -->

        <button class="ngxp-btn" (click)="handleNextButtonClick(step)">
          next »
        </button>
        <!-- <button class="ngxp-btn" (click)="tourService.end();setValuesOnNext(step?.anchorId)">end</button> -->
      </div>
    </div>
  </ng-template>
</tour-step-template>
