<div style="margin-top: 100.44px">
  <section class="container-header-scp">
    <section class="">
      <div class="row">
        <div class="col-md-8" style="padding-left: 33px">
          <h3>
            <i class="icon icon-back pr-2" (click)="goBack()"></i> Here's your
            quote
          </h3>
        </div>
        <div class="col-md-4" style="padding-right: 56px">
          <div style="width: 98%; justify-content: end; display: flex">
            <button
              type="button"
              class="btn active-btn"
              *ngIf="arr1 && arr1.length == 0"
              [disabled]="
                (quoteSolutionData && quoteSolutionData.length) !=
                  (completedConfigs && completedConfigs.length) ||
                (quoteStatusCommercial &&
                  quoteStatusCommercial != 'Commercial Updated')
              "
              (click)="addToCart()"
            >
              CHECKOUT
            </button>
          </div>
        </div>
      </div>
    </section>
  </section>
  <div
    class="pt-4"
    style="margin: 0px 51px; border-top: 1px solid #667586"
  ></div>
  <section class="get-quote container">
    <div class="row">
      <div
        [ngClass]="{
          'col-lg-12': displayPricingPanel == false,
          'col-lg-9': displayPricingPanel == true
        }"
        class="col-xs-12 col-sm-12 col-md-8"
      >
        <div class="card ct-card card-wrapper-height">
          <div class="card-header card-header-wrapper">
            <div
              class="d-flex justify-content-between align-items-center"
              style="width: 100%"
            >
              <!-- <div class="w33">
                                <div class="left">
                                    <div class="media ct-inline-form">
                                        <span class="align-self-center mr-3">Group By:</span>
                                        <div class="media-body">
                                            <div class="select">
                                                <select [(ngModel)]="getQuoteObj.groupBySel"
                                                    (change)="onGroupByChange($event.target.value)" class="form-control"
                                                    id="groupDp">
                                                    <option *ngFor="let group of getQuoteObj.groupByData"
                                                        [ngValue]="group">{{group.value}}</option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> -->
              <div class="quoteCode_details w77">
                QuoteCode : <span> IZOMCCDEMO001</span>
              </div>
            </div>
            <div class="d-flex justify-content-between align-items-center">
              <ng-container *ngIf="isOptimusSales">
                <p class="quote-status" *ngIf="quoteStatusCommercial">
                  <b>Quote Status :</b> {{ quoteStatusCommercial }}
                </p>
              </ng-container>
            </div>
          </div>
          <div class="grpby-content-wrapper">
            <div class="card-body padding-none" style="height: 300px">
              <div
                class="custom-tabs hide-group active"
                id="feasibility"
                style="height: 0px"
              >
                <div
                  class="tab-content mCustomScrollbar"
                  id="nav-tabContent"
                  style="height: 480px"
                >
                  <!--380px-->
                  <div class="tab-pane fade show active">
                    <ng-container>
                      <div *ngFor="let el of configarr; let i = index">
                        <ul style="padding-left: 10px">
                          <li>
                            <div
                              class="row izone-review-config"
                              style="display: block"
                            >
                              <div class="card cardHeader">
                                <div class="row accHeaders1">
                                  <div
                                    class="col-md-3"
                                    style="margin-left: 25px"
                                  >
                                    Status
                                  </div>
                                  <div
                                    class="col-md-3"
                                    style="margin-left: 49px"
                                  >
                                    Location
                                  </div>
                                  <div
                                    class="col-md-4"
                                    style="margin-left: 45px"
                                  >
                                    Service
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="row izone-review-config"
                              style="display: block"
                            >
                              <mat-accordion style="width: 77rem !important">
                                <mat-expansion-panel
                                  [expanded]="currentlyOpenedItemIndex == i"
                                  (opened)="openPanel(el, i)"
                                  (closed)="closePanel(el, i)"
                                >
                                  <mat-expansion-panel-header>
                                    <div class="row" style="width: 100%">
                                      <div class="col-4 txt-center">
                                        <mat-panel-title class="accHeaders1">
                                          Quote Generated
                                        </mat-panel-title>
                                      </div>
                                      <div class="col-4 txt-center">
                                        <mat-panel-title class="accHeaders1">
                                          {{ el.locationName }}
                                        </mat-panel-title>
                                      </div>
                                      <div class="col-4">
                                        <mat-panel-title class="accHeaders1">
                                          IZO<sup>TM</sup> &nbsp;Multi Cloud
                                          Connect Flex
                                        </mat-panel-title>
                                      </div>
                                    </div>
                                  </mat-expansion-panel-header>
                                  <mat-expansion-panel-body>
                                    <div
                                      class="p-2 ng-tns-c211-3"
                                      style="font-family: Gotham-Book"
                                    >
                                      <b>Commercials</b>
                                    </div>
                                    <br class="break ng-tns-c211-3" />
                                    <table
                                      class="table table-responsive-sm ng-tns-c211-3"
                                    >
                                      <thead>
                                        <th>Chargeable Items</th>
                                        <th>Description</th>
                                        <th>Bandwidth</th>
                                        <th>MRC (USD)</th>
                                        <th>NRC (USD)</th>
                                        <!---->
                                      </thead>
                                      <tbody>
                                        <!---->
                                        <tr
                                          class="table-card ng-star-inserted"
                                          style=""
                                        >
                                          <td class="vm-title">
                                            Virtual Circuit Charge
                                          </td>
                                          <td>
                                            Microsoft Azure -
                                            <span>Chicago</span>
                                          </td>
                                          <!----><!----><!----><!---->
                                          <td class="ng-star-inserted">
                                            100Mbps
                                          </td>
                                          <!----><!----><!----><!----><!---->
                                          <td>295.25</td>
                                          <td>1061.29</td>
                                        </tr>
                                        <tr
                                          class="table-card ng-star-inserted"
                                          style=""
                                        >
                                          <td class="vm-title">
                                            Virtual Circuit Charge
                                          </td>
                                          <td>
                                            Google Cloud Provider -
                                            <span>Chicago</span>
                                          </td>
                                          <!----><!----><!----><!---->
                                          <td class="ng-star-inserted">
                                            50Mbps
                                          </td>
                                          <!----><!----><!----><!----><!---->
                                          <td>147.63</td>
                                          <td>530.65</td>
                                        </tr>

                                        <tr
                                          class="table-card ng-star-inserted"
                                          style=""
                                        >
                                          <td class="vm-title">
                                            Virtual Circuit Charge
                                          </td>
                                          <td>
                                            Amazon Web Services -
                                            <span>Chicago</span>
                                          </td>
                                          <!----><!----><!----><!---->
                                          <td class="ng-star-inserted">
                                            50Mbps
                                          </td>
                                          <!----><!----><!----><!----><!---->
                                          <td>147.63</td>
                                          <td>530.65</td>
                                        </tr>
                                        <tr
                                          class="table-card ng-star-inserted"
                                          style=""
                                        >
                                          <td class="vm-title">
                                            Edge Connector Charge
                                          </td>
                                          <!----><!---->
                                          <td class="ng-star-inserted">
                                            Edge Connect Internet-Link1
                                          </td>
                                          <td>50Mbps</td>
                                          <td>137.97</td>
                                          <td>0.00</td>
                                        </tr>
                                        <tr
                                          class="table-card ng-star-inserted"
                                          style=""
                                        >
                                          <td class="vm-title">
                                            Virtual Device Charge
                                          </td>
                                          <td>Cisco Catalyst 8000v Router</td>
                                          <td>-</td>
                                          <td>1931.56</td>
                                          <td>0.00</td>
                                          <!---->
                                        </tr>
                                        <tr
                                          class="table-card ng-star-inserted"
                                          style=""
                                        >
                                          <td class="vm-title">
                                            VNF License Charge
                                          </td>
                                          <td>DNA Advantage</td>
                                          <td>-</td>
                                          <td>585.83</td>
                                          <td>0.00</td>
                                          <!---->
                                        </tr>
                                      </tbody>
                                    </table>
                                  </mat-expansion-panel-body>
                                </mat-expansion-panel>
                              </mat-accordion>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
              <!-- <div style="margin-top: 20%;">
                            <app-visual></app-visual>
                          </div> -->
            </div>
          </div>

          <!-- <div class="card-body padding-none">
                            <div class="custom-tabs hide-group" id="feasibility">
                                <div class="tab-content mCustomScrollbar" id="nav-tabContent">
                                    <div class="tab-pane fade show active">
                                        <ng-container>
                                            <div class="quote-list">
                                                <div>
                                                    <ul>
                                                        <li>
                                                            <div class="row">
                                                                <div
                                                                    class="col-6 col-md-6 col-sm-6 align-items-center d-flex">
                                                                    <label class="check-box check-box-left">
                                                                        <input type="checkbox">
                                                                        <span class="checkmark"></span>
                                                                        <span> Pricing in progress </span>
                                                                    </label>
                                                                </div>
                                                                <div
                                                                    class="col-4  col-md-4 col-sm-4 align-items-center d-flex">
                                                                    <label> IZO Network Edge </label>
                                                                </div>
                                                                <div *ngIf="false"
                                                                    class="col-1  col-md-1  col-sm-1 align-items-center d-flex">
                                                                    <a  >
                                                                        <i class="fa fa-trash fa-lg"></i>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </div> -->
        </div>
      </div>
      <!-- [ngClass]="{'active-grp':  (defaultTabSeleted || tabSelected) === 'Ongoing' }" -->
      <div
        [ngClass]="{
          'col-lg-3': displayPricingPanel === true,
          'quote-display': displayPricingPanel === false
        }"
        class="col-xs-12 col-sm-12 col-md-4"
        style="padding-left: 0 !important"
      >
        <div class="card ct-card blue-card card-wrapper-height">
          <div
            class="card-header d-flex justify-content-between align-items-center flex-row"
          >
            <div class="title">Quote</div>
            <div
              class="right"
              *ngIf="
                quoteStatusCommercial &&
                quoteStatusCommercial == 'Commercial Updated' &&
                arr1 &&
                arr1.length == 0
              "
            >
              <a
                class="white-icon"
                (click)="downloadQuote()"
                style="cursor: pointer"
              >
                <i
                  [ngClass]="{ 'disable-pointer': defaultDisable === true }"
                  class="icon-download"
                ></i>
                <!--  -->
              </a>
              <a
                href="javascript:void(0)"
                [ngClass]="{ 'disable-pointer': defaultDisable === true }"
                class="white-icon"
                (click)="onShareQuoteOpen()"
              >
                <i class="icon-share"></i>
                <!--  -->
              </a>
            </div>
          </div>
          <div class="card-body mCustomScrollbar">
            <div class="get-quote-summary">
              <div class="d-flex justify-content-between">
                <div class="left" style="width: 10rem">
                  <!-- <div class="select"> -->
                  <select
                    (change)="
                      onContractChange(
                        $event.target.value,
                        $event.target.selectedIndex
                      )
                    "
                    [value]="Monthsupdate"
                    class="form-control select1"
                    [disabled]="true"
                    data-toggle="tooltip"
                    data-placement="top"
                    title="{{ Monthsupdate }}"
                  >
                    <option
                      *ngFor="let eachContract of contractData"
                      [value]="eachContract"
                    >
                      {{ eachContract | titlecase }} Contract
                    </option>
                  </select>
                  <!-- <mat-form-field>
                                            <mat-label>{{Monthsupdate}}</mat-label>
                                        <mat-select  (change)="onContractChange($event.target.value, $event.target.selectedIndex)" [value]="Monthsupdate" class="form-control" [disabled]="true" matTooltip="{{Monthsupdate}}" [matTooltipDisabled]="Monthsupdate != 'Non-committed term – Daily Rate plan'">
                                            <option *ngFor="let eachContract of contractData" [value]="eachContract" style = "color: white !important;">
                                                {{eachContract | titlecase}} Contract
                                            </option>
                                        </mat-select>
                                    </mat-form-field> -->
                  <!-- </div> -->
                </div>
                <div class="right" style="width: 75px">
                  <div class="select">
                    <select
                      (change)="onCurrencyChange($event.target.value)"
                      class="form-control select1"
                      [value]="currency"
                      [disabled]="true"
                    >
                      <option *ngFor="let obj of currencyData" [value]="obj">
                        {{ obj }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>

              <div class="price-summary">
                <div class="d-flex justify-content-between heading">
                  <div class="left">Monthly (MRC)</div>
                  <div class="right amt-unit">
                    <span> 3245.87 </span>
                    <span
                      *ngIf="
                        quoteStatusCommercial == 'Submitted to Commercial' ||
                        cflag
                      "
                      >0.0</span
                    >
                  </div>
                </div>
              </div>
              <div class="price-summary">
                <div class="d-flex justify-content-between heading">
                  <div class="left">One Time (NRC)</div>
                  <div class="right amt-unit">
                    <span> 2122.59 </span>
                    <span
                      *ngIf="
                        quoteStatusCommercial == 'Submitted to Commercial' ||
                        cflag
                      "
                      >0.0</span
                    >
                  </div>
                </div>
              </div>
              <div class="price-summary hidden-content">
                <div class="d-flex justify-content-between heading">
                  <div class="left">Total Solution Quote</div>
                  <!-- <div class="right">
                                            0</div> -->
                  <div
                    class="right"
                    style="font-family: 'Gotham-Medium'; font-size: 12px"
                  >
                    5368.46
                  </div>
                  <div
                    class="right"
                    *ngIf="
                      quoteStatusCommercial == 'Submitted to Commercial' ||
                      cflag
                    "
                  >
                    0
                  </div>
                </div>
                <div class="d-flex justify-content-between amt">
                  <div
                    class="left"
                    style="font-family: 'Gotham-Book'; font-size: 12px"
                  >
                    MRC
                  </div>
                  <div
                    class="right"
                    *ngIf="
                      quoteStatusCommercial == 'Submitted to Commercial' ||
                      cflag
                    "
                  >
                    0
                  </div>
                  <div
                    class="right"
                    style="font-family: 'Gotham-Book'; font-size: 12px"
                  >
                    3245.87
                  </div>
                </div>
                <div class="d-flex justify-content-between amt">
                  <div
                    class="left"
                    style="font-family: 'Gotham-Book'; font-size: 12px"
                  >
                    NRC
                  </div>
                  <div
                    class="right"
                    *ngIf="
                      quoteStatusCommercial == 'Submitted to Commercial' ||
                      cflag
                    "
                  >
                    0
                  </div>
                  <div
                    class="right"
                    style="font-family: 'Gotham-Book'; font-size: 12px"
                  >
                    2122.59
                  </div>
                </div>
              </div>
              <div class="price-summary">
                <div class="d-flex justify-content-between heading">
                  <div
                    class="left"
                    style="font-family: 'Gotham-Medium'; font-size: 13px"
                  >
                    Other Details
                  </div>
                </div>

                <div class="d-flex justify-content-between amt ll1">
                  <div class="left">Taxes</div>
                  <div class="right">Extra</div>
                </div>
                <div class="d-flex justify-content-between amt ll1">
                  <div class="left">Validity of Quote</div>
                  <div class="right">30 Days</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<ng-template #shareModal>
  <div id="shareModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div role="document">
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-content-between align-items-center flex-row"
        >
          <h5 class="modal-title">Share Quote Details</h5>
          <button
            type="button"
            class="close"
            (click)="onShareQuoteClose()"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="icon-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <div class="col">
            <label>E-Mail Id</label>
            <input
              type="text"
              class="form-control"
              [(ngModel)]="shareEmailId"
              (input)="onEmail($event)"
              (blur)="validateShare()"
              data-matomo-unmask
            />
            <span *ngIf="status && status == false" class="text-danger"
              >Invalid email address
            </span>
            <span
              *ngIf="
                shareEmailId == null ||
                shareEmailId == undefined ||
                shareEmailId == ''
              "
              class="text-danger"
              >Email address required</span
            >
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            [disabled]="!status"
            (click)="shareQuote()"
            class="close"
            class="btn active-btn"
          >
            Share
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #messageModal>
  <div
    class="service-details-modal"
    id=""
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="" role="document">
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-content-between align-items-center flex-row"
        >
          <h5 class="modal-title">Message</h5>
          <button
            type="button"
            (click)="closeModal()"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="icon-close"></i>
          </button>
        </div>
        <div class="modal-body">
          <!-- <h4>{{msgModalData.message}}</h4> -->
          <h4>{{ msg }}</h4>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            (click)="closeModal()"
            class="close"
            data-dismiss="modal"
            class="btn active-btn"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #askPricePopup>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">Get Ask Price</h5>
    <button type="button" class="close" (click)="hide()" aria-label="Close">
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body ask-price-popup">
    <p>Please enter the Ask Price</p>
    <div class="row">
      <div class="col-md-2">
        <p>
          Total Configurations Selected -
          <span class="text-color">{{ length }}</span>
        </p>
      </div>
      <div class="col-md-2 pl-0">
        <p>
          Total MRC -
          <span class="text-color">{{
            askPrice.totalMRC | number : "1.2-2"
          }}</span>
        </p>
      </div>
      <div class="col-md-2">
        <p>
          Total NRC -
          <span class="text-color">{{
            askPrice.totalNRC | number : "1.2-2"
          }}</span>
        </p>
      </div>
      <div class="col-md-3 pr-0 margin-top-negative">
        <!-- <p>Total NRC - <span class="text-color">{{askPrice.totalNRC |number:'1.0-0'}}</span></p> -->
        <p class="inline-style">Expected Total MRC -</p>
        <input
          class="input-label"
          [value]="this.askPrice.expectedTotalMRC | number : '1.2-2'"
        />
        <!-- (input)="getTotalARC($event)" -->
      </div>
      <div class="col-md-3 pl-0 margin-top-negative">
        <p class="inline-style">Expected Total NRC -</p>
        <input
          class="input-label"
          [value]="this.askPrice.expectedTotalNRC | number : '1.2-2'"
        />
        <!-- (input)="getTotalNRC($event)" -->
      </div>
    </div>

    <div class="row">
      <div class="w-100 modal-scroll">
        <div *ngFor="let detail of finalList">
          <div
            *ngFor="let item of detail['configurations']; let i = index"
            class="row w-100 ask-price-sec"
          >
            <mat-card class="w-100">
              <mat-card-content>
                <div class="row w-100">
                  <div class="col-md-1 mt-3">
                    <div class="col-md-12">
                      <mat-checkbox
                        [checked]="detail.isSelected"
                        (change)="onCheckChange($event, item, detail)"
                      >
                      </mat-checkbox>
                    </div>
                  </div>
                  <div class="col-md-2 pl-0">
                    <div class="col-md-12">
                      <p>
                        Location<br /><b>{{ item.locationName }}</b>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-2 pl-2">
                    <div class="col-md-12">
                      <p>
                        MRC<br /><b>{{ item.mrc | number : "1.2-2" }}</b>
                      </p>
                    </div>
                  </div>
                  <div class="col-md-2 pl-2">
                    <div class="col-md-12">
                      <p>
                        NRC<br /><b>{{ item.nrc | number : "1.2-2" }}</b>
                      </p>
                    </div>
                  </div>

                  <div class="col-md-2 pl-2">
                    <p>
                      Expected MRC<br />
                      <input
                        type="number"
                        [disabled]="!detail.isSelected"
                        [value]="item.expectedMrc"
                        class="search-box"
                        [(ngModel)]="item.expectedMrc"
                        (input)="getSiteARC($event, item)"
                      />
                      <!-- <span *ngIf="detail.primary.isArcError" class="red-color"><br/>Please enter a higher value</span> -->
                    </p>
                  </div>
                  <div class="col-md-2 pl-2">
                    <p>
                      Expected NRC<br />
                      <input
                        type="number"
                        [disabled]="!detail.isSelected"
                        [value]="item.expectedNrc"
                        class="search-box"
                        [(ngModel)]="item.expectedNrc"
                        (input)="getSiteNRC($event, item)"
                      />
                      <!-- <span *ngIf="detail.secondary.isArcError" class="red-color"><br/>Please enter a higher value</span> -->
                    </p>
                  </div>
                </div>
              </mat-card-content>
            </mat-card>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      (click)="hide()"
      class="btn secondary-outline-btn buttonMargin"
    >
      CANCEL
    </button>
    <button
      type="button"
      [disabled]="length == 0"
      class="btn active-btn"
      (click)="onAskForPriceDone()"
      prevent-double-click
    >
      SUBMIT
    </button>
  </div>

  <tour-step-template></tour-step-template>
</ng-template>
