import { environment } from '../environments/environment';
export const appConstant = new function () {
    this.delegationUrl = environment.devUrl + environment.omsService;
    this.nodeOptimus = environment.nodeUrl + environment.nodeOptimus;
    this.authenticationUrl = environment.devUrl;
    this.customerService = environment.devUrl + environment.customerService;
    this.omsService = environment.devUrl + environment.omsService //environment.devUrl + environment.omsService http://10.149.67.5:7072/optimus-oms;
    this.wholesaleService = environment.devUrl + environment.wholesaleService
    this.omsProduct = environment.devUrl + environment.omsProduct //http://10.149.67.5:7070/optimus-product;
    // this.omsProduct=environment.productUrl +environment.omsProduct
    this.omsQuote = environment.devUrl + environment.quoteService;
    //this.locationUrl = environment.devUrl + environment.locationService;
    this.solutionandSite = environment.devUrl + environment.solutionUrl;
    this.locationUrl = environment.devUrl + environment.location  //environment.devUrl + environment.location; 'http://10.149.67.5:7071/optimus-location'
    this.configurationUrl = environment.devUrl + environment.configserviceUrl;
    this.quoteUrl = environment.devUrl + environment.omsService;
    this.notificationService = environment.devUrl + environment.notificationService;
    this.optimusService = environment.devUrl + environment.optimusService;
    this.userManagementService = environment.devUrl + environment.userManagementService;
    this.serviceInventory = environment.devUrl + environment.serviceInventory;
    this.optimusTicket = environment.devUrl + environment.ticket;
    this.optimusBilling = environment.devUrl + environment.billing;
    this.customerReports = environment.devUrl + environment.reports;
    this.serviceFullfillment = environment.devUrl + environment.serviceFullfillment;
    this.prepareFulfillment = environment.devUrl + environment.prepareFulfillment;
    this.l2oworkflow=environment.devUrl + environment.l2oworkflow;
    this.serviceActivation=environment.devUrl+environment.serviceActivation
    this.swiftApi = environment.devUrl+environment.swiftApi;
    this.objectStorageEnabled= environment.objectStorageEnabled;
    this.mapKey = environment.mapApiKey;
    this.userManagement=environment.devUrl+environment.userManagement;
    this.serviceHandOver=environment.devUrl+environment.serviceHandOver;
    this.optimusAudit = environment.devUrl + environment.audit;
    this.omsAmendment= environment.devUrl + environment.omsService;
    this.isSandboxURL = false;
    this.userName = '';
    this.optimusSdwanService = environment.devUrl + environment.sdwanOmsService;
    // this.omsURL = 'https://10.133.208.101/optimus-mwie-oms/api/v1/izo-ne/quotes';
    this.omsmwieProduct = environment.devUrl + environment.omsmwieProduct,
    this.omsmwieProductScpService = environment.devUrl + environment.omsProduct
    this.mwieomsService = environment.devUrl + '/optimus-mwieoms';
    this.serviceinventory = environment.devUrl + '/optimus-serviceinventory';
    this.scpmwieproduct = environment.devUrl + '/optimus-product';
    this.preparefulfillment = environment.devUrl + '/optimus-preparefulfillment';
};

export const TASK_URL = {
    'customer': '/tasks/customer/',
    'admin': '/tasks/'
}
