<div *ngIf="displaytyo == false" style="margin-top: 100.44px">
  <section class="container smView">
    <section class="container-header">
      <div class="row">
        <div
          class="col col-md-8 align-self-start"
          style="padding-left: 10px !important"
        >
          <h2 style="font-size: 24px !important">Thank You!</h2>
          <p>Track,the provisioning status of your ordered sites here.</p>
          <!-- <a href="/reviewaccept" class="back-arrow">
                        <i class="icon icon-back"></i>
                    </a> -->
        </div>
        <div class="col col-md-4 align-self-end" style="padding-right: 30px">
          <div class="row" style="float: right">
            <button
              class="btn secondary-dark-outline-btn editButton"
              (click)="trackYourOrder()"
            >
              TRACK YOUR ORDER
            </button>
            <button class="btn active-btn" (click)="exitOrder()">EXIT</button>
          </div>
        </div>
        <!-- <div class="col col-md-1 align-self-end text-right">

                </div> -->
      </div>
    </section>

    <div class="card ct-card card-wrapper-height">
      <div class="row pb-10" style="padding-left: 15px; padding-right: 15px">
        <div
          class="card-header card-header-wrapper"
          style="width: 100%; margin-left: 0rem; margin-right: 0 rem"
        >
          <div
            class="d-flex justify-content-between align-items-center col1"
            style="width: 100%"
          >
            <div>
              <div class="left">
                <div class="heading1">ORDER ID : 99887766</div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="grpby-content-wrapper">
        <div style="width: 100%; background-color: white; margin-bottom: 40px">
          <div class="colClass">
            <div class="attr-type header-text sub-header">Order Details</div>
            <div class="row p-2">
              <!--p-2 for right alignment-->
              <div class="col-md-3">
                <div class="order-labels">Order Type</div>
                <div class="attr-labels">New</div>
              </div>
              <div class="col-md-3">
                <div class="order-labels">Product Name</div>
                <div class="attr-labels">
                  IZO<sup>TM</sup> Multi Cloud Conect Flex
                </div>
              </div>
              <div class="col-md-3">
                <div class="order-labels">Order Date</div>
                <div class="attr-labels">{{ today | date : "dd/MM/yyyy" }}</div>
              </div>
              <div class="col-md-3">
                <div class="order-labels">Quote Code</div>
                <div class="attr-labels">IZOMCCDEMO001</div>
              </div>
            </div>
            <div class="row p-2">
              <div class="col-md-3">
                <div class="order-labels">Connection Alias Name</div>
                <div class="attr-labels">DEMO</div>
              </div>
              <div class="col-md-3">
                <div class="order-labels">Contract Term</div>

                <div class="attr-labels">1 Month</div>
              </div>
            </div>
          </div>
          <div class="colClass">
            <div class="attr-type header-text sub-header">Location</div>
            <div class="row p-2">
              <div class="col-md-3">
                <div class="order-labels">Region</div>
                <div class="attr-labels">AMER</div>
              </div>
              <div class="col-md-3">
                <div class="order-labels">Location</div>
                <div class="attr-labels">Chicago</div>
              </div>
            </div>
          </div>
          <div class="colClass">
            <div class="attr-type header-text sub-header">Connector</div>
            <div class="row p-2">
              <div class="col-md-3">
                <div>
                  <div class="order-labels">Connection Preference</div>
                  <div class="attr-labels">Internet</div>
                </div>
                <div>
                  <div class="order-labels">Bandwidth</div>
                  <div class="attr-labels">50Mbps</div>
                </div>
                <div>
                  <div class="order-labels">Link Type</div>
                  <div class="attr-labels">Single</div>
                </div>
                <div>
                  <div class="order-labels">Alias Name</div>
                  <div class="attr-labels">SingleLink-TCL DEMO</div>
                </div>
              </div>
            </div>
          </div>
          <div class="colClass">
            <div class="attr-type header-text sub-header">VNF Service</div>
            <div class="row p-2" *ngFor="let data of vnfDetails">
              <div class="col-md-3 pt-2" *ngFor="let item of data.attributes">
                <div>
                  <div class="order-labels">Router Preference</div>
                  <div class="attr-labels">Cisco Catalyst 8000v Router</div>
                </div>
                <div>
                  <div class="order-labels">Core Preference</div>
                  <div class="attr-labels">Small (2 Core)</div>
                </div>
                <div>
                  <div class="order-labels">Memory</div>
                  <div class="attr-labels">8GB</div>
                </div>
                <div>
                  <div class="order-labels">Alias Name</div>
                  <div class="attr-labels">2-Core-8GB</div>
                </div>
                <div>
                  <div class="order-labels">Local As Number</div>
                  <div class="attr-labels">64550</div>
                </div>
                <div>
                  <div class="order-labels">Device Type</div>
                  <div class="attr-labels">Router</div>
                </div>
                <div>
                  <div class="order-labels">Device Category</div>
                  <div class="attr-labels">Virtual Router</div>
                </div>
                <div>
                  <div class="order-labels">Size</div>
                  <div class="attr-labels">Small</div>
                </div>
                <div>
                  <div class="order-labels">License</div>
                  <div class="attr-labels">Tier-1</div>
                </div>
                <div>
                  <div class="order-labels">License Type</div>
                  <div class="attr-labels">Subscription</div>
                </div>
                <div>
                  <div class="order-labels">CPE Basis Chasis</div>
                  <div class="attr-labels">5678DEMO123</div>
                </div>
              </div>
            </div>
          </div>
          <div class="colClass">
            <div class="attr-type header-text sub-header">Cloud Connect</div>
            <div
              class="row p-2"
              *ngFor="let data of cloudDetails; let i = index"
            >
              <div class="col-md-3 pt-2" *ngFor="let item of data.attributes">
                <div *ngIf="item.name == 'cloud provider'">
                  <div class="order-labels">Cloud Provider</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'interconnect location'">
                  <div class="order-labels">Interconnect Location</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'cloud bandwidth'">
                  <div class="order-labels">Bandwidth</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'link type'">
                  <div class="order-labels">Link Type</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'cloud alias name'">
                  <div class="order-labels">Alias Name</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'primary ip address'">
                  <div class="order-labels">Primary Ip Address</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'primary remote as number'">
                  <div class="order-labels">Primary Remote As Number</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'primary bgp key'">
                  <div class="order-labels">Primary Bgp Key</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'primary neighbour ip'">
                  <div class="order-labels">Primary Neighbour Ip</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'primary account id'">
                  <div class="order-labels">Primary Account Id</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'primary subnet'">
                  <div class="order-labels">Primary Subnet</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'primary service key'">
                  <div class="order-labels">Primary Service Key</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'primary vlan'">
                  <div class="order-labels">Primary Vlan</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'primary peer type'">
                  <div class="order-labels">Primary Peer Type</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'secondary ip address'">
                  <div class="order-labels">Secondary Ip Address</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'secondary remote as number'">
                  <div class="order-labels">Secondary Remote As Number</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'secondary local as number'">
                  <div class="order-labels">Secondary Local As Number</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'secondary bgp key'">
                  <div class="order-labels">Secondary Bgp Key</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'secondary neighbour ip'">
                  <div class="order-labels">Secondary Neighbour Ip</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'secondary subnet'">
                  <div class="order-labels">Secondary Subnet</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'secondary service key'">
                  <div class="order-labels">Secondary Service Key</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'secondary vlan'">
                  <div class="order-labels">Secondary vlan</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
                <div *ngIf="item.name == 'secondary peer type'">
                  <div class="order-labels">Secondary Peer Type</div>
                  <div class="attr-labels">{{ item.value }}</div>
                </div>
              </div>
              <hr
                *ngIf="i == 0 && cloudDetails.length > 1"
                style="border-top: 2px dotted black"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<div *ngIf="displaytyo == true" style="margin-top: 108px">
  <section class="container">
    <section class="container-header">
      <div class="row">
        <div class="col col-lg-6 align-self-start">
          <h2>View Order Details</h2>
          <p>
            Also,can configure multiple solutions using the bulk upload option.
          </p>
          <a (click)="displaytyo = false" href="#" class="back-arrow">
            <i class="icon icon-back"></i>
          </a>
        </div>
        <div class="col col-md-6 align-self-end text-right">
          <button class="btn active-btn">Cancel Order</button>
          <button class="btn active-btn" (click)="exitOrder()">EXIT</button>
        </div>
      </div>
    </section>

    <div class="card ct-card card-wrapper-height">
      <div class="row pb-10" style="padding-left: 15px; padding-right: 15px">
        <div
          class="card-header card-header-wrapper"
          style="width: 100%; margin-left: 0rem; margin-right: 0 rem"
        >
          <span class="text-uppercase fontStyle bold-text"
            >ORDER LiST <i class="icon-caret-right"></i
          ></span>
          <span class="text-default fontStyle text-size"
            >ORDER ID
            <b class="tab-text selectionClss">99887766</b>
          </span>
        </div>
      </div>
    </div>

    <div
      class="order-details-content"
      style="
        border-top: 1px solid #ebebeb;
        background-color: white;
        padding: 1.6428571428571428rem 0;
        margin-top: 0rem;
        height: 500px;
        padding-top: 5px;
      "
    >
      <div class="row pb-10" style="padding-left: 30px; padding-right: 30px">
        <div
          class="col card-header"
          style="width: 100%; background-color: white"
        >
          <p class="bold-text" style="margin-bottom: 0px">Order Type: New</p>
          <p class="bold-text" style="margin-bottom: 0px">
            Order Category: Multi Cloud Connect
          </p>
          <p class="bold-text">Base Circuit Bandwidth: 200Mbps</p>
        </div>
      </div>
      <div
        class="card-header"
        style="background-color: white; margin-left: 1rem; margin-right: 1rem"
      >
        <div class="row justify-content-between">
          <div class="col-md-3 order-labels">
            Order Date
            <span class="ml-1 bold-text">{{
              today | date : "dd/MM/yyyy"
            }}</span>
          </div>

          <div class="col-md-3 order-labels">
            Product Name
            <span class="ml-1 bold-text"
              >IZO<sup>TM</sup> &nbsp; Multi Cloud Connect Flex</span
            >
          </div>

          <!-- <div class="col-md-3 order-labels">
            Bandwidth on demand
            <span class="ml-1 bold-text">2 Mbps</span>
          </div> -->

          <!-- <div class="col-md-3 d-flex cursorPointer">
            COF Download
            <span class="ml-1 bold-text">
              <i class="icon-download downloadicon" style="color: black"></i>
            </span>
          </div> -->
        </div>
      </div>
      <div style="padding: 20px">
        <div class="row justify-content-between">
          <div class="col-md-6 bold-text order-labels">Address A-End</div>
          <div class="col d-flex bold-text order-labels">As Number(remote)</div>
        </div>
        <div class="row justify-content-between">
          <div class="col-md-6">
            EQUINIX CH3, 1905 LUNT AVENUE, ELK GROVE VILLAGE, IL 60007, USA
          </div>
          <div class="col d-flex">12076</div>
        </div>

        <div
          class="row justify-content-between"
          style="margin-top: 40px; width: 100%"
        >
          <div class="col-md-3 bold-text order-labels">Service ID</div>
          <div class="col-md-3 bold-text order-labels">Schedule ID</div>
          <div class="col-md-3 bold-text order-labels">Request Start Date</div>
          <div class="col-md-3 bold-text order-labels">Request End Date</div>
        </div>
        <div class="row justify-content-between" style="width: 100%">
          <div class="col-md-3">01USACHIC321456987N</div>
          <div class="col-md-3">77883344</div>
          <div class="col-md-3">{{ today | date : "dd/MM/yyyy" }}</div>
          <div class="col-md-3">{{ today | date : "dd/MM/yyyy" }}</div>
        </div>
      </div>
      <div class="col justify-content-between align-items-center">
        <section>
          <div class="row">
            <div
              class="col-md-12 d-flex justify-content-center"
              style="margin-top: 30px; margin-left: 100px"
            >
              <div class="order-tracking-status p-20" style="width: 100%">
                <ul>
                  <li>
                    <span></span>
                    <p class="font-black">
                      Order Placed<br />{{ today | date : "dd/MM/yyyy" }}
                    </p>
                  </li>
                  <li class="current" id="scheduled">
                    <span></span>
                    <p class="font-black">Under Provisioning</p>
                  </li>

                  <li id="activation">
                    <span></span>
                    <p class="font-black">Order Provisioned</p>
                  </li>
                  <li id="deactivation">
                    <span></span>
                    <p class="font-black">
                      Connection Active<br />Expected By
                      {{ today | date : "dd/MM/yyyy" }}
                    </p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>
  </section>
</div>
