import {
  Routes,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
} from '@angular/router';
import { ErrorPageComponent } from './common/component/error-page/error-page.component';
import { LoginComponent } from './login/login.component';
import { ProductselectComponent } from './productselect/productselect.component';
import { VariantselectComponent } from './variantselect/variantselect.component';

const allPartnerProfiles: string[] = [
  'OPT_PARTNER_PROFILE_AUTHORISED_SOLUTION',
  'OPT_PARTNER_PROFILE_AFFILIATED_SOLUTION',
  'OPT_PARTNER_PROFILE_MASTER_AGENT',
  'OPT_PARTNER_PROFILE_PREMIER_SOLUTION',
  'OPT_PARTNER_PROFILE_FOCUS_PARTNER',
  'OPT_PARTNER_PROFILE_SERVICE_PROVIDER',
  'OPT_PARTNER_PROFILE_ALLIANCE_PARTNER',
  'OPT_PARTNER_PROFILE_DISTRIBUTER',
  'OPT_PARTNER_PROFILE_MEDIA_VERTICAL',
];
export const AppRoutes: Routes = [
  {
    path: '',
    component: LoginComponent,
  },

  { path: 'error', component: ErrorPageComponent },

  {
    path: 'izomcc-scp',
    loadChildren: () =>
      import('./izomcc-scp/izomcc-scp.module').then(
        (m) => m['IzomccScpModule']
      ),
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'product',
    component: ProductselectComponent,
  },
  {
    path: 'variant',
    component: VariantselectComponent,
  },
  { path: '**', pathMatch: 'full', redirectTo: 'serviceUnavailable' },
];

export function redirectToFunc(
  rejectedPermissionName: string,
  activateRouteSnapshot: ActivatedRouteSnapshot,
  routerStateSnapshot: RouterStateSnapshot
) {
  console.log('5', 'ngxtest redirect event');
  if (activateRouteSnapshot.params['id'] === 42) {
    return 'login';
  } else {
    return 'dashboard';
  }
}
