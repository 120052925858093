import { Component, OnInit, Input, ViewChild, TemplateRef, ChangeDetectorRef, ApplicationRef, EventEmitter, Output } from '@angular/core';
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: '[messageModal]',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.css']
})

export class MessageModalComponent implements OnInit {
    constructor(private bsModalService: BsModalService, private changeDetectorRef: ChangeDetectorRef, private applicationRef: ApplicationRef) {

    }
    @Input('messageModal') messageSettings: any;
    @ViewChild("messageModal") messageModal: TemplateRef<any>;
    @Output() emitEvent=new EventEmitter();
    msgModalData: any = {
        message: "",
        modalRef: [],
        isDisableClose:false
    }

    closeModal() {
        this.messageSettings.close();
        if(this.msgModalData.isDisableClose){
         this.emitEvent.emit('redirect')
        }
    }

    ngOnInit() {

		
		

        this.messageSettings.open = (data) => {
			this.msgModalData.message = '';
			let msgArr = data.message.split(',');
				for(var m=0; m < msgArr.length; m++) {
					
					if(this.msgModalData.message.length) {
						this.msgModalData.message = this.msgModalData.message + '<br><br>'+ msgArr[m];
					} else {
						this.msgModalData.message =  msgArr[m];
					}
				}
			//this.msgModalData.message = '<div>{{msgModalData.message}}</div>';
				
            this.msgModalData.isDisableClose=data.isDisableClose?data.isDisableClose:false;
            this.msgModalData.modalRef.push(this.bsModalService.show(this.messageModal, { class: "modal-sm" }));
            // if (this.msgModalData.message.toLowerCase().indexOf('postal code is not associated') > -1 || this.msgModalData.message.indexOf('Pincode of current Pin location is changed') > -1 || this.msgModalData.message.toLowerCase().indexOf('Address doesnt belong to the entered pincode') > -1) {
                this.applicationRef.tick(); //map page two binding issue fix
            // }

        }

        this.messageSettings.close = () => {
            //this.msgModalData.modalRef.hide();
            this.msgModalData.modalRef.forEach(ref => {
                ref.hide();
            });
            this.msgModalData.modalRef = [];
        }
    }
}