import { Injectable } from '@angular/core';
import { CommonValidationService } from '../common/service/common-validation.service';
import { appConstant } from '../app.constants';

@Injectable({
  providedIn: 'root'
})

export class IzomccScpService {


  quoteObj: any;
  previousUrl: string;
  currentUrl: string;


  constructor() { }
  izomccScpServiceConst = {
    //landing page api
    mapDetails: appConstant.omsmwieProductScpService + '/api/v1/products/izomcc/scp/location',
    deviceDetails: appConstant.omsProduct + '/api/v1/products/izomcc/device/details?vendorOEM=',
    sizeDetails: appConstant.omsProduct + '/api/v1/products/izomcc/device/config/details?deviceName=',
    reviewConfig: appConstant.mwieomsService + '/api/v1/izo-mcc/quotes',
    shareQuote: appConstant.omsmwieProduct + '/izo-mcc/quotes/',
    downloadQuote: appConstant.mwieomsService + '/api/v1/izo-mcc/quotes',
    currencyconvert: appConstant.mwieomsService + '/api/v1/izo-mcc/quotes/',
    quoteStageUpdate: appConstant.omsmwieProduct + '/izo-mcc/quotes',
    sfdcStatusUpdate: appConstant.omsmwieProduct + '/izo-mcc/quotes/{{quoteId}}/le/{{quoteleId}}/sfdc/status?stage=Proposal Sent',
    //  myinventory:appConstant.omsmwieProduct+'/izomcc/scp/servicedetails?customerId=',
    //landing page api
    myinventory: appConstant.serviceinventory + '/api/v1/si/izomcc/scp/servicedetails?customerId=',
    generateOrderForm: appConstant.omsmwieProduct + '/izo-mcc/quotes/{{quoteId}}/le/{{quoteleId}}/scp/orders',
    configPageAttributes: appConstant.scpmwieproduct + '/api/v1/products/izomcc/scp/attributes',
    shareEmailQuote: appConstant.mwieomsService + '/api/v1/izo-mcc/quotes/',
    //orderDetails : appConstant.prepareFulfillment + '/api/v1/izomcc/scp/order/',
    createQuotescp: appConstant.omsmwieProduct + '/izo-mcc/quotes/scp?customerId=',
    getQuotescp: appConstant.omsmwieProduct + '/izo-mcc/quotes/',
    orderDetails: appConstant.mwieomsService + '/api/v1/izo-mcc/orders/',
    customerorder: appConstant.preparefulfillment + '/api/v1/izomcc/scp/inventory/servicedetails?customerId=',
    omsIzomccQuotes: `${appConstant.mwieomsService}/api/v1/izo-mcc/quotes`,
    iPSecPost: appConstant.preparefulfillment + '/api/v1/izomcc/scp/'
  }

  postGenerateOrderForm(successFn, errorFn) {
    return null;
}

getLocationListMC(successFn, errorFn) {
    return null;
}

loadDeviceDetails(vendorOEM, successFn, errorFn) {
    return null;
}

loadSizeDetails(deviceName, successFn, errorFn) {
    return null;
}

getReviewDetails(quoteId, successFn, errorFn) {
    return null;
}

shareQuote(mailId, quoteId, quoteleId, successFn, errorFn) {
    return null;
}

downloadQuote(quoteId, quoteLeId, successFn, errorFn) {
    return null;
}

currencyConvert(quoteId, quoteLeId, currCode, successFn, errorFn) {
    return null;
}

updateQuoteStage(quoteId, quoteLeId, quoteStage, successFn, errorFn) {
    return null;
}

postSFDCStatusUpdate(successFn, errorFn) {
    return null;
}

attributes4configpage(successFn, errorFn) {
    return null;
}

shareEmailQuote(mailId, quoteId, quoteleId, successFn, errorFn) {
    return null;
}

orderDetails(orderId, successFn, errorFn) {
    return null;
}

createQuoteRequest(custId, data, successFn, errorFn) {
    return null;
}

updateQuoteRequest(quoteObj, solnId, configId, data, successFn, errorFn) {
    return null;
}

getQuoteRequest(quoteId, successFn, errorFn) {
    return null;
}

salesOrderDetailsInventory(customerId, customerLeId, successFn, errorFn) {
    return null;
}

checkoutValidation(data, successFn, errorFn) {
    return null;
}

setQuoteObj(quoteObj: any) {
    return null;
}

getQuoteObj() {
    return null;
}

setPreviousUrl(previousUrl: string) {
    return null;
}

setCurrentUrl(currentUrl: string) {
    return null;
}

getPreviousUrl() {
    return null;
}

getCurrentUrl() {
    return null;
}

customerorders(customerId, customerLeId, successFn, errorFn) {
    return null;
}

generateIzomccPDF(successFn, errorFn, quoteId, quoteLeId, nat) {
    return null;
}

postEnableIpSec(payload, successFn, errorFn) {
    return null;
}

downloadPdfPost(payload, successFn, errorFn) {
    return null;
}

downloadconfigpdf(serviceId, tunnelNumber, successFn, errorFn) {
    return null;
}


}
