import {
  Component,
  OnInit,
  inject,
  ViewChild,
  ElementRef,
} from '@angular/core';
import {
  ActivatedRoute,
  ParamMap,
  Router,
  NavigationEnd,
} from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { IzomccScpService } from '../../izomcc-scp.service';
import { DataSharedSvc } from 'src/app/data-shared-svc';
import { elementAt, filter } from 'rxjs/operators';
import { attributes } from 'src/app/common/constants/service-inventory/service-request-attribute-list.constant';
import { FormBuilder, Validators } from '@angular/forms';
import { Location } from '@angular/common';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
import { MatSelect } from '@angular/material/select';
@Component({
  selector: 'app-designcanvas',
  templateUrl: './designcanvas.component.html',
  styleUrls: ['./designcanvas.component.css'],
})
export class DesigncanvasComponent implements OnInit {
  public readonly tourService = inject(TourService);
  isShow1 = false;
  isShow2 = false;
  isShow3 = false;
  isShow4 = false;
  orderId: any = '';
  selected = 'option2';
  priceOpen: any = false;
  bandwidth: any = ['Mbps', 'Gbps'];
  finalJsonList: any = [];
  @ViewChild('tour1') tour1 = ElementRef;
  @ViewChild('tour2') tour2 = MatSelect;
  @ViewChild('tour3') tour3 = ElementRef;
  @ViewChild('tour4') tour4 = ElementRef;
  @ViewChild('tour5') tour5 = ElementRef;
  @ViewChild('tour6') tour6 = ElementRef;
  @ViewChild('tour7') tour7 = ElementRef;
  @ViewChild('tour8') tour8 = ElementRef;
  @ViewChild('tour9') tour9 = ElementRef;
  @ViewChild('tour10') tour10 = ElementRef;
  @ViewChild('tour11') tour11 = ElementRef;
  @ViewChild('tour12') tour12 = ElementRef;
  @ViewChild('tour13') tour13 = ElementRef;
  @ViewChild('tour14') tour14 = ElementRef;
  @ViewChild('tour15') tour15 = ElementRef;
  @ViewChild('tour16') tour16 = ElementRef;
  @ViewChild('tour17') tour17 = ElementRef;
  @ViewChild('tour18') tour18 = ElementRef;
  @ViewChild('tour19') tour19 = ElementRef;
  @ViewChild('tour20') tour20 = ElementRef;
  @ViewChild('tour21') tour21 = ElementRef;
  @ViewChild('tour22') tour22 = ElementRef;
  @ViewChild('tour23') tour23 = ElementRef;
  @ViewChild('tour26') tour26 = ElementRef;

  connectorList: any = [
    {
      componentName: 'Connector',
      displayName: 'add Connector',
      attributesList: [
        // { "displayName": "Connection Preference", "type": "radios", "category": "basic", "componentId": 3, "displayData": [{ "key": "Internet", "value": "Internet" }], "selectedValue": "", "id": 1 },
        {
          displayName: 'Edge Connectivity Type',
          type: 'radios',
          category: 'basic',
          componentId: 3,
          displayData: [{ key: 'Internet', value: 'Internet' }],
          selectedValue: 'Internet',
          id: 1,
        },
        {
          displayName: 'Edge Connectivity Bandwidth',
          type: 'select',
          category: 'basic',
          componentId: 3,
          displayData: [
            { key: 'key', value: '120 MPBS' },
            { key: 'key', value: '150 MBPS' },
          ],
          selectedValue: '',
          bandwidth: ['120MBPS', '100MBPS'],
          id: 3,
        },
        {
          displayName: 'Link Type',
          type: 'radios',
          category: 'basic',
          componentId: 3,
          displayData: [{ key: 'Single', value: 'Single' }],
          selectedValue: 'Single',
          id: 2,
        },
        {
          displayName: 'Edge Alias Name',
          type: 'input',
          category: 'basic',
          componentId: 3,
          selectedValue: '',
          id: 5,
        },
        {
          displayName: 'Component Order Type',
          type: 'hidden',
          category: 'basic',
          componentId: 3,
          selectedValue: 'NEW',
          id: 5,
        },
        {
          displayName: 'bw_value',
          type: 'hidden',
          category: 'basic',
          componentId: 3,
          selectedValue: '',
          id: 6,
        },
        {
          displayName: 'bw_unit',
          type: 'hidden',
          category: 'basic',
          componentId: 3,
          selectedValue: '',
          id: 7,
        },
        {
          displayName: 'edge_sitecode',
          type: 'hidden',
          category: 'basic',
          componentId: 3,
          selectedValue: 'IP',
          id: 5,
        },
        {
          displayName: 'edge_sitecity',
          type: 'hidden',
          category: 'basic',
          componentId: 3,
          selectedValue: 'IP',
          id: 5,
        },
        {
          displayName: 'edge_sitecountry',
          type: 'hidden',
          category: 'basic',
          componentId: 3,
          selectedValue: 'IP',
          id: 5,
        },
      ],
    },
  ];
  VNFList: any = [];

  cloudConnect: any = [];

  AWSPrimaryConfg = {
    cloudConfgName: 'Amazon Web Services',
    attributes: [
      {
        displayName: 'IP address for Interface',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Primary Ip Address',
        showRequiredError: false,
        isNameValidation: false,
        id: 10,
      },
      {
        displayName: 'Neighbour IP',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Primary Neighbour Ip',
        showRequiredError: false,
        isNameValidation: false,
        id: 14,
      },
      {
        displayName: 'AS Number(remote)',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Primary Remote As Number',
        showRequiredError: false,
        isNameValidation: false,
        id: 11,
      },
      {
        displayName: 'BGP Key',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [
          { key: 'Single', value: 'Single' },
          { key: 'Dual', value: 'Dual' },
        ],
        selectedValue: '',
        name: 'Primary Bgp Key',
        showRequiredError: false,
        isNameValidation: false,
        id: 13,
      },
      {
        displayName: 'Account ID',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Primary Account Id',
        showRequiredError: false,
        isNameValidation: false,
        id: 15,
      },
      {
        displayName: 'Subnet',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Primary Subnet',
        showRequiredError: false,
        isNameValidation: false,
        id: 16,
      },
    ],
  };

  AzurepriConfg = {
    cloudConfgName: 'Microsoft Azure Primary',
    attributes: [
      {
        displayName: 'IP address for Interface',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Primary Ip Address',
        showRequiredError: false,
        isNameValidation: false,
        id: 10,
      },
      {
        displayName: 'Neighbour IP',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Primary Neighbour Ip',
        showRequiredError: false,
        isNameValidation: false,
        id: 14,
      },
      {
        displayName: 'AS Number(remote)',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Primary Remote As Number',
        showRequiredError: false,
        isNameValidation: false,
        id: 11,
      },
      {
        displayName: 'BGP Key',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [
          { key: 'Single', value: 'Single' },
          { key: 'Dual', value: 'Dual' },
        ],
        selectedValue: '',
        name: 'Primary Bgp Key',
        showRequiredError: false,
        isNameValidation: false,
        id: 13,
      },
      {
        displayName: 'Service Key',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Primary Service Key',
        showRequiredError: false,
        isNameValidation: false,
        id: 15,
      },
      {
        displayName: 'Subnet',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Primary Subnet',
        showRequiredError: false,
        isNameValidation: false,
        id: 17,
      },
      {
        displayName: 'VLAN',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Primary vlan',
        showRequiredError: false,
        isNameValidation: false,
        id: 18,
      },
      {
        displayName: 'Peer Type',
        type: 'select',
        category: 'basic',
        componentId: 3,
        displayData: [
          { key: 'Private', value: 'Private' },
          { key: 'Microsoft', value: 'Microsoft' },
        ],
        selectedValue: '',
        name: 'Primary Peer Type',
        showRequiredError: false,
        isNameValidation: false,
        id: 19,
      },
    ],
  };

  AzureSecConfg = {
    cloudConfgName: 'Microsoft Azure Secondary',
    attributes: [
      {
        displayName: 'IP address for Interface',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Secondary Ip Address',
        showRequiredError: false,
        isNameValidation: false,
        id: 10,
      },
      {
        displayName: 'Neighbour IP',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Secondary Neighbour Ip',
        showRequiredError: false,
        isNameValidation: false,
        id: 14,
      },
      {
        displayName: 'AS Number(remote)',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Secondary Remote As Number',
        showRequiredError: false,
        isNameValidation: false,
        id: 11,
      },
      {
        displayName: 'BGP Key',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [
          { key: 'Single', value: 'Single' },
          { key: 'Dual', value: 'Dual' },
        ],
        selectedValue: '',
        name: 'Secondary Bgp Key',
        showRequiredError: false,
        isNameValidation: false,
        id: 13,
      },
      {
        displayName: 'Service Key',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Secondary Service Key',
        showRequiredError: false,
        isNameValidation: false,
        id: 15,
      },
      {
        displayName: 'Subnet',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Secondary Subnet',
        showRequiredError: false,
        isNameValidation: false,
        id: 17,
      },
      {
        displayName: 'VLAN',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Secondary vlan',
        showRequiredError: false,
        isNameValidation: false,
        id: 18,
      },
      {
        displayName: 'Peer Type',
        type: 'select',
        category: 'basic',
        componentId: 3,
        displayData: [
          { key: 'Private', value: 'Private' },
          { key: 'Microsoft', value: 'Microsoft' },
        ],
        selectedValue: '',
        name: 'Secondary Peer Type',
        showRequiredError: false,
        isNameValidation: false,
        id: 19,
      },
    ],
  };

  gcpprimary = {
    cloudConfgName: 'Google Cloud Provider',
    attributes: [
      {
        displayName: 'Paring Key',
        type: 'input',
        category: 'basic',
        componentId: 3,
        displayData: [],
        selectedValue: '',
        name: 'Secondary Ip Address',
        showRequiredError: false,
        isNameValidation: false,
        id: 10,
      },
    ],
  };

  cloudConfiguration: any = [];

  cloudConfigurationn: any = [
    {
      cloudConfgName: 'Amazon Web Services',
      attributes: [
        {
          displayName: 'IP address for Interface',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Primary Ip Address',
          showRequiredError: false,
          isNameValidation: false,
          id: 10,
        },
        {
          displayName: 'Neighbour IP',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Primary Neighbour Ip',
          showRequiredError: false,
          isNameValidation: false,
          id: 14,
        },
        {
          displayName: 'AS Number(remote)',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Primary Remote As Number',
          showRequiredError: false,
          isNameValidation: false,
          id: 11,
        },
        {
          displayName: 'BGP Key',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [
            { key: 'Single', value: 'Single' },
            { key: 'Dual', value: 'Dual' },
          ],
          selectedValue: '',
          name: 'Primary Bgp Key',
          showRequiredError: false,
          isNameValidation: false,
          id: 13,
        },
        {
          displayName: 'Account ID',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Primary Account Id',
          showRequiredError: false,
          isNameValidation: false,
          id: 15,
        },
        {
          displayName: 'Subnet',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Primary Subnet',
          showRequiredError: false,
          isNameValidation: false,
          id: 16,
        },
      ],
    },
    {
      cloudConfgName: 'Microsoft Azure Primary',
      attributes: [
        {
          displayName: 'IP address for Interface',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Primary Ip Address',
          showRequiredError: false,
          isNameValidation: false,
          id: 10,
        },
        {
          displayName: 'Neighbour IP',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Primary Neighbour Ip',
          showRequiredError: false,
          isNameValidation: false,
          id: 14,
        },
        {
          displayName: 'AS Number(remote)',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Primary Remote As Number',
          showRequiredError: false,
          isNameValidation: false,
          id: 11,
        },
        {
          displayName: 'BGP Key',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [
            { key: 'Single', value: 'Single' },
            { key: 'Dual', value: 'Dual' },
          ],
          selectedValue: '',
          name: 'Primary Bgp Key',
          showRequiredError: false,
          isNameValidation: false,
          id: 13,
        },
        {
          displayName: 'Service Key',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Primary Service Key',
          showRequiredError: false,
          isNameValidation: false,
          id: 15,
        },
        {
          displayName: 'Subnet',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Primary Subnet',
          showRequiredError: false,
          isNameValidation: false,
          id: 17,
        },
        {
          displayName: 'VLAN',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Primary vlan',
          showRequiredError: false,
          isNameValidation: false,
          id: 18,
        },
        {
          displayName: 'Peer Type',
          type: 'select',
          category: 'basic',
          componentId: 3,
          displayData: [
            { key: 'Private', value: 'Private' },
            { key: 'Microsoft', value: 'Microsoft' },
          ],
          selectedValue: '',
          name: 'Primary Peer Type',
          showRequiredError: false,
          isNameValidation: false,
          id: 19,
        },
      ],
    },
    {
      cloudConfgName: 'Microsoft Azure Secondary',
      attributes: [
        {
          displayName: 'IP address for Interface',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Secondary Ip Address',
          showRequiredError: false,
          isNameValidation: false,
          id: 10,
        },
        {
          displayName: 'Neighbour IP',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Secondary Neighbour Ip',
          showRequiredError: false,
          isNameValidation: false,
          id: 14,
        },
        {
          displayName: 'AS Number(remote)',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Secondary Remote As Number',
          showRequiredError: false,
          isNameValidation: false,
          id: 11,
        },
        {
          displayName: 'BGP Key',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [
            { key: 'Single', value: 'Single' },
            { key: 'Dual', value: 'Dual' },
          ],
          selectedValue: '',
          name: 'Secondary Bgp Key',
          showRequiredError: false,
          isNameValidation: false,
          id: 13,
        },
        {
          displayName: 'Service Key',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Secondary Service Key',
          showRequiredError: false,
          isNameValidation: false,
          id: 15,
        },
        {
          displayName: 'Subnet',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Secondary Subnet',
          showRequiredError: false,
          isNameValidation: false,
          id: 17,
        },
        {
          displayName: 'VLAN',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Secondary vlan',
          showRequiredError: false,
          isNameValidation: false,
          id: 18,
        },
        {
          displayName: 'Peer Type',
          type: 'select',
          category: 'basic',
          componentId: 3,
          displayData: [
            { key: 'Private', value: 'Private' },
            { key: 'Microsoft', value: 'Microsoft' },
          ],
          selectedValue: '',
          name: 'Secondary Peer Type',
          showRequiredError: false,
          isNameValidation: false,
          id: 19,
        },
      ],
    },
    {
      cloudConfgName: 'Google Cloud Provider',
      attributes: [
        {
          displayName: 'Paring Key',
          type: 'input',
          category: 'basic',
          componentId: 3,
          displayData: [],
          selectedValue: '',
          name: 'Secondary Ip Address',
          showRequiredError: false,
          isNameValidation: false,
          id: 10,
        },
      ],
    },
  ];

  addCloudConfiguration: any = [
    {
      displayName: 'IP address for Interface',
      type: 'input',
      category: 'basic',
      componentId: 3,
      displayData: [],
      selectedValue: '',
    },
    {
      displayName: 'AS Number(remote)',
      type: 'input',
      category: 'basic',
      componentId: 3,
      displayData: [],
      selectedValue: '',
    },
    {
      displayName: 'AS Number(local)',
      type: 'input',
      category: 'basic',
      componentId: 3,
      displayData: [],
      selectedValue: '',
    },
    {
      displayName: 'BGP Key',
      type: 'select',
      category: 'basic',
      componentId: 3,
      displayData: [
        { key: 'Single', value: 'Single' },
        { key: 'Dual', value: 'Dual' },
      ],
      selectedValue: '',
    },
    {
      displayName: 'Neighbour IP',
      type: 'input',
      category: 'basic',
      componentId: 3,
      displayData: [],
      selectedValue: '',
    },
    {
      displayName: 'Account ID',
      type: 'input',
      category: 'basic',
      componentId: 3,
      displayData: [],
      selectedValue: '',
    },
  ];

  vnfServiceConfig: any = [
    {
      displayName: 'Local AS Number',
      type: 'input',
      category: 'basic',
      componentId: 3,
      displayData: [],
      selectedValue: '',
      name: 'Local As Number',
      showRequiredError: false,
      isNameValidation: false,
      formcontrol: 'asNumber',
      id: 19,
    },
  ];

  VNFConfig = [];
  public deviceList: any = [];
  sizeList: any = [];
  memoryList: any = [];
  isshow5: boolean = true;
  panelflag: boolean;
  radioFlag: any;
  hidebutton: boolean = true;
  dimImage: boolean = false;
  dimawsImage: boolean;
  dimazureImage: boolean;
  paramValue: any = {};
  arr1: any = [];
  arr2: any = [];
  isShowLocation: boolean = true;
  awsImage: boolean = false;
  azureImage: boolean = false;
  gcpImage: boolean = false;
  location: any;
  city: any = '';
  country: any = '';
  region: any = '';
  regionList: any = [];
  locationList: any = [];
  test: [];
  locationObj: any = [];
  locationsel: any;
  regionsel: any;
  disableLocation: boolean = false;
  diableRegion: boolean = false;
  countryList: any = [];
  hideConnector: boolean = false;
  hideVNF: boolean = false;
  hideCloud: boolean = false;
  connectionAliasName: any;
  contractTerm: any;
  addConnectorList: any = [];
  addVNFList: any = [];
  addCloudList: any = [];
  customer: any;
  quoteInfo: any;
  siteCode: any;
  regex: any = new RegExp('([0-9]+)|([a-zA-Z]+)', 'g');
  deviceName: string;
  deviceValue: string;
  eneteredSize: any;
  memorylength: any;
  cloudAddConfig: any = [];
  validateCloudConf: boolean;
  validateClouds: boolean;
  emptyaccordian: boolean = true;
  vnf_aliasname: any;
  cloudAttr: any = [];
  deviceAttr: any = [];
  connectorAttr: any = [];
  vnfAttr: any = [];
  markers: any = [];
  contractTermList = [];
  solution_alias_name: string = null;
  term_in_months: string = null;
  bandwidthList: any = [];
  conntrLinkTypes: any = [];
  conntrConnTypes: any = [];
  intrConntLocation: any = [];
  cloudLinkTypes: any = [];
  cloudProviders: any = [];
  showStep1: boolean = false;
  showStep2: boolean = false;
  showSecondaryForm: boolean = false;
  vnfASnumber: any = '';
  vnfaddidata: {
    componentMasterId: number;
    name: string;
    attributes: { name: string; value: any }[];
  };
  siteCountry: any;
  customerid: any;
  customerLeid: any;
  quoteObj: any = null;
  solnId: any = '';
  configId: any = '';
  secdhideCloud: boolean = false;
  previousUrl: string = null;
  currentUrl: string = null;
  isSubmitDisable: boolean = false;
  isGetQuoteDisable: boolean = true;
  ipPattern =
    '(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)';
  numberPattern = '^[0-9]*$';
  resQuoteObj: any = null;
  isaddBtndisable: boolean = false;
  hideConfigDetails: boolean = false;
  hideSolutionDetails: boolean = false;
  showThirdForm: boolean = false;
  // @ViewChild('ipaddress', { 'read': ElementRef }) isValidIP;
  latLongValue: string;
  showGCPServiceAttr: boolean = false;
  isdisableCP2: string = null;
  isdisableCP3: string = null;
  selectedCloud1: any = '';
  selectedCloud2: any = '';
  constructor(
    private _location: Location,
    public activatedRoute: ActivatedRoute,
    private _snackBar: MatSnackBar,
    private service: IzomccScpService,
    public router: Router,
    public datasharedSvc: DataSharedSvc,
    private fb: FormBuilder
  ) {
    this.getConfigPageAttributes();

    router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        console.log('prev event', event);
        this.previousUrl = this.currentUrl;
        this.currentUrl = event.url;
      });
  }

  solutionForm = this.fb.group({
    solution_alias_name: [''],
    term_in_months: [''],
  });

  locationForm = this.fb.group({
    region: [''],
    city: [''],
  });

  connectorForm = this.fb.group({
    connType: [''],
    bandwidth: [''],
    linkType: [''],
    aliasName: [''],
  });

  vnfForm = this.fb.group({
    routerPreference: [''],
    corePreference: [''],
    memory: [''],
    aliasName: [''],
  });

  cloudConnectForm = this.fb.group({
    cloudProvider: [''],
    location: [''],
    bandwidth: [''],
    linkType: [''],
    aliasName: [''],
  });

  secondcloudConnectForm = this.fb.group({
    cloudProvider: [''],
    location: [''],
    bandwidth: [''],
    linkType: [''],
    aliasName: [''],
  });

  thirdcloudConnectForm = this.fb.group({
    cloudProvider: [''],
    location: [''],
    bandwidth: [''],
    linkType: [''],
    aliasName: [''],
  });

  vnfServiceForm = this.fb.group({
    asNumber: [
      '',
      [Validators.required, Validators.pattern(/^(0|[1-9][0-9]*)$/)],
    ],
  });

  gcpServiceForm = this.fb.group({
    pairingKey: [
      '',
      [Validators.required, Validators.pattern(/^[a-zA-Z0-9,-./]+$/)],
    ],
  });

  awscloudServiceForm = this.fb.group({
    ipaddress: [''],
    neighborip: [''],
    asNumberRemote: [''],
    bgpkey: [''],
    accountId: [''],
    subnet: [''],
  });

  primAzurecloudServiceForm = this.fb.group({
    ipaddress: [''],
    neighborip: [''],
    asNumberRemote: [''],
    bgpkey: [''],
    serviceKey: [''],
    subnet: [''],
    vlan: [''],
    peertype: [''],
  });

  secdAzurecloudServiceForm = this.fb.group({
    ipaddress: [''],
    neighborip: [''],
    asNumberRemote: [''],
    bgpkey: [''],
    serviceKey: [''],
    subnet: [''],
    vlan: [''],
    peertype: [''],
  });

  preFilledValues = {
    'tour-1': 'TCL DEMO',
    'tour-2': '1 Month',
    'tour-3': '',
    'tour-4': '',
    'tour-5': 'Internet',
    'tour-6': '50Mbps',
    'tour-7': 'Dual',
    'tour-8': 'DualLink-TCL DEMO',
    'tour-9': 'Cisco Catalyst 8000v Router',
    'tour-10': 'Small (2core)',
    'tour-11': '8GB',
    'tour-12': '2-CORE_8GB',
    'tour-13': 'Microsoft Azure',
    'tour-14': '',
    'tour-15': '50Mbps',
    'tour-16': 'Dual',
    'tour-17': 'AZURE_50Mbps_Dual-Link',
    'tour-19': 'Google Cloud Provider',
    'tour-20': '',
    'tour-21': '50Mbps',
    'tour-22': 'Single',
    'tour-23': 'GCP_50Mbps_Single-Link',
    'tour-24': 'Amazon Web Services',
    'tour-25': '',
    'tour-26': '50Mbps',
    'tour-27': 'Single',
    'tour-28': 'AWS_50Mbps_Single-Link',
    'tour-29': '64550',
    'tour-30': '192.168.5.1',
    'tour-31': '192.168.5.2',
    'tour-32': '12076',
    'tour-33': 'password',
    'tour-34': '2dd9bf2b-b049-4969-afbf-75ef41136044',
    'tour-35': '/30',
    'tour-36': '500',
    'tour-37': 'Private',
    'tour-38': '192.168.6.1',
    'tour-39': '192.168.6.2',
    'tour-40': '12076',
    'tour-41': 'password',
    'tour-42': '2dd9bf2b-b049-4969-afbf-75ef41136044',
    'tour-43': '/30',
    'tour-44': '500',
    'tour-45': 'Private',
    'tour-46': '7e51371e-72a3-40b5-b844-2e3efefaee59/us-central1/2',
    'tour-47': '192.168.1.1',
    'tour-48': '192.168.1.2',
    'tour-49': '64566',
    'tour-50': 'password',
    'tour-51': '973632751559',
    'tour-52': '/30',
  };

  mappingObjectCloud = [
    {
      30: 0,
      31: 1,
      32: 2,
      33: 3,
      34: 4,
      35: 5,
      36: 6,
      37: 7,
    },
    {
      38: 0,
      39: 1,
      40: 2,
      41: 3,
      42: 4,
      43: 5,
      44: 6,
      45: 7,
    },
    {
      46: 0,
    },
    {
      47: 0,
      48: 1,
      49: 2,
      50: 3,
      51: 4,
      52: 5,
    },
  ];

  step = '';
  printval() {
    sessionStorage.setItem(
      'solutionform',
      JSON.stringify(this.solutionForm.value)
    );
    sessionStorage.setItem(
      'connectorForm',
      JSON.stringify(this.connectorForm.value)
    );
    sessionStorage.setItem(
      'cloudConnectForm',
      JSON.stringify(this.cloudConnectForm.value)
    );
    sessionStorage.setItem(
      'secondCloudConnectForm',
      JSON.stringify(this.secondcloudConnectForm.value)
    );
    console.log(
      this.solutionForm,
      this.secondcloudConnectForm,
      this.cloudConnectForm,
      this.connectorForm
    );
  }
  setValuesOnNext(step: any) {
    if (step === 'tour-1') {
      this.solutionForm.patchValue({
        solution_alias_name: this.preFilledValues['tour-1'],
      });
      const t: any = 'tour2';
      this[t].open();
      setTimeout(() => {
        let elem =
          document.getElementsByClassName('tour')[0].parentElement
            .parentElement;
        elem.style.position = 'relative';
        elem.style.left = '275px';
      }, 0);
    }
    if (step === 'tour-2') {
      this.solutionForm.patchValue({
        term_in_months: this.preFilledValues['tour-2'],
      });
      let elem =
        document.getElementsByClassName('tour')[0].parentElement.parentElement;
      console.log(elem);
      elem.style.position = 'relative';
      elem.style.left = '0px';
      const t: any = 'tour2';
      setTimeout(() => {
        this[t].close();
      }, 300);
      const step1 = document.getElementsByClassName('step1')[0];
      const step2 = document.getElementsByClassName('step2')[0];
      step1.classList.add('done');
      step1.classList.remove('editing');
      step2.classList.add('editing');
    }

    if (step === 'tour-4') {
      const t: any = 'tour5';
      this[t].open();
      setTimeout(() => {
        let elem =
          document.getElementsByClassName('tour')[0].parentElement
            .parentElement;
        elem.style.position = 'relative';
        elem.style.left = '210px';
      }, 0);
    }

    if (step === 'tour-5') {
      this.connectorForm.patchValue({
        connType: this.preFilledValues['tour-5'],
      });
      let elem =
        document.getElementsByClassName('tour')[0].parentElement.parentElement;
      console.log(elem);
      elem.style.position = 'relative';
      elem.style.left = '0px';
      const t: any = 'tour5';
      setTimeout(() => {
        this[t].close();
      }, 300);

      const t1: any = 'tour6';
      this[t1].open();
      setTimeout(() => {
        let elem =
          document.getElementsByClassName('tour')[0].parentElement
            .parentElement;
        elem.style.position = 'relative';
        elem.style.left = '210px';
      }, 0);
    }

    if (step === 'tour-6') {
      this.connectorForm.patchValue({
        bandwidth: this.preFilledValues['tour-6'],
      });
      let elem =
        document.getElementsByClassName('tour')[0].parentElement.parentElement;
      console.log(elem);
      elem.style.position = 'relative';
      elem.style.left = '0px';
      const t: any = 'tour6';
      setTimeout(() => {
        this[t].close();
      }, 300);
    }

    if (step === 'tour-7') {
      this.connectorForm.patchValue({
        linkType: this.preFilledValues['tour-7'],
      });
    }

    if (step === 'tour-8') {
      this.connectorForm.patchValue({
        aliasName: this.preFilledValues['tour-8'],
      });

      const t: any = 'tour9';
      this[t].open();
      setTimeout(() => {
        let elem =
          document.getElementsByClassName('tour')[0].parentElement
            .parentElement;
        elem.style.position = 'relative';
        elem.style.left = '210px';
      }, 0);

      const step2 = document.getElementsByClassName('step2')[0];
      const step3 = document.getElementsByClassName('step3')[0];
      step2.classList.add('done');
      step2.classList.remove('editing');
      step3.classList.add('editing');
    }

    if (step === 'tour-9') {
      this.vnfForm.patchValue({
        routerPreference: this.preFilledValues['tour-9'],
      });
      let elem =
        document.getElementsByClassName('tour')[0].parentElement.parentElement;
      console.log(elem);
      elem.style.position = 'relative';
      elem.style.left = '0px';
      let t: any = 'tour9';
      setTimeout(() => {
        this[t].close();
      }, 300);

      let t1 = 'tour10';
      this[t1].open();
      setTimeout(() => {
        let elem =
          document.getElementsByClassName('tour')[0].parentElement
            .parentElement;
        elem.style.position = 'relative';
        elem.style.left = '210px';
      }, 0);
    }
    if (step === 'tour-10') {
      this.vnfForm.patchValue({
        corePreference: this.preFilledValues['tour-10'],
      });
      let elem =
        document.getElementsByClassName('tour')[0].parentElement.parentElement;
      console.log(elem);
      elem.style.position = 'relative';
      elem.style.left = '0px';
      let t: any = 'tour10';
      setTimeout(() => {
        this[t].close();
      }, 300);

      let t1 = 'tour11';
      this[t1].open();
      setTimeout(() => {
        let elem =
          document.getElementsByClassName('tour')[0].parentElement
            .parentElement;
        elem.style.position = 'relative';
        elem.style.left = '210px';
      }, 0);
    }
    if (step === 'tour-11') {
      this.vnfForm.patchValue({
        memory: this.preFilledValues['tour-11'],
      });

      let elem =
        document.getElementsByClassName('tour')[0].parentElement.parentElement;
      console.log(elem);
      elem.style.position = 'relative';
      elem.style.left = '0px';
      const t: any = 'tour11';
      setTimeout(() => {
        this[t].close();
      }, 300);
    }
    if (step === 'tour-12') {
      this.vnfForm.patchValue({
        aliasName: this.preFilledValues['tour-12'],
      });
      const step3 = document.getElementsByClassName('step3')[0];
      const step4 = document.getElementsByClassName('step4')[0];
      step3.classList.add('done');
      step3.classList.remove('editing');
      step4.classList.add('editing');
    }

    if (step === 'tour-13') {
      this.cloudConnectForm.patchValue({
        cloudProvider: this.preFilledValues['tour-13'],
      });
    }
    if (step == 'tour-14') {
      const t: any = 'tour15';
      this[t].open();
      setTimeout(() => {
        let elem =
          document.getElementsByClassName('tour')[0].parentElement
            .parentElement;
        elem.style.position = 'relative';
        elem.style.left = '210px';
      }, 0);
    }
    if (step === 'tour-15') {
      this.cloudConnectForm.patchValue({
        bandwidth: this.preFilledValues['tour-15'],
      });
      let elem =
        document.getElementsByClassName('tour')[0].parentElement.parentElement;
      console.log(elem);
      elem.style.position = 'relative';
      elem.style.left = '0px';
      const t: any = 'tour15';
      setTimeout(() => {
        this[t].close();
      }, 300);
    }
    if (step === 'tour-16') {
      this.cloudConnectForm.patchValue({
        linkType: this.preFilledValues['tour-16'],
      });
    }
    if (step === 'tour-17') {
      this.cloudConnectForm.patchValue({
        aliasName: this.preFilledValues['tour-17'],
      });
      this.cloudConfiguration.push(this.AzurepriConfg, this.AzureSecConfg);
    }

    if (step === 'tour-18') {
      let btn = document.getElementById('addCloud');
      btn.click();
    }

    if (step === 'tour-19') {
      this.secondcloudConnectForm.patchValue({
        cloudProvider: this.preFilledValues['tour-19'],
      });
      setTimeout(() => {
        document.getElementById('gcpactive').classList.add('active');
      }, 100);
    }
    if (step === 'tour-20') {
      const t: any = 'tour21';
      this[t].open();
      setTimeout(() => {
        let elem =
          document.getElementsByClassName('tour')[0].parentElement
            .parentElement;
        elem.style.position = 'relative';
        elem.style.left = '210px';
      }, 0);
    }
    if (step === 'tour-21') {
      this.secondcloudConnectForm.patchValue({
        bandwidth: this.preFilledValues['tour-21'],
      });
      let elem =
        document.getElementsByClassName('tour')[0].parentElement.parentElement;
      console.log(elem);
      elem.style.position = 'relative';
      elem.style.left = '0px';
      const t: any = 'tour21';
      setTimeout(() => {
        this[t].close();
      }, 300);
    }
    if (step === 'tour-22') {
      this.secondcloudConnectForm.patchValue({
        linkType: this.preFilledValues['tour-22'],
      });
    }
    if (step === 'tour-23') {
      this.secondcloudConnectForm.patchValue({
        aliasName: this.preFilledValues['tour-23'],
      });
      const step4 = document.getElementsByClassName('step4')[0];
      step4.classList.add('done');
      step4.classList.remove('editing');
      this.cloudConfiguration.push(this.gcpprimary);
      let btn = document.getElementById('addCloud');
      btn.click();
    }

    if (step === 'tour-24') {
      this.thirdcloudConnectForm.patchValue({
        cloudProvider: this.preFilledValues['tour-24'],
      });
      setTimeout(() => {
        document.getElementById('awsactive').classList.add('active');
      }, 100);
    }
    if (step === 'tour-25') {
      const t: any = 'tour26';
      this[t].open();
      setTimeout(() => {
        let elem =
          document.getElementsByClassName('tour')[0].parentElement
            .parentElement;
        elem.style.position = 'relative';
        elem.style.left = '210px';
      }, 0);
    }
    if (step === 'tour-26') {
      this.thirdcloudConnectForm.patchValue({
        bandwidth: this.preFilledValues['tour-26'],
      });
      let elem =
        document.getElementsByClassName('tour')[0].parentElement.parentElement;
      console.log(elem);
      elem.style.position = 'relative';
      elem.style.left = '0px';
      const t: any = 'tour26';
      setTimeout(() => {
        this[t].close();
      }, 300);
    }
    if (step === 'tour-27') {
      this.thirdcloudConnectForm.patchValue({
        linkType: this.preFilledValues['tour-27'],
      });
    }
    if (step === 'tour-28') {
      this.thirdcloudConnectForm.patchValue({
        aliasName: this.preFilledValues['tour-28'],
      });
      const step4 = document.getElementsByClassName('step4')[0];
      step4.classList.add('done');
      step4.classList.remove('editing');
      this.cloudConfiguration.push(this.AWSPrimaryConfg);
    }

    if (step === 'tour-29') {
      this.vnfServiceForm.patchValue({
        asNumber: this.preFilledValues['tour-29'],
      });
    }
    let objectIndex = -1;
    if (
      step.startsWith('tour-') &&
      Number(step.slice(5)) >= 30 &&
      Number(step.slice(5)) <= 52
    ) {
      const stepNumber = Number(step.slice(5));

      // Iterate through the mappingObjectCloud array
      for (let i = 0; i < this.mappingObjectCloud.length; i++) {
        const mappingObject = this.mappingObjectCloud[i];

        // Check if the stepNumber exists in the current mappingObject
        if (mappingObject.hasOwnProperty(stepNumber)) {
          // Found the object index
          objectIndex = i;
          break;
        }
      }
      this.cloudConfiguration[objectIndex]['attributes'][
        this.mappingObjectCloud[objectIndex][stepNumber]
      ].selectedValue = this.preFilledValues[step];
    }

    if (step === 'tour-53') {
      sessionStorage.setItem(
        'solutionform',
        JSON.stringify(this.solutionForm.value)
      );
      sessionStorage.setItem(
        'connectorForm',
        JSON.stringify(this.connectorForm.value)
      );
      sessionStorage.setItem(
        'cloudConnectForm',
        JSON.stringify(this.cloudConnectForm.value)
      );
      sessionStorage.setItem(
        'secondCloudConnectForm',
        JSON.stringify(this.secondcloudConnectForm.value)
      );
      // console.log(this.cloudConnectForm, this.secondcloudConnectForm, )
      this.router.navigate(['/getquote']);
    }
  }

  handleNextButtonClick(step: any) {
    if (this.tourService.hasNext(step)) {
      this.tourService.next();
      this.setValuesOnNext(step?.anchorId);
    } else {
      this.tourService.end();
      this.setValuesOnNext(step?.anchorId);
    }
  }

  ngOnInit(): void {
    const tourSteps = [
      {
        anchorId: 'tour-1', // Solution Alias Name
        content:
          'Enter a unique alias name to identify your cloud solution i.e. "IZO_Internet/Private_UniqueID".',
        title: 'Solution Alias Name',
        placement: 'top',
      },
      {
        anchorId: 'tour-2', // Contract Term
        content: 'Select term for service for your cloud solution.',
        title: 'Contract Duration and Mode',
        placement: 'top',
      },
      {
        anchorId: 'tour-3', // Region
        content: 'Select the region based on your cloud  availbility.',
        title: 'Region selection',
        placement: 'top',
      },
      {
        anchorId: 'tour-4', // City
        content:
          'Select the city within selected region for your cloud solution.',
        title: 'City Selection',
        placement: 'top',
      },
      {
        anchorId: 'tour-5', // Connection Type
        content:
          'Choose the type of connection for your cloud solution (e.g., dedicated, shared).',
        title: 'Select Connection Type',
        placement: 'top',
      },
      {
        anchorId: 'tour-6', // Bandwidth
        content:
          'Specify the desired bandwidth for your cloud solution connection.',
        title: 'Select Bandwidth',
        placement: 'top',
      },
      {
        anchorId: 'tour-7', // Link Type
        content:
          'Choose the type of link for your cloud solution (e.g., point-to-point, multipoint).',
        title: 'Provide Link Type',
        placement: 'top',
      },
      {
        anchorId: 'tour-8', // Alias Name
        content:
          'Give your connector a unique alias name for easy identification.',
        title: 'Connector Alias Name',
        placement: 'top',
      },
      {
        anchorId: 'tour-9', // Router Preference
        content: 'Select your preferred router type for your VNF service.',
        title: 'Router preference',
        placement: 'top',
      },
      {
        anchorId: 'tour-10', // Core Preference
        content:
          'Choose your preferred core size based on your VNF performance needs.',
        title: 'VNF Sizing Preference',
        placement: 'top',
      },
      {
        anchorId: 'tour-11', // Memory
        content:
          'Based on your core preference, select the appropriate memory allocation.',
        title: 'Memory Sizing',
        placement: 'top',
      },
      {
        anchorId: 'tour-12', // Alias Name
        content:
          'Assign a unique alias name to easily identify your VNF service.',
        title: 'VNF Alias Name',
        placement: 'top',
      },
      {
        anchorId: 'tour-13', // Cloud Provider
        content:
          'Choose your preferred cloud provider for your cloud connect service.',
        title: 'Select Cloud Provider',
        placement: 'top',
      },
      {
        anchorId: 'tour-14', // Interconnect Location
        content:
          'Specify the interconnect location for your cloud connect service.',
        title: 'Interconnect Location',
        placement: 'top',
      },
      {
        anchorId: 'tour-15', // Bandwidth
        content:
          'Define the desired bandwidth for edge for your cloud connect service.',
        title: 'Edge Bandwidth Selection',
        placement: 'top',
      },
      {
        anchorId: 'tour-16', // Link Type
        content:
          'Choose the CSP link type for your cloud connect service (e.g., dedicated, shared).',
        title: 'Select Link Type',
        placement: 'top',
      },
      {
        anchorId: 'tour-17', // Alias Name
        content:
          'Give your cloud connect service a unique alias name for easy reference.',
        title: 'Cloud Connect Alias Name',
        placement: 'top',
      },
      {
        anchorId: 'tour-18', // Cloud Provider
        content:
          'Choose your preferred cloud provider for your cloud connect service.',
        title: 'Select Cloud Provider',
        placement: 'top',
      },
      {
        anchorId: 'tour-19', // Cloud Provider
        content:
          'Choose your preferred cloud provider for your cloud connect service.',
        title: ' Select Cloud Provider',
        placement: 'top',
      },
      {
        anchorId: 'tour-20', // Interconnect Location
        content:
          'Specify the interconnect location for your cloud connect service.',
        title: 'Select Interconnect Location',
        placement: 'top',
      },
      {
        anchorId: 'tour-21', // Bandwidth
        content: 'Define the desired bandwidth for your cloud connect service.',
        title: 'Select Bandwidth',
        placement: 'top',
      },
      {
        anchorId: 'tour-22', // Link Type
        content:
          'Choose the link type for your cloud connect service (e.g., dedicated, shared).',
        title: 'Select Link Type',
        placement: 'top',
      },
      {
        anchorId: 'tour-23', // Alias Name
        content:
          'Give your cloud connect service a unique alias name for easy reference.',
        title: 'Cloud Connect Alias Name',
        placement: 'top',
      },
      {
        anchorId: 'tour-24', // Cloud Provider
        content:
          'Choose your preferred cloud provider for your cloud connect service.',
        title: ' Select Cloud Provider',
        placement: 'top',
      },
      {
        anchorId: 'tour-25', // Interconnect Location
        content:
          'Specify the interconnect location for your cloud connect service.',
        title: 'Select Interconnect Location',
        placement: 'top',
      },
      {
        anchorId: 'tour-26', // Bandwidth
        content: 'Define the desired bandwidth for your cloud connect service.',
        title: 'Select Bandwidth',
        placement: 'top',
      },
      {
        anchorId: 'tour-27', // Link Type
        content:
          'Choose the link type for your cloud connect service (e.g., dedicated, shared).',
        title: 'Select Link Type',
        placement: 'top',
      },
      {
        anchorId: 'tour-28', // Alias Name
        content:
          'Give your cloud connect service a unique alias name for easy reference.',
        title: 'Cloud Connect Alias Name',
        placement: 'top',
      },
      {
        anchorId: 'tour-29', // VNF Detail AS Number
        content:
          'Enter the Autonomous System (AS) number (Local) for your VNF.',
        title: 'VNF Detail AS Number (Local)',
        placement: 'bottom',
      },

      {
        anchorId: 'tour-30', // Azure Primary - IP Address for Interface
        content: 'Specify the IP address for the primary interface in Azure.',
        title: 'Azure Primary - IP Address for Interface',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-31', // Azure Primary - Neighbour IP
        content:
          'Enter the IP address of the neighbor for the primary interface in Azure.',
        title: 'Azure Primary - Neighbour IP',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-32', // Azure Primary - AS Number Remote
        content:
          'Enter the AS number of the remote peer for the primary interface in Azure.',
        title: 'Azure Primary - AS Number Remote',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-33', // Azure Primary - BGP Key
        content: 'Provide the BGP key for the primary interface in Azure.',
        title: 'Azure Primary - BGP Key',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-34', // Azure Primary - Service Key
        content: 'Enter the service key for the primary interface in Azure.',
        title: 'Azure Primary - Service Key',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-35', // Azure Primary - Subnet
        content: 'Specify the subnet for the primary interface in Azure.',
        title: 'Azure Primary - Subnet',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-36', // Azure Primary - VLAN
        content: 'Enter the VLAN ID for the primary interface in Azure.',
        title: 'Azure Primary - VLAN',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-37', // Azure Primary - Peer Type
        content:
          'Choose the peer type for the primary interface in Azure (e.g., public, private).',
        title: 'Azure Primary - Peer Type',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-38', // Azure Secondary - IP Address for Interface
        content: 'Specify the IP address for the secondary interface in Azure.',
        title: 'Azure Secondary - IP Address for Interface',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-39', // Azure Secondary - Neighbour IP
        content:
          'Enter the IP address of the neighbor for the secondary interface in Azure.',
        title: 'Azure Secondary - Neighbour IP',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-40', // Azure Secondary - AS Number Remote
        content:
          'Enter the AS number of the remote peer for the secondary interface in Azure.',
        title: 'Azure Secondary - AS Number Remote',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-41', // Azure Secondary - BGP Key
        content: 'Provide the BGP key for the secondary interface in Azure.',
        title: 'Azure Secondary - BGP Key',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-42', // Azure Secondary - Service Key
        content: 'Enter the service key for the secondary interface in Azure.',
        title: 'Azure Secondary - Service Key',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-43', // Azure Secondary - Subnet
        content: 'Specify the subnet for the secondary interface in Azure.',
        title: 'Azure Secondary - Subnet',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-44', // Azure Secondary - VLAN
        content: 'Enter the VLAN ID for the secondary interface in Azure.',
        title: 'Azure Secondary - VLAN',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-45', // Azure Secondary - Peer Type
        content:
          'Choose the peer type for the secondary interface in Azure (e.g., public, private).',
        title: 'Azure Secondary - Peer Type',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-46',
        content:
          'Provide the pairing key for connecting with Google Cloud Platform (GCP).',
        title: 'GCP Pairing Key',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-47',
        content: 'Enter the IP address for the primary interface.',
        title: 'Primary IP Address',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-48',
        content:
          'Enter the IP address of the neighbor for the primary interface.',
        title: 'Primary Neighbour IP',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-49',
        content:
          'Enter the AS number of the remote peer for the primary interface.',
        title: 'Primary Remote AS Number',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-50',
        content: 'Provide the BGP key for the primary interface.',
        title: 'Primary BGP Key',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-51',
        content: 'Enter the Account ID for the primary interface.',
        title: 'Primary Account ID',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-52',
        content: 'Specify the subnet for the primary interface.',
        title: 'Primary Subnet',
        placement: 'bottom',
      },
      {
        anchorId: 'tour-53',
        content:
          'Click here to get a quote for the services you have selected.',
        title: 'Get Quote',
        placement: 'bottom',
      },
    ];

    this.tourService.initialize(tourSteps, {
      enableBackdrop: false,
      backdropConfig: {
        offset: 10,
        closeOnBackdropClick: false,
      },
    });

    setTimeout(() => {
      this.startTour();
    }, 200);

    this.activatedRoute.paramMap.subscribe((params: any) => {
      console.log('params ', params);
      this.quoteObj = params.params;
      console.log('else param ', this.quoteObj);
    });

    this.customer = JSON.parse(this.datasharedSvc.getCustomerFromSession());
    this.customerLeid = JSON.parse(
      this.datasharedSvc.getCustomerLeFromSession()
    );

    this.city = 'Chicago';
    if (this.city) {
      this.getMapLocations(this.region, this.country, this.city);
      let customerObj = {
        city: 'Chicago',
        country: 'UNITED STATES',
        region: 'AMER',
        popStatus: 'Live',
        latitude: '41.8781',
        longitude: '-87.6298',
        draggable: 'false',
        icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
        siteCode: 'CHI',
        siteAddress:
          'EQUINIX CH3, 1905 LUNT AVENUE, ELK GROVE VILLAGE, IL 60007, USA',
        siteType: null,
      };
      if (customerObj.country) {
        this.country = customerObj.country.toLocaleUpperCase();
      } else {
        let countryNameUser = JSON.parse(
          sessionStorage.getItem('customerLeCountry')
        );
        if (countryNameUser) {
          this.country = countryNameUser.toLocaleUpperCase();
        }
      }
    } else {
      let countryNameUser = JSON.parse(
        sessionStorage.getItem('customerLeCountry')
      );
      if (countryNameUser) {
        this.country = countryNameUser.toLocaleUpperCase();
      } else {
        let customerObj = JSON.parse(
          this.datasharedSvc.getCustomerFromSession()
        );
        this.country = customerObj.country.toLocaleUpperCase();
      }
      this.getMapLocations(this.region, this.country, this.city);
    }

    this.hidebutton = true;
    this.dimawsImage = false;
    this.dimazureImage = false;

    this.cloudConnectForm.get('cloudProvider').setValue('Amazon Web Services');
    this.cloudConnectForm.get('linkType').setValue('Single');
    this.connectorForm.get('linkType').setValue('Single');
    this.datasharedSvc.sharedData.subscribe((data: any) => {
      this.customerid = data.customerId;
      console.log('this.customerLeid', this.customerLeid);
    });

    console.log('quoteObj() ', this.quoteObj);
    let myObject = this.quoteObj;
    console.log('quoteObj Cond() ', Object.keys(myObject).length !== 0);
    if (Object.keys(myObject).length !== 0) {
      this.savedQuoteDetails(); // Auto populate quote details
    } else {
      this.selectCloud('Google Cloud Provider');
    }
  }

  startTour() {
    this.tourService.start();
    // this.tourService.startAt('tour-17');
  }

  getMapLocations(regionName, countryName, cityName) {
    const response = {
      data: [
        {
          city: 'Amsterdam',
          country: 'NETHERLANDS',
          region: 'EMEA',
          popStatus: 'Live',
          latitude: '52.3031732618186',
          longitude: '4.93831828657571',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'AV2',
          siteAddress:
            'COLO AT EQUINIX (NETHERLANDS) BV, EQUINIX AM7,TATA COMMUNICATIONS (NETHERLANDS) B.V.  KUIPERBERGWEG 13,1101 AE  AMSTERDAM,THE NETHERLANDS',
          siteType: null,
        },
        {
          city: 'Ashburn',
          country: 'UNITED STATES',
          region: 'AMER',
          popStatus: 'Live',
          latitude: '39.0437',
          longitude: '-77.4875',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'ASH',
          siteAddress:
            'COLO AT EQUINIX (ASHBURN)  EQUINIX DC2, 21715 Filigree Court, Ashburn, VA 20147, US',
          siteType: null,
        },
        {
          city: 'Bangalore',
          country: 'INDIA',
          region: 'APAC',
          popStatus: 'Live',
          latitude: '12.9716',
          longitude: '77.5946',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'BLR',
          siteAddress:
            'EQUINIX BAN 1A, AIT CAMPUS, BIDARAHALLI, BENGALURU, KARNATAKA 562 109, INDIA',
          siteType: null,
        },
        {
          city: 'Chennai',
          country: 'INDIA',
          region: 'APAC',
          popStatus: 'Live',
          latitude: '13.0827',
          longitude: '80.2707',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'CHN',
          siteAddress:
            'EQUINIX MAA1, MAHAJAN TOWERS, NO. 45, WEST CIT NAGAR, NANDANAM, CHENNAI, TAMIL NADU 600 035, INDIA',
          siteType: null,
        },
        {
          city: 'Chicago',
          country: 'UNITED STATES',
          region: 'AMER',
          popStatus: 'Live',
          latitude: '41.8781',
          longitude: '-87.6298',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'CHI',
          siteAddress:
            'EQUINIX CH3, 1905 LUNT AVENUE, ELK GROVE VILLAGE, IL 60007, USA',
          siteType: null,
        },
        {
          city: 'Dallas',
          country: 'UNITED STATES',
          region: 'AMER',
          popStatus: 'Live',
          latitude: '32.7767',
          longitude: '-96.7970',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'DAL',
          siteAddress:
            'EQUINIX DA1, 1950 N STEMMONS FREEWAY, DALLAS, TX 75207, USA',
          siteType: null,
        },
        {
          city: 'Delhi',
          country: 'INDIA',
          region: 'APAC',
          popStatus: 'Live',
          latitude: '28.7041',
          longitude: '77.1025',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'DEL',
          siteAddress:
            'EQUINIX NDC DEL 1B, PLOT NO 6, NEW DELHI CHATTARPUR ENCLAVE, PHASE 3, NEW DELHI, INDIA',
          siteType: null,
        },
        {
          city: 'Dublin',
          country: 'IRELAND',
          region: 'EMEA',
          popStatus: 'Live',
          latitude: '53.3498',
          longitude: '-6.2603',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'DB1',
          siteAddress: 'EQUINIX DB1, UNIT 5, KILSHANE ROAD, DUBLIN 15, IRELAND',
          siteType: null,
        },
        {
          city: 'Frankfurt',
          country: 'GERMANY',
          region: 'EMEA',
          popStatus: 'Live',
          latitude: '50.1109',
          longitude: '8.6821',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'FR1',
          siteAddress:
            'EQUINIX FRANKFURT II (FR2), LICHTENBERGER STRASSE 2, FRANKFURT  66386, GERMANY',
          siteType: null,
        },
        {
          city: 'Hong Kong',
          country: 'CHINA',
          region: 'APAC',
          popStatus: 'Live',
          latitude: '22.3193',
          longitude: '114.1694',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'HK1',
          siteAddress:
            'EQUINIX HK1, 15/F, BIG WAVE BAY, TKO GATEWAY DATA CENTER, HONG KONG, CHINA',
          siteType: null,
        },
        {
          city: 'Hyderabad',
          country: 'INDIA',
          region: 'APAC',
          popStatus: 'Live',
          latitude: '17.3850',
          longitude: '78.4867',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'HYD',
          siteAddress:
            'EQUINIX NDC HYD 1, PLOT NO. 2, IT PARK NANAKRAMGUDA, HYDERABAD  500 032, INDIA',
          siteType: null,
        },
        {
          city: 'Kolkata',
          country: 'INDIA',
          region: 'APAC',
          popStatus: 'Live',
          latitude: '22.5726',
          longitude: '88.3639',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'KOL',
          siteAddress:
            'EQUINIX NDC KOL 1, ECOSPACE BUSINESS PARK, NEW TOWN, RAJARHAT, KOLKATA  700 156, INDIA',
          siteType: null,
        },
        {
          city: 'London',
          country: 'UNITED KINGDOM',
          region: 'EMEA',
          popStatus: 'Live',
          latitude: '51.5074',
          longitude: '-0.1278',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'LD6',
          siteAddress:
            'EQUINIX LD6, 8/9 HARBOUR EXCHANGE, LONDON  E14 9GE, UNITED KINGDOM',
          siteType: null,
        },
        {
          city: 'Los Angeles',
          country: 'UNITED STATES',
          region: 'AMER',
          popStatus: 'Live',
          latitude: '34.0522',
          longitude: '-118.2437',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'LA1',
          siteAddress:
            'EQUINIX LA1, 624 S GRAND AVENUE, LOS ANGELES, CA 90017, USA',
          siteType: null,
        },
        {
          city: 'Madrid',
          country: 'SPAIN',
          region: 'EMEA',
          popStatus: 'Live',
          latitude: '40.4168',
          longitude: '-3.7038',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'MD1',
          siteAddress:
            'EQUINIX MADRID (MD1), CALLE ACANTO, 8, SAN FERNANDO DE HENARES  28830, SPAIN',
          siteType: null,
        },
        {
          city: 'Miami',
          country: 'UNITED STATES',
          region: 'AMER',
          popStatus: 'Live',
          latitude: '25.7617',
          longitude: '-80.1918',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'MI1',
          siteAddress: 'EQUINIX MI1, 50 NE 9TH STREET, MIAMI, FL 33132, USA',
          siteType: null,
        },
        {
          city: 'Mumbai',
          country: 'INDIA',
          region: 'APAC',
          popStatus: 'Live',
          latitude: '19.0760',
          longitude: '72.8777',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'MUM',
          siteAddress: 'EQUINIX MUM 1, KURLA MUMBAI  400 070, INDIA',
          siteType: null,
        },
        {
          city: 'New York',
          country: 'UNITED STATES',
          region: 'AMER',
          popStatus: 'Live',
          latitude: '40.7128',
          longitude: '-74.0060',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'NY5',
          siteAddress: 'EQUINIX NY5, 800 SEC AVE, NEW YORK, NY 10017, USA',
          siteType: null,
        },
        {
          city: 'Paris',
          country: 'FRANCE',
          region: 'EMEA',
          popStatus: 'Live',
          latitude: '48.8566',
          longitude: '2.3522',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'PA8',
          siteAddress:
            'EQUINIX PA8, 15 BOULEVARD DU GENERAL DE GAULLE, SAINT DENIS 93200, FRANCE',
          siteType: null,
        },
        {
          city: 'Pune',
          country: 'INDIA',
          region: 'APAC',
          popStatus: 'Live',
          latitude: '18.5204',
          longitude: '73.8567',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'PUN',
          siteAddress: 'EQUINIX NDC PUNE 1, INDIA',
          siteType: null,
        },
        {
          city: 'San Jose',
          country: 'UNITED STATES',
          region: 'AMER',
          popStatus: 'Live',
          latitude: '37.3382',
          longitude: '-121.8863',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'SV8',
          siteAddress:
            'EQUINIX SV8, 529 BRYANT STREET, PALO ALTO, CA 94301, USA',
          siteType: null,
        },
        {
          city: 'Sao Paulo',
          country: 'BRAZIL',
          region: 'AMER',
          popStatus: 'Live',
          latitude: '-23.5505',
          longitude: '-46.6333',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'SP2',
          siteAddress:
            'EQUINIX SP2, RUA DR. JOAO PASSALAQUA, 100, SAO PAULO  SP  01304-010, BRAZIL',
          siteType: null,
        },
        {
          city: 'Seattle',
          country: 'UNITED STATES',
          region: 'AMER',
          popStatus: 'Live',
          latitude: '47.6062',
          longitude: '-122.3321',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'SE2',
          siteAddress: 'EQUINIX SE2, 2020 5TH AVENUE, SEATTLE, WA 98121, USA',
          siteType: null,
        },
        {
          city: 'Singapore',
          country: 'SINGAPORE',
          region: 'APAC',
          popStatus: 'Live',
          latitude: '1.3521',
          longitude: '103.8198',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'SG1',
          siteAddress:
            'EQUINIX SG1, 26A AYER RAJAH CRESCENT, SINGAPORE 139963, SINGAPORE',
          siteType: null,
        },
        {
          city: 'Stockholm',
          country: 'SWEDEN',
          region: 'EMEA',
          popStatus: 'Live',
          latitude: '59.3293',
          longitude: '18.0686',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'STO',
          siteAddress:
            'EQUINIX SK2, OLOF PALMES GATA 14, 114 37 STOCKHOLM, SWEDEN',
          siteType: null,
        },
        {
          city: 'Sydney',
          country: 'AUSTRALIA',
          region: 'APAC',
          popStatus: 'Live',
          latitude: '-33.8688',
          longitude: '151.2093',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'SY3',
          siteAddress:
            'EQUINIX SY3, 47 BOURKE ROAD, ALEXANDRIA NSW 2015, AUSTRALIA',
          siteType: null,
        },
        {
          city: 'Tokyo',
          country: 'JAPAN',
          region: 'APAC',
          popStatus: 'Live',
          latitude: '35.6895',
          longitude: '139.6917',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'TY1',
          siteAddress:
            'EQUINIX TY1, KINSHICHO GATE TOWER, 2-2-1, WAKAKUSAGI, SUMIDA-KU, TOKYO  130 0013, JAPAN',
          siteType: null,
        },
        {
          city: 'Toronto',
          country: 'CANADA',
          region: 'AMER',
          popStatus: 'Live',
          latitude: '43.6510',
          longitude: '-79.3470',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'TR2',
          siteAddress:
            'EQUINIX TR2, 45 Parliament Street, Toronto, ON, M5A 3C4, CANADA',
          siteType: null,
        },
        {
          city: 'Beijing',
          country: 'CHINA',
          region: 'APAC',
          popStatus: 'via Partner',
          latitude: '39.9042',
          longitude: '116.4074',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'BE1',
          siteAddress: 'EQUINIX BE1, 100A OLYMPIC VILLAGE, BEIJING, CHINA',
          siteType: null,
        },
        {
          city: 'Guangzhou',
          country: 'CHINA',
          region: 'APAC',
          popStatus: 'via Partner',
          latitude: '23.1291',
          longitude: '113.2644',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'GZ1',
          siteAddress:
            'EQUINIX GZ1, 47-63 FUTIAN SOUTHERN ROAD, FUTIAN DISTRICT, SHENZHEN, CHINA',
          siteType: null,
        },
        {
          city: 'Shanghai',
          country: 'CHINA',
          region: 'APAC',
          popStatus: 'via Partner',
          latitude: '31.2304',
          longitude: '121.4737',
          draggable: 'false',
          icon: 'https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000',
          siteCode: 'SH1',
          siteAddress: 'EQUINIX SH1, CHANGNING DISTRICT, SHANGHAI, CHINA',
          siteType: null,
        },
      ],
    };
    this.regionList = response.data;
    if (countryName != '' && countryName != undefined) {
      this.regionList.forEach((c) => {
        if (c.country == countryName) {
          this.locationObj.push(c);
        }
      });
      this.region = this.locationObj.find(
        (ele) => ele.country == countryName
      ).region;
      this.locationForm.get('region').setValue(this.region);
      this.onSelectionRegion();
      this.onLocSumbit();
      this.diableRegion = true;
    } else if (cityName != '' && cityName != undefined) {
      this.regionList.forEach((ci) => {
        console.log('cond ', ci.city == cityName);
        if (ci.city == cityName) {
          this.locationObj.push(ci);
        }
      });
      console.log('locationObj', this.locationObj);
      this.region = this.locationObj.find(
        (ele) => ele.city == this.city
      ).region;
      this.locationForm.get('region').setValue(this.region);
      this.locationForm.get('city').setValue(this.city);
      this.onSelectionLocation();
      this.onLocSumbit();
      this.disableLocation = true;
      this.diableRegion = true;
    } else if (regionName != '' && regionName != undefined) {
      this.regionList.forEach((re) => {
        if (re.region == regionName) {
          this.locationObj.push(re);
        }
      });
      this.region = regionName;
      this.locationForm.get('region').setValue(this.region);
      this.onSelectionRegion();
      this.onLocSumbit();
      this.diableRegion = true;
      console.log('locationObj', this.locationObj);
    } else {
    }

    //prepare unique location objects
    var unique = this.locationObj.filter(
      (value, index, self) =>
        index ===
        self.findIndex(
          (t) => t.country === value.country && t.city === value.city
        )
    );
    this.locationObj = unique;
    console.log('unique', unique);
    console.log('locationObj', this.locationObj);
  }

  openPanel(el, i) {
    // let obj = { panelflag: false }
    // Object.assign(obj, el)
  }

  closePanel(el, i) {
    // let obj = { panelflag: true }
    // Object.assign(obj, el)
  }

  cloudSave(data, index) {
    console.warn(
      'interLocation => cloudSave ',
      this.cloudConnectForm.get('location').value
    );
    console.log(this.cloudConnectForm.value);
    if (this.cloudConnectForm.valid) {
      let cloudProvider = this.cloudConnectForm.get('cloudProvider').value;
      console.log('cloudProvider ', cloudProvider);

      if (cloudProvider != null) {
        if (
          cloudProvider == 'Amazon Web Services' &&
          this.cloudConfiguration.length == 2
        ) {
          this.cloudConfiguration.push(this.AWSPrimaryConfg);
        } else if (
          cloudProvider == 'Amazon Web Services' &&
          this.cloudConfiguration.length == 0
        ) {
          this.cloudConfiguration.push(this.AWSPrimaryConfg);
          // this.showGCPServiceAttr = false;
        } else if (
          cloudProvider == 'Microsoft Azure' &&
          this.cloudConfiguration.length == 0
        ) {
          this.cloudConfiguration.push(this.AzurepriConfg, this.AzureSecConfg);
          // this.showGCPServiceAttr = false;
        } else if (
          cloudProvider == 'Microsoft Azure' &&
          this.cloudConfiguration.length == 1
        ) {
          this.cloudConfiguration.push(this.AzurepriConfg, this.AzureSecConfg);
        } else if (
          cloudProvider == 'Google Cloud Provider' &&
          this.cloudConfiguration.length <= 3
        ) {
          this.showGCPServiceAttr = true;
        }
      }
    } else {
      console.warn('NOT VALID FORM');
    }
  }

  secdcloudSave(data, index) {
    console.log(this.secondcloudConnectForm.value);

    if (this.secondcloudConnectForm.valid) {
      let cloudProvider =
        this.secondcloudConnectForm.get('cloudProvider').value;
      console.log('cloudProvider ', cloudProvider);
      if (cloudProvider != null) {
        if (
          cloudProvider == 'Amazon Web Services' &&
          this.cloudConfiguration.length == 2
        ) {
          this.cloudConfiguration.push(this.AWSPrimaryConfg);
        } else if (
          cloudProvider == 'Amazon Web Services' &&
          this.cloudConfiguration.length == 0
        ) {
          this.cloudConfiguration.push(this.AWSPrimaryConfg);
        } else if (
          cloudProvider == 'Microsoft Azure' &&
          this.cloudConfiguration.length == 0
        ) {
          this.cloudConfiguration.push(this.AzurepriConfg, this.AzureSecConfg);
        } else if (
          cloudProvider == 'Microsoft Azure' &&
          this.cloudConfiguration.length == 1
        ) {
          this.cloudConfiguration.push(this.AzurepriConfg, this.AzureSecConfg);
        } else if (
          cloudProvider == 'Google Cloud Provider' &&
          this.cloudConfiguration.length <= 3
        ) {
          this.showGCPServiceAttr = true;
        }
        console.log(this.cloudConfiguration);
      }
    }
    this.validateAddiConfig();
  }

  selectCloud(cloud) {
    console.log('cloud provider ', cloud);
    console.log('selectedCloud1 ', this.selectedCloud1);
    if (this.selectedCloud1 === cloud) {
      console.log('Same Cloud selection ');
      return;
    }

    this.selectedCloud1 = cloud;
    if (cloud == 'Amazon Web Services') {
      this.cloudConnectForm.get('linkType').setValue('Single');
      this.cloudConnectForm
        .get('cloudProvider')
        .setValue('Amazon Web Services');
      //reset values by switching option
      this.cloudConnectForm.get('bandwidth').setValue('');
      this.cloudConnectForm.get('aliasName').setValue('');
      //secondary cloud
      this.isdisableCP2 = 'Amazon Web Services';
      this.secondcloudConnectForm
        .get('cloudProvider')
        .setValue('Microsoft Azure');
      this.secondcloudConnectForm.get('linkType').setValue('Dual');
      console.log('secondcloudConnectForm ', this.secondcloudConnectForm.value);

      console.log('cloudConfiguration ', this.cloudConfiguration);
      this.cloudConfiguration.forEach((el) => {
        console.log('el ', el);
        if (
          el.cloudConfgName == 'Microsoft Azure Primary' ||
          el.cloudConfgName == 'Microsoft Azure Secondary'
        ) {
          el.attributes.forEach((ea) => {
            ea.selectedValue = '';
          });
        }
      });
      this.showGCPServiceAttr = false;
      this.gcpServiceForm.get('pairingKey').reset();
    } else if (cloud == 'Microsoft Azure') {
      this.cloudConnectForm.get('cloudProvider').setValue('Microsoft Azure');
      this.cloudConnectForm.get('linkType').setValue('Dual');
      //reset values by switching option
      this.cloudConnectForm.get('bandwidth').setValue('');
      this.cloudConnectForm.get('aliasName').setValue('');
      //secondary cloud
      this.isdisableCP2 = 'Microsoft Azure';
      this.secondcloudConnectForm
        .get('cloudProvider')
        .setValue('Amazon Web Services');
      this.secondcloudConnectForm.get('linkType').setValue('Single');
      console.log('secondcloudConnectForm ', this.secondcloudConnectForm.value);

      console.log('cloudConfiguration ', this.cloudConfiguration);
      this.cloudConfiguration.forEach((el) => {
        console.log('el ', el);
        if (el.cloudConfgName == 'Amazon Web Services') {
          el.attributes.forEach((ea) => {
            ea.selectedValue = '';
          });
        }
      });
      this.showGCPServiceAttr = false;
      this.gcpServiceForm.get('pairingKey').reset();
      // this.pairingKeyEndVal = false;
    } else if (cloud == 'Google Cloud Provider') {
      this.cloudConnectForm.get('linkType').setValue('Single');
      this.cloudConnectForm
        .get('cloudProvider')
        .setValue('Google Cloud Provider');
      this.cloudConnectForm.get('bandwidth').setValue('');
      this.cloudConnectForm.get('aliasName').setValue('');
      //secondary cloud
      this.isdisableCP2 = 'Google Cloud Provider';
      this.secondcloudConnectForm
        .get('cloudProvider')
        .setValue('Amazon Web Services');
      this.secondcloudConnectForm.get('linkType').setValue('Single');
      console.log('secondcloudConnectForm ', this.secondcloudConnectForm.value);

      console.log('cloudConfiguration ', this.cloudConfiguration);
    }

    //removing additional config by switching option
    if (this.cloudConfiguration.length > 0) {
      this.showSecondaryForm = false;
      this.showThirdForm = false;
      this.isaddBtndisable = false;
    }

    this.hideConfigDetails = false;
    this.cloudConfiguration = [];
    console.log('cloudConfiguration ', this.cloudConfiguration);

    let cp: string = null;
    if (cloud == 'Amazon Web Services') {
      cp = 'Microsoft Azure';
    } else if (cloud == 'Microsoft Azure') {
      cp = 'Amazon Web Services';
    } else if (cloud == 'Google Cloud Provider') {
      cp = 'Amazon Web Services';
    }

    this.secondselectCloud(cp);
  }

  secondselectCloud(cloud) {
    console.log('cloud provider ', cloud);
    console.log('cloudConnectForm ', this.cloudConnectForm.value);
    console.log('secondcloudConnectForm ', this.secondcloudConnectForm.value);
    console.log('thirdcloudConnectForm ', this.thirdcloudConnectForm.value);
    let CP1: string = this.cloudConnectForm.get('cloudProvider').value;
    console.log('selectedCloud2 ', this.selectedCloud2);
    if (this.selectedCloud2 === cloud) {
      console.log('Same Cloud selection ');
      return;
    }

    this.selectedCloud2 = cloud;

    if (cloud == 'Amazon Web Services') {
      this.secondcloudConnectForm.get('linkType').setValue('Single');
      this.secondcloudConnectForm
        .get('cloudProvider')
        .setValue('Amazon Web Services');
      //reset values by switching option
      this.secondcloudConnectForm.get('bandwidth').setValue('');
      this.secondcloudConnectForm.get('aliasName').setValue('');
      //third cloud
      this.isdisableCP3 = 'Amazon Web Services';
      if (CP1 == 'Microsoft Azure') {
        console.log('cond 11 ');
        this.thirdcloudConnectForm
          .get('cloudProvider')
          .setValue('Google Cloud Provider');
        this.thirdcloudConnectForm.get('linkType').setValue('Single');
      } else if (CP1 == 'Google Cloud Provider') {
        console.log('cond 12 ');
        this.thirdcloudConnectForm
          .get('cloudProvider')
          .setValue('Microsoft Azure');
        this.thirdcloudConnectForm.get('linkType').setValue('Dual');
      }

      //removing additional config by switching option
      if (this.cloudConfiguration.length > 0) {
        this.isaddBtndisable = false;
      }
      this.showThirdForm = false;
      console.log('showThirdForm ', this.showThirdForm);
      // this.hideConfigDetails = false;
      // // this.cloudConfiguration = [];

      let cloudConfigs: any[] = this.cloudConfiguration;
      console.log('this.isdisableCP3 ', this.isdisableCP3);
      if (this.isdisableCP3 == 'Amazon Web Services') {
        for (let i = 0; i < this.cloudConfiguration.length + 1; i++) {
          const element = cloudConfigs[i];
          console.log(element);
          if (
            element.cloudConfgName == 'Amazon Web Services' &&
            CP1 != 'Amazon Web Services'
          ) {
            cloudConfigs.splice(i, 1);
          }
        }
      } else if (
        this.isdisableCP3 == 'Microsoft Azure Primary' ||
        this.isdisableCP3 == 'Microsoft Azure Secondary'
      ) {
        for (let i = 0; i < this.cloudConfiguration.length + 1; i++) {
          const element = cloudConfigs[i];
          console.log(element);
          if (
            (element.cloudConfgName == 'Microsoft Azure Primary' ||
              element.cloudConfgName == 'Microsoft Azure Secondary') &&
            CP1 != 'Microsoft Azure'
          ) {
            cloudConfigs.splice(i, 2);
          }
        }
      } else if (
        this.isdisableCP3 == 'Google Cloud Provider' &&
        CP1 != 'Microsoft Azure'
      ) {
        this.showGCPServiceAttr = false;
        this.gcpServiceForm.reset();
      }

      console.log('cloudConfiguration ', this.cloudConfiguration);
    }
  }

  addCloud(data, index) {
    console.log('cloudConnectForm ', this.cloudConnectForm.valid);
    console.log('secondcloudConnectForm ', this.secondcloudConnectForm.valid);
    console.log('thirdcloudConnectForm ', this.thirdcloudConnectForm.valid);

    if (this.showSecondaryForm) {
      this.addThirdCloud();
    } else {
      this.addSecondCloud();
    }
  }

  addSecondCloud() {
    console.log('cloudConnectForm ', this.cloudConnectForm.valid);
    console.log('secondcloudConnectForm ', this.secondcloudConnectForm.valid);
    console.log('thirdcloudConnectForm ', this.thirdcloudConnectForm.valid);

    if (this.cloudConnectForm.get('location').value == '') {
      this.openSnackBar('Select interconnect location');
      return;
    } else if (this.cloudConnectForm.get('bandwidth').value == '') {
      this.openSnackBar('Select cloud bandwidth');
      return;
    } else if (this.cloudConnectForm.get('linkType').value == '') {
      this.openSnackBar('Select cloud link type');
      return;
    } else if (this.cloudConnectForm.get('aliasName').value == '') {
      this.openSnackBar('Enter cloud aliasname');
      return;
    } else {
      this.secondcloudConnectForm.get('bandwidth').setValue('');
      this.secondcloudConnectForm.get('aliasName').setValue('');
      let city = this.locationForm.get('city').value;
      this.secondcloudConnectForm.get('location').setValue(city);

      this.showSecondaryForm = true;
      this.hideConfigDetails = false;
      console.log('secondcloudConnectForm ', this.secondcloudConnectForm.value);
    }
  }

  addThirdCloud() {
    if (this.secondcloudConnectForm.get('location').value == '') {
      this.openSnackBar('Select interconnect location');
      return;
    } else if (this.secondcloudConnectForm.get('bandwidth').value == '') {
      this.openSnackBar('Select cloud bandwidth');
      return;
    } else if (this.secondcloudConnectForm.get('linkType').value == '') {
      this.openSnackBar('Select cloud link type');
      return;
    } else if (this.secondcloudConnectForm.get('aliasName').value == '') {
      this.openSnackBar('Enter cloud aliasname');
      return;
    } else {
      this.thirdcloudConnectForm.get('bandwidth').setValue('');
      this.thirdcloudConnectForm.get('aliasName').setValue('');
      let city = this.locationForm.get('city').value;
      this.thirdcloudConnectForm.get('location').setValue(city);

      this.showThirdForm = true;
      this.isaddBtndisable = true;
      this.hideConfigDetails = false;
      console.log('thirdcloudConnectForm ', this.thirdcloudConnectForm.value);
    }
  }

  connectorSave(data) {
    console.log('connForm ', this.connectorForm.value);
    if (this.vnfForm.valid) {
      this.prepareConntrObject();
    }
  }

  VNFSave() {
    console.log('vnfform ', this.vnfForm.value);

    if (this.vnfForm.valid) {
      this.emptyaccordian = false;

      console.log(this.VNFList);
      let compName = 'VNF service';
      let componentMasterId = 1;
      let attributesList = [];

      if (this.vnfForm.valid) {
        if (this.VNFConfig.length == 0)
          this.VNFConfig.push({
            displayname: this.vnfForm.get('routerPreference').value,
            attributes: [
              {
                displayName: 'AS Number',
                selectedValue: '',
                name: 'as_number',
                type: 'input',
                showRequiredError: false,
              },
            ],
          });
        console.log(this.VNFConfig);
        let componentObj = {
          name: compName,
          componentMasterId: componentMasterId,
          attributes: attributesList,
        };
        this.addVNFList.push(componentObj);
        console.log('addVNFList', this.addVNFList);
        console.log('VNFConfig ', this.VNFConfig);
        this.emptyaccordian = false;
        this.prepareDeviceObject();
      }
    }
    // else {
    //   this.openSnackBar("Enter VNF details ");
    // }
  }

  cloudConfigSave() {
    console.log('cloudConfigSave ');
    this.isSubmitDisable = true;
    if (!this.solutionForm.valid) {
      this.openSnackBar('Enter solution and contract term details ');
      console.log('cond 1 ');
      this.isSubmitDisable = false;
      return;
    } else if (!this.locationForm.valid) {
      this.openSnackBar('Enter region and city details ');
      console.log('cond 2 ');
      this.isSubmitDisable = false;
      return;
    } else if (!this.vnfForm.valid) {
      this.openSnackBar('Enter VNF details ');
      console.log('cond 3 ');
      this.isSubmitDisable = false;
      return;
    } else if (!this.connectorForm.valid) {
      this.openSnackBar('Enter connector details ');
      console.log('cond 4 ');
      this.isSubmitDisable = false;
      return;
    } else if (!this.cloudConnectForm.valid) {
      this.openSnackBar('Enter cloud connect details');
      console.log('cond 6 ');
      this.isSubmitDisable = false;
      return;
    } else if (this.showSecondaryForm && !this.secondcloudConnectForm.valid) {
      this.openSnackBar('Enter secondary cloud details or remove it');
      console.log('cond 7 ');
      this.isSubmitDisable = false;
      return;
    } else if (!this.vnfServiceForm.valid) {
      this.openSnackBar('In VNF service details, Enter valid AS number');
      console.log('cond 6 ');
      this.isSubmitDisable = false;
      return;
    } else {
      console.log('else');
      let isValid = this.validateAddiConfig();
      console.log('isValid ', isValid);
      console.log('vnfServiceForm', this.vnfServiceForm.get('asNumber').value);

      if (isValid) {
        // this.hideCloud = true;
        // this.secdhideCloud = true;
        // this.hideConnector = true;
        // this.hideVNF = true;
        // this.showStep1 = true;
        // this.showStep2 = true;
        this.hideConfigDetails = true;
        this.hideSolutionDetails = true;
        //CALLING PREFUNCTIONS
        this.prepareAWSObject();
        this.prepareAZUREObject();
        this.prepareDeviceObject();
        this.generateQuote();
      } else {
        this.openSnackBar('In cloud service details, Provide the valid fields');
        this.isSubmitDisable = false;
      }
    }
  }

  validateAddiConfig() {
    let valid: boolean = false;
    console.log(
      'this.cloudConfiguration.length ',
      this.cloudConfiguration.length
    );
    if (this.cloudConfiguration.length > 0) {
      this.cloudConfiguration.forEach((ele) => {
        ele.attributes.forEach((item) => {
          if (item.selectedValue == '' || null) {
            item.showRequiredError = true;
          } else if (this.hasWhiteSpace(item.selectedValue)) {
            item.showRequiredError = true;
          } else if (
            (item.displayName == 'Neighbour IP' ||
              item.displayName == 'IP address for Interface') &&
            !this.hasIPPattern(item.selectedValue)
          ) {
            item.isNameValidation = true;
          } else if (
            item.displayName == 'Subnet' &&
            !this.hasSubnetPattern(item.selectedValue)
          ) {
            item.isNameValidation = true;
          } else if (
            item.displayName == 'Account ID' &&
            !this.hasAccountIdPattern(item.selectedValue)
          ) {
            item.isNameValidation = true;
          } else if (
            item.displayName == 'VLAN' &&
            !this.hasVlan(item.selectedValue)
          ) {
            item.isNameValidation = true;
          } else if (
            item.displayName == 'AS Number(remote)' &&
            !this.hasASNumberPattern(item.selectedValue)
          ) {
            item.isNameValidation = true;
          } else {
            item.showRequiredError = false;
            item.isNameValidation = false;
          }
        });
      });

      loop1: for (const ele of this.cloudConfiguration) {
        if (
          ele.attributes.some(
            (item) => item.selectedValue == '' || item.selectedValue == null
          )
        ) {
          valid = false;
          break loop1;
        } else if (
          ele.attributes.some((item) => item.showRequiredError == true)
        ) {
          valid = false;
          break loop1;
        } else if (
          ele.attributes.some((item) => item.isNameValidation == true)
        ) {
          valid = false;
          break loop1;
        } else {
          valid = true;
        }
      }
    }

    return valid;
  }

  hasSubnetPattern(s) {
    let pattern = /^(\/\d{1,2})$/;
    return pattern.test(s);
  }

  hasAccountIdPattern(s) {
    let pattern = /^([0-9]{12})$/;
    return pattern.test(s);
  }

  hasASNumberPattern(s) {
    let pattern = /^(0|[1-9][0-9]*)$/;
    // let pattern = /^(0*(?:[1-9][0-9]?|10))$/
    return pattern.test(s);
  }

  hasWhiteSpace(s) {
    return /\s/g.test(s);
  }

  hasVlan(s) {
    let pattern = /^(409[0-6]|(40[0-8]|[1-3]\d\d|[1-9]\d|[1-9])\d|[1-9])$/;
    return pattern.test(s);
  }

  hasIPPattern(s) {
    let pattern =
      /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;
    return pattern.test(s);
  }

  editConnector(data, index) {
    this.hideConnector = false;
  }
  editVNF() {
    this.hideVNF = false;
  }
  editCloud(data, index) {
    this.hideCloud = false;
    this.secdhideCloud = false;
    this.editConfigDetails();
  }

  editStep1(data, index) {
    this.showStep1 = false;
  }

  editStep2(data, index) {
    this.showStep2 = false;
    this.isSubmitDisable = false;
  }

  editConfigDetails() {
    this.hideConfigDetails = false;
    this.isSubmitDisable = false;
    this.isGetQuoteDisable = true;
  }

  editSolutionDetails() {
    this.hideSolutionDetails = false;
    this.isSubmitDisable = false;
    this.isGetQuoteDisable = true;
  }

  onSelectionRegion() {
    this.region = this.locationForm.get('region').value;
    console.log('region ', this.region);
  }

  onSelectionLocation() {
    this.city = this.locationForm.get('city').value;
    console.log('onSelectionLocation', this.city);
    this.siteCode = this.locationObj.find(
      (ele) => ele.city == this.city
    ).siteCode;
    this.siteCountry = this.locationObj.find(
      (ele) => ele.city == this.city
    ).country;
    console.log('siteCode', this.siteCode);
    console.log('siteCountry', this.siteCountry);
  }

  onSolnSumbit() {
    console.log(this.solutionForm.value);
  }

  onLocSumbit() {
    console.log('onLocSumbit', this.locationForm.value);
    let city = this.locationForm.get('city').value;
    console.warn('interLocation => ', city);
    this.cloudConnectForm.get('location').setValue(city);
    this.secondcloudConnectForm.get('location').setValue(city);

    if (city != '') {
      let citydetails = this.locationObj.filter((x) => x.city == city);
      this.latLongValue =
        citydetails[0].latitude + ',' + citydetails[0].longitude;
      console.log('latLongValue', this.latLongValue);
    }
  }

  getConfigPageAttributes() {
    const response = {
      data: {
        iZONECloudComponent: [
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Alias Name',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Bandwidth',
            attributeValue: '50Mbps',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Bandwidth',
            attributeValue: '100Mbps',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Bandwidth',
            attributeValue: '200Mbps',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Bandwidth',
            attributeValue: '300Mbps',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Bandwidth',
            attributeValue: '400Mbps',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Bandwidth',
            attributeValue: '500Mbps',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Bandwidth',
            attributeValue: '1000Mbps',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Bandwidth',
            attributeValue: '2000Mbps',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Bandwidth',
            attributeValue: '5000Mbps',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Bandwidth',
            attributeValue: '10000Mbps',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Provider',
            attributeValue: 'Amazon Web Services',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Provider',
            attributeValue: 'Google Cloud Provider',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Cloud Provider',
            attributeValue: 'Microsoft Azure',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Miami',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Osaka',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Paris',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Tokyo',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Amsterdam',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Ashburn',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Atlanta',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Chicago',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Dallas',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Frankfurt',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Hongkong',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'London',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Los Angeles',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Madrid',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'New York',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'San Jose',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Sao Paulo',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Singapore',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Slough',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Stockholm',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Sydney',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Toronto',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Interconnect Location',
            attributeValue: 'Zurich',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Link Type',
            attributeValue: 'Dual',
          },
          {
            attributeType: 'IZONE Cloud Component',
            attributeName: 'Link Type',
            attributeValue: 'Single',
          },
        ],
        iZONEEdgeConnectivity: [
          {
            attributeType: 'IZONE Edge Connectivity',
            attributeName: 'Edge Connectivity Type',
            attributeValue: 'Internet',
          },
          {
            attributeType: 'IZONE Edge Connectivity',
            attributeName: 'Edge Connectivity Type',
            attributeValue: 'Ethernet',
          },
          {
            attributeType: 'IZONE Edge Connectivity',
            attributeName: 'Edge Connectivity Type',
            attributeValue: 'MPLS',
          },
          {
            attributeType: 'IZONE Edge Connectivity',
            attributeName: 'Link Type',
            attributeValue: 'Dual',
          },
          {
            attributeType: 'IZONE Edge Connectivity',
            attributeName: 'Link Type',
            attributeValue: 'Single',
          },
        ],
        iZONEVNFComponent: [
          {
            attributeType: 'IZONE VNF Component',
            attributeName: 'Alias Name',
          },
          {
            attributeType: 'IZONE VNF Component',
            attributeName: 'Connection Type',
            attributeValue: 'Redundant',
          },
          {
            attributeType: 'IZONE VNF Component',
            attributeName: 'Connection Type',
            attributeValue: 'Single',
          },
        ],
        contract_term: [
          {
            attributeType: 'IZONE Common Component',
            attributeName: 'Contract Term',
            attributeValue: '1 Month',
            displayValue: 'Non-committed term – Daily Rate plan',
          },
          {
            attributeType: 'IZONE Common Component',
            attributeName: 'Contract Term',
            attributeValue: '12 Month',
            displayValue: '12 Month',
          },
          {
            attributeType: 'IZONE Common Component',
            attributeName: 'Contract Term',
            attributeValue: '24 Month',
            displayValue: '24 Month',
          },
          {
            attributeType: 'IZONE Common Component',
            attributeName: 'Contract Term',
            attributeValue: '36 Month',
            displayValue: '36 Month',
          },
          {
            attributeType: 'IZONE Common Component',
            attributeName: 'Contract Term',
            attributeValue: '48 Month',
            displayValue: '48 Month',
          },
          {
            attributeType: 'IZONE Common Component',
            attributeName: 'Contract Term',
            attributeValue: '60 Month',
            displayValue: '60 Month',
          },
        ],
        deviceAttr: [
          {
            size: 'Large (6core)',
            memory: '16GB',
            oem: 'Cisco',
            deviceName: 'Cisco Catalyst 8000v Router',
          },
          {
            size: 'Extra Large (8core)',
            memory: '16GB',
            oem: 'Cisco',
            deviceName: 'Cisco Catalyst 8000v Router',
          },
          {
            size: 'Small (2core)',
            memory: '8GB',
            oem: 'Cisco',
            deviceName: 'Cisco Catalyst 8000v Router',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Cisco',
            deviceName: 'Cisco Catalyst 8000v Router',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Aruba',
            deviceName: 'Aruba EdgeConnect SD-WAN',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Check Point',
            deviceName: 'Check Point CloudGuard Network Security',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Cisco',
            deviceName: 'Cisco Adaptive Security Appliance',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Cisco',
            deviceName: 'Cisco Firepower Threat Defense (FTD) Firewall',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Cisco',
            deviceName: 'Cisco 8000V Controller Mode SD-WAN',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Cisco',
            deviceName: 'Cisco CSR SD-WAN',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'F5 Networks',
            deviceName: 'NGINX Plus Load Balancer',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Fortinet',
            deviceName: 'FortiGate Secure SD-WAN',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Fortinet',
            deviceName: 'FortiGate VM Series Firewall',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Juniper',
            deviceName: 'Juniper vSRX SD-WAN',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Juniper',
            deviceName: 'Juniper vSRX Virtual Firewall',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Palo Alto',
            deviceName: 'Prisma Virtual ION SD-WAN',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Palo Alto',
            deviceName: 'Palo Alto Networks VM Series Firewall',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Versa',
            deviceName: 'FlexVNF SDWAN',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Vmware',
            deviceName: 'VMware SD-WAN',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Zscaler',
            deviceName: 'Zscaler App Connector',
          },
          {
            size: 'Medium (4core)',
            memory: '8GB',
            oem: 'Zscaler',
            deviceName: 'Zscaler Private Service Edge',
          },
        ],
      },
    };

    this.cloudAttr = response.data.iZONECloudComponent;
    console.log('cloudAttr ', this.cloudAttr);
    this.connectorAttr = response.data.iZONEEdgeConnectivity;
    console.log('connectorAttr ', this.connectorAttr);
    this.contractTermList = response.data.contract_term;
    console.log('contractTermList ', this.contractTermList);

    //device attributes like core preference, memory, etc
    this.deviceAttr = response.data.deviceAttr;
    let devattr = this.deviceAttr;
    // sort the core in descending order
    this.deviceAttr = devattr.sort((a, b) => (a.size > b.size ? -1 : 1));
    console.log('deviceAttr ', this.deviceAttr);
    this.vnfAttr = response.data.iZONEVNFComponent;
    console.log('vnfAttr ', this.vnfAttr);

    this.cloudAttr.forEach((el) => {
      if (el.attributeName == 'Cloud Bandwidth') {
        this.bandwidthList.push(el.attributeValue);
      }
    });

    this.conntrLinkTypes = this.connectorAttr.filter(
      (x) => x.attributeName == 'Link Type'
    );
    this.conntrConnTypes = this.connectorAttr.filter(
      (x) => x.attributeName == 'Edge Connectivity Type'
    );
    this.intrConntLocation = this.cloudAttr.filter(
      (x) => x.attributeName == 'Interconnect Location'
    );
    this.cloudLinkTypes = this.cloudAttr.filter(
      (x) => x.attributeName == 'Link Type'
    );
    this.cloudProviders = this.cloudAttr.filter(
      (x) => x.attributeName == 'Cloud Provider'
    );

    console.log('bandwidthList ', this.bandwidthList);
    console.log('conntrLinkTypes ', this.conntrLinkTypes);
    console.log('cloudLinkTypes ', this.cloudLinkTypes);
    console.log('cloudProviders ', this.cloudProviders);
  }

  prepareDeviceObject() {
    this.vnfASnumber = this.vnfServiceForm.get('asNumber').value;
    console.log('vnfASnumber', this.vnfASnumber);
    let devicedata = this.vnfForm.value;

    let coresize = '';
    if (devicedata.corePreference == 'Large (6core)') {
      coresize = '6';
    } else if (devicedata.corePreference == 'Medium (4core)') {
      coresize = '4';
    } else if (devicedata.corePreference == 'Small (2core)') {
      coresize = '2';
    } else if (devicedata.corePreference == 'Extra Large (8core)') {
      coresize = '8';
    }

    let data = {
      componentMasterId: 1,
      name: 'Edge Device',
      attributes: [
        {
          name: 'Device OEM',
          value: 'Cisco',
        },
        {
          name: 'Device Model',
          value: devicedata.routerPreference,
        },
        {
          name: 'Vnf Core Size',
          value: devicedata.corePreference,
        },
        {
          name: 'Memory',
          value: devicedata.memory,
        },
        {
          name: 'Device Alias Name',
          value: devicedata.aliasName,
        },
        {
          name: 'Local As Number',
          value: this.vnfASnumber, //"234:4556:98678"
        },
        {
          name: 'Link Type',
          value: 'Single',
        },
        {
          name: 'Component Order Type',
          value: 'New',
        },
        {
          name: 'Device Type',
          value: '8000V',
        },
        {
          name: 'Device Category',
          value: 'ROUTER',
        },
        {
          name: 'size',
          value: coresize,
        },
        {
          name: 'latLong',
          value: this.latLongValue,
          // "value": "52.3031732618186,4.93831828657571"
        },
      ],
    };

    console.log('json ', data);
    this.vnfaddidata = data;
  }

  prepareConntrObject() {
    console.log('prepareConntrObject');
    let connectordata = this.connectorForm.value;

    let data = {
      componentMasterId: 3,
      name: 'Edge Connectivity',
      attributes: [
        {
          attributeMasterId: 35,
          name: 'Edge Alias Name',
          value: connectordata.aliasName,
        },
        {
          attributeMasterId: 36,
          name: 'Edge Connectivity Type',
          value: connectordata.connType,
        },
        {
          attributeMasterId: 37,
          name: 'Edge Connectivity Bandwidth',
          value: connectordata.bandwidth,
        },
        {
          attributeMasterId: 38,
          name: 'Link Type',
          value: connectordata.linkType,
        },
        {
          attributeMasterId: 5,
          name: 'Component Order Type',
          value: 'New',
        },
      ],
    };

    console.log('json ', data);
    return data;
  }

  prepareAWSObject() {
    console.log('prepareAWSObject');
    let primClouddata = this.cloudConnectForm.value;
    let secdClouddata = this.secondcloudConnectForm.value;
    let cloudAddiData = this.AWSPrimaryConfg.attributes;
    let cloudData = null;
    if (primClouddata.cloudProvider == 'Amazon Web Services') {
      cloudData = primClouddata;
    } else {
      cloudData = secdClouddata;
    }

    console.warn('cloudData => ', cloudData);

    let ipaddress,
      primasnumber,
      neighbourip,
      accountId,
      bgpkey,
      subnet = '';

    this.AWSPrimaryConfg.attributes.forEach((e) => {
      if (e.name == 'Primary Ip Address') {
        ipaddress = e.selectedValue;
      } else if (e.name == 'Primary Remote As Number') {
        primasnumber = e.selectedValue;
      } else if (e.name == 'Primary Neighbour Ip') {
        neighbourip = e.selectedValue;
      } else if (e.name == 'Primary Account Id') {
        accountId = e.selectedValue;
      } else if (e.name == 'Primary Bgp Key') {
        bgpkey = e.selectedValue;
      } else if (e.name == 'Primary Subnet') {
        subnet = e.selectedValue;
      }
    });

    let data = {
      componentMasterId: 3,
      name: 'Cloud',
      attributes: [
        {
          attributeMasterId: 1,
          name: 'Cloud Provider',
          value: 'Amazon Web Services',
        },
        {
          attributeMasterId: 2,
          name: 'Interconnect Location',
          value: cloudData.location,
        },
        {
          attributeMasterId: 3,
          name: 'Cloud Bandwidth',
          value: cloudData.bandwidth,
        },
        {
          attributeMasterId: 4,
          name: 'Link Type',
          value: cloudData.linkType,
        },
        {
          attributeMasterId: 8,
          name: 'Cloud Alias Name',
          value: cloudData.aliasName,
        },
        {
          attributeMasterId: 27,
          name: 'Primary Service Key',
          value: 'NA',
        },
        {
          attributeMasterId: 21,
          name: 'Primary Account Id',
          value: accountId,
        },
        {
          attributeMasterId: 28,
          name: 'Primary Peer Type',
          value: 'NA',
        },
        {
          attributeMasterId: 14,
          name: 'Primary Ip Address',
          value: ipaddress,
        },
        {
          attributeMasterId: 22,
          name: 'Primary Subnet',
          value: subnet,
        },
        {
          attributeMasterId: 17,
          name: 'Primary Bgp Key',
          value: bgpkey,
        },
        {
          attributeMasterId: 19,
          name: 'Primary Neighbour Ip',
          value: neighbourip,
        },
        {
          attributeMasterId: 26,
          name: 'Primary vlan',
          value: 'NA',
        },
        {
          attributeMasterId: 18,
          name: 'Primary Remote As Number',
          value: primasnumber,
        },
        {
          attributeMasterId: 5,
          name: 'Component Order Type',
          value: 'New',
        },
        {
          attributeMasterId: 5,
          name: 'Cloud Site Code',
          value: this.siteCode,
        },
        {
          attributeMasterId: 5,
          name: 'Cloud Site Country',
          value: this.siteCountry,
        },
      ],
    };

    console.log('json ', data);
    return data;
  }

  prepareAZUREObject() {
    console.log('prepareAZUREObject');
    let primClouddata = this.cloudConnectForm.value;
    let secdClouddata = this.secondcloudConnectForm.value;
    let primCloudAddiData = this.AzurepriConfg.attributes;
    let secdCloudAddiData = this.AzureSecConfg.attributes;
    console.log('primCloudAddiData ', primCloudAddiData);
    console.log('secdCloudAddiData ', secdCloudAddiData);

    let cloudData = null;
    if (primClouddata.cloudProvider == 'Microsoft Azure') {
      cloudData = primClouddata;
    } else {
      cloudData = secdClouddata;
    }

    let primipaddress,
      primasnumber,
      primneighbourip,
      primbgpkey,
      primvlan,
      primSubnet,
      primpeertype,
      primservicekey = '';

    this.AzurepriConfg.attributes.forEach((e) => {
      if (e.name == 'Primary Ip Address') {
        primipaddress = e.selectedValue;
      } else if (e.name == 'Primary Remote As Number') {
        primasnumber = e.selectedValue;
      } else if (e.name == 'Primary Neighbour Ip') {
        primneighbourip = e.selectedValue;
      } else if (e.name == 'Primary Bgp Key') {
        primbgpkey = e.selectedValue;
      } else if (e.name == 'Primary Subnet') {
        primSubnet = e.selectedValue;
      } else if (e.name == 'Primary vlan') {
        primvlan = e.selectedValue;
      } else if (e.name == 'Primary Peer Type') {
        primpeertype = e.selectedValue;
      } else if (e.name == 'Primary Service Key') {
        primservicekey = e.selectedValue;
      }
    });

    let secdipaddress,
      secdasnumber,
      secdasLocalnumber,
      secdneighbourip,
      secdaccountId,
      secdbgpkey,
      secdSubnet,
      secdVlan,
      secdPeertype,
      secdServicekey = '';

    this.AzureSecConfg.attributes.forEach((e) => {
      if (e.name == 'Secondary Ip Address') {
        secdipaddress = e.selectedValue;
      } else if (e.name == 'Secondary Remote As Number') {
        secdasnumber = e.selectedValue;
      } else if (e.name == 'Secondary Neighbour Ip') {
        secdneighbourip = e.selectedValue;
      } else if (e.name == 'Secondary Account Id') {
        secdaccountId = e.selectedValue;
      } else if (e.name == 'Secondary Bgp Key') {
        secdbgpkey = e.selectedValue;
      } else if (e.name == 'Secondary Subnet') {
        secdSubnet = e.selectedValue;
      } else if (e.name == 'Secondary vlan') {
        secdVlan = e.selectedValue;
      } else if (e.name == 'Secondary Peer Type') {
        secdPeertype = e.selectedValue;
      } else if (e.name == 'Secondary Service Key') {
        secdServicekey = e.selectedValue;
      }

      // else if (e.name == "Secondary Local As Number") {
      //   secdasLocalnumber = e.selectedValue;
      // }
    });

    let data = {
      componentMasterId: 2,
      name: 'Cloud',
      attributes: [
        {
          attributeMasterId: 1,
          name: 'Cloud Provider',
          value: 'Microsoft Azure',
        },
        {
          attributeMasterId: 2,
          name: 'Interconnect Location',
          value: cloudData.location,
        },
        {
          attributeMasterId: 3,
          name: 'Cloud Bandwidth',
          value: cloudData.bandwidth,
        },
        {
          attributeMasterId: 4,
          name: 'Link Type',
          value: cloudData.linkType,
        },
        {
          attributeMasterId: 8,
          name: 'Cloud Alias Name',
          value: cloudData.aliasName,
        },
        {
          attributeMasterId: 27,
          name: 'Primary Service Key',
          value: primservicekey,
          //"de1c3c53-56c8-40b2-aaew-a029f1d975g6"
        },
        {
          attributeMasterId: 21,
          name: 'Primary Account Id',
          value: 'NA',
        },
        {
          attributeMasterId: 28,
          name: 'Primary Peer Type',
          value: primpeertype,
          //"Private"
        },
        {
          attributeMasterId: 14,
          name: 'Primary Ip Address',
          value: primipaddress,
        },
        {
          attributeMasterId: 22,
          name: 'Primary Subnet',
          value: primSubnet,
          //"/30"
        },
        {
          attributeMasterId: 17,
          name: 'Primary Bgp Key',
          value: primbgpkey,
        },
        {
          attributeMasterId: 19,
          name: 'Primary Neighbour Ip',
          value: primneighbourip,
        },
        {
          attributeMasterId: 26,
          name: 'Primary vlan',
          value: primvlan,
          //300
        },
        {
          attributeMasterId: 18,
          name: 'Primary Remote As Number',
          value: primasnumber,
        },
        {
          attributeMasterId: 27,
          name: 'Secondary Service Key',
          value: secdServicekey,
          //"de1c3c53-56c8-40b2-aaew-a029f1d975g6"
        },
        {
          attributeMasterId: 28,
          name: 'Secondary Peer Type',
          value: secdPeertype,
          //"Private"
        },
        {
          attributeMasterId: 14,
          name: 'Secondary Ip Address',
          value: secdipaddress,
        },
        {
          attributeMasterId: 22,
          name: 'Secondary Subnet',
          value: secdSubnet,
          //"/30"
        },
        {
          attributeMasterId: 17,
          name: 'Secondary Bgp Key',
          value: secdbgpkey,
        },
        {
          attributeMasterId: 19,
          name: 'Secondary Neighbour Ip',
          value: secdneighbourip,
        },
        {
          attributeMasterId: 26,
          name: 'Secondary vlan',
          value: secdVlan,
          //300
        },
        {
          attributeMasterId: 18,
          name: 'Secondary Remote As Number',
          value: secdasnumber,
        },
        {
          attributeMasterId: 21,
          name: 'Secondary Account Id',
          value: 'NA',
        },
        // {
        //   "name": "Secondary Local As Number",
        //   "value": secdasLocalnumber
        // },
        {
          attributeMasterId: 5,
          name: 'Component Order Type',
          value: 'New',
        },
        {
          attributeMasterId: 5,
          name: 'Cloud Site Code',
          value: this.siteCode,
        },
        {
          attributeMasterId: 5,
          name: 'Cloud Site Country',
          value: this.siteCountry,
        },
      ],
    };
    console.log('json ', data);
    return data;
  }

  openSnackBar(msg) {
    this._snackBar.open(msg, 'OK', {
      panelClass: ['warning-snackbar'],
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
    });
  }

  openSuccessSnackBar(msg) {
    this._snackBar.open(msg, '', {
      panelClass: ['success-snackbar'],
      duration: 6000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
    });
  }

  generateQuote() {
    console.log('generateQuote ', this.solnId != '' && this.configId != '');
    if (this.solnId != '' && this.configId != '') {
      this.updateQuote4Config();
    } else {
      this.createQuote4Config();
    }
  }

  savedQuoteDetails() {
    console.log('savedQuoteDetails');
    // console.log("savedQuoteDetails -> ", (this.solnId != "" && this.configId != ""));
    // this.openLoaderModal({ message: "Please wait " });
    // @ts-ignore
    if (
      this.quoteObj != null &&
      this.quoteObj != undefined &&
      //@ts-ignore
      this.quoteObj != {} &&
      this.quoteObj != ''
    ) {
      this.service.getQuoteRequest(
        this.quoteObj.quoteId,
        (res) => {
          this.resQuoteObj = res;
          console.log('savedQuoteDetails res ', res);
          console.log('resQuoteObj ', this.resQuoteObj);
          let legalEntities = this.resQuoteObj.data.legalEntities[0];
          console.log('legalEntities ', legalEntities);
          let solutionObj =
            this.resQuoteObj.data.legalEntities[0].productFamilies[0]
              .solutions[0];
          console.log('solutionObj ', solutionObj);
          let solncomponent =
            this.resQuoteObj.data.legalEntities[0].productFamilies[0]
              .solutions[0].configurations[0].components;
          console.log('solncomponent ', solncomponent);

          this.customerid = this.resQuoteObj.data.customerId;
          this.customerLeid = legalEntities.customerLegalEntityId;
          let solnAliasName = legalEntities.legalAttributes.find(
            (ele) => ele.displayValue == 'SOLUTION ALIAS NAME'
          ).attributeValue;
          this.siteCode = legalEntities.legalAttributes.find(
            (ele) => ele.displayValue == 'SITE CODE'
          ).attributeValue;
          this.siteCountry = legalEntities.legalAttributes.find(
            (ele) => ele.displayValue == 'SITE COUNTRY'
          ).attributeValue;
          this.solutionForm.get('solution_alias_name').setValue(solnAliasName);
          this.solutionForm
            .get('term_in_months')
            .setValue(legalEntities.termInMonths);
          console.log('legalAttributes ', legalEntities.legalAttributes);

          //Populate the respective region and location
          this.region = legalEntities.legalAttributes.find(
            (ele) => ele.displayValue == 'REGION'
          ).attributeValue;
          this.regionList.forEach((re) => {
            if (re.region == this.region) {
              if (re.country == this.country) {
                this.locationObj.push(re);
              }
            }
          });
          this.locationObj = [...new Set(this.locationObj)];
          this.locationForm.get('region').setValue(this.region);
          this.city = solutionObj.configurations[0].locationName;
          this.locationForm.get('city').setValue(this.city);
          this.onSelectionRegion();
          this.onLocSumbit();
          this.diableRegion = true;

          // solutionid and configid
          this.solnId = solutionObj.productSolutionId;
          this.configId = solutionObj.configurations[0].cloudConfigurationId;

          let edgeconnector, edgevnf, edgecloud;
          let count: number = 0;
          solncomponent.forEach((e) => {
            if (e.name == 'Edge Connectivity') {
              edgeconnector = e.attributes;
              edgeconnector.forEach((e) => {
                if (e.name == 'Edge Alias Name') {
                  this.connectorForm.get('aliasName').setValue(e.value);
                } else if (e.name == 'Edge Connectivity Type') {
                  this.connectorForm.get('connType').setValue(e.value);
                } else if (e.name == 'Edge Connectivity Bandwidth') {
                  this.connectorForm.get('bandwidth').setValue(e.value);
                } else if (e.name == 'Link Type') {
                  this.connectorForm.get('linkType').setValue(e.value);
                }
              });
            } else if (e.name == 'Edge Device') {
              edgevnf = e.attributes;
              edgevnf.forEach((e) => {
                if (e.name == 'Vnf Core Size') {
                  this.vnfForm.get('corePreference').setValue(e.value);
                } else if (e.name == 'Memory') {
                  this.vnfForm.get('memory').setValue(e.value);
                } else if (e.name == 'Device Alias Name') {
                  this.vnfForm.get('aliasName').setValue(e.value);
                } else if (e.name == 'Device Model') {
                  this.vnfForm.get('routerPreference').setValue(e.value);
                } else if (e.name == 'Local As Number') {
                  this.vnfServiceForm.get('asNumber').setValue(e.value);
                }
              });
              this.VNFSave();
            } else if (e.name == 'Cloud') {
              edgecloud = e.attributes;
              count++;
              console.log('count ', count);
              if (edgecloud && count == 1) {
                let setPrimCloudServices,
                  setSecdCloudServices = null;
                edgecloud.forEach((e) => {
                  if (e.name == 'Cloud Provider') {
                    this.cloudConnectForm
                      .get('cloudProvider')
                      .setValue(e.value);
                    this.selectCloud(e.value);
                  } else if (e.name == 'Interconnect Location') {
                    this.cloudConnectForm.get('location').setValue(e.value);
                  } else if (e.name == 'Cloud Bandwidth') {
                    this.cloudConnectForm.get('bandwidth').setValue(e.value);
                  } else if (e.name == 'Link Type') {
                    this.cloudConnectForm.get('linkType').setValue(e.value);
                  } else if (e.name == 'Cloud Alias Name') {
                    this.cloudConnectForm.get('aliasName').setValue(e.value);
                    this.cloudSave('', '');

                    this.cloudConfiguration.forEach((c) => {
                      if (c.cloudConfgName == 'Amazon Web Services') {
                        setPrimCloudServices = c.attributes;
                        console.log(
                          'setPrimCloudServices ',
                          setPrimCloudServices
                        );
                      } else if (
                        c.cloudConfgName == 'Microsoft Azure Primary'
                      ) {
                        setPrimCloudServices = c.attributes;
                        console.log(
                          'setPrimCloudServices ',
                          setPrimCloudServices
                        );
                      } else if (
                        c.cloudConfgName == 'Microsoft Azure Secondary'
                      ) {
                        setSecdCloudServices = c.attributes;
                        console.log(
                          'setSecdCloudServices ',
                          setSecdCloudServices
                        );
                      }
                    });
                  }

                  //additional service details
                  if (e.name == 'Primary Account Id') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Account Id') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Ip Address') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Ip Address') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Bgp Key') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Bgp Key') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Neighbour Ip') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Neighbour Ip') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Remote As Number') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Remote As Number') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Subnet') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Subnet') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary vlan') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary vlan') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Service Key') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Service Key') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Peer Type') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Peer Type') {
                        set.selectedValue = e.value;
                      }
                    });
                  }

                  //secondary service details
                  else if (e.name == 'Secondary Ip Address') {
                    setSecdCloudServices.forEach((set) => {
                      if (set.name == 'Secondary Ip Address') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Secondary Neighbour Ip') {
                    setSecdCloudServices.forEach((set) => {
                      if (set.name == 'Secondary Neighbour Ip') {
                        set.selectedValue = e.value;
                      }
                    });
                  }
                  this.populateAzureSecondaryconfig();

                  // else if (e.name == "Secondary Account Id") {
                  //   setSecdCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Account Id") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }

                  // else if (e.name == "Secondary Bgp Key") {
                  //   setSecdCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Bgp Key") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }

                  // else if (e.name == "Secondary Remote As Number") {
                  //   setSecdCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Remote As Number") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                  // else if (e.name == "Secondary Local As Number") {
                  //   setSecdCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Local As Number") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                  // else if (e.name == "Secondary Subnet") {
                  //   setPrimCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Subnet") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                  // else if (e.name == "Secondary vlan") {
                  //   setPrimCloudServices.forEach(set => {
                  //     if (set.name == "Secondary vlan") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                  // else if (e.name == "Secondary Service Key") {
                  //   setPrimCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Service Key") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                  // else if (e.name == "Secondary Peer Type") {
                  //   setPrimCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Peer Type") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                });
              } else if (edgecloud && count == 2) {
                let setPrimCloudServices,
                  setSecdCloudServices = null;
                this.showSecondaryForm = true;
                edgecloud.forEach((e) => {
                  if (e.name == 'Cloud Provider') {
                    this.secondcloudConnectForm
                      .get('cloudProvider')
                      .setValue(e.value);
                  } else if (e.name == 'Interconnect Location') {
                    this.secondcloudConnectForm
                      .get('location')
                      .setValue(e.value);
                  } else if (e.name == 'Cloud Bandwidth') {
                    this.secondcloudConnectForm
                      .get('bandwidth')
                      .setValue(e.value);
                  } else if (e.name == 'Link Type') {
                    this.secondcloudConnectForm
                      .get('linkType')
                      .setValue(e.value);
                  } else if (e.name == 'Cloud Alias Name') {
                    this.secondcloudConnectForm
                      .get('aliasName')
                      .setValue(e.value);
                    this.secdcloudSave('', '');
                    this.cloudConfiguration.forEach((c) => {
                      if (c.cloudConfgName == 'Amazon Web Services') {
                        setPrimCloudServices = c.attributes;
                        console.log(
                          'setPrimCloudServices ',
                          setPrimCloudServices
                        );
                      } else if (
                        c.cloudConfgName == 'Microsoft Azure Primary'
                      ) {
                        setPrimCloudServices = c.attributes;
                        console.log(
                          'setPrimCloudServices ',
                          setPrimCloudServices
                        );
                      } else if (
                        c.cloudConfgName == 'Microsoft Azure Secondary'
                      ) {
                        setSecdCloudServices = c.attributes;
                        console.log(
                          'setSecdCloudServices ',
                          setSecdCloudServices
                        );
                      }
                    });
                  }

                  //additional service details
                  if (e.name == 'Primary Account Id') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Account Id') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Ip Address') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Ip Address') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Bgp Key') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Bgp Key') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Neighbour Ip') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Neighbour Ip') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Remote As Number') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Remote As Number') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Subnet') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Subnet') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary vlan') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary vlan') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Service Key') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Service Key') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Primary Peer Type') {
                    setPrimCloudServices.forEach((set) => {
                      if (set.name == 'Primary Peer Type') {
                        set.selectedValue = e.value;
                      }
                    });
                  }

                  //secondary service details
                  else if (e.name == 'Secondary Ip Address') {
                    setSecdCloudServices.forEach((set) => {
                      if (set.name == 'Secondary Ip Address') {
                        set.selectedValue = e.value;
                      }
                    });
                  } else if (e.name == 'Secondary Neighbour Ip') {
                    setSecdCloudServices.forEach((set) => {
                      if (set.name == 'Secondary Neighbour Ip') {
                        set.selectedValue = e.value;
                      }
                    });
                  }
                  this.populateAzureSecondaryconfig();
                  if (this.city != '') {
                    let citydetails = this.locationObj.filter(
                      (x) => x.city == this.city
                    );
                    this.latLongValue =
                      citydetails[0].latitude + ',' + citydetails[0].longitude;
                    console.log('latLongValue', this.latLongValue);
                  }

                  // else if (e.name == "Secondary Account Id") {
                  //   setSecdCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Account Id") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }

                  // else if (e.name == "Secondary Bgp Key") {
                  //   setSecdCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Bgp Key") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }

                  // else if (e.name == "Secondary Remote As Number") {
                  //   setSecdCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Remote As Number") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                  // else if (e.name == "Secondary Local As Number") {
                  //   setSecdCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Local As Number") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                  // else if (e.name == "Secondary Subnet") {
                  //   setPrimCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Subnet") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                  // else if (e.name == "Secondary vlan") {
                  //   setPrimCloudServices.forEach(set => {
                  //     if (set.name == "Secondary vlan") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                  // else if (e.name == "Secondary Service Key") {
                  //   setPrimCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Service Key") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                  // else if (e.name == "Secondary Peer Type") {
                  //   setPrimCloudServices.forEach(set => {
                  //     if (set.name == "Secondary Peer Type") {
                  //       set.selectedValue = e.value;
                  //     }
                  //   });
                  // }
                });
              }
            }
          });

          this.emptyaccordian = false;
          this.hideSolutionDetails = true;
          this.hideConfigDetails = true;
          this.isSubmitDisable = true;
          this.isGetQuoteDisable = false;
        },
        (error) => {
          this.openSnackBar(error.error.message);
        }
      );
    }
    // this.datasharedSvc.close();
  }

  createQuote4Config() {
    console.log('createdQuote4Config');
    this.openLoaderModal({ message: 'Please wait ' });
    let locationdata = this.locationForm.value;
    let devicedata = this.vnfForm.value;
    let connectordata = this.connectorForm.value;
    let primClouddata = this.cloudConnectForm.value;
    let secdClouddata = this.secondcloudConnectForm.value;

    let solnsecdata = this.solutionForm.value;
    let awsdata = this.prepareAWSObject();
    let vnfdata = this.vnfaddidata;
    let conntrdata = this.prepareConntrObject();
    let azuredata = this.prepareAZUREObject();

    let contractTermType = this.contractTermList.find(
      (x) => x.attributeValue == solnsecdata.term_in_months
    ).displayValue;
    console.log('contractTermType ', contractTermType);

    //composing request payload for create quote
    let payload = {
      customerLeId: this.customerLeid,
      productName: 'IZOMCC',
      orderType: 'NEW',
      quoteId: null,
      quoteleId: null,
      solutions: [
        {
          productName: 'IZOMCC',
          offeringName: 'IZOMCC',
          solution_alias_name: solnsecdata.solution_alias_name,
          configurations: [
            {
              siteCountry: this.siteCountry,
              locationName: this.city,
              siteCode: this.siteCode,
              region: this.region,
              term_in_months: solnsecdata.term_in_months,
              components: [vnfdata, conntrdata],
              isActionTaken: false,
              serviceUptime: 'Routine-99.5%',
              provisioningThrough: 'Middleware',
              contract_type: contractTermType,
            },
          ],
        },
      ],
    };

    awsdata.attributes.forEach((el) => {
      if (el.name == 'Cloud Alias Name' && el.value != '') {
        payload.solutions[0].configurations[0].components.push(awsdata);
      }
    });

    azuredata.attributes.forEach((el) => {
      if (el.name == 'Cloud Alias Name' && el.value != '') {
        payload.solutions[0].configurations[0].components.push(azuredata);
      }
    });

    console.log('CreateQuote ', payload);
    this.service.createQuoteRequest(
      this.customerid,
      payload,
      (res) => {
        console.log('createQuoteRequest ', res);
        if (res.responseCode == 200 && res.status == 'SUCCESS') {
          this.quoteObj = res.data;
          this.datasharedSvc.close();
          console.log('quoteObj ', this.quoteObj);
          this.service.setQuoteObj(this.quoteObj);
          this.datasharedSvc.setQuoteObjInSession(
            JSON.stringify(this.quoteObj)
          );
          this.openSuccessSnackBar(
            'Configuration details saved successfully !'
          );
          this.getQuoteDetails();
          this.isSubmitDisable = true;
          this.isGetQuoteDisable = false;
        } else {
          this.openSnackBar('Internal server error, Try again later');
          this.isSubmitDisable = false;
          this.isGetQuoteDisable = true;
        }
      },
      (error) => {
        console.log('error ', error);
        this.datasharedSvc.close();
        if (error.error.responseCode == 500) {
          this.openSnackBar('Internal server error, Try again later');
          this.isSubmitDisable = false;
          this.isGetQuoteDisable = true;
        } else {
          this.openSnackBar(error.error.message);
        }
      }
    );
  }

  getQuoteDetails() {
    this.service.getQuoteRequest(
      this.quoteObj.quoteId,
      (res) => {
        console.log('getQuoteRequest ', res);
        this.resQuoteObj = res;
        console.log('resQuoteObj ', this.resQuoteObj);
        let solutions =
          res.data.legalEntities[0].productFamilies[0].solutions[0];
        console.log('solutions ', solutions);
        this.solnId = solutions.productSolutionId;
        console.log('solnId ', this.solnId);
        this.configId = solutions.configurations[0].cloudConfigurationId;
        console.log('configId ', this.configId);
      },
      (error) => {
        this.openSnackBar(error.error.message);
      }
    );
  }

  updateQuote4Config() {
    console.log('updateQuote4Config');
    this.openLoaderModal({ message: 'Please wait ' });
    let solnsecdata = this.solutionForm.value;
    let awsdata = this.prepareAWSObject();
    let vnfdata = this.vnfaddidata;
    let conntrdata = this.prepareConntrObject();
    let azuredata = this.prepareAZUREObject();

    let contractTermType = this.contractTermList.find(
      (x) => x.attributeValue == solnsecdata.term_in_months
    ).displayValue;
    console.log('contractTermType ', contractTermType);

    //composing request payload for update quote
    let payload = {
      productName: 'IZOMCC',
      offeringName: 'IZOMCC',
      solution_alias_name: solnsecdata.solution_alias_name,
      configurations: [
        {
          siteCountry: this.siteCountry,
          locationName: this.city,
          siteCode: this.siteCode,
          region: this.region,
          term_in_months: solnsecdata.term_in_months,
          components: [vnfdata, conntrdata],
          isActionTaken: false,
          serviceUptime: 'Routine-99.5%',
          provisioningThrough: 'Middleware',
          contract_type: contractTermType,
        },
      ],
    };
    awsdata.attributes.forEach((el) => {
      if (el.name == 'Cloud Alias Name' && el.value != '') {
        payload.configurations[0].components.push(awsdata);
      }
    });

    azuredata.attributes.forEach((el) => {
      if (el.name == 'Cloud Alias Name' && el.value != '') {
        payload.configurations[0].components.push(azuredata);
      }
    });

    console.log('updateQuote ', payload);
    this.service.updateQuoteRequest(
      this.quoteObj,
      this.solnId,
      this.configId,
      payload,
      (res) => {
        console.log('updateQuote ', res);
        this.datasharedSvc.close();
        if (res.responseCode == 200 && res.status == 'SUCCESS') {
          this.getQuoteDetails();
          this.isSubmitDisable = true;
          this.isGetQuoteDisable = false;
          this.openSuccessSnackBar(
            'Configuration details updated successfully !'
          );
        } else {
          this.openSnackBar('Internal server error, Try again later');
          this.isSubmitDisable = false;
          this.isGetQuoteDisable = true;
        }
      },
      (error) => {
        this.datasharedSvc.close();
        console.log('error ', error);
        this.openSnackBar('Internal server error, Try again later');
        this.isSubmitDisable = false;
        this.isGetQuoteDisable = true;
      }
    );
  }

  openLoaderModal(data) {
    // this.datasharedSvc.open(data);
  }

  validationCall() {
    console.log('validationCall');
    this.openLoaderModal({ message: 'Please wait ' });
    let solnsecdata = this.solutionForm.value;
    let awsdata = this.prepareAWSObject();
    let vnfdata = this.vnfaddidata;
    let conntrdata = this.prepareConntrObject();
    let azuredata = this.prepareAZUREObject();
    console.log('quoteObj ', this.quoteObj);

    //composing request payload for create quote
    let payload = {
      customerId: this.customerid,
      customerLeId: this.customerLeid,
      productName: 'IZOMCC',
      orderType: 'NEW',
      quoteId: this.quoteObj.quoteId,
      quoteleId: this.quoteObj.quoteleId,
      solutions: [
        {
          productName: 'IZOMCC',
          offeringName: 'IZOMCC',
          solution_alias_name: solnsecdata.solution_alias_name,
          configurations: [
            {
              siteCountry: this.siteCountry,
              locationName: this.city,
              siteCode: this.siteCode,
              region: this.region,
              term_in_months: solnsecdata.term_in_months,
              components: [vnfdata, conntrdata],
              isActionTaken: false,
            },
          ],
        },
      ],
    };
    awsdata.attributes.forEach((el) => {
      if (el.name == 'Cloud Alias Name' && el.value != '') {
        payload.solutions[0].configurations[0].components.push(awsdata);
      }
    });

    azuredata.attributes.forEach((el) => {
      if (el.name == 'Cloud Alias Name' && el.value != '') {
        payload.solutions[0].configurations[0].components.push(azuredata);
      }
    });
    this.router.navigate(['/getquote']);
    console.log('checkout validation ', payload);
    this.service.checkoutValidation(
      payload,
      (res) => {
        console.log('checkout validation ', res);
        let status = res.data.status;
        let validatemsg = res.data.message;
        this.datasharedSvc.close();
        if (
          status == 'Pass' &&
          validatemsg == 'No error found in service engine'
        ) {
          this.openSuccessSnackBar('Quote created successfully !');
          return this.router.navigate(['/getquote']);
        } else {
          this.isSubmitDisable = false;
          this.isGetQuoteDisable = true;
          this.openSnackBar(validatemsg);
          return this.router.navigate(['/getquote']);
        }
      },
      (error) => {
        console.log('error ', error);
        this.datasharedSvc.close();
        this.router.navigate(['/getquote']);
        // this.openSnackBar("Internal server error, Try again later");
      }
    );
  }

  removeCloud() {
    this.showSecondaryForm = false;
    this.secdhideCloud = false;
    this.isaddBtndisable = false;
    this.secondcloudConnectForm.get('location').setValue('');
    this.secondcloudConnectForm.get('bandwidth').setValue('');
    this.secondcloudConnectForm.get('aliasName').setValue('');
    console.log('reset 2nd cloud ', this.secondcloudConnectForm.value);
    console.log('cloudConfiguration ', this.cloudConfiguration);
    let cloud_provider = this.secondcloudConnectForm.get('cloudProvider').value;
    let cloudConfigs: any[] = this.cloudConfiguration;
    if (cloud_provider == 'Microsoft Azure') {
      for (let i = 0; i < this.cloudConfiguration.length + 1; i++) {
        const element = cloudConfigs[i];
        console.log(element);
        if (
          element.cloudConfgName == 'Microsoft Azure Primary' ||
          element.cloudConfgName == 'Microsoft Azure Secondary'
        ) {
          cloudConfigs.splice(i, 2);
        }
      }
    } else if (cloud_provider == 'Amazon Web Services') {
      for (let i = 0; i < this.cloudConfiguration.length + 1; i++) {
        const element = cloudConfigs[i];
        console.log(element);
        if (element.cloudConfgName == 'Amazon Web Services') {
          cloudConfigs.splice(i, 1);
        }
      }
    }
    this.cloudConfiguration = cloudConfigs;
    console.log('cloudConfiguration ', this.cloudConfiguration);
    this.validateAddiConfig();
  }

  goBack() {
    // this._location.back();
    return this.router.navigate(['/landingPage']);
  }

  populateAzureSecondaryconfig() {
    console.log('populateAzureSecondaryconfig ');

    let secdCloud, primaryCloud: any;
    this.cloudConfiguration.forEach((c) => {
      if (c.cloudConfgName == 'Microsoft Azure Primary') {
        primaryCloud = c.attributes;
      } else if (c.cloudConfgName == 'Microsoft Azure Secondary') {
        secdCloud = c.attributes;

        primaryCloud.forEach((pc) => {
          secdCloud.forEach((e) => {
            if (
              e.displayName == 'AS Number(remote)' &&
              pc.displayName == 'AS Number(remote)'
            ) {
              e.selectedValue = pc.selectedValue;
              // console.log('primaryCloud ', primaryCloud);
              // console.log('pc ', pc.selectedValue);
            } else if (
              e.displayName == 'BGP Key' &&
              pc.displayName == 'BGP Key'
            ) {
              e.selectedValue = pc.selectedValue;
              // console.log('pc ', pc.selectedValue);
            } else if (
              e.displayName == 'Service Key' &&
              pc.displayName == 'Service Key'
            ) {
              e.selectedValue = pc.selectedValue;
              // console.log('pc ', pc.selectedValue);
            } else if (
              e.displayName == 'Subnet' &&
              pc.displayName == 'Subnet'
            ) {
              e.selectedValue = pc.selectedValue;
              // console.log('pc ', pc.selectedValue);
            } else if (e.displayName == 'VLAN' && pc.displayName == 'VLAN') {
              e.selectedValue = pc.selectedValue;
              // console.log('pc ', pc.selectedValue);
            } else if (
              e.displayName == 'Peer Type' &&
              pc.displayName == 'Peer Type'
            ) {
              e.selectedValue = pc.selectedValue;
              // console.log('pc ', pc.selectedValue);
            }
          });
        });
      }
    });

    // console.log('primaryCloud ', primaryCloud);
    // console.log('secdCloud ', secdCloud);
  }
}
