<h2 mat-dialog-title>Congratulations!</h2>
<mat-dialog-content>Your connection is active.</mat-dialog-content>
<mat-dialog-actions>
  <button
    mat-button
    mat-dialog-close
    cdkFocusInitial
    style="margin: auto; background-color: #ea7926; color: #fff"
  >
    Ok
  </button>
</mat-dialog-actions>
