// import { Component, OnInit } from '@angular/core';
// import {MaterialModule} from "src/app/common/material.module";
import { Component, OnInit, ViewEncapsulation, TemplateRef, ViewChild, Input, AfterViewInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocationStrategy } from '@angular/common';
import { DataSharedSvc } from 'src/app/data-shared-svc';
import { BsModalService } from 'ngx-bootstrap/modal';
import { DomSanitizer } from '@angular/platform-browser';
import { MatSnackBar } from '@angular/material/snack-bar';

import { IzomccScpService } from '../../izomcc-scp.service';

@Component({
  selector: 'app-getquote',
  templateUrl: './getquote.component.html',
  styleUrls: ['./getquote.component.css']
})
export class GetquoteComponent implements OnInit, AfterViewInit {
  public displayPricingPanel: Boolean = true;
  public tabSelected: string = 'Completed';
  public defaultTabSeleted: string = 'Ongoing';
  getQuoteObj: any = {
    groupByData: [
      { key: 1, value: "All" },
      { key: 2, value: "Ongoing" },
      { key: 3, value: "Completed" }
    ],
    groupBySel: {},
    // shareEmailId: "",
    pageReady: false,
    validation: {

    },

    currentTabData: [],
    currentActiveTab: 0,
  };
  private tabList: any = [];



  disable: boolean;
  configurations: any;
  quoteSolutionData: any = [];
  configurationCode: any;
  quoteId: any;
  quoteDetails: any;
  quoteInfo: any;

  public contractData = ['Non-committed term – Daily Rate plan', '3 Month', '6 Month', '12 Month', '18 Month', '24 Month', '36 Month', '60 Month'];
  testData = ['one', 'two', 'three'];
  testSampleData: string = 'three';

  payloadStage = {
    stage: 'Get Quote'
  }
  isCommercialTriggered: any;
  Monthsupdate: string = '1 Month';
  payload = {
    termInMonths: ''
  };
  currency: any = "USD";
  currencyData = ["USD", "INR", "EUR", "SGD"]
  compDetails: any;
  arcVal: any = "XXXX";
  nrcVal: any = "XXXX";
  mrcVal: any = "XXXX";
  dflag: boolean = false;

  shareEmailId: ""
  readonly tabs = [
    { name: 'hello', label: 'Hello tab' },
    { name: 'world', label: 'World tab' }
  ]
  /** Izone */

  public configarr: any = [
    {
      locationName : 'Chicago',
      offeringName : 'IZO Multi Cloud Connect Flex'
    }
  ];
  public panelOpenState: boolean = false;
  public edgeList: any = [];
  public cloudList: any = [];
  public connectList: any = [];
  public selectedPricingConfigs: any = [];
  public onGoingConfigs: any = [];
  public completedConfigs: any = [];
  private offeringName: string = 'IZO Multi Cloud Connect';
  public totalPriceCalc: any = {};
  public defaultDisable: Boolean = false;
  public defaultDisableBtn: Boolean = false;
  public quoteStatusCommercial: string;
  public checkoutEnable: Boolean = false;
  messageModalSettings: any = {};
  @ViewChild('shareModal') shareModal: TemplateRef<any>;
  @ViewChild('messageModal') messageModal: TemplateRef<any>;
  msg: any = "";
  confirmModal: any = {};
  fileUrl: any;
  status: boolean = false;
  contracts: string;
  isOptimusSales: boolean = false;
  flag = {
    checkoutBasedonWF: false,
    OldQuoteEdit: false,
    downGradeOrderRetainLegacyCPE: false
  }
  userCategoryModule: string;
  customProfileAccess: boolean;
  isCommercialUser: boolean;
  customerFeasibilityAccess: boolean;
  isCustomerUser: boolean;


  expectedTotalNRC: 0
  selectedSiteCount: Number = 0;

  finalList: any = [];
  length: any = 0;
  sum: number = 0;
  totalArc: number;
  sum1: any = 0;
  totalNrc: number;
  btnFlag: boolean = false;
  quoteleID: any;
  arr1: any = [];
  arrlength: any;
  cflag: boolean;
  regex: any = new RegExp('([0-9]+)|([a-zA-Z]+)', 'g');
  public currentlyOpenedItemIndex: number;
  arr: any;

  constructor(private router: Router, public izomccScpService: IzomccScpService, public datasharedSvc: DataSharedSvc, private sanitizer: DomSanitizer,
    public activatedroute: ActivatedRoute, private location: LocationStrategy, public bsModalService: BsModalService, private _snackBar: MatSnackBar) { }


  ngOnInit(): void {


    // this.tabList = [{ tabName: 'Ongoing' }, { tabName: 'Completed' }];
    // this.getQuoteObj['tablist'] = this.tabList;
//     let solutionForm = JSON.parse(sessionStorage.getItem('solutionform'))['term_in_months']
//     const index = this.contractData.indexOf(solutionForm);

// if (index !== -1) {
//     // Remove the element from its current position
//     this.contractData.splice(index, 1);
//     // Insert it at the beginning of the array
//     this.contractData.unshift(solutionForm);
// }

    this.quoteInfo = JSON.parse(this.datasharedSvc.getQuoteObjFromSession());
    this.isOptimusSales = this.datasharedSvc.getListOfActions().indexOf('OPT_L2O_CUSTOMER_ORDER_CREATE') > -1;
    ////console.log(this.datasharedSvc.getListOfActions())
    // this.
    this.activatedroute.params.subscribe(params => {
      if (params['quoteId']) {

        this.dflag = true;
        this.quoteInfo = params
        this.datasharedSvc.setQuoteObjInSession(JSON.stringify(this.quoteInfo));
        this.getReviewDetails();
      } else {
        this.getReviewDetails();
      }
    })

    let quoteLeId;
    if (this.quoteInfo.quoteleId == null || this.quoteInfo.quoteleId == undefined) {
      quoteLeId = this.quoteInfo.legalEntities[0].quoteleId;
      this.quoteleID = quoteLeId
    }
    else {
      quoteLeId = this.quoteInfo.quoteleId;
      this.quoteleID = quoteLeId
    }
    this.izomccScpService.updateQuoteStage(this.quoteInfo.quoteId, quoteLeId, this.payloadStage, (res) => {
    }, (error) => {

    })
    this.datasharedSvc.sharedData.subscribe((data) => {
      console.log(data)
      if (data['userType'] === 'PARTNER') {
        if (!this.router.url.includes('macd')) {
          this.userCategoryModule = 'partner';
          this.customProfileAccess = true;
          this.isCommercialUser = true;
          this.customerFeasibilityAccess = true;
        }
        this.isOptimusSales = true;
      } else if (data['userType'] === 'CUSTOMER') {
        this.isCustomerUser = true;
      }
    });
    this.preventBackButton();

  }
  // goBack() {
  //   this.location.back();
  // }

  ngAfterViewInit(){
    setTimeout(()=>{
      let elem = document.getElementsByClassName('mat-expansion-indicator')[0] as HTMLElement
      elem.style.display="none";
    },100)
  }
  private preventBackButton() {
    for (let i = 0; i < history.length; i++) {
      window.history.pushState(null, null, null);
    }
    this.location.onPopState(() => {
      history.pushState(null, null, window.location.pathname);
    });
  }

  public onCurrencyChange(event) {
    // this.izomccScpService.currencyConvert(this.quoteInfo.quoteId,this.quoteleID,event, (res) => {
    //   location.reload()
    // },(error) => {
    // })
  }

  public defaultSelection(configArr): void {
    configArr.map((eachConfig: any) => {
      const pricingConfig = this.filterConfiguration(eachConfig);
      this.selectedPricingConfigs.push(pricingConfig[0].configurations[0]);
    })
    this.selectedConfigsPricCalc(this.selectedPricingConfigs);

    // this.selectedPricingConfigs = this.configarr;
  }

  public selectedConfig(config, event): void {
    if (event.checked === true) {
      const pricingConfig = this.filterConfiguration(config);
      this.selectedPricingConfigs.push(pricingConfig[0].configurations[0]);
    } else if (event.checked === false && this.selectedPricingConfigs.length > 0) {
      this.selectedPricingConfigs = [...this.selectedPricingConfigs.filter(deleteConfig => {
        return deleteConfig.configurationCode !== config.configurationCode
      })]
    }
    if (this.selectedPricingConfigs.length === 0) {
      // this.defaultQuoteCalculations();
    } else {
      this.selectedConfigsPricCalc(this.selectedPricingConfigs);
    }
  }

  private filterConfiguration(selectedConfig) {
    return this.quoteSolutionData.filter(solution => {
      return solution.configurations[0].configurationCode === selectedConfig.configurationCode
    })
  }

  // private defaultQuoteCalculations() {

  //   let pricingObj = {
  //     totalArc: 0,
  //     totalNrc: 0,
  //     totalMrc: 0,
  //     totaltcv: 0
  //   }
  //   pricingObj['totalArc'] = this.quoteDetails.legalEntities[0].finalArc,
  //   pricingObj['totalNrc'] = this.quoteDetails.legalEntities[0].finalNrc;
  //   pricingObj['totalMrc'] = this.quoteDetails.legalEntities[0].finalMrc,
  //     pricingObj['totaltcv'] = this.quoteDetails.legalEntities[0].totalTcv;
  //   this.totalPriceCalc = pricingObj;
  //   //console.log(this.quoteDetails.legalEntities[0].finalArc.toFixed(2))
  // }

  defaultButtonValidations() {
    return this.onGoingConfigs.length > 0 ? true : false
  }

  private selectedConfigsPricCalc(pricingConfigs?) {
    let pricingObj = {
      totalArc: 0,
      totalNrc: 0,
      totalMrc: 0,
      totaltcv: 0
    }
    pricingConfigs.map((eachConfig) => {
      pricingObj['totalArc'] = pricingObj['totalArc'] + eachConfig['arc'];
      pricingObj['totalNrc'] = pricingObj['totalNrc'] + eachConfig['nrc'];
      pricingObj['totalMrc'] = pricingObj['totalMrc'] + eachConfig['mrc'];
      pricingObj['totaltcv'] = pricingObj['totaltcv'] + eachConfig['tcv'];
      //console.log(eachConfig['arc'].toFixed(2))
    })
    this.totalPriceCalc = pricingObj;
    this.defaultDisable = this.defaultButtonValidations();
    //console.log(pricingObj['totalArc'] .toFixed(2))

    ////console.log('tets disable', this.defaultDisable)
    // this.onContractChange(this.Monthsupdate)
  }
  private getReviewDetails(): void {
    if (this.quoteInfo && this.quoteInfo.quoteId) {
      this.izomccScpService.getReviewDetails(this.quoteInfo.quoteId, (res) => {
        this.quoteDetails = res.data;

        this.quoteStatusCommercial = 'Commercial Updated'
        // this.quoteStatusCommercial = this.quoteDetails.legalEntities[0].commercialStatus;
        // if((this.quoteStatusCommercial.includes("C1")) || (this.quoteStatusCommercial.includes("C2")) || (this.quoteStatusCommercial.includes("C3"))){
        //   this.cflag=true
        // }else{
        this.cflag = false
        //}
        this.quoteSolutionData = this.quoteDetails.legalEntities[0].productFamilies[0].solutions;
        this.arrlength = this.quoteSolutionData.length
        this.compDetails = this.quoteDetails.legalEntities[0].productFamilies[0].solutions[0].configurations[0]
        this.arcVal = this.compDetails.arc;
        this.nrcVal = this.compDetails.mrc
        this.mrcVal = this.compDetails.nrc
        this.configurationCode = this.quoteDetails.quoteCode
        this.isCommercialTriggered = this.quoteDetails.legalEntities[0].isCommercialTriggered;
        // this.Monthsupdate = this.quoteDetails.legalEntities[0].termInMonths ? this.quoteDetails.legalEntities[0].termInMonths == '1 Month' ? 'Non-committed term – Daily Rate plan' : this.quoteDetails.legalEntities[0].termInMonths : null;
        //this.arr = this.quoteDetails.legalEntities[0].termInMonths ? this.quoteDetails.legalEntities[0].termInMonths.match(this.regex) : null;
        //this.Monthsupdate = this.arr != null ? this.arr[0] == 1 ?  " Month" : "Months" : ""
        console.log(this.Monthsupdate)
        // this.currency = this.quoteDetails.legalEntities[0].currency;
        // this.quoteDetails.legalEntities[0].legalAttributes.forEach(row=>{
        //   this.pricingFlag=false
        //   if(row['displayValue']=='RECURRING_CHARGE_TYPE'){
        //     this.pricingFlag=true
        //   }
        //   console.log(row)
        // })


        this.quoteSolutionData.map(solution => {
          if ((solution.configurations[0].approveStatus === false && solution.configurations[0].rejectionStatus === false) && (this.quoteStatusCommercial && this.quoteStatusCommercial == 'Commercial Updated')) {
            //  alert('1')
            this.completedConfigs.push(solution)
            console.log(this.completedConfigs)
          }
          else if (solution.configurations[0].approveStatus === false && solution.configurations[0].rejectionStatus === false) {
            // alert('2')
            this.onGoingConfigs.push(solution)
          } else {
            // alert('3')
            this.completedConfigs.push(solution)
          }
        })
        this.completedConfigs.length > 0 ? this.statusSelected('Completed') : this.statusSelected('Ongoing');
        //this.checkStatus(this.completedConfigs)
      }, (error) => {
        console.log(error)
      })
    }
    //console.log(this.completedConfigs)
  }
  /** Chcekout page */
  public addToCart() {
    this.router.navigate(['checkout']);
    // this.izomccScpService.postSFDCStatusUpdate((success) => {

    // }, (error) => { });

  }

  /** contract term */
  public onContractChange(value?, index?): void {
    // const contractTerm = value.split(' ')[0];
    // // (this.totalPriceCalc["arc"] * (contractTerm / 12) + this.totalPriceCalc["nrc"]).toFixed(2)


    // this.Monthsupdate = value;
    // this.payload.termInMonths = this.contractData[index];
    // ////console.log('index+++++++++++++ tests ', value);
    // ////console.log('index+++++++++++++', value, contractTerm, this.totalPriceCalc);
    // this.totalPriceCalc['totalArc'] = this.totalPriceCalc['totalArc'] * (contractTerm / 12);
    // this.totalPriceCalc['totalNrc'] = this.totalPriceCalc['totalNrc'] * (contractTerm / 12);
    // // this.totalPriceCalc['totalMrc'] = this.totalPriceCalc['totalMrc'] * (contractTerm / 12);
    // this.totalPriceCalc['totalSolution'] = this.totalPriceCalc['totalSolution'] * (contractTerm / 12);

  }


  /** Izone Get Quote */
  // public onGroupByChange(value): void {
  //   this.selectedConfigsPricCalc(this.selectedPricingConfigs = []);
  //   if (value === 'All') {
  //     this.getQuoteObj['tablist'] = this.tabList;
  //     this.statusSelected('Ongoing');
  //     this.tabSelected = 'Ongoing';
  //     this.defaultTabSeleted = 'Ongoing'
  //   } else {
  //     this.tabSelected = value;
  //     this.defaultTabSeleted = value;
  //     const filtered = this.tabList.filter(each => { return each.tabName == value });
  //     this.getQuoteObj['tablist'] = filtered;
  //     if (this.completedConfigs.length > 0 && value === 'Completed') {
  //       this.displayPricingPanel = true;
  //     } else {
  //       this.displayPricingPanel = false;
  //     }
  //     this.statusSelected(value);
  //   }
  // }

  // public selectTab(value): void {
  //   this.selectedConfigsPricCalc(this.selectedPricingConfigs = []);
  //   this.tabSelected = value;
  //   this.defaultTabSeleted = value;
  //   this.getQuoteObj[value] = true;
  //   this.statusSelected(value);
  // }

  public statusSelected(value?) {
    console.log(value)
    this.completedConfigs = [];
    this.onGoingConfigs = [];
    this.configarr = [
      {
        locationName : 'Chicago',
        service: 'IZO Multi Cloud Connect Flex'
      }
    ];
    let tempConfig = [];
    this.quoteSolutionData.map(solution => {
      if ((solution.configurations[0].approveStatus === false && solution.configurations[0].rejectionStatus === false) && (this.quoteStatusCommercial && this.quoteStatusCommercial == 'Commercial Updated')) {
        this.completedConfigs.push(solution)
        console.log(this.completedConfigs)
      } else if (solution.configurations[0].approveStatus === false && solution.configurations[0].rejectionStatus === false) {
        this.onGoingConfigs.push(solution)
      } else {
        this.completedConfigs.push(solution)
      }

    })
    value === 'Ongoing' ? tempConfig = this.onGoingConfigs : value === 'Completed' ? tempConfig = this.completedConfigs : tempConfig = []
    // if (this.completedConfigs.length > 0 && value === 'Completed') {
    //   this.displayPricingPanel = true;
    //   this.tabSelected = 'Completed';
    //   this.defaultTabSeleted = 'Completed';
    // } else if (this.completedConfigs.length === 0 && value === 'Ongoing') {
    //   this.displayPricingPanel = false;
    //   this.tabSelected = 'Ongoing';
    //   this.defaultTabSeleted = 'Ongoing';
    // } else if (this.completedConfigs.length > 0 && value === 'Ongoing') {
    //   this.displayPricingPanel = false;
    //   this.tabSelected = 'Ongoing';
    //   this.defaultTabSeleted = 'Ongoing';
    // }
    tempConfig.map(data => {
      let obj = {};
      if (data.configurations[0].accessType == 'New access link required') {
        obj = {
          productSolutionId: data.productSolutionId,
          cloudConfigurationId: data.configurations[0].cloudConfigurationId,
          locationName: data.configurations[0].locationName,
          offeringName: this.offeringName,
          accessType: data.configurations[0].accessType,
          accessRequired: data.configurations[0].accessRequired,
          serviceUptime: data.configurations[0].serviceUptime,
          refOrderId: data.configurations[0].refOrderId,
          configurationCode: data.configurations[0].configurationCode,
          rejectionStatus: data.configurations[0].rejectionStatus,
          approveStatus: data.configurations[0].approveStatus,
          isActionTaken: data.configurations[0].isActionTaken
        };
      }
      else {
        obj = {
          productSolutionId: data.productSolutionId,
          cloudConfigurationId: data.configurations[0].cloudConfigurationId,
          locationName: data.configurations[0].locationName,
          offeringName: this.offeringName,
          accessType: data.configurations[0].accessType,
          accessRequired: data.configurations[0].accessRequired,
          serviceUptime: data.configurations[0].serviceUptime,
          configurationCode: data.configurations[0].configurationCode,
          rejectionStatus: data.configurations[0].rejectionStatus,
          approveStatus: data.configurations[0].approveStatus,
          isActionTaken: data.configurations[0].isActionTaken
        };
      }
      this.configarr.push(obj);
    })
    this.defaultSelection(this.configarr);

  }

  public openPanel(el, i) {
    this.currentlyOpenedItemIndex = i;
    this.panelOpenState = true;
    //for(let obj of this.configarr['configurations']){
    let solutionObj = this.quoteSolutionData.filter(x => x.productSolutionId == el.productSolutionId)
    // }
    this.buildAttributes(solutionObj);
  }

  toggleClass(i: number): void {
    this.currentlyOpenedItemIndex = i;
  }

  public closePanel(el, i): void {
    if (this.currentlyOpenedItemIndex === i) {
      this.currentlyOpenedItemIndex = -1;
      this.panelOpenState = false;
    }
  }

  private buildAttributes(solutionObj): void {
    this.edgeList = [];
    this.cloudList = [];
    this.connectList = [];
    for (let config of solutionObj[0]['configurations']) {
      for (let item of config['components']) {
        let componentObj = {};
        if (item['name'] == 'Edge Connectivity') {
          componentObj = {
            "attributes": item['attributes']
          }
          let index1 = componentObj['attributes'].findIndex(ele => ele.name == 'Component Order Type')
          if (index1 > -1)
            componentObj['attributes'].splice(index1, 1)
          // componentObj['attributes'].push(componentObj['attributes'].shift())
          this.connectList.push(componentObj)
          this.connectList.forEach(ele => {
            if (ele.attributes[0].name !== 'Edge Connectivity Type') {
              ele.attributes.push(ele.attributes.shift())
            }
          });
          let connectAttr;
          this.connectList.map(eachConnect => {
            connectAttr = eachConnect.attributes.filter(connect => {
              return connect.name != 'Edge Connectivity Bandwidth-Link2'
            })
          })
          // this.connectList[0]['attributes'] = connectAttr
          this.connectList = [...this.connectList];
          // this.connectList  = [connectAttr];
        } else if (item['name'] == 'Cloud') {
          componentObj = {
            "attributes": item['attributes']
          }
          console.log(componentObj)
          if (componentObj['attributes'].find(ele => ele.name == 'Cloud Provider').value == 'Amazon Web Services') {
            let index1 = componentObj['attributes'].findIndex(ele => ele.name == 'Primary Service Key')
            if (index1 > -1)
              componentObj['attributes'].splice(index1, 1)
            let index2 = componentObj['attributes'].findIndex(ele => ele.name == 'Primary Peer Type')
            if (index2 > -1)
              componentObj['attributes'].splice(index2, 1)
            let index3 = componentObj['attributes'].findIndex(ele => ele.name == 'Primary vlan')
            if (index3 > -1)
              componentObj['attributes'].splice(index3, 1)
            let index4 = componentObj['attributes'].findIndex(ele => ele.name == 'Cloud Site Country')
            if (index4 > -1)
              componentObj['attributes'].splice(index4, 1)
            let index5 = componentObj['attributes'].findIndex(ele => ele.name == 'Component Order Type')
            if (index5 > -1)
              componentObj['attributes'].splice(index5, 1)
            let index6 = componentObj['attributes'].findIndex(ele => ele.name == 'Cloud Site Code')
            if (index6 > -1)
              componentObj['attributes'].splice(index6, 1)
          }
          else if (componentObj['attributes'].find(ele => ele.name == 'Cloud Provider').value == 'Microsoft Azure') {
            let index1 = componentObj['attributes'].findIndex(ele => ele.name == 'Primary Account Id')
            if (index1 > -1)
              componentObj['attributes'].splice(index1, 1)
            let index2 = componentObj['attributes'].findIndex(ele => ele.name == 'Secondary Account Id')
            if (index2 > -1)
              componentObj['attributes'].splice(index2, 1)
            let index3 = componentObj['attributes'].findIndex(ele => ele.name == 'Cloud Site Country')
            if (index3 > -1)
              componentObj['attributes'].splice(index3, 1)
            let index4 = componentObj['attributes'].findIndex(ele => ele.name == 'Component Order Type')
            if (index4 > -1)
              componentObj['attributes'].splice(index4, 1)
            let index5 = componentObj['attributes'].findIndex(ele => ele.name == 'Cloud Site Code')
            if (index5 > -1)
              componentObj['attributes'].splice(index5, 1)

          }
          this.cloudList.push(componentObj)
          console.log(this.cloudList)
          //   this.cloudList.forEach(ele => {
          //     if(ele.attributes.find(data => data.name == 'Cloud Provider').value == 'Amazon Web Services'){
          //   let index1 = ele.attributes.findIndex(item => item.name == 'Primary Service Key')
          //   if(index1>-1)
          //   ele.attributes.splice(index1,1)
          //   let index2 = ele.attributes.findIndex(item => item.name == 'Primary Peer Type')
          //   if(index2>-1)
          //   ele.attributes.splice(index2,1)
          //   let index3 = ele.attributes.findIndex(item => item.name == 'Primary vlan')
          //   if(index3>-1)
          //   ele.attributes.splice(index3,1)
          //   let index4 = ele.attributes.findIndex(item => item.name == 'Cloud Site Country')
          //   if(index4>-1)
          //   ele.attributes.splice(index4,1)
          // }
          //  else if(ele.attributes.find(data => data.name == 'Cloud Provider').value == 'Microsoft Azure'){
          //   let index5 = ele.attributes.findIndex(item => item.name == 'Primary Account Id')
          //   if(index5>-1)
          //   ele.attributes.splice(index5,1)
          //   let index6 = ele.attributes.findIndex(item => item.name == 'Secondary Account Id')
          //   if(index6>-1)
          //   ele.attributes.splice(index6,1)
          //   let index7 = ele.attributes.findIndex(item => item.name == 'Cloud Site Country')
          //   if(index7>-1)
          //   ele.attributes.splice(index7,1)
          //  }
          //     })
        } else if (item['name'] == 'Edge Device') {
          componentObj = {
            "attributes": item['attributes']
          }
          let index1 = componentObj['attributes'].findIndex(ele => ele.name == 'Device OEM')
          if (index1 > -1)
            componentObj['attributes'].splice(index1, 1)
          let index2 = componentObj['attributes'].findIndex(ele => ele.name == 'Device Type')
          if (index2 > -1)
            componentObj['attributes'].splice(index2, 1)
          let index3 = componentObj['attributes'].findIndex(ele => ele.name == 'Device Category')
          if (index3 > -1)
            componentObj['attributes'].splice(index3, 1)
          let index4 = componentObj['attributes'].findIndex(ele => ele.name == 'size')
          if (index4 > -1)
            componentObj['attributes'].splice(index4, 1)
          let index5 = componentObj['attributes'].findIndex(ele => ele.name == 'License')
          if (index5 > -1)
            componentObj['attributes'].splice(index5, 1)
          let index6 = componentObj['attributes'].findIndex(ele => ele.name == 'License Type')
          if (index6 > -1)
            componentObj['attributes'].splice(index6, 1)
          let index7 = componentObj['attributes'].findIndex(ele => ele.name == 'CPE Basis Chasis')
          if (index7 > -1)
            componentObj['attributes'].splice(index7, 1)

          let index8 = componentObj['attributes'].findIndex(ele => ele.name == 'Component Order Type')
          if(index8 >-1)
          componentObj['attributes'].splice(index8,1)

          let index9 = componentObj['attributes'].findIndex(ele => ele.name == 'latLong')
          if(index9 >-1)
          componentObj['attributes'].splice(index9,1)

          this.edgeList.push(componentObj)
          console.log(this.edgeList)
        }
      }
    }

  }

  onShareQuoteOpen() {
    this.getQuoteObj.shareModalRef = this.bsModalService.show(this.shareModal, { class: "modal-sm" });
  }

  validateShare() {
    var pattern = /^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$/;
    ////console.log(!pattern.test(String(this.shareEmailId).toLowerCase()))
    // var emailpattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
    // this.getQuoteObj.validation.emailMandate = this.getQuoteObj.shareEmailId === "";
    // this.getQuoteObj.validation.invalidEmail = !this.getQuoteObj.validation.emailMandate && !(emailpattern.test(String(this.getQuoteObj.shareEmailId).toLowerCase()));
    // return !this.getQuoteObj.validation.invalidEmail && !this.getQuoteObj.validation.emailMandate;
  }

  onShareQuote() {
    // this.getQuoteObj.validation.submitted = true;
    // ////console.log(this.validateShare())
    // if (!this.validateShare()) {
    //   alert('in')
    //   return;
    // }

    this.shareQuote();
  }

  openLoaderModal(data) {
    this.datasharedSvc.open(data);
  }

  onShareQuoteClose() {
    this.getQuoteObj.shareEmailId = "";
    this.getQuoteObj.validation.invalidEmail = false;
    this.getQuoteObj.validation.emailMandate = false;
    this.getQuoteObj.validation.submitted = false;
    this.getQuoteObj.shareModalRef.hide();
  }

  openMessageModal() {
    this.confirmModal = this.bsModalService.show(this.messageModal, { class: "modal-sm" });
  }

  closeMessageModal() {
    this.messageModalSettings.close();
  }

  onEmail(evt) {
    this.shareEmailId = evt.target.value
    var pattern = /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
    this.status = pattern.test(String(this.shareEmailId).toLowerCase())
  }

  shareQuote() {
    this.openLoaderModal({ message: "Please wait" });
    let quoteLeId;
    if (this.quoteInfo.quoteleId == null || this.quoteInfo.quoteleId == undefined) {
      quoteLeId = this.quoteInfo.legalEntities[0].quoteleId;
    }
    else {
      quoteLeId = this.quoteInfo.quoteleId;
    }

    console.log(this.shareEmailId, 'this.shareEmailId');

    this.izomccScpService.shareEmailQuote(this.shareEmailId, this.quoteInfo.quoteId, quoteLeId, (data) => {
      this.onShareQuoteClose();
      this.msg = "Shared successfully"
      setTimeout(() => {
        this.datasharedSvc.close();
      }, 100);
      this.openMessageModal();
    }, (err) => {
      this.msg = "Something Went Wrong"
      this.openMessageModal();
    })
  }

  closeModal() {
    this.confirmModal.hide()
    // this.bsModalService.hide(this.messageModal,{ class: "modal-sm" })
    // this.confirmModal.hide();
  }

  downloadQuote() {
    this.openLoaderModal({ message: "Please wait" });
    let quoteLeId;
    if (this.quoteInfo.quoteleId == null || this.quoteInfo.quoteleId == undefined) {
      quoteLeId = this.quoteInfo.legalEntities[0].quoteleId;
    }
    else {
      quoteLeId = this.quoteInfo.quoteleId;
    }
    this.izomccScpService.downloadQuote(this.quoteInfo.quoteId, quoteLeId, (res) => {
      ////console.log(res, "res")
      var blobData = new Blob([res], { type: 'application/pdf' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blobData);
      link.download = "Quote_" + this.quoteInfo.quoteId + ".pdf";
      link.click();
      link.remove();
      this.msg = "Quote Document Downloaded Successfully"
      this.datasharedSvc.close();
    }, (err) => {
      ////console.log(err)
      this.openLoaderModal({ message: "Something Went Wrong" });

      setTimeout(() => {
        this.datasharedSvc.close();
      }, 35000);
    })
  }


  // checkStatus(array){
  //   //console.log(array)
  //   let arr1=[]
  //   let arr2=[]
  //   //console.log(this.completedConfigs.length)
  //   array.forEach(row=>{
  //     row['configurations'].forEach(comp=>{
  //      if(comp['rejectionStatus'] == true){
  //        this.arr1.push(row)

  //      }else{
  //       arr2.push(row)

  //      }
  //     })
  //   })
  // }

  // public onGroupByChange(value): void {//
  //   this.selectedConfigsPricCalc(this.selectedPricingConfigs = []);
  //   if (value === 'All') {
  //     this.getQuoteObj['tablist'] = this.tabList;
  //     this.statusSelected('Ongoing');
  //     this.tabSelected = 'Ongoing';
  //     this.defaultTabSeleted = 'Ongoing'
  //   } else {
  //     this.tabSelected = value;
  //     this.defaultTabSeleted = value;
  //     const filtered = this.tabList.filter(each => { return each.tabName == value });
  //     this.getQuoteObj['tablist'] = filtered;
  //     if (this.completedConfigs.length > 0 && value === 'Completed') {
  //       this.displayPricingPanel = true;
  //     } else {
  //       this.displayPricingPanel = false;
  //     }
  //     this.statusSelected(value);
  //   }
  // }

  goBack() {
    console.log("goBack ", this.quoteInfo);
    let url = '/config/' + this.quoteInfo.quoteId + '/le/' + this.quoteInfo.quoteleId;
    return this.router.navigate([url]);
  }

  // goBack() {
  //   this._location.back();
  // }

}


