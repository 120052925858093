import { Injectable } from '@angular/core';
import { appConstant } from 'src/app/app.constants';
import { CommonValidationService } from '../../common/service/common-validation.service';
@Injectable()
export class OrderFormService {

  objectStorageEnabled = appConstant.objectStorageEnabled;

  private OrderFormServiceConst = {
    customers: `${appConstant.customerService}/api/v1/customers`,
    customerLes: `${appConstant.customerService}/api/v1/customers/le`,
    users: `${appConstant.delegationUrl}/api/v1/users`,
    omsQuoteLes: `${appConstant.omsService}/api/v1/quotes/{{quoteId}}/le/{{quoteleId}}`,
    omsIpcQuotes: `${appConstant.omsService}/api/v2/ipc/quotes`,
    omsIpcDocusign: `${appConstant.omsService}/api/v1/ipc/quotes`,
    omsIpcMilestoneChildQuotes: `${appConstant.omsService}/api/isv/v1/ipc/quotes`,
    omsIpcQuoteLes: `${appConstant.omsmwieProduct}/api/v1/izo-mcc/quotes/{{quoteId}}/le/{{quoteleId}}`,
    // omsIpcQuoteLes1: `${appConstant.omsmwieProduct}/izo-mcc/quotes/{{quoteId}}/le/{{quoteleId}}`,
    omsIpcQuoteLes1: `${appConstant.omsmwieProduct}/izo-mcc/quotes/{{quoteId}}/le/{{quoteleId}}`,
    omsIpcOrders: `${appConstant.omsService}/api/v1/ipc/orders`,
    locations: `${appConstant.locationUrl}/api/v1/locations/details`,
    getleowners: `${appConstant.omsService}/api/v1/ipc/quotes/customer`,
    milestoneDoc: `${appConstant.customerService}/api/isv/v1/le`,
    milestoneAttachment: `${appConstant.customerService}/api/isv/v1/customers/le`,
    milestoneApproveQuotes: `${appConstant.omsService}/api/isv/v1/ipc/milestone/approvequotes`,
    milestoneCOFDownload: `${appConstant.omsService}/api/isv/v1/ipc/milestone`,
    getLegalentityForPartner: `${appConstant.omsService}/api/v1/partners/quotes/{{quoteId}}`,
    quoteDetails: `${appConstant.omsService}/api/illBulk/isBulk?`,
    omsIzomccQuotes: `${appConstant.mwieomsService}/api/v1/izo-mcc/quotes`,
    reviewConfig: appConstant.omsmwieProduct + '/izo-mcc/quotes',
    omsGof: `${appConstant.omsService}/api/v1/ipc/quotes`,
    quoteStageUpdate: appConstant.omsmwieProduct + '/izo-mcc/quotes',
    'creditCheckStatus': appConstant.omsService + '/api/v1/creditcheck/',
    'orderList': appConstant.mwieomsService + '/api/v1/izo-mcc/orders/',
    'provisionTeam': appConstant.mwieomsService + '/api/v1/users/admin/contact',
    'checkLegal': appConstant.customerService + '/api/v1/customers/le/splegal/international',
    'izomccCofDownload': appConstant.mwieomsService + '/api/v1/izo-mcc/orders/',
    'getIzomccAttributes': appConstant.mwieomsService + '/api/v1/izo-ne/quotes/{{quoteId}}/le/{{quoteleId}}',

  }

  // https://10.133.208.101/optimus-mwieoms/api/v1/izo-ne/quotes/59319/le/59309/attributes

  CommonConst = {
    billingDetails: appConstant.customerService + '/api/v1/customers/le/',
    postingBillingDetails: appConstant.customerService + '/api/v1/customers/le/',
    userDetails: appConstant.omsService + '/api/v1/users/userdetails',
    postUserDetails: appConstant.omsService + '/api/v1/users/userdetails',
    getGST: appConstant.customerService + '/api/v1/customers/le/',
    postGST: appConstant.customerService + '/api/v1/customers/',
    cityUrl: appConstant.locationUrl + '/api/v1/locations/pincode',
    getCountries: appConstant.omsProduct + '/api/v1/products/locations?productName=',
    customerContractingAddress: appConstant.customerService + '/api/v1/customers/',
    customerLegalEntity: appConstant.customerService + '/api/v1/customers/',
    acceptVerification: appConstant.customerService + '/api/v1/customers/le/',
    postSitewiseBilling: appConstant.omsService + '/api/v1/quotes/quoteId/',
    getSitewiseBillingAttr: appConstant.omsService + '/api/v1/quotes/quoteId/',
    getMasterGSTList: appConstant.omsService + '/api/v1/quotes/gst/billing/le/',
    deleteBillingDetail: appConstant.omsService + '/api/v1/quotes/site/billing/delete?quoteId='
  }
  httpService:any
  constructor( private commonValidationService: CommonValidationService) { }

  getVmDetails(quoteId, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.omsIpcQuotes}/${quoteId}`;
    this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  getMilestoneChildVmDetails(quoteId, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.omsIpcMilestoneChildQuotes}/${quoteId}`;
    this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  /** Izone */
  getQuote(quoteId, isMacd, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.omsIzomccQuotes}/${quoteId}`;
    this.httpService.getHttp(requestUrl, successFn, errorFn);
  }


  /** Izone */
  postListOfSites(locationId, successFn, errorFn) {
    let requestUrl = this.OrderFormServiceConst.locations;
    return this.httpService.postHttp(requestUrl, locationId, successFn, errorFn);
  }
  getContractingInfo(params, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.customerLes}/${params.customerLegalEntityId}/sle/${params.supplierLegalEntityId}`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  getCustomerLegalEntity(customerId, successFn, errorFn, isPartner?) {
    var partner = isPartner ? '?isPartner=yes' : ''
    let requestUrl = `${this.OrderFormServiceConst.customers}/${customerId}/le${partner}`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  getSupplierLegalEntity(customerLegalId, quoteleId, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.customerLes}/${customerLegalId}?product=IPC&quoteLeId=${quoteleId}`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  getCustomerBillingAddress(customerLegalId, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.customerLes}/${customerLegalId}/billingInfo`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }

  postLOU(params: any, successFn, errorFn) {
    let requestUrl = appConstant.omsmwieProduct + `/izo-mcc/quotes/` + params.quoteId + `/le/` + params.quoteToLe + `/attribute`
    // let requestUrl = appConstant.omsmwieProduct + `/izo-ne/quotes/` + params.quoteId + `/le/` + params.quoteToLe + `/attribute`
    // let requestUrl = `${this.commonValidationService.transformURL(this.OrderFormServiceConst.omsIpcQuoteLes)}/attribute`;
    return this.httpService.postHttp(requestUrl, params, successFn, errorFn);
  }
  // postSalesOrder(params, successFn, errorFn) {
  //   let requestUrl = `${this.OrderFormServiceConst.omsIpcQuotes}/${params.quoteId}/le/${params.quoteLeId}/contractentity`;
  //   return this.httpService.postHttp(requestUrl, params, successFn, errorFn);
  // }
  getBilling(customerId, successFn, errorFn) {
    // let requestUrl = `${this.OrderFormServiceConst.customerLes}/${customerId}/attributes?productName=IZONE`;
    let requestUrl = `${this.OrderFormServiceConst.customerLes}/${customerId}/attributes?productName=IZOMCC`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  /**Izone */
  getbillingAddress(customerLegalId, billingid, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.customerLes}/${customerLegalId}/billing/${billingid}`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  getCustomerContactInfo(successFn, errorFn) {
    let requestUrl = `${this.commonValidationService.transformURL(this.OrderFormServiceConst.omsQuoteLes)}/contactattributes`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }

  getDocuments(legalEntityId, successFn, errorFn) {
    // let requestUrl = `${this.OrderFormServiceConst.customerLes}/${legalEntityId}/attachments?productName=IZONE`;
    let requestUrl = `${this.OrderFormServiceConst.customerLes}/${legalEntityId}/attachments?productName=IZOMCC`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  getAttachment(attachmentId, customLeID, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.customerLes}/${customLeID}`;
    if (this.objectStorageEnabled) {
      requestUrl = `${requestUrl}/tempdownloadurl/${attachmentId}`
      return this.httpService.downloadCofDocument(this.commonValidationService.transformURL(requestUrl), successFn, errorFn);
    } else {
      requestUrl = `${requestUrl}/download/${attachmentId}`
      return this.httpService.getHttpPDF(this.commonValidationService.transformURL(requestUrl), successFn, errorFn);
    }
  }
  generatePDF(successFn, errorFn, quoteId, quoteleId, nat) {
    let requestUrl = `${this.OrderFormServiceConst.omsIpcQuotes}/${quoteId}/le/${quoteleId}/cofpdf?nat=${nat}`;
    return this.httpService.getHttpPDF(requestUrl, successFn, errorFn);
  }
  cofNotification(successFn, errorFn) {
    let requestUrl = `${this.commonValidationService.transformURL(this.OrderFormServiceConst.omsIpcQuoteLes)}/cofdownload/accountmanagernotification`;
    this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  postDelegation(params, successFn, errorFn) {
    let requestUrl = `${this.commonValidationService.transformURL(this.OrderFormServiceConst.omsIpcQuoteLes)}/delegate/notification`;
    return this.httpService.postHttp(requestUrl, params, successFn, errorFn);
  }
  getDocusign(successFn, errorFn, quoteId, quoteleId, nat, mail, name, params) {
    let requestUrl = `${this.OrderFormServiceConst.omsIzomccQuotes}/${quoteId}/le/${quoteleId}/docusign?nat=${nat}&email=${mail}&name=${name}`;
    return this.httpService.postHttp(requestUrl, params, successFn, errorFn);
  }
  getUserList(successFn, errorFn, customerLeId) {
    let requestUrl = `${this.OrderFormServiceConst.customerLes}/${customerLeId}/contacts`;
    this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  getCOFUserList(customerId, quoteId: number, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.users}?customerId=${customerId}&delegate=${quoteId}`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  setPaymentCurrency(successFn, errorFn, quoteId, quoteleId, params) {
    let requestUrl = `${this.commonValidationService.transformURL(this.OrderFormServiceConst.omsIpcQuoteLes1)}/attributes`;
    this.httpService.postHttp(requestUrl, params, successFn, errorFn);
  }

  getAttributesIZOMCC(successFn, errorFn) {let requestUrl = `${this.commonValidationService.transformURL(this.OrderFormServiceConst.omsIpcQuoteLes1)}/attributes`;return this.httpService.getHttp(requestUrl, successFn, errorFn);}

  saveAttribute(successFn, errorFn, attr) {
    let requestUrl = `${this.commonValidationService.transformURL(this.OrderFormServiceConst.omsIpcQuoteLes)}/attributes`;
    return this.httpService.postHttp(requestUrl, attr, successFn, errorFn);
  }
  saveAttributeipc(quoteInfo, successFn, errorFn, attr) {
    let requestUrl = `${this.OrderFormServiceConst.omsIpcQuotes}/${quoteInfo.quoteId}/le/${quoteInfo.quoteleId}/attributes`;
    return this.httpService.postHttp(requestUrl, attr, successFn, errorFn);
  }
  postLOUipc(quoteInfo, params, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.omsIpcQuotes}/${quoteInfo.quoteId}/le/${quoteInfo.quoteleId}/attribute`;
    return this.httpService.postHttp(requestUrl, params, successFn, errorFn);
  }
  postCCA(params, successFn, errorFn) {
    let requestUrl = appConstant.omsmwieProduct + `/izo-mcc/quotes/` + params.quoteId + `/le/` + params.quoteToLe + `/attributes`
    // let requestUrl = appConstant.omsmwieProduct + `/izo-ne/quotes/` + params.quoteId + `/le/` + params.quoteToLe + `/attributes`
    // let requestUrl = `${this.commonValidationService.transformURL(this.OrderFormServiceConst.omsIpcQuoteLes)}/attribute`;
    return this.httpService.postHttp(requestUrl, params, successFn, errorFn);
  }
  postLaunchNow(orderId, orderLeId, postObj, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.omsIpcOrders}/${orderId}/le/${orderLeId}/launch`;
    return this.httpService.postHttp(requestUrl, postObj, successFn, errorFn)
  }
  postServiceDelivery(orderId, orderLeId, postObj, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.omsIpcOrders}/${orderId}/le/${orderLeId}/servicedelivery`;
    return this.httpService.postHttp(requestUrl, postObj, successFn, errorFn)
  }
  getCustomerIpcContactInfo(successFn, errorFn) {
    let requestUrl = `${this.commonValidationService.transformURL(this.OrderFormServiceConst.omsIpcQuoteLes)}/contactattributes`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  getLeOwnersForIpc(customerId, customerLeId, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.getleowners}/${customerId}/le/${customerLeId}/leowners`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  getDocumentsForMilestone(customerLegalEntityId, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.milestoneDoc}/${customerLegalEntityId}/attachments?productName=IPC`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }
  getAttachmentsForMilestone(attachmentId, customerLegalEntityId, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.milestoneAttachment}/${customerLegalEntityId}/tempdownloadurl/${attachmentId}`
    return this.httpService.getHttpPDF(this.commonValidationService.transformURL(requestUrl), successFn, errorFn);
  }
  postOnAgreeForMilestone(quoteId, successFn, errorFn) {
    let postObj = {
      'quoteId': quoteId
    }
    let requestUrl = `${this.OrderFormServiceConst.milestoneApproveQuotes}`;
    return this.httpService.postHttp(requestUrl, postObj, successFn, errorFn)
  }
  downloadCOFForMilestone(quoteId, quoteLeId, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.milestoneCOFDownload}/${quoteId}/le/${quoteLeId}/cofpdf`
    return this.httpService.getHttpPDF(this.commonValidationService.transformURL(requestUrl), successFn, errorFn);
  }
  validateCofData(quoteId, quoteLeId, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.omsIpcDocusign}/${quoteId}/le/${quoteLeId}/cof/validate`;
    return this.httpService.postHttp(requestUrl, {}, successFn, errorFn)
  }
  getLeContactInfo(customerLeId, successFn, errorFn) {
    return this.httpService.getHttp(this.OrderFormServiceConst.customerLes + '/' + customerLeId + '/contactInfo', successFn, errorFn);
  }
  getUserDetails(successFn, errorFn) {
    this.httpService.getHttp(this.CommonConst.userDetails, successFn, errorFn);
  }

  getCustomerContractingAddress(customerLeId, successFn, errorFn) {
    return this.httpService.getHttp(this.CommonConst.customerContractingAddress + customerLeId + '/cca', successFn, errorFn);
  }

  getLegalentityForPartner(successFn, errorFn) {
    return this.httpService.getHttp(
      this.commonValidationService.transformURL(this.OrderFormServiceConst.getLegalentityForPartner), successFn, errorFn);
  }

  getQuoteDetailsByQuoteId(quoteId, successFn, errorFn) {
    this.httpService.getHttp(this.OrderFormServiceConst.quoteDetails + 'quoteId=' + quoteId, successFn, errorFn);
  }

  getSupplierLegalEntityIzone(customerLegalId, quoteleId, successFn, errorFn) {
    // let requestUrl = `${this.OrderFormServiceConst.customerLes}/${customerLegalId}?product=IZONE`;
    let requestUrl = `${this.OrderFormServiceConst.customerLes}/${customerLegalId}?product=IZOMCC`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }

  getReviewDetails(quoteId, successFn, errorFn) {
    return this.httpService.getHttp(this.OrderFormServiceConst.reviewConfig + '/' + quoteId, successFn, errorFn);
  }

  getAttributes(successFn, errorFn) {
    let requestUrl = `${this.commonValidationService.transformURL(this.OrderFormServiceConst.omsIpcQuoteLes)}/attributes`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }

  /** Izone Attributes */
  getIzoneAttributes(successFn, errorFn) {
    let requestUrl = `${this.commonValidationService.transformURL(this.OrderFormServiceConst.getIzomccAttributes)}/attributes`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }

  public postStoreData(quoteParams, params, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.omsIzomccQuotes}/${quoteParams.quoteId}/le/${quoteParams.quoteLeId}/attributes`;
    return this.httpService.postHttp(requestUrl, params, successFn, errorFn);
  }

  public postSalesOrder(quoteParams, params, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.omsGof}/${quoteParams.quoteId}/le/${quoteParams.quoteLeId}/contractentity`;
    return this.httpService.postHttp(requestUrl, params, successFn, errorFn);
  }

  // public postSalesOrderForIzomcc(quoteParams, params, successFn, errorFn) {
  //   let requestUrl = `${this.OrderFormServiceConst.izoneGof}/${quoteParams.quoteId}/le/${quoteParams.quoteLeId}/contractentity`;
  //   return this.httpService.postHttp(requestUrl, params, successFn, errorFn);
  // }

  getDocumentsIzomcc(legalEntityId, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.customerLes}/${legalEntityId}/attachments?productName=IZONE`;
    return this.httpService.getHttp(requestUrl, successFn, errorFn);
  }

  updateQuoteStage(quoteId, quoteLeId, quoteStage, successFn, errorFn) {
    return this.httpService.postHttp(this.OrderFormServiceConst.quoteStageUpdate + '/' + quoteId + '/le/' + quoteLeId + '/stage' + '/' + quoteStage.stage, quoteStage, successFn, errorFn)
  }

  public postSalesOrderForIzomcc(quoteParams, params, successFn, errorFn) {

    let requestUrl = `${this.OrderFormServiceConst.omsIzomccQuotes}/${quoteParams.quoteId}/le/${quoteParams.quoteLeId}/contractentity`;
    return this.httpService.postHttp(requestUrl, params, successFn, errorFn);
  }

  generateIzomccPDF(successFn, errorFn, quoteId, quoteLeId, nat) {
    let requestUrl = `${this.OrderFormServiceConst.omsIzomccQuotes}/${quoteId}/le/${quoteLeId}/cofpdf?nat=${nat}`;
    return this.httpService.getHttpPDF(requestUrl, successFn, errorFn);
  }

  cofNotificationForIzomcc(quoteId, quoteLeId, successFn, errorFn) {
    return this.httpService.getHttp(this.OrderFormServiceConst.omsIzomccQuotes + '/' + quoteId + '/le/' + quoteLeId + '/cofdownload/accountmanagernotification', successFn, errorFn);
  }

  validateCofDataIzomcc(quoteId, quoteLeId, successFn, errorFn) {
    let requestUrl = `${this.OrderFormServiceConst.omsIzomccQuotes}/${quoteId}/le/${quoteLeId}/cof/validate`;
    console.log(requestUrl)
    return this.httpService.postHttp(requestUrl, {}, successFn, errorFn)
  }

  getCreditCheckStatus(quoteId, quoteleId, successFn, errorFn) {
    return this.httpService.getHttp(this.OrderFormServiceConst.creditCheckStatus + quoteId + '/le/' + quoteleId + '/status', successFn, errorFn);

  }


  postnewCreditCheckStatus(quoteId, quoteleId, successFn, errorFn) {
    return this.httpService.postHttp(this.OrderFormServiceConst.creditCheckStatus  + quoteId + '/synccreditcheckstatus', {}, successFn, errorFn);

  }

  postOnAgree(quoteId,data,successFn, errorFn){
    return this.httpService.postHttp(this.OrderFormServiceConst.omsIzomccQuotes + '/' + quoteId +'/approve/' +'quotes',data,successFn, errorFn)
 }

 postDelegates(quoteId,quoteleId,data, successFn, errorFn) {
  return this.httpService.postHttp(this.OrderFormServiceConst.omsIzomccQuotes + '/' + quoteId +'/le/' + quoteleId +'/delegate/notification',data,successFn, errorFn)

}

 /** Order Enrichment */
  getOrderList(orderId, successFn, errorFn) {
    this.httpService.getHttp(this.OrderFormServiceConst.orderList + orderId, successFn, errorFn);
  }
  getProvisionTeamDetails(successFn, errorFn) {
    return this.httpService.getHttp(this.OrderFormServiceConst.provisionTeam, successFn, errorFn);
  }
  checkIntity(successFn, errorFn, postObj) {
    this.httpService.postHttp(this.OrderFormServiceConst.checkLegal, postObj, successFn, errorFn);
}

getCOFPdf(url: string, successFn, errorFn) {
  return this.httpService.getPDFFromObjectStorage(url, successFn, errorFn);
}

cofDownloadIzomcc(successFn, errorFn, orderId, orderLeId) {
  this.httpService.downloadCofDocument(this.OrderFormServiceConst.izomccCofDownload + orderId + '/le/' + orderLeId + '/cofpdf', successFn, errorFn);
}


}
