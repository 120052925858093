import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sitewiseBillingStatePipe'
})
export class SitewiseBillingStatePipe implements PipeTransform {

  transform(billingArray,state,productName) {
    if(billingArray.length>0){
     if(state.toUpperCase()=='ALL'){
         return billingArray;
     }
    else{
        if(productName=='IAS' || productName=='GVPN'){
          return billingArray.filter(billing=>billing.addressInfo['userAddress']['state'].toLowerCase()==state.toLowerCase());
        }
        else{
          let matchingState=[];
          billingArray.forEach(link => {
            link.sites.forEach(site => {
              if(site.addressInfo['userAddress']['state'].toLowerCase()==state.toLowerCase()){
                matchingState.push(link);
              }
            });
          });
          return matchingState;
        }
    }

  }
}

}