import { Component, OnInit, Input, ViewChild, TemplateRef, ViewEncapsulation } from '@angular/core';
import { BsModalService } from "ngx-bootstrap/modal";
import { DataSharedSvc } from '../../../data-shared-svc';

@Component({
    selector: '[loaderModal]',
    templateUrl: './loader-modal.component.html',
    styleUrls: ['./loader-modal.component.css']
})

export class LoaderModalComponent implements OnInit {
    constructor(private bsModalService: BsModalService,private dataSharedSvc: DataSharedSvc) {

    }
    @Input('loaderModal') loaderSettings: any;
    @ViewChild("loaderModal") loaderModal: TemplateRef<any>;
    msgModalData: any = {
        message: "abc",
        modalRef: []
    }

    closeModal() {
        this.loaderSettings.close();
    }
    config: any = {
        backdrop: true,
        ignoreBackdropClick: true,
        keyboard: false,
        class: "modal-sm modal-dialog-centered"
    }
    isMobileView : any = false;
    ngOnInit() {
        this.dataSharedSvc.sharedData.subscribe((data : any) => {
            this.isMobileView = data['isMobileView'];
        }) 

        this.loaderSettings.open = (data) => {
            this.msgModalData.message = data.message;
            this.config.class = this.isMobileView ? 'modal-sm modal-dialog-centered tcl-loader-modal' : 'modal-sm modal-dialog-centered';
            this.msgModalData.modalRef.push(this.bsModalService.show(this.loaderModal, this.config));

        }

        this.loaderSettings.close = () => {
            //this.msgModalData.modalRef.hide();
            this.msgModalData.modalRef.forEach(ref => {
                ref.hide();
            });
            this.msgModalData.modalRef = [];
            
            /***Remove open modal fix****/
            // var modals: any = document.querySelectorAll('.modal');
            // var otherModalFound = false;
            // if (modals.length) {
            //     modals.forEach(e => {
            //         if (e.getElementsByClassName('loader-modal-ex').length) {
            //             //e.outerHTML = "";
            //             e.remove();
            //         } else {
            //             otherModalFound = true;
            //         }
            //     });

            //     if (!otherModalFound) {
            //         if (document.getElementsByClassName('modal-backdrop').length) {
            //            // document.getElementsByClassName('modal-backdrop')[0].remove();
            //         }
            //     }

            // }
            /***end****/
        }
    }
}