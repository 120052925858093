<!--Step wizard -->
<div class="step-wizard d-flex justify-content-center" *ngIf="showWidget">
    <div class="step-wizard-row">
        <div class="steps-row d-flex flex-row ">
            <div id="dynamic" [ngClass]="{'current': i === activeIndex}" class="step-item d-flex flex-row"
                *ngFor="let title of componentLabels; let i = index;">
                <div style="min-width:90px" class="step-number-content">
                    <div class="step-label">
                        <span>{{title.Name}}</span>
                    </div>
                    <div (click)="expandComponent($event)" class="step-number">{{title.Step}}</div>
                </div>
                <ul class="nested-steps d-flex flex-row align-items-end" *ngIf="activateClass != undefined">
                    <li class="subList" [ngClass]="{'active': i === activateClass}"
                        *ngFor="let category of title.SubCategory; let i=index">
                        <span class="sub-label">{{category}}</span>
                        <span class="steps-bullets"></span>
                    </li>
                </ul>
            </div>
        </div>
    </div>
</div>