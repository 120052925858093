import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'orderFrom'
})
export class OrderFromPipe implements PipeTransform {
  retrivedValue: {};
  constructor() {}
  transform(retrivedValue: any, input:any) {
   let temp;

     for (let key in retrivedValue) {
      
       if(retrivedValue[key].offeringName===input){
          temp = retrivedValue[key];
        }
      }
    
    return temp;
  }

}
