<main>
  <section style="margin: 0px 51px">
    <section class="container-header">
      <div class="row">
        <div class="col-sm-12 align-self-start">
          <div
            style="display: flex; justify-content: space-between; width: 91%"
          >
            <h2>
              Review and Finalise Order-Form
              <a
                (click)="routeBack()"
                class="back-arrow cursor-pointer"
                tooltip="Click Here Go To Sales Form"
              >
                <i class="icon icon-back"></i>
              </a>
            </h2>
            <button class="refresh-credit-check" (click)="submit()">
              Submit
            </button>
          </div>
          <p>
            Please ensure you open and read through the Terms and Conditions
            associated with this order and indicate your agreement by clicking
            on the checkbox. <br />
            Please sign-off on order or delegate for signature once you have
            agreed to the Terms and Conditions and reviewed all the details in
            the order form.
          </p>
        </div>
      </div>
    </section>

    <section
      class="order-container"
      [ngClass]="showloader ? 'loadingPDF' : 'order-container'"
    >
      <div class="row" style="margin-right: -30px; margin-left: -29px">
        <div class="col col-12">
          <div class="card ct-card min-height-tab">
            <div
              class="card-header d-flex justify-content-around align-items-center pr-0 pl-0"
            >
              <div class="custom-tabs w-100 cursor-pointer">
                <nav>
                  <div
                    class="nav nav-tabs custom-tabs text-uppercase text-center pr-0"
                    id="Tab"
                    role="tablist"
                  >
                    <a
                      class="nav-item nav-link"
                      [ngClass]="{ active: showingTab == 'solution' }"
                      (click)="checkPlaceOrderEnabled('solution')"
                    >
                      <span placement="right" [tooltip]="popTooltip"
                        >Terms & <br />
                        Conditions</span
                      >
                    </a>

                    <a
                      class="nav-item nav-link"
                      id="total-charges-tab"
                      role="tab"
                      aria-controls="total-charges"
                      aria-selected="false"
                      (click)="
                        showingTab = 'charges';
                        checkPlaceOrderEnabled('charges')
                      "
                      [ngClass]="{ active: showingTab == 'charges' }"
                      >Total <br />Charges</a
                    >

                    <a
                      class="nav-item nav-link"
                      id="billing-tab"
                      role="tab"
                      aria-controls="billing-payment"
                      aria-selected="false"
                      (click)="
                        showingTab = 'payments';
                        checkPlaceOrderEnabled('payments')
                      "
                      [ngClass]="{ active: showingTab == 'payments' }"
                      >Billing/ <br />Payments
                    </a>

                    <a
                      class="nav-item nav-link"
                      id="customer-info-tab"
                      role="tab"
                      (click)="checkPlaceOrderEnabled('customer')"
                      [ngClass]="{ active: showingTab == 'customer' }"
                      aria-controls="customer-info"
                      aria-selected="false"
                      >contracting <br />
                      info</a
                    >

                    <a
                      class="nav-item nav-link"
                      id="conditions-tab"
                      role="tab"
                      aria-controls="conditions"
                      aria-selected="false"
                      [ngClass]="{ active: showingTab == 'terms' }"
                      (click)="checkPlaceOrderEnabled('terms')"
                      >Order

                      <br />Summary
                    </a>
                  </div>
                </nav>
              </div>
            </div>

            <div class="credit_message">
              <p
                [ngClass]="
                  !creditCheck.creditCheckStatusFlag ? 'success' : 'error'
                "
                *ngIf="creditCheck.creditCheckStatus"
              >
                {{ creditCheck.creditCheckStatus }}
              </p>
              <p class="error" *ngIf="creditCheck.creditCheckError">
                {{ creditCheck.creditCheckError }}
              </p>
              <p class="error" *ngIf="isPoMandatoryOrder">
                Thank you for generating Customer Order Form. Your organization
                has mandated us to process the delivery upon receiving a valid
                Purchase Order. Request you to kindly submit a purchase order
                under the manage PO section.
              </p>
            </div>

            <div class="card-body">
              <div class="tab-content customNormalstyle" id="myTabContent">
                <!---------------------------------------Solution Details-------------------------------------------->
                <div
                  class="customScroller tab-pane fade text-justify active show"
                  *ngIf="showingTab == 'solution'"
                  id="solution-details"
                  role="tabpanel"
                  aria-labelledby="solution-details"
                  style="overflow-y: scroll; max-height: 500px"
                >
                  <div>
                    <div class="col-xs-12 text-center">
                      <h5 class="m-t-10 font-bold">
                        GENERAL TERMS AND CONDITIONS —IZO™ Multi Cloud Connect
                        Flex
                      </h5>
                    </div>

                    <p>
                      These IZO™ Multi Cloud Connect Flex General Terms and
                      Conditions(“Agreement” or “Contract Terms”) govern your
                      access to and use of the corresponding Services (as
                      defined below) and form a binding agreement between the
                      Tata Communications contracting party specified in the
                      invoices issued to you for a corresponding Service (also
                      referred to as “Tata Communications,” “we,” “us,” or
                      “our”) and you or the entity you represent (also referred
                      to as “Customer”, “you,” or “your”). You and we may be
                      identified individually as a “Party,” or together as
                      “Parties” under this Agreement.
                    </p>

                    <p>
                      This Agreement takes effect on the earlier to occur of the
                      time when you click an “I Accept” button, check box, or
                      similar item presented with this Agreement or the date on
                      which you use any of the Services (the “Effective Date”).
                      You represent to us that you are lawfully able to enter
                      into contracts (e.g., you are not a minor). If you are
                      entering into this Agreement for an entity, such as the
                      company you work for, you represent to us that you have
                      legal authority to bind that entity.
                    </p>

                    <ol>
                      <li>
                        <strong> <b>1.</b> Use of the Services</strong>
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>1.1 Generally. </b> </label> You may
                            access and use the Services in accordance with this
                            Agreement. Service level agreements and additional
                            contract terms apply to certain Services. You will
                            comply with the terms of this Agreement and all
                            laws, rules, and regulations applicable to your use
                            of the Services.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>1.2 Your Account. </b> </label> To access
                            the Services, you must have a Tata Communications
                            account associated with a valid email address and a
                            valid form of payment. Unless explicitly permitted
                            by the Contract Terms, you will only create one
                            account per email address.
                          </li>
                        </ol>
                      </li>

                      <li style="padding-top: 10px">
                        <strong>2. Changes.</strong>
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>2.1 </b> </label> We may change or
                            discontinue any Service from time to time for any
                            reason. We will deliver at least ninety (90)
                            days’prior notice if we discontinue material
                            functionality of a Service that you are using,
                            except that notice will not be required if such
                            notice period (a) poses a security or intellectual
                            property issue to us or the Services, (b) is
                            economically or technically burdensome, or (c) would
                            cause us to violate any applicable law, rule,
                            regulation, or contractual requirement. We may
                            change, discontinue, or add Service level agreements
                            from time to time in accordance with the Contract
                            Terms.
                          </li>
                        </ol>
                      </li>

                      <li style="padding-top: 10px">
                        <strong>3. Your Responsibilities.</strong>

                        <ol>
                          <li style="padding-top: 14px">
                            We may, at our option, suspend or terminate a
                            Service or this Agreement if you or an End User
                            fails to comply with any of the following
                            obligations.
                          </li>
                          <li style="padding-top: 14px">
                            <label><b>3.1 Your Accounts. </b> </label> (a) You
                            are responsible for all activities that occur under
                            your account, regardless of whether the activities
                            are authorized by you or undertaken by you, your
                            employees or a third party (including your
                            contractors, agents, or End Users).(b) Neither we
                            nor our Affiliates are responsible for unauthorized
                            access to your account.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>3.2 Your Content</b> </label> You will
                            ensure that neither Your Content nor the Services
                            will violate any applicable law. You are solely
                            responsible for the development, content, operation,
                            maintenance, and use of Your Content.
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b>3.3 Your Security and Backup.</b>
                            </label>
                            You are responsible for properly configuring and
                            using the Services and otherwise taking appropriate
                            action to secure, protect and backup your accounts
                            and Your Content in a manner that will provide
                            appropriate security and protection, which might
                            include use of encryption to protect Your Content
                            from unauthorized access and routinely archiving
                            Your Content.
                          </li>

                          <li style="padding-top: 14px">
                            <label
                              ><b>3.4 Log-In Credentials and Account Keys.</b>
                            </label>
                            Log-in credentials and private keys generated during
                            your purchase or use of the Services are for your
                            internal use only and you will not sell, transfer,
                            or sublicense them to any other entity or person,
                            except that you may disclose your private key to
                            your agents and subcontractors performing work on
                            your behalf.
                          </li>

                          <li style="padding-top: 14px">
                            <label><b>3.5 End Users.</b> </label> You will be
                            deemed to have taken any action that you facilitate,
                            assist, or permit any person or entity to take in
                            connection with this Agreement, Your Content or use
                            of the Services. You are responsible for use of Your
                            Content and the Services by End Users. You will
                            ensure that all End Users comply with your
                            obligations under this Agreement and that the terms
                            of your agreement with each End User are consistent
                            with this Agreement. If you become aware of any
                            violation of your obligations under this Agreement
                            caused by an End User, you will immediately suspend
                            access to the Services by such End User. We do not
                            provide any support or services to End Users unless
                            we have a separate agreement with you or an End User
                            obligating us to provide such support or services.
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b>3.6 Fraudulent Use of Services.</b>
                            </label>
                            You are solely responsible for all Service Fees
                            and/or any other charges incurred in connection with
                            the Services, whether incurred by yourself, your End
                            Users or third parties, even if such Service Fees
                            and/or other charges were incurred through or as a
                            result of fraudulent or unauthorized use of the
                            Services.
                          </li>

                          <li style="padding-top: 14px">
                            <label><b> 3.7 Misuse of the Services.</b> </label>
                            You are prohibited from marketing and/or re-branding
                            the Services purchased by you as your own product
                            and services or to resell the Services to third
                            parties. You will not use the Services nor allow the
                            Services to be used to transmit, distribute or store
                            contents or messages (including e-mail messages)
                            which are illegal, potentially harmful (including
                            but not limited to viruses, worms, password-cracking
                            programs or trojan horses); or fraudulent or
                            misleading (including but not limited to false,
                            deceptive, or misleading statements, claims, or
                            representations), as determined by us in our sole
                            reasonable discretion, or to transmit or distribute
                            unsolicited e-mail messages where such e-mail
                            messages could reasonably be expected to provoke
                            complaints (e.g., SPAM). You acknowledge and agree
                            that we do not monitor and will have no liability or
                            responsibility for the content of any communications
                            transmitted via the Services. We may, at our own
                            option, suspend or terminate the Services if
                            Customer or End Users do not comply with this
                            Section.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>3.8 Acceptable Use.</b> </label> Your use
                            of the Services shall always comply with our
                            then-current Acceptable Use Policy ("AUP”) and
                            then-current Privacy Policy (“Privacy Policy”), as
                            amended by us from time to time and which are
                            available on the Tata Communications webpage. You
                            hereby expressly acknowledge that you have reviewed,
                            understand, and accept our AUP and Privacy Policy.
                          </li>

                          <li style="padding-top: 14px">
                            <label><b>3.9 Your Network Security.</b> </label>
                            You are responsible for maintaining the security of
                            your internal network from unauthorized access
                            through the Internet. We shall not be liable for
                            unauthorized access to your network or other
                            breaches of your network security.
                          </li>

                          <li style="padding-top: 14px">
                            <label><b>3.10 Third Party Products.</b> </label> In
                            the event that we provide third-party hardware or
                            software products to you on a pass-through basis,
                            and to the extent that such hardware or software is
                            not provided as part of a Service, we make no
                            representations or warranties as to any third-party
                            hardware or software provided to you, all of which
                            is transferred to you on an “AS IS” basis and
                            subject to any third-party terms and conditions. You
                            will look solely to the warranties and remedies
                            provided by the equipment manufacturer and
                            third-party licensor, if any.
                          </li>

                          <li style="padding-top: 14px">
                            <label
                              ><b
                                >3.11 Fulfilment of Preconditions for Service
                                Delivery.</b
                              >
                            </label>
                            Notwithstanding anything to the contrary stated in
                            this Agreement, you agree that you and/or End Users
                            might need to fulfill certain prerequisites to use
                            the corresponding Service ("Service Prerequisites”).
                            We will inform you of Service Prerequisites before
                            your payment of the Service Fees for such Service.
                            Where Service Prerequisites have been so indicated,
                            you agree to the following –

                            <ol type="i">
                              <li style="padding-bottom: 5px">
                                <b>a.</b> You must fulfill the Service
                                Prerequisites before using the corresponding
                                Service.
                              </li>
                              <li style="padding-bottom: 5px">
                                <b>b.</b> You agree that payment of the Service
                                Fee for a corresponding Service is not
                                sufficient to satisfy any applicable Service
                                Prerequisite or permit you to use the applicable
                                Service
                              </li>
                              <li style="padding-bottom: 5px">
                                <b>c.</b> The Service Term shall commence on the
                                Service Commencement Date, irrespective of your
                                fulfillment of the corresponding Service
                                Prerequisites.
                              </li>
                              <li style="padding-bottom: 5px">
                                <b>d.</b> You will not claim any refunds of
                                Service Fees or extensions of the Service Term
                                where you were unable to use the Service after
                                the Service Commencement Date solely due to your
                                act or omission, including your failure to
                                timely satisfy the Service Prerequisites.
                              </li>
                            </ol>
                          </li>
                        </ol>
                      </li>

                      <li style="padding-top: 10px">
                        <strong>4. SERVICES.</strong>
                        <ol>
                          <li style="padding-top: 14px">
                            <label>
                              <b>4.1 Customer Order Form Acceptance.</b>
                            </label>
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>(a) General.</b> </label> You may submit
                            one or more COFs requesting delivery of Services
                            which we may accept upon the earliest to occur of
                            the following:(i) sending a Welcome Letter
                            acknowledging receipt and acceptance of the COF;
                            (ii) executing the COF in accordance with the terms
                            of this Agreement; or (iii) provisioning the
                            Services and sending an In-Service Notification to
                            you. Tata Communications shall be contractually
                            bound in respect of a particular COF at (but not
                            before) the occurrence of either event specified
                            above. For the avoidance of doubt, you shall not be
                            obligated to submit, nor shall we be obligated to
                            accept, any COF.
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b>(b) Electronic Signature Acceptance.</b>
                            </label>
                            The Parties expressly acknowledge and agree: (i) a
                            human readable electronic version of this Agreement
                            or a COF containing each Party’s electronic
                            signature, or containing a mix of physical
                            signatures and electronic signatures of the Parties,
                            shall constitute an original version of this
                            Agreement or COF, as applicable; (ii) a Party’s use
                            of a key pad, mouse or other device to select an
                            item, button, icon or similar act/action, to
                            otherwise insert their electronic signature into
                            this Agreement or COF constitutes that Party’s
                            signature as if it had manually signed the same;
                            (iii) the electronic signature platform of Tata
                            Communications or its designated third party
                            constitutes a valid authentication technology; (iv)
                            no certification authority or other third party
                            verification is necessary to validate each Party’s
                            electronic signature; and (v) the lack of such
                            certification or verification will not in any way
                            affect the validity of that Party’s signature or the
                            enforceability of this Agreement or any COF, as
                            applicable.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>(c) Affiliates.</b> </label> You and your
                            Affiliates may purchase Services pursuant to this
                            Agreement, provided that an authorized
                            representative of your Affiliate submits a COF in
                            accordance with this Agreement. The terms of this
                            Agreement shall apply to any such COF as if the
                            relevant Affiliate had executed this Agreement. You
                            shall be the customer of record for all Services
                            provided hereunder and, together with any ordering
                            Affiliate, shall be jointly and severally liable for
                            all obligations set forth in this Agreement,
                            notwithstanding that your Affiliate might execute an
                            applicable COF or that the invoices for a Service
                            might be sent to your Affiliate at the address
                            designated in the applicable COF. If one of your
                            Affiliates enters into a COF pursuant to this
                            Agreement, then references to “you” herein shall be
                            deemed references to the Affiliate.
                          </li>
                        </ol>
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>4.2 Equipment.</b> </label>
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>(a) General.</b> </label> If requested by
                            you, we have the option and sole discretion to
                            install, for an additional charge to you, certain
                            communications equipment in connection with any
                            Service which we deliver to you or third parties
                            with whom you have a contractual relationship (such
                            equipment, <b>“Customer Equipment”</b>). You must
                            ensure that any Customer Equipment is connected and
                            used in accordance with any instructions, safety
                            procedures, and security procedures applying to the
                            use of that equipment. You must also ensure that any
                            Customer Equipment is technically compatible with
                            the Service and approved for that purpose under
                            applicable law or regulation.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>(b)</b> </label> Tata Communications,
                            including any Affiliate, licensor, or designated
                            third party vendor, also may provide equipment for
                            use at your premises as part of any Services (“Tata
                            Communications-Provided CPE”). Any Tata
                            Communications-Provided CPE shall be identified in
                            the applicable COF, together with associated pricing
                            and shipping information. Notwithstanding anything
                            herein to the contrary, the rights and obligations
                            of the parties in respect of the software associated
                            with the Tata Communications-Provided CPE shall be
                            governed by Section 10.4 of this Agreement. The
                            other rights and obligations of the Parties with
                            respect of the Tata Communications-Provided CPE
                            shall be governed by Section 5.1 of this Agreement.
                          </li>
                        </ol>
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>4.3 Your Premises.</b> </label> You will
                            allow us access to and use of your premises to the
                            extent required by us for the installation,
                            connection, inspection and scheduled or emergency
                            maintenance or removal of the Facilities relating to
                            the Services. We shall have a right to inspect any
                            of your premises to ensure that you and/or any End
                            User is complying with all applicable laws, rules
                            and regulations regarding the Services and use of
                            the Facilities. Any such inspection or
                            non-inspection, however, shall not relieve you of
                            any of its duties under this Agreement nor shall it
                            cause us to waive any of its rights hereunder or
                            impose any duty, obligation, or liability onto us.
                            You represent to us that you have obtained or will
                            obtain on a timely basis all permissions and
                            consents from third parties necessary to allow us
                            access as set forth herein. You will be responsible
                            for providing and maintaining at your own expense
                            power, heating, ventilation, and air conditioning as
                            necessary to maintain the proper environment for the
                            Facilities on your premises. You will provide, and
                            will ensure that we are provided with, a safe place
                            to work and comply with all laws and regulations
                            regarding the working conditions on your premises.
                            In the event that you fail to meet your obligations
                            regarding your premises hereunder and, as a result,
                            we are unable to install or continue the delivery of
                            Services, then notwithstanding the absence of
                            Services, you will pay all Service Fees for such
                            Services during such period of time. You will
                            provide your correct premises contact details and
                            addresses for installation and provision of the
                            Service in the applicable COF. We will not be liable
                            for any delays in the installation or provision of
                            Service resulting from your error, discrepancy,
                            change, or relocation relating to your premises. To
                            the extent you change the supplied premises contact
                            details, addresses or specifications, you will
                            communicate such changes in the format prescribed by
                            us with our prior written consent (not to be
                            unreasonably withheld).
                          </li>
                        </ol>
                        <ol>
                          <li style="padding-top: 14px">
                            <label>
                              <b>4.4 Services  Provided  by Our Affiliates.</b>
                            </label>
                            If  a  COF  provides  for  the  provision  of
                            Services in a jurisdiction other than a jurisdiction
                            within which we are authorized to  provide
                            services,  such  Services  may  be  provided  to
                            you  and/or  to  your  End User  by one  or  more
                            of  our Affiliates and  we  may  coordinate  or
                            manage  that Affiliate’s Services in that
                            jurisdiction. In certain jurisdictions where our
                            Affiliate is  providing  the  Services,  then  the
                            relevant  Affiliate  has  the  right  to  require  a
                            letter of undertaking or similar document (“LOU”)
                            from you and/or your End User in  a  format
                            prescribed  by  our  Affiliate.  We  and  our
                            Affiliate  shall  have  no obligation to provide any
                            Services until it receives the LOU. If a COF
                            requires the delivery  of  Services  in  a
                            jurisdiction  where,  in  order  for  such COF  to
                            be enforceable, additional terms must be added, the
                            Parties shall incorporate such additional  terms in
                            the  COF  (preserving  to  the  fullest practicable
                            extent  this Agreement).
                          </li>
                        </ol>

                        <ol>
                          <li style="padding-top: 14px">
                            <label>
                              <b>4.5 Services Provided by Third Parties.</b>
                            </label>
                            The  Services  may  be  provided  in conjunction
                            with other foreign-end administrations, underlying
                            or interconnecting third party carriers, local loop
                            providers or any other authorized providers
                            (collectively or individually “Third  Party Service
                            Providers”). Our obligations  under  this Agreement
                            do  not  apply,  unless  otherwise expressly
                            specified as part of a COF, to the lines,
                            facilities, or services provided by any Third Party
                            Service Provider.
                          </li>
                        </ol>
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>4.6 Acceptance of Services.</b> </label>
                            Unless  otherwise  stated  in  the  applicable
                            Service Schedule   or   COF,   the   Services
                            shall   be   accepted  or   deemed  accepted  in
                            accordance with the following procedure: (i) upon
                            your receipt of an In-Service Notification for a
                            Service, you will have two (2) business days to test
                            the Service and notify us in writing of your
                            acceptance or rejection of the Service; (ii) you may
                            reject a Service only on the basis that the agreed
                            technical specifications, as set forth in the COF
                            and/or the applicable Service Schedule, have not
                            been met; and (iii) if you notify us of your
                            rejection, we shall remedy the deficiency and a new
                            In-Service  Notification  will  be  delivered  to
                            you  and  the  procedures  set  forth  in this
                            Section 4.6 will be repeated. Your failure to notify
                            us of your acceptance or rejection  of  the
                            Services  within  the  foregoing  time  period will
                            be  deemed  to constitute your acceptance of such
                            Services.
                          </li>
                        </ol>
                      </li>

                      <li style="padding-top: 10px">
                        <strong>5. Ownership.</strong>
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>5.1 Equipment.</b> </label>
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b>(a) Outright Sale of Hardware.</b>
                            </label>
                            If  you purchase the  Tata  Communications-Provided
                            CPE  from  us  pursuant  to a COF,  then  title and
                            risk  for  that  Tata Communications-Provided CPE
                            shall transfer to you at FOB Point of Origination.
                            You must obtain all necessary import/export licenses
                            to enable the hardware to clear customs and enter
                            the country where you will maintain the hardware.
                            You must pay any and all duties, charges, and
                            applicable import taxes, including VAT and/or
                            withholding  taxes associated  with  the  relevant
                            Tata Communications-Provided CPE (<b
                              >“Import Duties”</b
                            >).
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b
                                >(b) Hardware
                                Provided as Part of the Services.</b
                              >
                            </label>
                            If we provide   Tata Communications-Provided CPE to
                            you as part of the Services, title and ownership
                            will  remain  with us, one  of  our Affiliates ,or
                            one  of  our designated  third  party vendors, as
                            applicable. As the owner of record, we, one of our
                            Affiliates, or one of  our designated  third  party
                            vendors, as  applicable, shall  (i)  be  the
                            licensor  of record; (ii) obtain all necessary
                            import/export licenses related to the hardware; and
                            (iii) be responsible for any Import Duties.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>(c)</b> </label> In respect of Section
                            5.1 (b) above, risk of loss in the Tata
                            Communications-Provided CPE shall transfer to you
                            upon delivery to your relevant premises and you
                            shall be required to pay us for the cost of
                            repairing or replacing damaged hardware (ordinary
                            wear and tear excepted). You must immediately inform
                            us if the hardware is damaged in any way on or after
                            delivery. You shall procure and maintain all risk
                            insurance against loss or damage to the hardware for
                            not less than the full replacement value of the
                            hardware. Upon termination of the relevant COF, you
                            shall return the hardware to us or our Affiliate or
                            third party vendor, as determined in our sole
                            discretion, at your cost.
                          </li>
                        </ol>
                        <ol>
                          <li style="padding-top: 14px">
                            <label>
                              <b>5.2 Facilities.</b> Except  as  otherwise
                              agreed  in  a  COF,  title  to  all  Facilities
                              shall remain with us. We will provide and maintain
                              the Facilities in good working order. We  shall
                              not,  and  shall  not  permit  others  to,
                              change,  rearrange,  disconnect, remove,  repair,
                              attempt  to  repair,  or  otherwise  tamper  with
                              any  Facilities, without our prior written
                              consent. We shall ensure that the Facilities shall
                              not be used for any purpose other than that for
                              which we provide them. We shall not take any
                              action that causes or permits the imposition or
                              maintenance of any lien or encumbrance on the
                              Facilities. We will not be liable to you or any
                              other person for interruption of service or for
                              any other loss, cost or damage caused or related
                              to improper use or maintenance of the Facilities
                              by you or third parties to which you provide
                              access to the Facilities.</label
                            >
                          </li>
                        </ol>
                      </li>

                      <li style="padding-top: 10px">
                        <strong>6. Fees and Payment.</strong>
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>6.1 Billing</b> </label>
                            We will commence invoicing for the Services on the Service
                            Commencement  Date  regardless  of  whether  you
                            are  otherwise  prepared  to accept delivery of the
                            Services. Service Fees shall be payable monthly in
                            advance, except for Service Fees which are dependent
                            on use, which shall be invoiced in arrears. Billing
                            for  partial  months  will  be  prorated  based  on
                            a  calendar  month. You will pay all amounts due
                            within thirty (30) days from the date of invoice to
                            the bank account designated by us from time to time.
                            Unless otherwise specified in the COF, all amounts
                            shall be invoiced and paid in U.S. Dollars. Any
                            amount due but not received by us will accrue
                            interest at the Interest Rate from the due date to
                            the date of actual payment. We shall have the right
                            to set off any amounts due hereunder  which  are
                            not  paid  when  due  against  any  amounts  owed
                            to  you  or your  Affiliates  by  us  or  our
                            Affiliates  pursuant  to  this  or  any  other
                            agreement between the Parties. Notwithstanding
                            anything to the contrary, we shall have the right
                            to  assign  our  receivables  and/or  the  right to
                            receive  payments  (including any  applicable
                            interest  thereof)  for  invoices  raised  in
                            relation  to  the  Services under  this  Agreement,
                            to  a  bank  or  any  other  accredited  financial
                            institution, provided  that  such  assignment shall
                            in  no  way  delegate,  limit  or  derogate our
                            obligations with respect to the Services under this
                            Agreement.
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b>6.2 Credit Approval and Deposits.</b>
                            </label>
                            We reserve the right to conduct a credit check on
                            you at any time in connection with our delivery of
                            Services. You authorize us to obtain, and upon
                            request shall assist us in obtaining, information
                            about your financial condition from third parties,
                            including without limitation banks, credit reporting
                            agencies and other businesses that provide like
                            information. Upon our request, you will make a
                            deposit or provide other security for the payment of
                            Service Fees or any other charges, as specified by
                            us, (i) as a condition to our acceptance of any
                            Service that is requisitioned by you through the
                            Tata Communications Site, or (ii)in the event
                            Customer fails to comply with the payment terms set
                            forth in this Agreement twice in any twelve (12)
                            month period, as a condition to our continuation of
                            delivery of any Services. We will hold the deposit
                            or other security as security for payment of the
                            Service Fees. When the provision of Services to you
                            is properly terminated in accordance with this
                            Agreement, the amount of the deposit will be
                            credited to your account and any remaining credit
                            balance will be refunded to you within thirty (30)
                            days of such termination. You acknowledge and agree
                            that any failure by you to comply with any request
                            made by us under this section shall constitute a
                            material breach of this Agreement.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>6.3 Credit Limit.</b> </label> The
                            provision of Services to you may be subject to a
                            credit limit (the <b>“Credit Limit”</b>) as may be
                            specified in the COF and established by us in our
                            sole reasonable discretion. We shall have the right
                            at any time to increase or decrease the Credit Limit
                            by giving at least ten (10) calendar days’ prior
                            written notice to you. If at any time we determine
                            that the sum (the "Accrued Liability") of (i) total
                            invoiced amounts which remain unpaid, plus (ii) the
                            unbilled but accrued usage of the Services, has
                            exceeded the then current Credit Limit, we shall
                            have the right to demand by written notice that you
                            make an immediate payment to us by electronic
                            transfer (or such other method as agreed by the
                            parties) of such amount required: (i) to reduce its
                            aggregate Accrued Liability to less than the Credit
                            Limit, and (ii) to ensure that the Credit Limit
                            shall not be exceeded prior to the next invoice due
                            date. Upon such a demand, the demanded amount shall
                            become immediately due and payable, and you will pay
                            the amount within twenty-four (24) hours of your
                            receipt of such notice. If you fail to remit such
                            payment when due, we shall have the right without
                            further notice to immediately suspend and/or
                            terminate the Services and/or this Agreement.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>6.4 Disputed Invoices.</b> </label> In
                            the event you dispute in good faith any portion of
                            our invoice, you must pay the undisputed portion of
                            the invoice and submit a written claim  for  the
                            disputed  amount,  together  with  all  information
                            relevant  to  the dispute (including account
                            numbers, circuit IDs and the reason for the
                            dispute). All disputes must be submitted to us
                            within forty-five (45) days of receipt of the first
                            invoice for the applicable charges. You acknowledge
                            that it is reasonable for us to require you  to
                            dispute charges  within that  time, and you
                            therefore waive the  right  to  dispute  any
                            charges  not  disputed  within  the  time  frame
                            set  forth above.
                          </li>
                        </ol>
                      </li>

                      <li style="padding-top: 10px">
                        <strong>7. Taxes & Regulatory Fees.</strong>
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>7.1 </b> </label> All Service Fees are
                            exclusive of applicable taxes. Except for taxes
                            based on our net income, you will be responsible for
                            payment of all applicable VAT, GST, consumption,
                            sales, use, excise, access, bypass, franchise,
                            regulatory or other like taxes, fees, charges, or
                            surcharges, whether now or hereafter enacted,
                            however designated, imposed on or based on the
                            provision, sale, or use of the Services (hereafter
                            "Taxes"). We may invoice you, and you will reimburse
                            us, for any regulatory fees, assessments, or
                            contributions (collectively,
                            <b>“Regulatory Fees”</b>) charged to us by any
                            Governmental Authority in connection with Services
                            provided hereunder. To the extent you are or believe
                            you are exempt from payment of certain Taxes or
                            Regulatory Fees, you will provide us with a copy of
                            a valid exemption certificate. We will give effect
                            to all valid exemption certificates in the next full
                            billing cycle following receipt of the certificate
                            from you, but only to the extent we are permitted to
                            do so under applicable laws. Notwithstanding the
                            foregoing, in the event that your exemption
                            certificate is or becomes invalid during an
                            applicable Service Term, and we are assessed or held
                            responsible for additional Taxes, Regulatory Fees,
                            penalties or late charges, you will be responsible
                            for all such amounts in accordance with this Section
                            7.1
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>7.2 </b> </label> If you are or were
                            required by law to make any deduction or withholding
                            from any payment due hereunder to us, then
                            notwithstanding anything to the contrary contained
                            herein, the gross amount payable by you to us will
                            be increased so that, after any such deduction or
                            withholding for Taxes, the net amount received by us
                            will not be less than what we would have received
                            had no such deduction or withholding been required.
                            If any taxing or Governmental Authority asserts that
                            you should have made a deduction or withholding for
                            or on account of any Taxes with respect to all or a
                            portion of any payments made hereunder, or that we
                            should have collected certain Taxes from you which
                            we did not collect, you hereby agree to indemnify us
                            for such Taxes and hold us harmless on an after-tax
                            basis from and against any Taxes, interest or
                            penalties levied or asserted in connection
                            therewith.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>7.3 </b> </label>
                            The following additional provisions shall
                            be applicable where Services are provided by Tata
                            Communications entities in India –
                          </li>
                        </ol>
                        <ol>
                          <li style="padding-bottom: 7px">
                            <b>(i)</b> In case of any change in rate of Taxes or
                            basis of levy of Taxes or any other change which
                            results in a higher Tax rate being applicable to an
                            invoice already issued for goods or Services
                            provided by us, such additional Tax shall be borne
                            and payable by you.
                          </li>
                          <li style="padding-bottom: 7px">
                            <b>(ii)</b> You shall not upload or make any
                            amendment in relation to our invoice on the
                            Governmental Authority’s goods and services tax
                            network (GSTN) portal (or by any other name known in
                            future) without informing us in advance. Further,
                            where  such  changes  are  made  by you or  on your
                            behalf  on  the  GSTN  portal without prior
                            notification to us, and where we receive any
                            notification from the GSTN  portal  in  regard  to
                            such  changes,  we  reserve  the  right  to  reject
                            such changes or to refrain from acting upon such
                            changes on the GSTN portal.
                          </li>
                          <li style="padding-bottom: 7px">
                            <b>(iii)</b> We  will  raise  and  issue  a  valid
                            tax  invoice  and  receipt  voucher  along  with
                            other documents, strictly in accordance with the
                            details specified by you while subscribing for the
                            Service. You will provide accurate details of your
                            premises in accordance  with  the  requirements  of
                            applicable  goods  and  services  taxation laws,
                            failing  which  we  shall  proceed  to  issue  the
                            invoice  on  the  basis  of  your address available
                            to us.
                          </li>
                          <li style="padding-bottom: 7px">
                            <b>(iv)</b> In case of supply of Services, where the
                            place of supply is dependent on the registered
                            address provided by you, such registered address
                            would be treated as ‘location of service recipient’
                            on our records as required under applicable goods
                            and services taxation laws.
                          </li>
                          <li style="padding-bottom: 7px">
                            <b>(v)</b> In  case  any  taxation  claim, penalty,
                            credit  loss  or  related  claim  is  made against
                            us  on  account  of  your  failure  to provide  the
                            correct  address  and/or details in the COF while
                            subscribing for the Services, you will indemnify and
                            hold us  harmless  against  any  such claim,
                            penalty,  credit  loss, or  interest or other
                            incurred liability, as the case may be.
                            Additionally, if any cost is incurred by us
                            (including  but  not  limited  to  tax,  interest,
                            or  penalty)  due  to  non-compliance with
                            applicable goods and services taxation laws by you
                            or on your behalf, then you will indemnify and hold
                            us harmless for any such cost, tax, penalty,
                            interest, or other amount payable or paid by us.
                          </li>
                          <li style="padding-bottom: 7px">
                            <b>(vi)</b> Where we provide any discount on Service
                            Fees before the supply of goods or Services, such
                            discount shall be mentioned in the COF and on the
                            applicable invoice. The net amount of the invoice
                            shall be treated as consideration for the supply of
                            such goods and Services.
                          </li>
                          <li style="padding-bottom: 7px">
                            <b>(vii)</b> We will issue e-invoices in accordance
                            with prescribed regulations and the details
                            mentioned by you when subscribing for the Services.
                            Once e-invoices are generated in  accordance  with
                            your  details,  we  will  not  be  able  to  cancel
                            and issue credit notes if the same is not allowed by
                            GST law or if we are not allowed to cancel the
                            e-invoices through the Invoice Registrar Portal
                            (IRP).
                          </li>
                          <li style="padding-bottom: 7px">
                            <b>(viii)</b> Allocation of consideration value in
                            relation to fixed lease line Services
                          </li>
                          <li style="padding-bottom: 7px">
                            <b>(1)</b> In case of India Based Services,
                            consideration towards Services will be allocated in
                            proportion of the Services to be availed of by each
                            point of termination/origination  in  India.  We
                            will  issue  an  invoice  from  each state in which
                            each such point is located at a proportionate
                            percentage or value of the total consideration
                            prescribed to each such point. Such value or
                            percentage will be identified in the relevant COF.
                          </li>
                          <li style="padding-bottom: 7px">
                            <b>(2)</b> In case of India Global Services where
                            only one point is located within India, we will
                            issue an invoice for full consideration from the
                            state in which such point is located within India.
                            However, in case two or more points are located in
                            India, we will issue an invoice for proportionate
                            percentage or value of total consideration from each
                            of the states in which each such point is located
                            within India. Such value or percentage will be
                            identified in the relevant COF.
                          </li>
                          <li style="padding-bottom: 7px">
                            <b>(3)</b> In case of link-based Services such as
                            Internet leased line services, where there is only
                            one point of installation with respect to such
                            link-based products or services, we will issue an
                            invoice for full consideration from the state in
                            which such point is located within India.
                          </li>
                          <li style="padding-bottom: 7px">
                            <b>(4)</b> In each of the above-mentioned cases
                            under sub-section (1) to (3), where you have not
                            provided the details of your GST registration number
                            and address of the state in which the place of
                            supply is determined, credit of taxes paid may not
                            be available to you.
                          </li>
                        </ol>
                      </li>

                      <li style="padding-top: 10px">
                        <strong>8. Term</strong>
                        Unless earlier terminated in accordance with the terms
                        of this Agreement, you agree that –
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>8.1 </b> </label> The initial term of
                            this Agreement shall start on the Effective Date and
                            end on the third (3rd) anniversary of the Effective
                            Date (the <b>“Initial Agreement Term”</b>). After
                            the Initial Agreement Term, the term of this
                            Agreement shall continue on an annual basis until
                            terminated by either Party by delivering written
                            notice to the other Party at least ninety (90) days
                            before the effective termination date. The Initial
                            Term and any annual renewal terms are collectively
                            referred to as the <b>“Agreement Term.”</b>
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>8.2 </b> </label> The term for each
                            Service shall commence on the Service Commencement
                            Date and end on the last day of the minimum service
                            period set forth in the applicable Service Schedule
                            or similar document (<b>“Initial Service Term”</b>).
                            At the end of the Initial Service Term, the Service
                            Term shall automatically renew on a month-to-month
                            basis until terminated by either Party in accordance
                            with Section 9.1 of this Agreement. The Initial
                            Service Term and any extension thereof are
                            collectively referred to as the
                            <b>“Service Term.”</b>
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>8.3 </b> </label> Except as otherwise set
                            forth herein, we shall deliver the Service for the
                            entire duration of the applicable Service Term, and
                            you shall pay all Service Fees for delivery thereof
                            through the end of the Service Term or for such
                            longer time as you actually use the Services. To the
                            extent that the Service Term for any Service extends
                            beyond the Agreement Term, then this Agreement shall
                            remain in full force and effect for such Service
                            until the expiration or termination of such Service
                            Term.
                          </li>
                        </ol>
                      </li>

                      <li style="padding-top: 10px">
                        <strong>
                          9. Termination and Effect of Termination</strong
                        >

                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>9.1 Termination. </b> </label> (a)Except
                            as otherwise specified in a Service Schedule, either
                            Party may terminate an individual Service at the end
                            of its Initial Service Term or Service Term,
                            whichever is applicable, by providing written notice
                            to the other Party at least ninety (90) days before
                            the effective termination date, subject to your
                            payment to us of any outstanding Service Fees,
                            including connection and/or disconnection charges,
                            for the terminated Services.(b)Either Party (the
                            <b>“Non-Defaulting Party”</b>) may also terminate a
                            Service upon written notice of termination to the
                            other Party (“Defaulting Party”) if (i) the
                            Defaulting Party breaches a material provision of
                            this Agreement or the applicable COF and the
                            Defaulting Party fails to cure such breach within
                            thirty (30) days after receipt of written notice of
                            breach from the Non-Defaulting Party; or (ii) any
                            bankruptcy, insolvency, administration, liquidation,
                            receivership or winding up proceeding is commenced
                            in respect of the Defaulting Party. (c)Termination
                            of one Service will not affect the rights and
                            obligations of the Parties with regard to other
                            Services ordered under this Agreement.
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b>9.2 Obligation on Termination.</b>
                            </label>
                            Customer must not, without our prior written
                            consent, copy or download the Software and must
                            promptly return all tangible material relating to
                            the Software to Tata Communications upon the earlier
                            to occur of a Service termination or termination of
                            this Agreement. Customer may keep one copy of the
                            Software if such possession is required by
                            applicable law or required to permit the continued
                            delivery of a Service to the Customer after
                            termination of this Agreement. The Software is and
                            will remain the sole and exclusive property of Tata
                            Communications, our Affiliates, or our suppliers.
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b
                                >9.3 Additional Termination or Suspension by
                                Tata Communications.</b
                              >
                            </label>
                            We shall have an additional right to immediately
                            terminate or suspend this Agreement or any COF(s)
                            (as applicable), and discontinue or suspend the
                            delivery of the affected Services (without
                            liability) if:
                          </li>

                          <ol>
                            <li style="padding-top: 14px">
                              <label> <b>(a) </b> </label> you fail to make
                              a payment when due and fail to cure such breach
                              within fifteen(15) days after receipt of written
                              notice from us; or
                            </li>

                            <li style="padding-top: 14px">
                              <label> <b>(b) </b> </label> you  or  any  End
                              User  has  violated  any  law,  rule,  regulation
                              or  policy  of  any Governmental Authority related
                              to the Services or your or an End User’s use
                              thereof; or
                            </li>

                            <li style="padding-top: 14px">
                              <label> <b>(c) </b> </label> you or any End User
                              has engaged in conduct that has caused or may
                              cause (in our sole reasonable judgment) damage to
                              our network or third parties; or
                            </li>
                            <li style="padding-top: 14px">
                              <label> <b>(d) </b> </label> we receive any
                              direction, notification or instruction from any
                              Governmental Authority to suspend or terminate the
                              provision of Services to you.
                            </li>
                            <label style="padding-top: 10px">
                              Upon any suspension pursuant to (a), (b), (c) or
                              (d) above, we shall provide written notice (where
                              practicable) thereof to you.
                            </label>
                          </ol>

                          <li style="padding-top: 14px">
                            <label> <b>9.4 Remedies</b> </label> The rates and
                            charges set forth in each COF are established in
                            reliance on the Service Term commitment made
                            therein. If you cancel any of the Services or an
                            applicable COF during a Service Term commitment for
                            any reason other than one described in Section 9.1
                            of this Agreement or in an applicable Service
                            Schedule, or in the event we terminate a COF because
                            of any reasons set forth in Section 9.1 or 9.3, then
                            you agree to pay to us, within ten (10) days of such
                            termination: (i) an amount equal to the total of any
                            and all waived installation charges as reflected on
                            the terminated COF(s), (ii) an amount equal to one
                            hundred percent (100%) of the Service Fees payable
                            for the unexpired remainder of the first twelve (12)
                            months of the Service Term plus, if applicable,
                            fifty percent (50%) of the Service Fees payable for
                            the unexpired remainder of months thirteen (13)
                            through the end of the Service Term(s) of the
                            terminated COF(s), plus (iii) any documented third
                            party charges or expenses not covered by (i) and
                            (ii) above incurred by us in respect of the
                            terminated COF(s)(including any Local Loop charges).
                            You acknowledge that the foregoing is a genuine and
                            reasonable estimate of our loss arising from such
                            termination and constitutes liquidated damages and
                            not a penalty. In addition, you shall be obligated
                            to pay us for any Services delivered to you up to
                            the date of termination plus amounts set forth in
                            this Section 9.4. Your obligations pertaining to any
                            accrued but unpaid amounts arising out of this
                            Agreement in relation to any one or all of the
                            Services shall survive the termination of this
                            Agreement.
                          </li>
                        </ol>
                      </li>

                      <li style="padding-top: 10px">
                        <strong>10. Proprietary Rights.</strong>
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>10.1 Intellectual Property.</b> </label>
                            You are and will remain exclusively entitled to all
                            right and interest in and to all of your Technology,
                            and we are and shall remain exclusively entitled to
                            all right and interest in and to all of our
                            Technology. You shall not, directly, or indirectly,
                            reverse engineer, de-compile, disassemble or
                            otherwise attempt to derive source code or other
                            trade secrets from our Technology.
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b
                                >10.2 Intellectual Property Infringement
                                Claims.</b
                              >
                            </label>
                            In the event of a third party claim of intellectual
                            property infringement, we may, at our sole option,
                            (i) obtain for Customer the right to continue using
                            the Services, (ii) modify the Services so that the
                            Services are non-infringing, (iii) replace the
                            Services with a functionally equivalent,
                            non-infringing service, or (iv) if the alternatives
                            in clauses (i) through (iii), above, are not
                            available, we may notify Customer and terminate such
                            infringing Services without penalty to either Party.
                            Notwithstanding anything in this Agreement to the
                            contrary, this section is Customer’s sole and
                            exclusive remedy for any intellectual property
                            infringement claims.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>10.3 Restrictions.</b> </label> Neither
                            Party shall have the right to use the other Party’s
                            or its Affiliates’ trademarks, logos, trade dress,
                            service marks, trade names or service names in any
                            manner, or to refer to the other Party by name or
                            identifiable description in any marketing,
                            promotional or advertising materials or activities,
                            without the written consent of the other Party.
                            Notwithstanding the foregoing, we may provide to any
                            of our other customers or potential customers who
                            are bound by a nondisclosure agreement access to a
                            list of our customers and a generic description of
                            the services purchased by such customers, which list
                            may use Customer's trade name (but not trademark)
                            and the Services purchased by Customer (provided
                            that financial terms relating to the purchase shall
                            not be disclosed).
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>10.4 Licenses.</b> </label> Ownership of
                            any and all intellectual property rights in any Tata
                            Communications-Provided CPE, software, operating
                            manuals, and associated documentation made available
                            as part of any Service or otherwise generated by or
                            for us in connection with this Agreement shall
                            remain the sole property of Tata Communications or
                            our licensors, unless otherwise agreed to in writing
                            between the Parties. We will grant you a personal,
                            non-transferable, and non-exclusive license to use
                            and to permit your End-Users to use, in object code
                            form, all software and associated written and
                            electronic documentation and data furnished by us
                            pursuant to this Agreement (“Software”), solely as
                            necessary for receipt of the Service and solely in
                            accordance with this Agreement and the applicable
                            written and electronic documentation. The term of
                            any license we grant pursuant to this section is
                            co-terminus with the term of the Service with which
                            the Software is associated. If you purchase the Tata
                            Communications-Provided CPE, once title and risk of
                            loss pass to you, you will be granted a perpetual,
                            royalty-free license to use the Software in
                            connection with the Tata Communications-Provided CPE
                            for so long as you continue to own or otherwise use
                            the Tata Communications-Provided CPE in accordance
                            with the terms hereof.
                          </li>
                        </ol>
                      </li>

                      <li style="padding-top: 10px">
                        <strong
                          >11. Warranties, Indemnification, and
                          Liabilities.</strong
                        >
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>11.1 Your Warranties.</b> </label> You
                            represent and warrant that (i) you have the legal
                            right and authority, and will maintain the legal
                            right and authority during each Service Term, to
                            install and use the Services as contemplated
                            hereunder; (ii) the performance of your obligations
                            under this Agreement and use of Services will not
                            violate any applicable law, rule or regulation or
                            any applicable manufacturers' specifications or
                            unreasonably interfere with use of the Services or
                            our Network by Tata Communications or any of our
                            other customers; (iii) you are authorized and have
                            completed all required corporate actions necessary
                            to execute this Agreement; and (iv) you will shall
                            not carry out any act or omission that results in
                            Tata Communications breaching any law, rule or
                            regulation.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>11.2 Our Warranties.</b> </label> We
                            represent and warrant that (i) we have the legal
                            right and authority, and will maintain the legal
                            right and authority during each Service Term, to
                            provide the Services ordered by you hereunder; (ii)
                            the performance of our obligations under this
                            Agreement will not violate any applicable law, rule,
                            or regulation; and (iii) we are authorized and have
                            completed all required corporate actions necessary
                            to execute this Agreement.
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b>11.3 Disclaimer of Warranties.</b>
                            </label>
                            EXCEPT  FOR  WARRANTIES  EXPRESSLY  MADE HEREIN,
                            WE   MAKE   NO   WARRANTIES   OR
                            REPRESENTATIONS,EXPRESS   OR IMPLIED,   EITHER IN
                            FACT   OR   BY   OPERATION   OF   LAW, STATUTORY
                            OR OTHERWISE,  INCLUDING  WARRANTIES OF
                            MERCHANTABILITY,  SATISFACTORY QUALITY, FITNESS FOR
                            A PARTICULAR PURPOSE, TITLE, OR NON-INFRINGEMENT.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>11.4 Indemnification.</b> </label> Each
                            Party shall indemnify the other from and against any
                            claims  by  third  parties  (including  any
                            Governmental  Authority)  and  expenses (including
                            legal fees and court costs)  arising from damage to
                            tangible property, personal injury or death caused
                            by such Party’s negligence or willful misconduct.
                            You  will  also  indemnify,  defend  and  hold  us
                            harmless  from  any  and  all  claims (including
                            claims  by  any  Governmental  Authority  seeking
                            to  impose  penal  or criminal sanctions) (i)
                            relating to your or an End User’s use of the
                            Services; and/or (ii) arising from your breach of
                            Section 11.1or the Section 3.8of this Agreement.
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b>11.5 Indemnification Claim Procedure.</b>
                            </label>
                            The indemnified Party under the aforementioned
                            clause (i) must notify the other Party in writing
                            promptly upon learning of any claim or suit for
                            which indemnification may be sought, provided that
                            failure to do so shall have no effect except to the
                            extent the other Party is prejudiced  thereby; (ii)
                            shall  have  the  right  to  participate  in such
                            defense  or settlement with its own counsel and at
                            its sole expense, but the other Party shall have
                            control of the defense and settlement; and (iii)
                            shall reasonably cooperate with the defense.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>11.6 Damages.</b> </label>
                            _NOTWITHSTANDING ANY OTHER PROVISION HEREOF, NEITHER
                            PARTY SHALL BE LIABLE FOR (A) ANY INDIRECT,
                            INCIDENTAL, SPECIAL, CONSEQUENTIAL, EXEMPLARY OR
                            PUNITIVE DAMAGES OR (B) ANY DAMAGES FOR LOST
                            PROFITS, LOST REVENUES, LOSS OF GOODWILL, LOSS OF
                            ANTICIPATED SAVINGS, LOSS OF CUSTOMERS, LOSS OF
                            DATA, INTERFERENCE WITH BUSINESS OR COST OF
                            PURCHASING REPLACEMENT SERVICES, ARISING OUT OF THE
                            PERFORMANCE OR FAILURE TO PERFORM UNDER THIS
                            AGREEMENT, WHETHER OR NOT CAUSED BY THE ACTS OR
                            OMISSIONS OR NEGLIGENCE (INCLUDING GROSS NEGLIGENCE
                            OR WILLFUL MISCONDUCT) OF ITS EMPLOYEES OR AGENTS,
                            AND REGARDLESS OF WHETHER SUCH PARTY HAS BEEN
                            INFORMED OF THE POSSIBILITY OR LIKELIHOOD OF SUCH
                            DAMAGES. FOR ANY LIABILITY NOT EXCLUDED BY THE
                            FOREGOING OR ANYTHING ELSE IN THIS AGREEMENT,
                            NEITHER PARTY WILL BE LIABLE IN AN AMOUNT THAT
                            EXCEEDS, IN THE AGGREGATE FOR ALL SUCH LIABILITIES,
                            THE MOST RECENT TWELVE (12) MONTHS OF CHARGES
                            COLLECTED BY US PURSUANT TO THE APPLICABLE SERVICE
                            INSTANCE GIVING RISE TO THE LIABILITY.
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b
                                >11.7 Limitation For Services-Related
                                Matters.</b
                              >
                            </label>
                            EXCEPT FOR ANY REFUNDS THAT MAY BE ALLOWED UNDER
                            THIS AGREEMENT DUE TO CHRONIC SERVICE FAILURES,AS
                            DETERMINED IN OUR SOLE DISCRETION PURSUANT TO THE
                            CONTRACT TERMS, OUR SOLE LIABILITY AND YOUR SOLE
                            REMEDY FOR DAMAGES ARISING OUT OF OR RELATING TO ANY
                            ACT OR OMISSION RELATING TO THE FURNISHING OF OR THE
                            FAILURE TO FURNISH SERVICES (INCLUDING BUT NOT
                            LIMITED TO MISTAKES, OMISSIONS, INTERRUPTIONS,
                            FAILURE TO TRANSMIT OR ESTABLISH CONNECTIONS,
                            FAILURE TO SATISFY SERVICE LEVELS OR SPECIFICATIONS,
                            DELAYS, ERRORS OR OTHER DEFECTS) IS LIMITED TO ANY
                            APPLICABLE CREDIT ALLOWANCES DUE AND/OR YOUR RIGHT
                            TO TERMINATE A PARTICULAR SERVICE UNDER THE
                            APPLICABLE SERVICE LEVEL GUARANTEE AS SET FORTH IN
                            THE RELEVANT SERVICE SCHEDULE OR SIMILAR DOCUMENT.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>11.8 </b> </label> YOU ACKNOWLEDGE AND
                            AGREE THAT THE FOREGOING LIMITATIONS AND EXCLUSIONS
                            OF LIABILITY ARE REASONABLE AND ARE A MATERIAL
                            INDUCEMENT FOR US TO ENTER INTO AND PROVIDE SERVICES
                            PURSUANT TO THIS AGREEMENT. NOTWITHSTANDING THE
                            ABOVE, NOTHING IN THIS AGREEMENT SHALL BE CONSTRUED
                            AS LIMITING THE LIABILITY OF EITHER PARTY FOR (A)
                            PERSONAL INJURY OR DEATH RESULTING FROM THE
                            NEGLIGENCE OF A PARTY OR ITS EMPLOYEES, (B) FRAUD OR
                            FRAUDULENT MISREPRESENTATION, OR (C) WILFUL
                            MISCONDUCT.
                          </li>
                        </ol>
                      </li>

                      <li style="padding-top: 10px">
                        <strong>12. Miscellaneous.</strong>
                        <ol>
                          <li style="padding-top: 14px">
                            <label> <b>12.1 Assignment</b> </label> You will not
                            assign or otherwise transfer this Agreement or any
                            of  your  rights  and  obligations  under  this
                            Agreement,  without  our  prior  written consent.
                            Any assignment or transfer in violation of this
                            Section will be void. We may assign this Agreement
                            without your consent (a) in connection with a
                            merger, acquisition or sale of all or substantially
                            all of our assets, or (b) to any affiliate or as
                            part  of  a  corporate  reorganization;  and
                            effective  upon  such  assignment,  the assignee is
                            deemed  substituted  for  Tata  Communications as
                            a  party  to  this Agreement and Tata Communications
                            is fully released from all of its obligations and
                            duties  to  perform  under  this Agreement.
                            Subject  to  the  foregoing,  this Agreement will be
                            binding upon, and inure to the benefit of the
                            parties and their respective permitted successors
                            and assigns.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>12.2 Application of Tariffs.</b> </label>
                            In the event we are required to file tariffs with a
                            Governmental Authority, the terms set forth in the
                            applicable tariff shall govern our delivery of, and
                            your consumption or use of, such Services to the
                            extent required by law, rule or regulation. In the
                            event that any change to the terms and conditions
                            and/or the Service Fees results from such
                            application of tariffs to the Services and such
                            change has a materially adverse effect on you, then
                            you shall have a right to terminate the affected
                            Services by delivering written notice to us no later
                            than thirty (30) days after the earlier to occur of
                            the date on which you receive notice that we have
                            applied such tariff or the date on which we posted
                            notice of the tariff on a Tata Communications Site).
                            Your right to terminate shall become null and void
                            if a tariff change or other step prevents the
                            material adverse effect prior to expiration of the
                            30-day period described above.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>12.3 Personal Information.</b> </label>
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>(a)</b> </label> You confirm that you
                            have read and understood our Privacy Policy.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>(b)</b> </label> You here by consent to
                            our collection and processing of Personal
                            Information relating to you during the Agreement
                            Term for:
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>(i)</b> </label> the provision of our
                            Services under this Agreement, and for legal,
                            administrative and management purposes, such as
                            customer service, technical support, billing and
                            reconciliation, operational maintenance, fraud
                            detection and prevention, compliance with law, and
                            communication with you; and
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>(ii)</b> </label> sending information to
                            you via email, phone or postal mail about our
                            products, services and events that may be of
                            interest to you.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>(c)</b> </label> We may make such
                            information available to our Affiliates, employees,
                            agents, contractors and others who provide products
                            or services to us(such as advisers), and to
                            regulatory authorities and our potential purchasers
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>(d)</b> </label> You acknowledge and
                            understand that in order for us to provide the
                            Services, Personal Information may be transferred to
                            countries outside the contracting jurisdiction,
                            including the United States of America and/or India.
                            You warrant to us that you will obtain the necessary
                            consents for such transfer of Personal Information.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>(e)</b> </label> Each Party represents
                            and warrants to the other Party that it complies
                            with its obligations under relevant Privacy Laws.
                            You further represent and warrant to us that you
                            shall provide proper notices to, and obtain
                            necessary consents from, your end-users and/or
                            employees about how their Personal Information may
                            be used, stored, and disclosed to service providers
                            engaged by you.
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b>12.4 Internet Access and Content</b>
                            </label>
                            We provide only access to the Internet. We neither
                            operate nor control the information, services,
                            opinions, or other content of the Internet
                            (collectively, “Internet Content”), and we make no
                            warranties or representations regarding Internet
                            Content. You agree that you shall make no claim
                            whatsoever against us relating to Internet Content
                            or respecting any information, product, service or
                            software ordered through the Internet. We reserve
                            the right to take such measures as may be reasonably
                            necessary, in our sole discretion, to ensure
                            security and continuity of service on our Network,
                            including but not limited to identification and
                            blocking or filtering of Internet traffic sources
                            which we deem to pose a security or operational risk
                            or a risk of violation of our AUP and/or Section
                            3.8of this Agreement. You acknowledge and agrees
                            that we do not own or control third party networks,
                            and we are not responsible or liable for any
                            filtering or access restrictions imposed by such
                            third party networks or for the performance (or
                            non-performance) of such third party networks or
                            within interconnection points between our Network
                            and such third party networks.
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b>12.5 Regulatory and Legal Changes</b>
                            </label>
                            Regulatory and Legal Changes delivery of written
                            notice requesting negotiation, then (i)we may pass
                            such increased costs through to you upon thirty (30)
                            days’ notice and/or revise this Agreement as
                            appropriate to equitably adjust for such adverse
                            effect, and (ii) you may terminate the affected COF
                            without termination liability by delivering written
                            notice of termination no later than thirty (30) days
                            after the effective date of the rate increase or
                            after a materially adverse change to the Agreement
                            pursuant to clause (i).
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>12.6 Force Majeure.</b> </label> We and
                            our Affiliates will not be liable for any delay or
                            failure to perform any obligation under this
                            Agreement where the delay or failure results from
                            any cause beyond our reasonable control, including
                            acts of God, labor disputes or other industrial
                            disturbances, electrical or power outages, utilities
                            or other telecommunications failures, earthquake,
                            storms or other elements of nature, blockages,
                            embargoes, riots, acts or orders of government, acts
                            of terrorism, or war. Except for your payment
                            obligations accruing under this Agreement up to the
                            date of a bona fide Force Majeure Event, neither
                            Party shall be liable, nor shall any credit
                            allowance or other remedy be extended, for any
                            performance that is prevented or hindered due to a
                            Force Majeure Event. If, during the Service Term, we
                            are not able to provide Services for a period in
                            excess of sixty (60) consecutive days for any reason
                            set forth in this Section, then either Party may
                            terminate the affected Service(s) upon written
                            notice to the other Party, and both Parties shall be
                            released from any further future liability in
                            relation to such Service(s).
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>12.7 Governing Law.</b> </label> Each
                            Service shall be governed by the applicable laws of
                            the country of registration of the Tata
                            Communications contracting party issuing the
                            invoices for such Service without reference to its
                            conflict of law provisions. The Parties irrevocably
                            submit to the exclusive jurisdiction of the courts
                            of such jurisdiction and any court of appeal
                            therefrom. Notwithstanding anything to the contrary
                            stated herein, all Services which are paid for by
                            you using a payment gateway through accounts issued
                            by banking institutions headquartered in the country
                            of registration of the applicable Tata
                            Communications contracting party shall be governed
                            by the laws of the country where such Tata
                            Communications contracting party is registered. If
                            you fail to pay any invoiced amount which you have
                            not disputed in accordance with this Agreement, we
                            may seek to recover the sum due in any court of
                            competent jurisdiction without reference to its
                            conflicts of law and you hereby submit to the
                            jurisdiction of any such court.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>12.8 Disputes.</b> </label> Any dispute
                            or claim relating in any way to your use of the
                            Services, or to any products or services sold or
                            distributed by Tata Communications, will be
                            adjudicated in the courts where our contracting
                            party is registered.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>12.9 Export Control.</b> </label> The
                            Parties acknowledge that products, software, and
                            technical information (including but not limited to
                            the Services, technical assistance, and training)
                            provided under this Agreement may be subject to
                            export control and sanctions laws and regulations of
                            the U.S. and other countries, and any use or
                            transfer of the products, software, or technical
                            information must comply with all applicable
                            regulations. The Parties will not use, distribute,
                            transfer, or transmit the products, software, or
                            technical information (even if incorporated into
                            other products) except in compliance with all
                            applicable export control and sanctions regulations.
                            If requested by either Party, the other Party agrees
                            to sign written assurances and other export-related
                            documents that might be required to comply with all
                            applicable export regulations.
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b
                                >12.10 Independent Contractors; Non-Exclusive
                                Rights.</b
                              >
                            </label>
                            We and you are independent contractors, and this
                            Agreement will not be construed to create a
                            partnership, joint venture, agency, or employment
                            relationship. Neither Party, nor any of its
                            respective Affiliates, is an agent of the other for
                            any purpose or has the authority to bind the other.
                            Both parties reserve the right (a) to develop or
                            have developed for it self products, services,
                            concepts, systems, or techniques that are similar to
                            or compete with the products, services, concepts,
                            systems, or techniques developed or contemplated by
                            the other party, and (b) to assist third party
                            developers or systems integrators who may offer
                            products or services which compete with the other
                            party’s products or services.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>12.11 Language.</b> </label> All
                            communications and notices made or given pursuant to
                            this Agreement must be in the English language. If
                            we provide a translation of the English language
                            version of this Agreement, the English language
                            version of the Agreement will control if there is
                            any conflict.
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b>12.12 Confidentiality and Publicity.</b>
                            </label>
                            You may use Tata Communications Confidential
                            Information only in connection with your use of the
                            Services as permitted under this Agreement. You will
                            not disclose Tata Communications Confidential
                            Information during the Term or at any time during
                            the five (5) year period following the end of the
                            Agreement Term. You will take all reasonable
                            measures to avoid disclosure, dissemination, or
                            unauthorized use of Tata Communications Confidential
                            Information, including, at a minimum, those measures
                            you take to protect your own confidential
                            information of a similar nature. You will not issue
                            any press release or make any other public
                            communication with respect to this Agreement or your
                            use of the Services.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>12.13 Notice.</b> </label>
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>(a) To You.</b> </label> We may provide
                            any notice to you under this Agreement by: (i)
                            posting a notice on the Tata Communications Site; or
                            (ii) sending a message to the email address then
                            associated with your account. Notices we provide by
                            posting on the Tata Communications Site will be
                            effective upon posting and notices we provide by
                            email will be effective when we send the email. It
                            is your responsibility to keep your email address
                            current. You will be deemed to have received any
                            email sent to the email address then associated with
                            your account when we send the email, whether or not
                            you actually receive the email
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>(b) To Us.</b> </label> Notices addressed
                            to us may be sent through email
                            <b>tolegal@tatacommunications.com.</b> Notices you
                            provide by email will be effective three (3) days
                            after you send the email
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b>12.14 No Third-Party Beneficiaries.</b></label
                            >
                            Except as explicitly set forth herein, this
                            Agreement does not create any third-party
                            beneficiary rights in any individual or entity that
                            is not a party to this Agreement.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>12.15 No Waivers.</b> </label> The
                            failure by us to enforce any provision of this
                            Agreement will not constitute a present or future
                            waiver of such provision nor limit our right to
                            enforce such provision at a later time. All waivers
                            by us must be in writing to be effective.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>12.16 Severability.</b> </label> If any
                            portion of this Agreement is held to be invalid or
                            unenforceable, the remaining portions of this
                            Agreement will remain in full force and effect. Any
                            invalid or unenforceable portions of this Agreement
                            will be interpreted to align with the intent of the
                            valid and enforceable portions of this Agreement. If
                            such construction is not possible, the invalid or
                            unenforceable portion will be severed from this
                            Agreement, but the rest of the Agreement will remain
                            in full force and effect.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>12.17 Anti-Bribery.</b> </label> You
                            represent that you have complied and shall comply
                            with all applicable anti-bribery laws and
                            regulations, including, without limitation, the U.S.
                            Foreign Corrupt Practices Act, the UK Bribery Act
                            and similar laws of any other Governmental
                            Authority. You further represent that you have not
                            taken and shall not take any action in furtherance
                            of an unlawful offer, promise, or payment to or for
                            the benefit of any person, including but not limited
                            to (i) any government official or employee
                            (including, without limitation, any official or
                            employee of a government agency, regulatory
                            authority or entity owned or controlled by the
                            government), any candidate for public office or
                            official of a political party, or any official or
                            employee of a public international organization (any
                            of the foregoing, a <b>“Government Official”</b>);
                            (ii) any member of a Government Official's family;
                            or (iii) any political party. In the event that you
                            are required to undergo our anti-bribery due
                            diligence process prior to delivery of a Service,
                            you further represent and warrant that any
                            certification provided by you as part of our due
                            diligence process is true and accurate. You will
                            notify us immediately in the event that any of the
                            statements in such certification shall no longer be
                            true and accurate.
                          </li>

                          <li style="padding-top: 14px">
                            <label>
                              <b
                                >12.18 Entire Understanding and Further
                                Assurance.</b
                              >
                            </label>
                            This Agreement constitutes the entire understanding
                            of the Parties related to the subject matter
                            hereof.  All prior written or oral agreements,
                            understandings, communications, or practices between
                            the Parties are hereby superseded insofar as they
                            relate to the Services hereunder.  This Agreement
                            may be amended only in a writing signed by a duly
                            authorized representative of each Party.  In the
                            event of any conflict between the documents
                            comprising   this   Agreement,   precedence shall
                            be   given   to   the documents  in  the following
                            descending  order:  (i)  these Contract Terms;
                            (ii)  the applicable  Service  Schedule, if  any;
                            and  (iii)  and  any  other  document expressly
                            referred  to  in  this  Agreement  which governs
                            the  Services. Each  Party shall perform, or  cause
                            to  be  done  and  performed, all  such acts  and
                            things,  and execute and deliver all such other
                            agreements, instruments and documents, as the other
                            Party  may  reasonably  request  in  order to
                            accomplish  the  intent  and purposes of this
                            Agreement, including the transactions contemplated
                            hereby.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>12.19 Definitions.</b> </label>
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>1. “Affiliate”</b> </label> means an
                            entity that directly or indirectly controls, is
                            controlled by or is under common control with a
                            Party. For purposes of the foregoing, “control”
                            means the ownership of more than fifty percent (50%)
                            of the (i) voting power to elect the directors of
                            the said entity, or (ii) ownership interest in the
                            said entity.
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b>2. “Customer Order Form” </b> or
                              <b> “COF”</b></label
                            >
                            means a request for Service, submitted by you in the
                            form (including any attachments) designated and
                            accepted by us in accordance with this Agreement,
                            that describes matters relating to our delivery of
                            Services to you (in all events to the exclusion of
                            any of your proposed terms and conditions of
                            purchase, purchase orders, or other proposed terms
                            of business).
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>3. “End Use”</b> </label> means any
                            person or entity deriving or making use of the
                            Services through you, including but not limited to,
                            Customer, one of your Affiliates, or one of your
                            customers.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>4. “Facilities”</b> </label> means any
                            and all property supplied by us that are used to
                            deliver the Services, including but not limited to
                            all terminal and other equipment, wires, fiber optic
                            cables, lines, circuits, radio links, ports,
                            routers, switches, cabinets, racks, private rooms
                            and the like, including Tata Communications-Provided
                            CPE installed on or at your premises but owned by us
                            pursuant to this Agreement. Facilities shall not
                            include any such devices sold to you by us or owned,
                            leased, or otherwise obtained by you from a third
                            party.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>5. “Force Majeure Event”</b> </label> is
                            any cause beyond a Party's reasonable control,
                            including, without limitation, any act of war, act
                            of God or nature, earthquake, hurricanes, tornados,
                            flood, fire or other similar casualty, embargo,
                            riot, terrorism, sabotage, strike or labor
                            difficulty, governmental act, law or regulation,
                            insurrections, terrorism, epidemic, quarantine,
                            inability to procure materials or transportation
                            facilities, failure of power, court order,
                            condemnation, failure of the Internet, failure of a
                            supplier or other cause, whether similar or
                            dissimilar to the foregoing, not resulting from the
                            actions or inactions of such Party.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>6. “Governmental Authority”</b> </label>
                            means any governmental or quasi-governmental body,
                            whether foreign or domestic, including any
                            department, agency, commission, bureau or other
                            administrative or regulatory bodies, courts, public
                            utilities, and communications authorities (e.g., the
                            U.S. Federal Communications Commission, USAC or
                            Telecom Regulation Authority of India).
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>7. “India Based Services”</b> </label>
                            mean those telecommunications Services which
                            originate from and terminate at two or more points
                            in India. Such Services may include, without
                            limitation, national private leased circuit
                            Services, national dedicated ethernet Services and
                            domestic virtual private network Services.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>8. India Global Services</b> </label>
                            India Global Services one or more points in India.
                            Such Services may include, without limitation,
                            global dedicated ethernet Services and global
                            virtual private network Services.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>9. “In-Service Notification”</b> </label>
                            means a written notice from us to you that the
                            Services ordered pursuant to a COF have been
                            installed by us, have been tested, and are
                            functioning properly in accordance with the
                            applicable Service Schedule.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>10. “Interest Rate”</b> </label> means
                            one and one-half percent (1.5%) per month or, if
                            such amount is not permitted by law, the highest
                            rate permitted by law.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>11. “Local Loop”</b> </label> means the
                            connection between your premises and a Point of
                            Presence.
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b>12. “Network” or “our Network”</b>
                            </label>
                            means the telecommunications network that is owned
                            and/or leased and operated and maintained by Tata
                            Communications or one of our Affiliates.
                          </li>

                          <li style="padding-top: 14px">
                            <label> <b>13. “Personal Information”</b> </label>
                            means any information that may identify an
                            individual, including without limitation names,
                            addresses, telephone numbers, electronic addresses,
                            passwords, credit card numbers or other account
                            data, customer proprietary network information, or
                            any information regarding an individual that is
                            protected under any Privacy Laws applicable to the
                            Services.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>14. “Point of Presence”</b> </label>
                            means a data center or similar space owned or leased
                            by us or any of our Affiliates for the purpose of,
                            among other things, locating and collocating
                            communications equipment and to which our Network is
                            directly connected.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>15. “Privacy Laws”</b> </label> means any
                            applicable law, regulation or binding policy of any
                            Governmental Authority that relates to the security
                            and protection of personally identifiable
                            information, data privacy, trans-border data flow or
                            data protection.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>16. “Service Term”</b> </label> means the
                            minimum duration for each Service or each component
                            of the Service, as defined in the relevant Service
                            Schedule or COF and calculated from the Service
                            Commencement Date, continuing thereafter in
                            accordance with Section 8.2 of this Agreement.
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b>17. “Service Commencement Date”</b>
                            </label>
                            means the earlier to occur of (i) the date on which
                            you have accepted or are deemed to have accepted the
                            Services in accordance with the provisions of
                            Section 4.6of this Agreement or the applicable
                            Service Schedule; or (ii) the date on which you
                            begin to use the Services for a purpose other than
                            testing.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>18. “Service Fees”</b> </label> means the
                            charges payable by you for the Services (including
                            but not limited to monthly recurring charges and
                            non-recurring charges) as identified on the Tata
                            Communications Site or on another document that
                            incorporates these Contract Terms.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>19. “Service Schedules”</b> </label>
                            means the forms so entitled setting forth details of
                            specific Services that you order pursuant to a COF.
                            References in any Service Schedule to the “General
                            Terms and Conditions for Delivery of Service,”
                            “Agreement,” “Master Services Agreement,” or “MSA”
                            shall be deemed a reference to this Agreement.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>20. “Services”</b> </label> means the
                            services set forth in Service Schedules.
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b
                                >21. “Tata Communications Confidential
                                Information”</b
                              >
                            </label>
                            means the provisions of this Agreement and all
                            information provided to you by us under, or in the
                            course of performing under, this Agreement where
                            such information is marked or reasonably identified
                            or identifiable as confidential or proprietary,
                            including without limitation technical, operational,
                            marketing, billing, pricing, and commercial
                            information in relation to the supply of Services.
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b>22. “Tata Communications-Provided CPE”</b>
                            </label>
                            means equipment that is furnished by us, one of our
                            Affiliates, one of our licensors, or one of our
                            designated third-party vendors, as applicable, for
                            use at your premises as a part of any Service.
                          </li>
                          <li style="padding-top: 14px">
                            <label>
                              <b>23. “Tata Communications Sit”</b>
                            </label>
                            means one or more websites maintained by Tata
                            Communications.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>24. “Technology”</b> </label> means
                            proprietary  technology,  including  without
                            limitation, Services,  software  tools,  hardware
                            designs,  algorithms,  software  (in  source and
                            object   forms),   user   interface   designs,
                            architecture,   class  libraries, objects, and
                            documentation (both printed and electronic), network
                            designs, know-how,  business  methods,  and  any
                            related  intellectual  property  rights throughout
                            the world (whether owned by a Party or licensed to
                            such Party from a third party).
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>25. “Welcome Letter”</b> </label> means a
                            written notice from us to you indicating that the
                            COF has been accepted by us in accordance with
                            Section 4.1(a)of this Agreement.
                          </li>
                          <li style="padding-top: 14px">
                            <label> <b>26. “Your Content”</b> </label> means the
                            information, opinion, ideas, or any other material
                            that transverses through the Services in any form,
                            whether such information, opinion, ideas, or other
                            materials come from you, your employees or a third
                            party (including your contractors, agents, or End
                            Users).
                          </li>
                        </ol>
                      </li>
                    </ol>

                    <a
                      class="text-link1"
                      href="https://optimus-preprod.tatacommunications.com/optimus/service-schedule?ssproduct=IZOMCC"
                      >T&C - Service Schedule
                      (https://optimus-preprod.tatacommunications.com/optimus/service-schedule?ssproduct=IZOMCC)</a
                    >
                  </div>
                </div>

                <!---------------------------------------Configuration Details--------------------------------------------------------->
                <div
                  [ngClass]="{
                    show: showingTab == 'configdetails',
                    active: showingTab == 'configdetails'
                  }"
                  class="customScroller tab-pane fade"
                  id="configdetails-details"
                  role="tabpanel"
                  aria-labelledby="site-details"
                >
                  <div
                    class="row contentRow"
                    *ngFor="let configs of configurations; let i = index"
                  >
                    <accordion class="widthClass">
                      <span>
                        <accordion-group panelClass="customClass">
                          <div
                            accordion-heading
                            class="clearfix cursor-pointer"
                          >
                            <p class="fontEdit">
                              IZO Multi Cloud Connect Flex -
                              {{ configs.locationName }}
                            </p>
                            <span class="icon"></span>
                          </div>

                          <div
                            class="attribute-wrapper"
                            style="padding-top: 17px"
                          >
                            <div class="row pd-10">
                              <div class="container">
                                <div class="row">
                                  <div class="tempalte-wrapper">
                                    <div class="row ml-1">
                                      <div class="p-2 col-12">
                                        <div *ngIf="showedge(configs)">
                                          <div
                                            class="attr-type header-text sub-header"
                                          >
                                            Selected Edge Device
                                          </div>
                                          <div
                                            *ngFor="
                                              let comp of configs.components;
                                              let i = index
                                            "
                                          >
                                            <div
                                              class="p-2"
                                              *ngIf="comp.name == 'Edge Device'"
                                            >
                                              <div
                                                class="w33"
                                                *ngFor="
                                                  let att of comp['attributes']
                                                "
                                              >
                                                <div class="attr-type">
                                                  {{ att.name }}
                                                </div>
                                                <div>{{ att.value }}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div *ngIf="showcloud(configs)">
                                          <div
                                            class="attr-type header-text sub-header"
                                          >
                                            Selected Cloud Connections
                                          </div>
                                          <div
                                            *ngFor="
                                              let comp of configs.components
                                            "
                                          >
                                            <div
                                              class="p-2"
                                              *ngIf="comp.name === 'Cloud'"
                                            >
                                              <div
                                                class="w25"
                                                *ngFor="
                                                  let att of comp['attributes']
                                                "
                                              >
                                                <div class="attr-type">
                                                  {{ att.name }}
                                                </div>
                                                <div>{{ att.value }}</div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div *ngIf="showconnect(configs)">
                                          <div
                                            class="attr-type header-text sub-header"
                                          >
                                            Selected Edge Connectivity
                                          </div>
                                          <div
                                            *ngFor="
                                              let comp of configs.components
                                            "
                                          >
                                            <div
                                              class="p-2"
                                              *ngIf="
                                                comp.name == 'Edge Connectivity'
                                              "
                                            >
                                              <span
                                                *ngFor="
                                                  let att of comp['attributes']
                                                "
                                              >
                                                <div
                                                  class="w33"
                                                  *ngIf="
                                                    att.name !=
                                                    'Edge Connectivity Bandwidth-Link2'
                                                  "
                                                >
                                                  <div class="attr-type">
                                                    {{ att.name }}
                                                  </div>
                                                  <div>{{ att.value }}</div>
                                                </div>
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="flex-row p-2 col-12">
                                        <div
                                          class="attr-type header-text sub-header"
                                        >
                                          Customer End Connectivity to Edge
                                        </div>
                                        <div class="p-2">
                                          <div>
                                            <span class="attr-type w33"
                                              >Access Type</span
                                            >
                                            <span
                                              class="attr-type w33"
                                              *ngIf="
                                                configs.accessType !=
                                                'New access link required'
                                              "
                                              >Access Required</span
                                            >
                                            <span class="attr-type w33"
                                              >Service Up Time</span
                                            >
                                            <span
                                              class="attr-type w33"
                                              *ngIf="
                                                configs.accessType ==
                                                'New access link required'
                                              "
                                              >Ref. Order Id</span
                                            >
                                          </div>
                                          <div>
                                            <span class="w33">{{
                                              configs.accessType
                                            }}</span>
                                            <span
                                              class="w30"
                                              *ngIf="
                                                configs.accessType !=
                                                'New access link required'
                                              "
                                              >{{
                                                configs.accessRequired
                                              }}</span
                                            >
                                            <span class="w33">{{
                                              configs.serviceUptime
                                            }}</span>
                                            <span
                                              class="w33"
                                              *ngIf="
                                                configs.accessType ==
                                                'New access link required'
                                              "
                                              >{{ configs.refOrderId }}</span
                                            >
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </accordion-group>
                      </span>
                    </accordion>
                  </div>
                </div>

                <!----------------------------------------Total Charges------------------------------------------------------------------->
                <div
                  class="customScroller tab-pane fade text-justify show"
                  [ngClass]="{
                    show: showingTab == 'charges',
                    active: showingTab == 'charges'
                  }"
                  id="total-charges"
                  role="tabpanel"
                  aria-labelledby="total-charges"
                >
                  <div class="col-md-12">
                    <span class="ng-star-inserted"
                      ><mat-accordion
                        class="mat-accordion mat-accordion-multi ng-star-inserted"
                        ><mat-expansion-panel
                          class="mat-expansion-panel ng-tns-c211-3 mat-expanded mat-expansion-panel-spacing ng-star-inserted"
                          style="margin-bottom: 20px !important"
                          ><mat-expansion-panel-header
                            role="button"
                            class="mat-expansion-panel-header mat-focus-indicator multi-dc-expansion-panel-header ng-tns-c213-4 ng-tns-c211-3 mat-expanded mat-expansion-toggle-indicator-after ng-star-inserted"
                            id="mat-expansion-panel-header-0"
                            tabindex="0"
                            aria-controls="cdk-accordion-child-0"
                            aria-expanded="true"
                            aria-disabled="false"
                            ><span class="mat-content ng-tns-c213-4"
                              ><mat-panel-title
                                class="mat-expansion-panel-header-title ng-tns-c213-4"
                                ><div>
                                  IZO<sup>TM</sup> &nbsp;Multi Cloud Connect
                                  Flex - Chicago
                                </div></mat-panel-title
                              ></span
                            ><span
                              class="mat-expansion-indicator ng-tns-c213-4 ng-trigger ng-trigger-indicatorRotate ng-star-inserted"
                              style="transform: rotate(180deg)"
                            ></span
                            ><!----></mat-expansion-panel-header
                          >
                          <div
                            role="region"
                            class="mat-expansion-panel-content ng-tns-c211-3 ng-trigger ng-trigger-bodyExpansion"
                            id="cdk-accordion-child-0"
                            aria-labelledby="mat-expansion-panel-header-0"
                            style="visibility: visible"
                          >
                            <div class="mat-expansion-panel-body ng-tns-c211-3">
                              <div
                                class="p-2 ng-tns-c211-3"
                                style="font-family: Gotham-Book"
                              >
                                <b>Commercials</b>
                              </div>
                              <br class="break ng-tns-c211-3" />
                              <table
                                class="table table-responsive-sm ng-tns-c211-3"
                              >
                                <thead>
                                  <th>Chargeable Items</th>
                                  <th>Description</th>
                                  <th>Bandwidth</th>
                                  <th>MRC (USD)</th>
                                  <th>NRC (USD)</th>
                                  <!---->
                                </thead>
                                <tbody>
                                  <!---->
                                  <tr
                                    class="table-card ng-star-inserted"
                                    style=""
                                  >
                                    <td class="vm-title">
                                      Virtual Circuit Charge
                                    </td>
                                    <td>
                                      Microsoft Azure - <span>Chicago</span>
                                    </td>
                                    <!----><!----><!----><!---->
                                    <td class="ng-star-inserted">100Mbps</td>
                                    <!----><!----><!----><!----><!---->
                                    <td>295.25</td>
                                    <td>1061.29</td>
                                  </tr>
                                  <tr
                                    class="table-card ng-star-inserted"
                                    style=""
                                  >
                                    <td class="vm-title">
                                      Virtual Circuit Charge
                                    </td>
                                    <td>
                                      Google Cloud Provider -
                                      <span>Chicago</span>
                                    </td>
                                    <!----><!----><!----><!---->
                                    <td class="ng-star-inserted">50Mbps</td>
                                    <!----><!----><!----><!----><!---->
                                    <td>147.63</td>
                                    <td>530.65</td>
                                  </tr>

                                  <tr
                                    class="table-card ng-star-inserted"
                                    style=""
                                  >
                                    <td class="vm-title">
                                      Virtual Circuit Charge
                                    </td>
                                    <td>
                                      Amazon Web Services - <span>Chicago</span>
                                    </td>
                                    <!----><!----><!----><!---->
                                    <td class="ng-star-inserted">50Mbps</td>
                                    <!----><!----><!----><!----><!---->
                                    <td>147.63</td>
                                    <td>530.65</td>
                                  </tr>
                                  <tr
                                    class="table-card ng-star-inserted"
                                    style=""
                                  >
                                    <td class="vm-title">
                                      Edge Connector Charge
                                    </td>
                                    <!----><!---->
                                    <td class="ng-star-inserted">
                                      Edge Connect Internet-Link1
                                    </td>
                                    <td>50Mbps</td>
                                    <td>137.97</td>
                                    <td>0.00</td>
                                  </tr>
                                  <tr
                                    class="table-card ng-star-inserted"
                                    style=""
                                  >
                                    <td class="vm-title">
                                      Virtual Device Charge
                                    </td>
                                    <td>Cisco Catalyst 8000v Router</td>
                                    <td>-</td>
                                    <td>1931.56</td>
                                    <td>0.00</td>
                                    <!---->
                                  </tr>
                                  <tr
                                    class="table-card ng-star-inserted"
                                    style=""
                                  >
                                    <td class="vm-title">VNF License Charge</td>
                                    <td>DNA Advantage</td>
                                    <td>-</td>
                                    <td>585.83</td>
                                    <td>0.00</td>
                                    <!---->
                                  </tr>
                                </tbody>
                              </table>
                              <!---->
                            </div>
                          </div></mat-expansion-panel
                        ><!----><!----></mat-accordion
                      ><!----></span
                    ><!----><!---->
                  </div>
                </div>

                <!--------------------------------------------Billing/Payments----------------------------------------------------------->

                <div
                  class="tab-pane fade text-justify active show mt-4"
                  *ngIf="showingTab == 'payments'"
                  id="billing"
                  role="tabpanel"
                  aria-labelledby="billing"
                >
                  <div class="col-md-12">
                    <div class="row">
                      <div class="col-md-6 ng-star-inserted">
                        <div class="row customerItem">
                          <div class="col-md-6 custom-border-bottom">
                            <span><b>Billing Currency</b></span>
                          </div>
                          <div class="col-md-6 custom-border-bottom">
                            <span class="ng-star-inserted"> USD</span
                            ><!----><!---->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 ng-star-inserted">
                        <div class="row customerItem">
                          <div class="col-md-6 custom-border-bottom">
                            <span><b>Payment Currency</b></span>
                          </div>
                          <div class="col-md-6 custom-border-bottom">
                            <span class="ng-star-inserted"> USD</span
                            ><!----><!---->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 ng-star-inserted">
                        <div class="row customerItem">
                          <div class="col-md-6 custom-border-bottom">
                            <span><b>Invoice Method</b></span>
                          </div>
                          <div class="col-md-6 custom-border-bottom">
                            <span class="ng-star-inserted">
                              Paper/Electronic</span
                            ><!----><!---->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 ng-star-inserted">
                        <div class="row customerItem">
                          <div class="col-md-6 custom-border-bottom">
                            <span><b>Billing Frequency</b></span>
                          </div>
                          <div class="col-md-6 custom-border-bottom">
                            <span class="ng-star-inserted"> Monthly</span
                            ><!----><!---->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 ng-star-inserted">
                        <div class="row customerItem">
                          <div class="col-md-6 custom-border-bottom">
                            <span><b>Billing Method</b></span>
                          </div>
                          <div class="col-md-6 custom-border-bottom">
                            <span class="ng-star-inserted"> Advance</span
                            ><!----><!---->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 ng-star-inserted">
                        <div class="row customerItem">
                          <div class="col-md-6 custom-border-bottom">
                            <span><b>Billing Type</b></span>
                          </div>
                          <div class="col-md-6 custom-border-bottom">
                            <span class="ng-star-inserted"> Billable</span
                            ><!----><!---->
                          </div>
                        </div>
                      </div>
                      <div class="col-md-6 ng-star-inserted">
                        <div class="row customerItem">
                          <div class="col-md-6 custom-border-bottom">
                            <span
                              ><b>Payment Term (from date of invoice)</b></span
                            >
                          </div>
                          <div class="col-md-6 custom-border-bottom">
                            <span class="ng-star-inserted"> 45 days</span
                            ><!----><!---->
                          </div>
                        </div>
                      </div>
                      <!---->
                      <div class="col-md-6">
                        <div class="row customerItem">
                          <div class="col-md-6 custom-border-bottom">
                            <span><b>Billing Address</b></span>
                          </div>
                          <div class="col-md-6 custom-border-bottom">
                            <span>
                              EQUINIX CH3, 1905 LUNT AVENUE, ELK GROVE VILLAGE,
                              IL 60007, USA</span
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!---------------------------------------Customer Contracting Info-------------------------------------------------------------->
              <div
                class="tab-pane fade active show"
                *ngIf="showingTab == 'customer'"
                id="customer-info"
                role="tabpanel"
                aria-labelledby="customer-info"
              >
                <div class="col-md-12 customMargin">
                  <h5 class="font-weight-bold" style="padding-left: 13px">
                    Customer Contracting Info
                  </h5>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-6">
                          <span class="rightColEdit"> Contact Name</span>
                        </div>
                        <div class="col-md-6 cfstyle">
                          <span class="ng-star-inserted"> Demo Customer</span
                          ><!----><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-4">
                          <span class="rightColEdit"> Contracting Entity</span>
                        </div>
                        <div class="col-md-6 cfstyle">
                          <span class="ng-star-inserted">
                            Demo Technology Solutions B.V.</span
                          ><!----><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-6">
                          <span class="rightColEdit">
                            Contact Title / Designation</span
                          >
                        </div>
                        <div class="col-md-6 cfstyle">
                          <!----><span class="ng-star-inserted"> - </span
                          ><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-4 cfstyle">
                          <!----><span class="rightColEdit ng-star-inserted">
                            VAT Number</span
                          ><!---->
                        </div>
                        <div class="col-md-6 cfstyle">
                          <!----><!----><span class="ng-star-inserted"
                            >US 335B01</span
                          ><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-6">
                          <span class="rightColEdit">Phone</span>
                        </div>
                        <div class="col-md-6 cfstyle">
                          <span class="ng-star-inserted"> +1-(357) 4589123</span
                          ><!----><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-4">
                          <span class="rightColEdit">Email Id</span>
                        </div>
                        <div class="col-md-6 cfstyle">
                          <span class="ng-star-inserted"> alux@Demo.com</span
                          ><!----><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-6">
                          <span class="rightColEdit"> Address</span>
                        </div>
                        <div class="col-md-6">
                          <span
                            ><p class="cfstyle">
                              EQUINIX CH3, 1905 LUNT AVENUE, ELK GROVE VILLAGE,
                              IL 60007, USA
                            </p></span
                          >
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" style="display: none">
                      <div class="row customerItem">
                        <div class="col-md-4">
                          <span class="rightColEdit"> PO Number</span>
                        </div>
                        <div class="col-md-6 cfstyle">
                          <!----><span class="ng-star-inserted">-</span
                          ><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6" style="display: none">
                      <div class="row customerItem">
                        <div class="col-md-6">
                          <span class="rightColEdit"> PO Date</span>
                        </div>
                        <div class="col-md-6 cfstyle">
                          <!----><span class="ng-star-inserted">-</span
                          ><!---->
                        </div>
                      </div>
                    </div>
                  </div>
                  <h5
                    class="font-weight-bold"
                    style="padding-left: 13px; padding-top: 20px"
                  >
                    Supplier Contracting Info
                  </h5>
                  <div class="row">
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-6">
                          <span class="rightColEdit"> Contact Name</span>
                        </div>
                        <div class="col-md-6 cfstyle">
                          <span class="ng-star-inserted"> Ramesh Marimuthu</span
                          ><!----><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-4">
                          <span class="rightColEdit"> Contracting Entity</span>
                        </div>
                        <div class="col-md-6 cfstyle">
                          <span> Tata Communications (USA) B.V.</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-6">
                          <span class="rightColEdit">
                            Contact Title / Designation</span
                          >
                        </div>
                        <div class="col-md-6 cfstyle">
                          <span class="ng-star-inserted"> Account Manager</span
                          ><!----><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-4">
                          <span class="rightColEdit"> GST Number</span>
                        </div>
                        <div class="col-md-6 cfstyle">
                          <span class="ng-star-inserted"> 27DEMO2808C1DM</span
                          ><!----><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-6">
                          <span class="rightColEdit"> Phone</span>
                        </div>
                        <div class="col-md-6 cfstyle">
                          <span class="ng-star-inserted"> +1 17323970630</span
                          ><!----><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-4">
                          <span class="rightColEdit"> Email Id</span>
                        </div>
                        <div class="col-md-6 cfstyle">
                          <span class="ng-star-inserted">
                            jayashree.j@tatacommunications.com</span
                          ><!----><!---->
                        </div>
                      </div>
                    </div>
                    <div class="col-md-6">
                      <div class="row customerItem">
                        <div class="col-md-6">
                          <span class="rightColEdit"> Address</span>
                        </div>
                        <div class="col-md-6">
                          <span
                            ><p class="cfstyle">
                              Videsh Sanchar Bhavan, MG Road, Opp Cross Maidan
                              Fort,Mumbai India,400001
                            </p></span
                          >
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <!---------------------------------------Terms & condition-------------------------------------------------------------->
              <div
                class="customScroller tab-pane fade text-justify active show"
                id="conditions"
                role="tabpanel"
                aria-labelledby="conditions"
                *ngIf="showingTab == 'terms'"
              >
                <div id="termsandconditions" #termsandconditions>
                  <div id="myTabContent" class="customOverFlow">
                    <span class="ng-star-inserted"
                      ><mat-accordion
                        class="mat-accordion mat-accordion-multi ng-star-inserted"
                        ><mat-expansion-panel
                          class="mat-expansion-panel ng-tns-c211-5 mat-expanded mat-expansion-panel-spacing ng-star-inserted"
                          ><mat-expansion-panel-header
                            role="button"
                            class="mat-expansion-panel-header mat-focus-indicator multi-dc-expansion-panel-header ng-tns-c213-6 ng-tns-c211-5 mat-expanded mat-expansion-toggle-indicator-after ng-star-inserted"
                            id="mat-expansion-panel-header-1"
                            tabindex="0"
                            aria-controls="cdk-accordion-child-1"
                            aria-expanded="true"
                            aria-disabled="false"
                            ><span class="mat-content ng-tns-c213-6"
                              ><mat-panel-title
                                class="mat-expansion-panel-header-title ng-tns-c213-6"
                                ><div>
                                  IZO<sup>TM</sup> &nbsp; Multi Cloud Connect
                                  Flex - Chicago
                                </div></mat-panel-title
                              ></span
                            ><span
                              class="mat-expansion-indicator ng-tns-c213-6 ng-trigger ng-trigger-indicatorRotate ng-star-inserted"
                              style="transform: rotate(180deg)"
                            ></span
                            ><!----></mat-expansion-panel-header
                          >
                          <div
                            role="region"
                            class="mat-expansion-panel-content ng-tns-c211-5 ng-trigger ng-trigger-bodyExpansion"
                            id="cdk-accordion-child-1"
                            aria-labelledby="mat-expansion-panel-header-1"
                            style="visibility: visible"
                          >
                            <div class="mat-expansion-panel-body ng-tns-c211-5">
                              <br class="break ng-tns-c211-5" />
                              <div class="p-2 col-12 ng-tns-c211-5">
                                <div class="attr-type header-text sub-header">
                                  Order Details
                                </div>
                                <div class="p-2">
                                  <div class="w33">
                                    <div class="attr-type">Order Type</div>
                                    <div class="attr-display">NEW</div>
                                  </div>
                                  <div class="w33">
                                    <div class="attr-type">Product Name</div>
                                    <div class="attr-display">
                                      IZO<sup>TM</sup> &nbsp; Multi Cloud
                                      Connect Flex
                                    </div>
                                  </div>
                                  <div class="w33">
                                    <div class="attr-type">Order Date</div>
                                    <div class="attr-display">
                                      {{ today | date : "dd/MM/yyyy" }}
                                    </div>
                                  </div>
                                  <div class="w33">
                                    <div class="attr-type">Quote Code</div>
                                    <div class="attr-display">
                                      IZOMCCDEMO001
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="p-2 col-12 ng-tns-c211-5">
                                <div class="p-2">
                                  <div class="w33" style="display: none">
                                    <div class="attr-type">Service ID</div>
                                    <div class="attr-display">-</div>
                                  </div>
                                  <div class="w33">
                                    <div class="attr-type">
                                      Connection alias name
                                    </div>
                                    <div class="attr-display">qwert</div>
                                  </div>
                                  <div class="w33">
                                    <div class="attr-type">Contract term</div>
                                    <div class="attr-display">1 Month</div>
                                  </div>
                                </div>
                              </div>
                              <div class="p-2 col-12 ng-tns-c211-5">
                                <div class="attr-type header-text sub-header">
                                  Location
                                </div>
                                <div class="p-2">
                                  <div class="w33">
                                    <div class="attr-type">Region</div>
                                    <div class="attr-display">AMER</div>
                                  </div>
                                  <div class="w33">
                                    <div class="attr-type">Location</div>
                                    <div class="attr-display">Chicago</div>
                                  </div>
                                </div>
                              </div>
                              <div class="p-2 col-12 ng-tns-c211-5">
                                <div class="attr-type header-text sub-header">
                                  Connector
                                </div>
                                <div class="ng-star-inserted" style="">
                                  <!---->
                                </div>
                                <div class="ng-star-inserted" style="">
                                  <div class="p-2 ng-star-inserted">
                                    <div class="w33 ng-star-inserted">
                                      <div class="attr-type">Alias Name</div>
                                      <div class="attr-display">
                                        SingleLink-TCL DEMO
                                      </div>
                                    </div>
                                    <div class="w33 ng-star-inserted">
                                      <div class="attr-type">
                                        Connectivity Type
                                      </div>
                                      <div class="attr-display">Internet</div>
                                    </div>
                                    <div class="w33 ng-star-inserted">
                                      <div class="attr-type">
                                        Connectivity Bandwidth
                                      </div>
                                      <div class="attr-display">50Mbps</div>
                                    </div>
                                    <div class="w33 ng-star-inserted">
                                      <div class="attr-type">Link Type</div>
                                      <div class="attr-display">Single</div>
                                    </div>
                                    <div class="w33 ng-star-inserted">
                                      <div class="attr-type">
                                        Component Order Type
                                      </div>
                                      <div class="attr-display">New</div>
                                    </div>
                                    <!---->
                                  </div>
                                  <!---->
                                </div>
                                <div class="ng-star-inserted" style="">
                                  <!---->
                                </div>
                                <!---->
                              </div>
                              <div class="p-2 col-12 ng-tns-c211-5">
                                <div class="attr-type header-text sub-header">
                                  VNF Service
                                </div>
                                <div>
                                  <div class="p-2">
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">Device Model</div>
                                      <div class="attr-display">
                                        Cisco Catalyst 8000v Router
                                      </div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">Vnf Core Size</div>
                                      <div class="attr-display">
                                        Small (2core)
                                      </div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">Memory</div>
                                      <div class="attr-display">8GB</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Device Alias Name
                                      </div>
                                      <div class="attr-display">2-CORE_8GB</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Local As Number
                                      </div>
                                      <div class="attr-display">64550</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">Link Type</div>
                                      <div class="attr-display">Single</div>
                                    </div>
                                    <!---->
                                  </div>
                                </div>
                              </div>
                              <div class="p-2 col-12 ng-tns-c211-5">
                                <div class="attr-type header-text sub-header">
                                  Cloud Connect
                                </div>
                                <div>
                                  <div class="p-2">
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Cloud Provider
                                      </div>
                                      <div class="attr-display">
                                        Microsoft Azure
                                      </div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Interconnect Location
                                      </div>
                                      <div class="attr-display">Chicago</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Cloud Bandwidth
                                      </div>
                                      <div class="attr-display">50Mbps</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">Link Type</div>
                                      <div class="attr-display">Dual</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Cloud Alias Name
                                      </div>
                                      <div class="attr-display">
                                        Azure_50Mbps_Dual-Link
                                      </div>
                                    </div>
                                    <!---->
                                  </div>
                                </div>
                                <!---->
                                <div class="ng-star-inserted" style="">
                                  <div class="p-2">
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Cloud Provider
                                      </div>
                                      <div class="attr-display">
                                        Google Cloud Provider
                                      </div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Interconnect Location
                                      </div>
                                      <div class="attr-display">Chicago</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Cloud Bandwidth
                                      </div>
                                      <div class="attr-display">50Mbps</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">Link Type</div>
                                      <div class="attr-display">Single</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Cloud Alias Name
                                      </div>
                                      <div class="attr-display">
                                        GCP_50Mbps_Single-Link
                                      </div>
                                    </div>
                                    <!---->
                                  </div>
                                </div>
                                <!----><!---->
                                <div class="ng-star-inserted" style="">
                                  <div class="p-2">
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Cloud Provider
                                      </div>
                                      <div class="attr-display">
                                        Amazon Web Services
                                      </div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Interconnect Location
                                      </div>
                                      <div class="attr-display">Chicago</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Cloud Bandwidth
                                      </div>
                                      <div class="attr-display">50Mbps</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">Link Type</div>
                                      <div class="attr-display">Single</div>
                                    </div>
                                    <div
                                      style="
                                        display: inline-block;
                                        padding-bottom: 20px;
                                        width: 25% !important;
                                      "
                                      class="ng-star-inserted"
                                    >
                                      <div class="attr-type">
                                        Cloud Alias Name
                                      </div>
                                      <div class="attr-display">
                                        AWS_50Mbps_Single-Link
                                      </div>
                                    </div>
                                    <!---->
                                  </div>
                                </div>
                                <!---->
                              </div>
                              <!---->
                            </div>
                          </div></mat-expansion-panel
                        ><!----><!----></mat-accordion
                      ><!----></span
                    ><!---->
                  </div>
                </div>
              </div>
            </div>
            <div
              class="card-up-footer row up-box align-items-center pl-0 text-uppercase"
            >
              <div
                class="footer-up-text pl-4"
                style="margin-top: 4px !important"
              >
                <span>Documents :</span>
              </div>
              <div
                *ngIf="isCommercialUser"
                class="footer-up-text cursor-pointer"
                (click)="validateImpInfo('downloadCof')"
                style="margin-left: 27px; margin-top: 5px"
                [class.disabled]="creditCheck.creditCheckStatusFlag"
              >
                <span>
                  <i class="icon icon-download" style="color: #ea7926"></i
                ></span>
                <span class="up-color ml-1">DRAFT ORDER FORM</span>
              </div>
              <div
                class="col-md-2 p-0 b-r-2 text-center pointer"
                *ngFor="let docs of reviewAccept.documents"
                (click)="openDocument(docs)"
                [class.disabled]="creditCheck.creditCheckStatusFlag"
              >
                <i class="icon icon-download" style="color: #ea7926"></i
                ><span
                  class="up-color ml-1"
                  style="font-weight: bolder !important; font-size: 0.8571rem"
                  >{{ docs.docType }}</span
                >
              </div>
              <!-- <div class="footer-up-text row col-7">
                            <div *ngIf="!isECOInternetVariant" class="text-uppercase align-self-center col p-0"
                                [class.disabled]="creditCheck.creditCheckStatusFlag">
                                <i class="icon icon-download" style="color: #ea7926"></i>
                                <a class="up-color ml-1" (click)="downloadServiceScheduleExhibits()"
                                    style="padding-left:5px">
                                    Exhibit</a>
                            </div>
                        </div> -->
            </div>

            <!-- <div class="card-footer  justify-content-between align-items-center  pl-0" style="padding: 7px 0px;">

                        <form class="form-inline">
                            <div class="form-group align-self-stretch col-10">
                                <label class="check-box check-box-left text-uppercase align-self-center" style="font-family: Gotham-Medium;font-size: 12px">
                                        <input type="checkbox" tabindex="0" name="termAndCondition" value="read-tc"
                                            [(ngModel)]="reviewAccept.termsCondition"
                                             (change)="checkTermsAndConditions()">

                                        <span class="checkmark"></span>

                                        I here by acknowledge that I have read and understood the terms and conditions.

                                    </label>
                            </div>

                            <div class="col-2">
                                <button [disabled]="!reviewAccept.termsCondition || creditCheck.creditCheckStatus !== 'Positive'" type="button" class="btn active-btn modal-slider float-right" style="width: 140px;" (click)="postGenerateOrderForm()"> DEPLOY ORDER</button>
                            </div>

                        </form>

                    </div> -->

            <div
              class="card-footer d-flex justify-content-between align-items-center order-form-footer pl-0"
              style="height: 10px"
            >
              <!-- *ngIf="flag.productValidation && isCommercialUser" -->
              <form class="form-inline">
                <div
                  class="form-group align-self-stretch"
                  style="width: auto; height: 10px; margin-top: 21px"
                >
                  <label
                    class="check-box check-box-left text-uppercase align-self-center"
                    style="font-family: Gotham-Medium; font-size: 12px"
                  >
                    <input
                      type="checkbox"
                      tabindex="0"
                      name="termAndCondition"
                      value="read-tc"
                      [(ngModel)]="reviewAccept.termsCondition"
                      (change)="checkTermsAndConditions()"
                    />

                    <span class="checkmark"></span>

                    I here by acknowledge that I have read and understood the
                    terms and conditions.
                  </label>
                </div>
                <!-- <div class="form-group align-self-stretch"
                            matTooltip="Please visit all the tabs and read Terms & Conditions"
                            style="width:auto;height: 10px;margin-top: 21px;">
                            <label class="check-box check-box-left text-uppercase">
                                <input type="checkbox" tabindex="0" value="read-tc" name="acceptOrder"
                                    [(ngModel)]="reviewAccept.electSign">
                                <span class="checkmark"></span>
                                I accept and electronically sign. <a class="text-link"
                                    (click)="$event.preventDefault();reviewAccept.is_terms_visited = true;checkPlaceOrderEnabled('terms')">
                                    T&amp;Cs</a>
                            </label>
                        </div> -->

                <div
                  class="form-group align-self-stretch"
                  style="width: auto; z-index: 1111"
                  *ngIf="
                    (!reviewAccept.isDocusign && !reviewAccept.isDelegated) ||
                    (reviewAccept?.isDelegated === 'Self' &&
                      listOfActions.indexOf('OPT_L2O_ORDER_CREATION') > -1)
                  "
                >
                  <label
                    class="check-box check-box-left text-uppercase align-self-center sales"
                    [popover]="salesPopTemplate"
                    containerClass="popCustomClass"
                    #pop="bs-popover"
                    (click)="
                      reviewAccept.placeOrder = ''; checkTermsAndConditions()
                    "
                    style="margin-top: 11px"
                  >
                    Other Signing Options
                  </label>
                </div>
                <button
                  *ngIf="
                    !reviewAccept.impersonateUser &&
                    !reviewAccept.isDocusign &&
                    reviewAccept.signing?.CLICK_THROUGH &&
                    reviewAccept.placeOrder == 'Place Order'
                  "
                  [ngClass]="
                    reviewAccept.enablePlaceOrder &&
                    !creditCheck.creditCheckStatusFlag
                      ? 'active-btn'
                      : 'inactive-btn'
                  "
                  class="btn text-uppercase order-btn"
                  (click)="
                    reviewAccept.confirmOrder = reviewAccept.payConfirm = false;
                    proceedNext()
                  "
                >
                  Place Order
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</main>

<ng-template #popTooltip>
  <div [innerHtml]="html"></div>
</ng-template>

<ng-template #popTooltip>
  <div [innerHtml]="checkmsg"></div>
</ng-template>
<!-- sign in popup-->
<ng-template #salesPopTemplate>
  <div class="padding-class p-8">
    <ng-container>
      <!-- <label class="radio-box" *ngIf="reviewAccept.signing.DOCUSIGN_AVAILABILITY">
                <input [(ngModel)]="reviewAccept.salesValue" value="docusign" type="radio" name="docusign"
                    (ngModelChange)="salesFn('docusign')" class="removeRadio" [disabled]="!reviewAccept.enablePlaceOrder || creditCheck.creditCheckStatusFlag">
                <span class="checkmark customRadioButton"></span>

                <ng-container>
                    <img class="icon-img" src="./assets/images/docusign.png" alt=""></ng-container>
            </label> -->
      <!-- *ngIf="!reviewAcceptObj.psamUserLogin" -->
      <label
        class="radio-box"
        style="margin-top: 15px"
        *ngIf="reviewAccept.isDelegated !== 'Self'"
      >
        <input
          [(ngModel)]="reviewAccept.salesValue"
          value="delegate"
          type="radio"
          name="delegate"
          (click)="salesFn('delegate')"
          class="removeRadio"
          [disabled]="
            creditCheck.creditCheckStatusFlag || !reviewAccept.termsCondition
          "
        />
        <span class="checkmark customRadioButton"></span>
        <ng-container
          ><span class="mt-3"
            ><b>Request for approval from authorised signatory</b></span
          >
        </ng-container>
      </label>
    </ng-container>
    <label class="radio-box" style="margin-top: 15px">
      <input
        [(ngModel)]="reviewAccept.salesValue"
        value="download"
        type="radio"
        name="download"
        (click)="saleSelectionChanged('Place Order')"
        (click)="
          reviewAccept.confirmOrder = reviewAccept.payConfirm = true;
          checkNATSelected(confirmOrderModal)
        "
        class="removeRadio"
        [disabled]="
          creditCheck.creditCheckStatusFlag || !reviewAccept.termsCondition
        "
      />
      <span class="checkmark customRadioButton"></span>
      <ng-container>Order as you click</ng-container>
    </label>
  </div>
</ng-template>
<!-- sign in popup end-->

<!--Document View Frame-->
<ng-template #MSA>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">DOCUMENT</h5>
    <button
      type="button"
      class="close"
      (click)="modalRef.hide()"
      aria-label="Close"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <iframe
      id="scpiframeid"
      type="application/pdf"
      style="width: 100%; height: 400px; max-height: 400px"
    >
    </iframe>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      data-dismiss="modal"
      class="btn active-btn"
      (click)="modalRef.hide()"
    >
      Close
    </button>
  </div>
</ng-template>

<!--Document view frame end-->

<ng-template #DocuSignForSalesLogin>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">DOCUSIGN</h5>
    <button
      type="button"
      class="close"
      (click)="
        reviewAccept.mailConfirmationModalRef.hide();
        reviewAccept.docusignFlag = false;
        resetFormValues()
      "
      aria-label="Close"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="col-md-12">
      <p>
        Please select the signer who is authorized to proceed with order
        acceptance
      </p>
      <div class="col-md-12 row">
        <p class="font-bold col-md-3 mt-3">
          SIGNER 1 <span style="color: red">*</span>
        </p>

        <div class="form-group col-md-6 p-0">
          <div class="">
            <mat-form-field class="col-md-12">
              <mat-select
                placeholder="Select User"
                [formControl]="selectedUser"
                (openedChange)="reviewAccept.docusignSearch = null"
              >
                <div class="search-section">
                  <input
                    type="text"
                    class="le-search-mat"
                    placeholder="Search"
                    [(ngModel)]="reviewAccept.docusignSearch"
                    data-matomo-unmask
                    autocomplete="off"
                  />
                  <i
                    class="fa fa-search le-search-icon le-search-icon-mat"
                    aria-hidden="true"
                  ></i>
                </div>
                <ng-container
                  *ngFor="
                    let user of reviewAccept.userList
                      | filter
                        : reviewAccept.docusignSearch
                        : 'customerLeContactEmailid'
                  "
                >
                  <mat-option
                    *ngIf="
                      user.isSelected !== 'firstReviewer' &&
                      user.isSelected !== 'secondSigner' &&
                      user.isSelected !== 'thirdSigner' &&
                      user.isSelected !== 'carbonCopy' &&
                      user.isSelected !== 'secondReviewer'
                    "
                    (click)="onSelectUser(user, 'firstSigner')"
                    [value]="user"
                  >
                    {{
                      displayCustomerName(
                        user.customerLeContactName,
                        user.customerLeContactEmailid
                      )
                    }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="col-md-12 row" *ngIf="showSecondSigner">
        <p class="font-bold col-md-3 mt-3">SIGNER 2</p>
        <div class="form-group col-md-6 p-0">
          <div class="">
            <mat-form-field class="col-md-12">
              <mat-select
                placeholder="Select User"
                [formControl]="secondSigner"
                (openedChange)="reviewAccept.secondSignerSearch = null"
              >
                <div class="search-section">
                  <input
                    type="text"
                    class="le-search-mat"
                    placeholder="Search"
                    [(ngModel)]="reviewAccept.secondSignerSearch"
                    data-matomo-unmask
                    autocomplete="off"
                  />
                  <i
                    class="fa fa-search le-search-icon le-search-icon-mat"
                    aria-hidden="true"
                  ></i>
                </div>
                <ng-container
                  *ngFor="
                    let user of reviewAccept.userList
                      | filter
                        : reviewAccept.secondSignerSearch
                        : 'customerLeContactEmailid'
                  "
                >
                  <mat-option
                    (click)="onSelectUser(user, 'secondSigner')"
                    *ngIf="
                      user.isSelected !== 'firstReviewer' &&
                      user.isSelected !== 'secondReviewer' &&
                      user.isSelected !== 'firstSigner' &&
                      user.isSelected !== 'thirdSigner' &&
                      user.isSelected !== 'carbonCopy'
                    "
                    [value]="user"
                  >
                    {{
                      displayCustomerName(
                        user.customerLeContactName,
                        user.customerLeContactEmailid
                      )
                    }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <p
          class="col-md-1 fa fa-trash mt-3 p-0"
          (click)="onClickTrashIcon('secondSigner')"
        ></p>
      </div>
      <div class="col-md-12 row" *ngIf="showThirdSigner">
        <p class="font-bold col-md-3 mt-3">SIGNER 3</p>
        <div class="form-group col-md-6 p-0">
          <div class="">
            <mat-form-field class="col-md-12">
              <mat-select
                placeholder="Select User"
                [formControl]="thirdSigner"
                (openedChange)="reviewAccept.thirdSignerSearch = null"
              >
                <div class="search-section">
                  <input
                    type="text"
                    class="le-search-mat"
                    placeholder="Search"
                    [(ngModel)]="reviewAccept.thirdSignerSearch"
                    data-matomo-unmask
                    autocomplete="off"
                  />
                  <i
                    class="fa fa-search le-search-icon le-search-icon-mat"
                    aria-hidden="true"
                  ></i>
                </div>
                <ng-container
                  *ngFor="
                    let user of reviewAccept.userList
                      | filter
                        : reviewAccept.thirdSignerSearch
                        : 'customerLeContactEmailid'
                  "
                >
                  <mat-option
                    (click)="onSelectUser(user, 'thirdSigner')"
                    *ngIf="
                      user.isSelected !== 'firstReviewer' &&
                      user.isSelected !== 'secondReviewer' &&
                      user.isSelected !== 'firstSigner' &&
                      user.isSelected !== 'secondSigner' &&
                      user.isSelected !== 'carbonCopy'
                    "
                    [value]="user"
                  >
                    {{
                      displayCustomerName(
                        user.customerLeContactName,
                        user.customerLeContactEmailid
                      )
                    }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <p
          class="col-md-1 fa fa-trash mt-3 p-0"
          (click)="onClickTrashIcon('thirdSigner')"
        ></p>
      </div>

      <div class="col-md-12 row" *ngIf="showCarbonCopy">
        <p class="font-bold col-md-3 mt-3">Carbon Copy</p>
        <div class="form-group col-md-6 p-0">
          <div class="">
            <mat-form-field class="col-md-12">
              <mat-select
                placeholder="Select User"
                [formControl]="customerCC"
                multiple
                (openedChange)="reviewAccept.customerCCSearch = null"
              >
                <div class="search-section">
                  <input
                    type="text"
                    class="le-search-mat"
                    placeholder="Search"
                    [(ngModel)]="reviewAccept.customerCCSearch"
                    data-matomo-unmask
                    autocomplete="off"
                  />
                  <i
                    class="fa fa-search le-search-icon le-search-icon-mat"
                    aria-hidden="true"
                  ></i>
                </div>
                <mat-select-trigger>
                  {{
                    customerCC.value && customerCC.value[0]
                      ? customerCC.value[0].customerLeContactName
                      : ""
                  }}
                  <span
                    *ngIf="customerCC.value?.length > 1"
                    class="example-additional-selection"
                  >
                    (+{{ customerCC.value.length - 1 }}
                    {{ customerCC.value?.length === 2 ? "other" : "others" }})
                  </span>
                </mat-select-trigger>
                <ng-container
                  *ngFor="
                    let user of reviewAccept.userList
                      | filter
                        : reviewAccept.customerCCSearch
                        : 'customerLeContactEmailid'
                  "
                >
                  <mat-option
                    (click)="onClickCCUser(user)"
                    *ngIf="
                      user.isSelected !== 'firstReviewer' &&
                      user.isSelected !== 'firstSigner' &&
                      user.isSelected !== 'secondSigner' &&
                      user.isSelected !== 'thirdSigner' &&
                      user.isSelected !== 'secondReviewer'
                    "
                    [value]="user"
                  >
                    {{
                      displayCustomerName(
                        user.customerLeContactName,
                        user.customerLeContactEmailid
                      )
                    }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <p
          class="col-md-1 fa fa-trash mt-3 p-0"
          (click)="onClickTrashIcon('carbonCopy')"
        ></p>
      </div>
      <div class="col-md-12 row" *ngIf="showFirstReviewer">
        <p class="font-bold col-md-3 mt-3">Reviewer 1</p>
        <div class="form-group col-md-6 p-0">
          <div class="">
            <mat-form-field class="col-md-12">
              <mat-select
                placeholder="Select User"
                [formControl]="firstReviewer"
                (openedChange)="reviewAccept.firstReviewerSearch = null"
              >
                <div class="search-section">
                  <input
                    type="text"
                    class="le-search-mat"
                    placeholder="Search"
                    [(ngModel)]="reviewAccept.firstReviewerSearch"
                    data-matomo-unmask
                    autocomplete="off"
                  />
                  <i
                    class="fa fa-search le-search-icon le-search-icon-mat"
                    aria-hidden="true"
                  ></i>
                </div>
                <ng-container
                  *ngFor="
                    let user of reviewAccept.userList
                      | filter : reviewAccept.firstReviewerSearch;
                    firstReviewerSearch: 'customerLeContactEmailid'
                  "
                >
                  <mat-option
                    (click)="onSelectUser(user, 'firstReviewer')"
                    *ngIf="
                      user.isSelected !== 'secondReviewer' &&
                      user.isSelected !== 'firstSigner' &&
                      user.isSelected !== 'secondSigner' &&
                      user.isSelected !== 'thirdSigner' &&
                      user.isSelected !== 'carbonCopy'
                    "
                    [value]="user"
                  >
                    {{
                      displayCustomerName(
                        user.customerLeContactName,
                        user.customerLeContactEmailid
                      )
                    }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <p
          class="col-md-1 fa fa-trash mt-3 p-0"
          (click)="onClickTrashIcon('firstReviewer')"
        ></p>
      </div>
      <div class="col-md-12 row" *ngIf="showSecondReviewer">
        <p class="font-bold col-md-3 mt-3">Reviewer 2</p>
        <div class="form-group col-md-6 p-0">
          <div class="">
            <mat-form-field class="col-md-12">
              <mat-select
                placeholder="Select User"
                [formControl]="secondReviewer"
                (openedChange)="reviewAccept.secondReviewerSearch = null"
              >
                <div class="search-section">
                  <input
                    type="text"
                    class="le-search-mat"
                    placeholder="Search"
                    [(ngModel)]="reviewAccept.secondReviewerSearch"
                    data-matomo-unmask
                    autocomplete="off"
                  />
                  <i
                    class="fa fa-search le-search-icon le-search-icon-mat"
                    aria-hidden="true"
                  ></i>
                </div>
                <ng-container
                  *ngFor="
                    let user of reviewAccept.userList
                      | filter
                        : reviewAccept.secondReviewerSearch
                        : 'customerLeContactEmailid'
                  "
                >
                  <mat-option
                    (click)="onSelectUser(user, 'secondReviewer')"
                    *ngIf="
                      user.isSelected !== 'firstReviewer' &&
                      user.isSelected !== 'firstSigner' &&
                      user.isSelected !== 'secondSigner' &&
                      user.isSelected !== 'thirdSigner' &&
                      user.isSelected !== 'carbonCopy'
                    "
                    [value]="user"
                  >
                    {{
                      displayCustomerName(
                        user.customerLeContactName,
                        user.customerLeContactEmailid
                      )
                    }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <p
          class="col-md-1 fa fa-trash mt-3 p-0"
          (click)="onClickTrashIcon('secondReviewer')"
        ></p>
      </div>
      <div
        *ngIf="reviewAccept.userList && reviewAccept.userList.length > 1"
        class="col-md-6 p-0"
        style="color: #e87624; margin: 0 auto"
      >
        <span
          class="additional-text pr-3"
          *ngIf="
            (!showSecondSigner || !showThirdSigner) && unSelectedUserPresent
          "
          (click)="onClickAddSigner()"
          >Add Signer</span
        >
        <span
          class="additional-text pr-3"
          *ngIf="
            (!showFirstReviewer || !showSecondReviewer) && unSelectedUserPresent
          "
          (click)="onClickAddReviewer()"
          >Add Reviewer</span
        >
        <span
          class="additional-text"
          *ngIf="
            !showCarbonCopy && unSelectedUserPresent && reviewAccept.salesLogin
          "
          (click)="showCarbonCopy = true"
          >Add Carbon Copy</span
        >
      </div>
    </div>
  </div>
  <div class="modal-footer mt-3">
    <button
      type="button"
      (click)="reviewAccept.mailConfirmationModalRef.hide()"
      class="btn secondary-outline-btn"
      (click)="reviewAccept.docusignFlag = false; resetFormValues()"
    >
      CANCEL
    </button>
    <button
      type="button"
      class="btn active-btn"
      *ngIf="reviewAccept.docusignFlag"
      [disabled]="
        reviewAccept.docuFlag ||
        !selectedUser?.value?.customerLeContactName ||
        (showSecondReviewer && !secondReviewer?.value?.customerLeContactName) ||
        (showFirstReviewer && !firstReviewer?.value?.customerLeContactName) ||
        (showCarbonCopy && !customerCC.value) ||
        (customerCC.value && customerCC.value.length === 0)
      "
      (click)="onClickSubmit()"
    >
      Submit
    </button>
  </div>
</ng-template>

<!-- Delegate modal -->
<ng-template #delgationTemplate>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">Nominate who will accept the order form</h5>
    <button
      type="button"
      class="close"
      (click)="modalRef.hide()"
      aria-label="Close"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <p>
        Please select the user who is authorized to proceed with order
        acceptance. We will then send the user an email with a link to this page
        so he (or she) will be able to complete order placement.
      </p>
      <!-- <input [(ngModel)]="reviewAccept.selectedDelegatePerson" [typeahead]="reviewAccept.listOfUser" typeaheadOptionField="username"
                            class="form-control" placeholder="Please select name" (typeaheadNoResults)="typeaheadNoResults($event)"> -->
      <angular2-multiselect
        [data]="reviewAccept.listOfUser"
        [(ngModel)]="reviewAccept.selectedDelegatePerson"
        [settings]="mailDropSettings"
      >
      </angular2-multiselect>

      <span
        *ngIf="
          reviewAccept.validation.submitted &&
          reviewAcceptObj.validation.invalidEmail
        "
        class="text-danger"
        >Invalid Email Address
      </span>
      <span
        *ngIf="
          reviewAccept.validation.submitted &&
          reviewAcceptObj.validation.emailMandate
        "
        class="text-danger"
        >Email Address Required</span
      >
      <div class="alert alert-danger pad-0" *ngIf="reviewAccept.noResult">
        No Results Found
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      data-dismiss="modal"
      class="btn secondary-dark-outline-btn editButton"
      (click)="
        reviewAccept.delegateRights = !reviewAccept.delegateRights;
        modalRef.hide()
      "
    >
      CANCEL
    </button>
    <button
      type="button"
      data-dismiss="modal"
      class="btn active-btn"
      (click)="modalRef.hide(); submitDelegation()"
      [disabled]="
        reviewAccept.selectedDelegatePerson == '' ||
        !reviewAccept.selectedDelegatePerson
      "
    >
      SUBMIT
    </button>
  </div>
</ng-template>
<ng-template #confirmOrderModal>
  <div class="modal-header">
    <h5 class="modal-title">Confirm Order</h5>
    <button
      type="button"
      class="close"
      (click)="modalRef.hide()"
      aria-label="Close"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label class="check-box check-box-left text-uppercase">
        <input
          type="checkbox"
          tabindex="0"
          value="read-tc"
          name="acceptOrder"
          [(ngModel)]="reviewAccept.electSign"
        />
        <span class="checkmark"></span>
        I accept and electronically sign.
      </label>
    </div>
    <div class="form-group">
      <label class="check-box check-box-left text-uppercase">
        <input
          type="checkbox"
          tabindex="0"
          value="read-tc"
          name="confirmOrder"
          [(ngModel)]="reviewAccept.confirmOrder"
        />
        <span class="checkmark"></span>
        I confirm the order placement
      </label>
    </div>
    <div class="form-group" *ngIf="quoteInfo.quoteType != 'MIGRATION'">
      <label class="check-box check-box-left text-uppercase">
        <input
          type="checkbox"
          tabindex="0"
          name="payConfirm"
          value="read-tc"
          [(ngModel)]="reviewAccept.payConfirm"
        />
        <span class="checkmark"></span>
        I agree to pay the associated charges
      </label>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn secondary-dark-outline-btn editButton"
      (click)="modalRef.hide()"
    >
      CANCEL
    </button>
    <button
      type="button"
      [ngClass]="
        reviewAccept.confirmOrder &&
        reviewAccept.payConfirm &&
        reviewAccept.electSign
          ? 'active-btn'
          : 'inactive-btn'
      "
      class="btn"
      (click)="modalRef.hide(); submitDepolyOrder(); clicked = true"
    >
      PLACE ORDER
    </button>
    <!-- <button type="button" *ngIf="quoteInfo.quoteType == 'MIGRATION'"
            [ngClass]="(reviewAccept.confirmOrder)?'active-btn':'inactive-btn'" class="btn"
            (click)="proceedNext();modalRef.hide()">SUBMIT</button> -->
  </div>
</ng-template>

<div [messageModal]="messageModalSettings"></div>
<!-- Delegate modal end-->

<!-- confirmation model -->

<ng-template #deployOrderTemplate>
  <div
    class="modal-header d-flex justify-content-between align-items-center flex-row"
  >
    <h5 class="modal-title">Confirm Order</h5>
    <button
      type="button"
      class="close"
      (click)="modalRef.hide()"
      aria-label="Close"
    >
      <i class="icon-close"></i>
    </button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <div class="form-group align-self-stretch">
        <p class="text-uppercase txtclr">
          I CONFIRM THE ORDER PLACEMENT AND I AGREE TO PAY THE ASSOCIATED
          CHARGES
        </p>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      data-dismiss="modal"
      class="btn secondary-dark-outline-btn editButton"
      (click)="
        reviewAccept.delegateRights = !reviewAccept.delegateRights;
        modalRef.hide()
      "
    >
      CANCEL
    </button>
    <button
      type="button"
      data-dismiss="modal"
      class="btn active-btn"
      [disabled]="clicked"
      (click)="modalRef.hide(); submitDepolyOrder(); clicked = true"
    >
      SUBMIT
    </button>
  </div>
</ng-template>
