import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'attrOrder' })
export class attrOrderPipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      return value.sort((a, b) => {
        return a.displayOrder - b.displayOrder;
      });
    }
  }
}
@Pipe({ name: 'customDisplayOrder' })
export class customOrderPipe implements PipeTransform {
  transform(value: any): any {
    return value.sort((a, b) => {
      if (a.customDisplayOrder === undefined) {
        a.customDisplayOrder = 100;
      }

      if (b.customDisplayOrder === undefined) {
        b.customDisplayOrder = 100;
      }

      return a.customDisplayOrder - b.customDisplayOrder;
    });
  }
}

@Pipe({ name: 'filter' })
export class filterPipe implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(function (item) {
      return JSON.stringify(item).toLowerCase().includes(searchText);
    });
  }
}

@Pipe({ name: 'keys', pure: false })
export class KeysPipe implements PipeTransform {
  transform(value: any, args: any[] = null): any {
    return Object.keys(value); //.map(key => value[key]);
  }
}

@Pipe({
  name: 'titlePipe',
})
export class TitlePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    return value.replace(/_/g, ' ');
  }
}
@Pipe({
  name: 'titleCasePipe',
})
export class TitleCasePipe implements PipeTransform {
  transform(value: any, args?: any): any {
    value = value.replace(/([A-Z])/g, ' $1').trim();
    return value.replace('_', ' ');
  }
}
@Pipe({
  name: 'minuteSeconds',
})
export class MinuteSecondsPipe implements PipeTransform {
  transform(value: number): string {
    let temp = value;
    const hours = Math.floor(temp / 60);
    let minutes = Math.floor(temp % 60);
    let convertedMin = minutes < 10 ? '0' + minutes.toString() : minutes;
    return hours + ':' + convertedMin;
  }
}
@Pipe({ name: 'filternew' })
export class filterPipeNew implements PipeTransform {
  transform(items: any[], searchText: string): any[] {
    if (!items) return [];
    if (!searchText) return items;
    searchText = searchText.toLowerCase();
    return items.filter(function (item) {
      return JSON.stringify(item).toLowerCase().includes(searchText);
    });
  }
}

@Pipe({
  name: 'uniqueBy',
})
export class UniqueByPipe implements PipeTransform {
  transform(value: any[], key: string): any[] {
    if (!Array.isArray(value) || !key) {
      return value;
    }

    return Array.from(new Map(value.map((item) => [item[key], item])).values());
  }
}
