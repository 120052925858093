<main _ngcontent-qnp-c446="">
  <section _ngcontent-qnp-c446="" class="container ias-select-variant">
    <section _ngcontent-qnp-c446="" class="container-header">
      <div _ngcontent-qnp-c446="" class="row">
        <div _ngcontent-qnp-c446="" class="col-md-7 align-self-start">
          <h2 _ngcontent-qnp-c446="">
            Please select the variant(s) relevant to you
          </h2>
          <p _ngcontent-qnp-c446="">
            Choose from any of the popular pre-defined configurations
          </p>
          <a _ngcontent-qnp-c446="" class="back-arrow"
            ><i
              _ngcontent-qnp-c446=""
              class="icon icon-back"
              style="color: white; cursor: pointer"
            ></i
          ></a>
        </div>
        <div _ngcontent-qnp-c446="" class="col-md-3 align-self-end">
          <p _ngcontent-qnp-c446="" class="name-font">Demo Customer</p>
        </div>
        <!-- <div _ngcontent-qnp-c446="" class="col-md-2 align-self-end text-right">
          <button
            _ngcontent-qnp-c446=""
            type="submit"
            class="btn active-btn"
            tourAnchor="tour-2"
            (click)="navigatetolanding()"
          >
            Next
          </button>
        </div> -->
      </div>
    </section>
    <section _ngcontent-qnp-c446="" class="three-column-layout">
      <div _ngcontent-qnp-c446="" class="row">
        <div _ngcontent-qnp-c446="" class="col-md-3">
          <!--bindings={
  "ng-reflect-ng-if": "false"
}-->
          <div _ngcontent-qnp-c446="" class="expand-panel">
            <div
              _ngcontent-qnp-c446=""
              class="card"
              ng-reflect-ng-class="[object Object]"
            >
              <label
                _ngcontent-qnp-c446=""
                placement="right"
                class="check-box check-box-right header label-space card-block-height d-flex align-items-center"
                ><div _ngcontent-qnp-c446="" class="pro-name-width">
                  <span _ngcontent-qnp-c446="">
                    IZO<sup>TM</sup> Multi Cloud Connect Direct</span
                  >
                </div>
                <input
                  _ngcontent-qnp-c446=""
                  type="checkbox"
                  value=""
                  aria-expanded="false"
                  aria-controls="singleInternet"
                  id="configurations" /><span
                  _ngcontent-qnp-c446=""
                  class="checkmark"
                  ng-reflect-ng-class="[object Object]"
                ></span
              ></label>
              <div _ngcontent-qnp-c446="" class="card-body">
                <p _ngcontent-qnp-c446="" class="text-center">
                  <img
                    _ngcontent-qnp-c446=""
                    alt=""
                    class="expand-card-img"
                    src="./assets/images/direct.png"
                  />
                </p>
                <p _ngcontent-qnp-c446="" class="text-left">
                  Secure cloud connectivity to your business network through IZO
                  Multi Cloud Connect Direct.
                </p>
              </div>
              <div
                class="row"
                style="margin: 0; width: 75%; margin-bottom: 10px"
              >
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <button class="btn active-btn">Submit</button>
                </div>
              </div>
            </div>
          </div>
          <!--bindings={
  "ng-reflect-ng-if": "true"
}-->
        </div>
        <div _ngcontent-qnp-c446="" class="col-md-3">
          <div _ngcontent-qnp-c446="" class="expand-panel">
            <div
              _ngcontent-qnp-c446=""
              class="card"
              ng-reflect-ng-class="[object Object]"
              tourAnchor="tour-1"
            >
              <label
                _ngcontent-qnp-c446=""
                placement="right"
                class="check-box check-box-right header label-space card-block-height d-flex align-items-center"
                ><div _ngcontent-qnp-c446="" class="pro-name-width">
                  <span _ngcontent-qnp-c446="">
                    IZO<sup>TM</sup> Multi Cloud Connect</span
                  >
                </div>
                <input
                  _ngcontent-qnp-c446=""
                  type="checkbox"
                  value="false"
                  aria-expanded="false"
                  aria-controls="singleInternet"
                  id="configurationss" /><span
                  _ngcontent-qnp-c446=""
                  class="checkmark"
                  ng-reflect-ng-class="[object Object]"
                ></span
              ></label>
              <div _ngcontent-qnp-c446="" class="card-body">
                <p _ngcontent-qnp-c446="" class="text-center">
                  <!--bindings={
  "ng-reflect-ng-if": "false"
}--><img
                    _ngcontent-qnp-c446=""
                    alt=""
                    class="expand-card-img"
                    src="https://optimus-preprod.tatacommunications.com/optimus/assets/images/scp-img.png"
                  /><!--bindings={
  "ng-reflect-ng-if": "true"
}-->
                </p>
                <p _ngcontent-qnp-c446="" class="text-left">
                  Provide multi cloud Connectivity with enhanced agility and
                  control through IZO Multi Cloud Connect.
                </p>
              </div>
              <div
                class="row"
                style="margin: 0; width: 75%; margin-bottom: 10px"
              >
                <div class="col-md-6"></div>
                <div class="col-md-6">
                  <button
                    class="btn active-btn"
                    tourAnchor="tour-2"
                    (click)="navigatetolanding()"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </div>
          <!--bindings={
  "ng-reflect-ng-if": "true"
}--><!--bindings={
  "ng-reflect-ng-if": "false"
}-->
        </div>
        <!--bindings={
  "ng-reflect-ng-for-of": "[object Object],[object Object"
}-->
      </div>
    </section>
  </section>
</main>

<tour-step-template>
  <ng-template let-step="step">
    <div class="tour-pointer"></div>
    <div class="tour">
      <p class="ngxp-title">{{ step?.title }}</p>
      <p class="ngxp-content">{{ step?.content }}</p>
      <div class="tour-step-navigation">
        <button
          [disabled]="!tourService.hasPrev(step)"
          class="ngxp-btn"
          (click)="tourService.prev()"
        >
          « prev
        </button>

        <button class="ngxp-btn" (click)="handleNextButtonClick(step)">
          next »
        </button>
        <!-- <button class="ngxp-btn" (click)="tourService.end();setValuesOnNext(step?.anchorId)">end</button> -->
      </div>
    </div>
  </ng-template>
</tour-step-template>
