import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  constructor(private router:Router, private tourService: TourService) { }

  ngOnInit(): void {
    const tourSteps:any = [
      {
        anchorId: 'tour-1', // Solution Alias Name
        content: 'Please click here to sign in.', // Updated content
        title: 'Sign In', // Updated title
        placement: 'bottom'
    }
    ]

    this.tourService.initialize(tourSteps, {
      enableBackdrop: false,
      backdropConfig: {
        //@ts-ignore
        offset: 10,
        closeOnBackdropClick: false,
      },
    });

    setTimeout(()=>{
      this.tourService.start();
    },200)
  }

  navigatetoproduct(){
    this.router.navigate(['/product'])
  }
  setValuesOnNext(step:any){


    if(step==='tour-1'){
      this.router.navigate(['/product'])
    }
  }

  handleNextButtonClick(step: any) {
    if (this.tourService.hasNext(step)) {
        this.tourService.next();
        this.setValuesOnNext(step?.anchorId);
    } else {
        this.tourService.end();
        this.setValuesOnNext(step?.anchorId);
    }
}
}
