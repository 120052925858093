<main>
  <section class="mapInfo-section">
    <section class="map-header">
      <div class="row">
        <div class="col-md-8 p-0 align-self-start">
          <h2 class="heading-name">IZO<sup>TM</sup> MCC Dashboard- VNF</h2>
        </div>
        <div class="col-md-4 p-0 align-self-start text-right">
          <div *ngIf="userCountry != ''">
            <!-- btn active-btn  -->
            <!-- <button type="button" class="btn active-btn new-connection" (click)="procedToNex()"> + NEW
                            CONNECTION</button> -->
          </div>
        </div>
      </div>
      <div class="stepper-horizontal" id="stepper1">
        <div class="step editing">
          <div class="step-circle"><span>1</span></div>
          <div class="step-title">Location</div>
          <div class="step-bar-left"></div>
          <div class="step-bar-right"></div>
        </div>
        <div class="step">
          <div class="step-circle"><span>2</span></div>
          <div class="step-title">Contract Term</div>
          <div class="step-bar-left"></div>
          <div class="step-bar-right"></div>
        </div>
        <div class="step">
          <div class="step-circle"><span>3</span></div>
          <div class="step-title">Connector</div>
          <!-- <div class="step-optional">Optional</div> -->
          <div class="step-bar-left"></div>
          <div class="step-bar-right"></div>
        </div>
        <div class="step">
          <div class="step-circle"><span>4</span></div>
          <div class="step-title">VNF Information</div>
          <!-- <div class="step-optional">Optional</div> -->
          <div class="step-bar-left"></div>
          <div class="step-bar-right"></div>
        </div>
        <div class="step">
          <div class="step-circle"><span>4</span></div>
          <div class="step-title">Cloud Information</div>
          <!-- <div class="step-optional">Optional</div> -->
          <div class="step-bar-left"></div>
          <div class="step-bar-right"></div>
        </div>
      </div>
    </section>
    <section class="map-header">
      <div class="row">
        <div class="col col-12 p-0">
          <div class="card ct-card">
            <div class="custom-tabs" style="width: 30%">
              <ul class="nav nav-tabs nav-justified" id="myTab" role="tablist">
                <li class="nav-item cursor-pointer pointer">
                  <a
                    class="nav-link show cursor-pointer pointer"
                    [ngClass]="{ active: currentStaus == 'Input' }"
                    id="service-desc-tab"
                    role="tab"
                    aria-controls="service-desc"
                    aria-selected="true"
                    (click)="currentTab('Input')"
                    >My Inventory</a
                  >
                </li>
                <li class="nav-item cursor-pointer pointer">
                  <a
                    class="nav-link show cursor-pointer pointer"
                    [ngClass]="{ active: currentStaus == 'Output' }"
                    id="basic-setting-tab"
                    role="tab"
                    aria-controls="basic-setting"
                    aria-selected="false"
                    (click)="currentTab('Output')"
                    [class.disabled]="userCountry == ''"
                    >Multi-Cloud Coverage</a
                  >
                </li>
              </ul>
            </div>
            <div class="card-body p-0">
              <div class="tab-content site-list-spacing" style="padding-top: 0">
                <!-- My Inventory Tab -->
                <div
                  class="tab-pane active show"
                  id="service-desc"
                  aria-labelledby="service-desc-tab"
                  *ngIf="currentStaus == 'Input'"
                >
                  <div class="service-desc-wrapper">
                    <div class="row">
                      <div class="col-12">
                        <div class="row">
                          <div class="col-12">
                            <div style="padding-bottom: 10px">
                              <agm-map
                                [latitude]="lat"
                                [longitude]="lng"
                                [zoom]="mapZoom"
                                [disableDefaultUI]="true"
                                [zoomControl]="true"
                                [usePanning]="true"
                                [streetViewControl]="true"
                                (mapClick)="close_window()"
                                [fullscreenControl]="false"
                                [restrictionOptions]="restrictionOptions"
                                minZoom="2"
                                maxZoom="5"
                                [fullscreenControl]="true"
                              >
                                <agm-marker
                                  *ngFor="
                                    let m of deviceArrayList;
                                    let i = index
                                  "
                                  [latitude]="m.latitude"
                                  [longitude]="m.longitude"
                                  [markerDraggable]="m.draggable"
                                  [iconUrl]="iconUrlGray.url"
                                  (mouseOver)="
                                    onMouseOver1(m.location, infoWindow)
                                  "
                                  (mouseOut)="
                                    onMouseOutLocationName(infoWindow)
                                  "
                                >
                                  <agm-info-window
                                    #infoWindow
                                    [isOpen]="show"
                                    [maxWidth]="320"
                                  >
                                    <div class="info-window">
                                      <div style="display: flex">
                                        <p class="location-name font-size">
                                          {{ m.location }}
                                          {{ locationNameMyInventory }}
                                        </p>
                                      </div>
                                    </div>
                                  </agm-info-window>
                                </agm-marker>
                                <!-- </div> -->
                              </agm-map>
                              <div></div>
                            </div>
                          </div>

                          <!-- Side navigation bar -->
                          <div class="side-navigation pt-1">
                            <!-- buttons -->
                            <div
                              class="tab default_font snt"
                              style="padding-left: 10px"
                            >
                              <button
                                class="tablinks"
                                [class.active]="
                                  currentTabname == 'Virtual Devices'
                                "
                                (click)="opentab('Virtual Devices')"
                                id="defaultOpen"
                              >
                                Device Inventory
                              </button>
                              <button
                                class="tablinks"
                                [class.active]="
                                  currentTabname == 'CSP Connections'
                                "
                                (click)="opentab('CSP Connections')"
                              >
                                CSP Connections
                              </button>
                              <button
                                class="tablinks"
                                [class.active]="currentTabname == 'Connecters'"
                                (click)="opentab('Connecters')"
                              >
                                Connector Inventory
                              </button>
                            </div>

                            <!-- table -->
                            <div class="table-div" style="width: 80%">
                              <div *ngIf="currentTabname == 'Virtual Devices'">
                                <div>
                                  <input
                                    type="text"
                                    placeholder="search by orderid"
                                    [(ngModel)]="orderidz1"
                                    class="search-btn"
                                  />
                                  <i
                                    class="fa fa-search le-search-icon le-search-icon-mat"
                                    aria-hidden="true"
                                    style="
                                      color: #ea7926;
                                      position: absolute;
                                      left: 52.1%;
                                      bottom: 35.3%;
                                    "
                                  ></i>
                                </div>
                                <!-- <div class="text-input flex">
                                                                    <input class="text-color-text font-sans text-md outline-0 bg-color-transparent grow" autocomplete="current-password" id="session_password" name="session_password" required="" type="password">

                                                                      <button aria-live="assertive" data-id="sign-in-form__password-visibility-toggle" class="font-sans text-md font-bold text-color-action z-10 ml-[12px] hover:cursor-pointer" aria-label="Show your LinkedIn password" data-tracking-control-name="homepage-basic_sign-in-password-visibility-toggle-btn" type="button">Show</button>

                                                                  </div> -->

                                <div class="pt-2 table-responsive">
                                  <table class="table default_font">
                                    <thead class="table-header">
                                      <tr class="tableHeader text-nowrap">
                                        <th
                                          *ngFor="
                                            let header of virtualDevicesheaders
                                          "
                                        >
                                          {{ header }}
                                        </th>
                                        <th></th>
                                        <!-- <th></th> -->
                                      </tr>
                                    </thead>
                                    <tbody
                                      class="text-nowrap table-hover"
                                      *ngFor="
                                        let iteam of deviceArrayList
                                          | orderIdFilter : orderidz1;
                                        let i = index
                                      "
                                    >
                                      <tr *ngIf="iteam.orderId">
                                        <td>{{ iteam.orderId }}</td>
                                        <td>{{ iteam.serviceId }}</td>
                                        <td>{{ iteam.offerringName }}</td>
                                        <td>
                                          {{ iteam.location | titlecase }}
                                        </td>

                                        <td *ngIf="iteam.status !== null">
                                          {{ iteam.status }}
                                        </td>
                                        <td *ngIf="iteam.status === null">
                                          Not Initialized
                                        </td>
                                        <td>
                                          <span
                                            *ngIf="customerLeId == iteam.cuLeId"
                                            [ngClass]="{
                                              'col-md-3': true,
                                              editpencil:
                                                iteam.provisioningThrough !==
                                                'Middleware',
                                              'disable-fa-pencil':
                                                iteam.provisioningThrough ==
                                                'Middleware'
                                            }"
                                          >
                                            <i
                                              class="fa fa-pencil fa-md"
                                              (click)="
                                                iteam.provisioningThrough !==
                                                'Middleware'
                                                  ? Openpop()
                                                  : null
                                              "
                                              [(ngModel)]="editoption"
                                              style="
                                                color: #0e0d0d;
                                                margin-right: 10px;
                                              "
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <span
                                            *ngIf="customerLeId == iteam.cuLeId"
                                            [ngClass]="{
                                              'col-md-3': true,
                                              editpencil:
                                                iteam.provisioningThrough !==
                                                'Middleware',
                                              'disable-fa-pencil':
                                                iteam.provisioningThrough ==
                                                'Middleware'
                                            }"
                                          >
                                            <i
                                              class="fa fa-trash fa-sm"
                                              (click)="
                                                iteam.provisioningThrough !==
                                                'Middleware'
                                                  ? Openpop()
                                                  : null
                                              "
                                              [(ngModel)]="editoption"
                                              style="
                                                color: #0e0d0d;
                                                margin-right: 10px;
                                              "
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </td>
                                        <!-- <td>
                                                                                    <div
                                                                                        [ngClass]="{'col-md-3': true, 'editpencil': iteam.provisioningThrough  !== 'Middleware', 'disable-fa-pencil':iteam.provisioningThrough  == 'Middleware'}">
                                                                                        <i class="fa fa-trash fa-sm "
                                                                                            (click)="iteam.provisioningThrough  !== 'Middleware' ? Openpop() : null"
                                                                                            [(ngModel)]='editoption'
                                                                                            style="color:#0e0d0d;margin-right:10px;"
                                                                                            aria-hidden="true"></i>
                                                                                    </div>
                                                                                </td> -->
                                        <!-- <td><i class="fa fa-trash fa-sm editdelete"
                                                                                        (click)="Openpop()"
                                                                                        style="color:#0d0d0c;"></i>
                                                                                </td> -->
                                      </tr>
                                      <tr
                                        *ngIf="
                                          iteam.provisioningThrough ==
                                          'Middleware'
                                        "
                                      >
                                        <td colspan="7">
                                          <div class="flowChart-section">
                                            <div
                                              *ngIf="
                                                iteam.noOfClouds == 2 &&
                                                iteam.cloudDetails.length == 2
                                              "
                                              class="aws-azure-info"
                                            >
                                              <div>
                                                <img
                                                  src="assets/images/scp/aws-azure.svg"
                                                />
                                              </div>

                                              <div class="aws-azure-cloudNames">
                                                <div
                                                  *ngIf="
                                                    iteam.cloudDetails[0]
                                                      ?.cloudName ==
                                                    'Amazon Web Services'
                                                  "
                                                >
                                                  {{
                                                    iteam?.cloudDetails[0]
                                                      ?.cloudLocation
                                                  }}
                                                </div>
                                                <div
                                                  *ngIf="
                                                    iteam.cloudDetails[1]
                                                      ?.cloudName ==
                                                    'Amazon Web Services'
                                                  "
                                                >
                                                  {{
                                                    iteam?.cloudDetails[1]
                                                      ?.cloudLocation
                                                  }}
                                                </div>
                                                <div
                                                  *ngIf="
                                                    iteam.cloudDetails[0]
                                                      ?.cloudName ==
                                                    'Microsoft Azure'
                                                  "
                                                >
                                                  {{
                                                    iteam?.cloudDetails[0]
                                                      ?.cloudLocation
                                                  }}
                                                </div>
                                                <div
                                                  *ngIf="
                                                    iteam.cloudDetails[1]
                                                      ?.cloudName ==
                                                    'Microsoft Azure'
                                                  "
                                                >
                                                  {{
                                                    iteam?.cloudDetails[1]
                                                      ?.cloudLocation
                                                  }}
                                                </div>
                                              </div>
                                            </div>
                                            <div
                                              *ngFor="
                                                let loc of iteam.cloudDetails
                                              "
                                            >
                                              <div
                                                *ngIf="
                                                  iteam.noOfClouds == 1 &&
                                                  iteam.cloudDetails.length ==
                                                    1 &&
                                                  loc.cloudName ==
                                                    'Amazon Web Services'
                                                "
                                              >
                                                <img
                                                  src="assets/images/scp/aws.svg"
                                                />
                                                <span
                                                  style="padding-left: 15px"
                                                  *ngIf="
                                                    loc.cloudName ==
                                                    'Amazon Web Services'
                                                  "
                                                  >{{ loc.cloudLocation }}</span
                                                >
                                              </div>
                                              <div
                                                *ngIf="
                                                  iteam.noOfClouds == 1 &&
                                                  iteam.cloudDetails.length ==
                                                    1 &&
                                                  loc.cloudName ==
                                                    'Microsoft Azure'
                                                "
                                              >
                                                <img
                                                  src="assets/images/scp/azure.svg"
                                                />
                                                <span
                                                  style="padding-left: 15px"
                                                  *ngIf="
                                                    loc.cloudName ==
                                                    'Microsoft Azure'
                                                  "
                                                  >{{ loc.cloudLocation }}</span
                                                >
                                              </div>
                                            </div>
                                            <div
                                              class="aws-msgInfo"
                                              *ngIf="
                                                iteam.provisioningThrough ==
                                                'Middleware'
                                              "
                                            >
                                              <span
                                                style="
                                                  padding-right: 36px;
                                                  color: red;
                                                "
                                                *ngIf="
                                                  iteam.status ==
                                                    'Provision-Fail' ||
                                                  iteam.status ==
                                                    'Provision-VNF-Status-Fail' ||
                                                  iteam.status ==
                                                    'Provision-VNF-Timeout-Fail' ||
                                                  iteam.status ==
                                                    'Provision-Status-Fail' ||
                                                  iteam.status ==
                                                    'Provision-Status-Timeout-Fail' ||
                                                  iteam.status ==
                                                    'Provision-Config-Status-Fail' ||
                                                  iteam.status ==
                                                    'Provision-Config-Timeout-Fail'
                                                "
                                              >
                                                Your order is taking more than
                                                expected time , We are working
                                                on it and will notify you
                                              </span>
                                              <!-- <button type="button"
                                                                                                class="btn active-btn enableIpsec-btn"
                                                                                                *ngIf=" iteam.provisioningThrough  == 'Middleware' && iteam.edgeConfigStatus=='Active' && iteam.edgeNames.includes('IZO IWAN') && customerLeId == iteam?.cuLeId"
                                                                                                [disabled]="!(iteam.tunnelNumber== 0 || ((iteam.tunnelNumber==1) && (iteam.tunnel1Status=='confirmed')))"
                                                                                                (click)="ipSecEnablepopup(iteam.serviceId , iteam.tunnelNumber, iteam.localAsNumber)">ENABLE
                                                                                                IPSEC </button> -->

                                              <!-- (click)="ipSecEnablepopup(iteam.serviceId , iteam.tunnelNumber)"
                                                                                                  [disabled]="!((iteam.tunnelNumber==0 || iteam.tunnelNumber==1))" -->
                                              <!-- [disabled]="!((iteam.tunnelNumber==0) || ( iteam.tunnelNumber==1 && (iteam.tunnel1Status=="confirmed || iteam.tunnel1Status=="Failed"))) -->
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div *ngIf="currentTabname == 'CSP Connections'">
                                <div>
                                  <input
                                    type="text"
                                    placeholder="search by orderid"
                                    [(ngModel)]="orderidz2"
                                    class="search-btn"
                                  />
                                  <i
                                    class="fa fa-search le-search-icon le-search-icon-mat"
                                    aria-hidden="true"
                                    style="
                                      color: #ea7926;
                                      position: absolute;
                                      left: 52.1%;
                                      bottom: 35.3%;
                                    "
                                  ></i>
                                </div>

                                <div class="pt-2 table-responsive">
                                  <table class="table default_font">
                                    <thead class="table-header">
                                      <tr class="tableHeader text-nowrap">
                                        <th
                                          *ngFor="let header of cspconnheaders"
                                        >
                                          {{ header }}
                                        </th>
                                        <th></th>
                                        <!-- <th></th> -->
                                      </tr>
                                    </thead>
                                    <tbody
                                      class="text-nowrap table-hover"
                                      *ngFor="
                                        let data of cloudArrayList
                                          | orderIdFilter : orderidz2;
                                        let i = index
                                      "
                                    >
                                      <tr *ngIf="data.orderId">
                                        <td>{{ data.orderId }}</td>
                                        <td>{{ data.serviceId }}</td>
                                        <td>
                                          {{ data.offerringName.split("-")[0] }}
                                        </td>

                                        <td>{{ data.location | titlecase }}</td>
                                        <td>
                                          {{
                                            data.bandWidthValue
                                              ? data.bandWidthValue
                                              : ""
                                          }}
                                          {{
                                            data.bandWidthUnit
                                              ? data.bandWidthUnit
                                              : ""
                                          }}
                                        </td>
                                        <td>
                                          {{
                                            data?.connectionType
                                              ? data?.connectionType
                                              : ""
                                          }}
                                        </td>

                                        <td *ngIf="data.status !== null">
                                          {{ data.status }}
                                        </td>
                                        <td *ngIf="data.status === null">
                                          Not Initialized
                                        </td>

                                        <td>
                                          <span
                                            *ngIf="customerLeId == data?.cuLeId"
                                            [ngClass]="{
                                              'col-md-3': true,
                                              editpencil:
                                                data.provisioningThrough !==
                                                'Middleware',
                                              'disable-fa-pencil':
                                                data.provisioningThrough ==
                                                'Middleware'
                                            }"
                                          >
                                            <i
                                              class="fa fa-pencil fa-md"
                                              (click)="
                                                data.provisioningThrough !==
                                                'Middleware'
                                                  ? Openpop()
                                                  : null
                                              "
                                              [(ngModel)]="editoption"
                                              style="
                                                color: #0e0d0d;
                                                margin-right: 10px;
                                              "
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <span
                                            *ngIf="customerLeId == data?.cuLeId"
                                            [ngClass]="{
                                              'col-md-3': true,
                                              editpencil:
                                                data.provisioningThrough !==
                                                'Middleware',
                                              'disable-fa-pencil':
                                                data.provisioningThrough ==
                                                'Middleware'
                                            }"
                                          >
                                            <i
                                              class="fa fa-trash fa-sm"
                                              (click)="
                                                data.provisioningThrough !==
                                                'Middleware'
                                                  ? Openpop()
                                                  : null
                                              "
                                              [(ngModel)]="editoption"
                                              style="
                                                color: #0e0d0d;
                                                margin-right: 10px;
                                              "
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </td>
                                        <!-- <td> <div (click)="data.provisioningThrough  !== 'Middleware' ? Openpop() : null"
                                                                                       [tooltip] = "data.provisioningThrough  === 'Middleware' ? 'editdsabled':''">
                                                                                        <i class="fa fa-pencil fa-md editpencil"
                                                                                            [(ngModel)]='editoption'
                                                                                            style="color:#0e0d0d;margin-right:10px;"
                                                                                            aria-hidden="true"></i>
                                                                                        </div></td> -->
                                        <!-- <td><i class="fa fa-trash fa-md editdelete"
                                                                                        (click)="Openpop()"
                                                                                        style="color:#0d0d0d;margin-right:10px"></i>
                                                                                </td> -->
                                        <!-- <td>
                                                                                    <div
                                                                                        [ngClass]="{'col-md-3': true, 'editpencil': data.provisioningThrough  !== 'Middleware', 'disable-fa-pencil':data.provisioningThrough  == 'Middleware'}">
                                                                                        <i class="fa fa-trash fa-sm "
                                                                                            (click)="data.provisioningThrough  !== 'Middleware' ? Openpop() : null"
                                                                                            [(ngModel)]='editoption'
                                                                                            style="color:#0e0d0d;margin-right:10px;"
                                                                                            aria-hidden="true"></i>
                                                                                    </div>
                                                                                </td> -->
                                      </tr>
                                      <tr
                                        *ngIf="
                                          data.provisioningThrough ==
                                            'Middleware' && data.orderId
                                        "
                                      >
                                        <td colspan="9">
                                          <div class="flowChart-section">
                                            <div class="aws-msgInfo">
                                              <span
                                                style="
                                                  padding-right: 36px;
                                                  color: red;
                                                "
                                                *ngIf="
                                                  data.offerringName.includes(
                                                    'Amazon Web Services'
                                                  ) &&
                                                  data.status == 'PROVISIONED'
                                                "
                                              >
                                                Please go to AWS Portal to
                                                enable the service
                                              </span>
                                              <!-- <span
                                                                                                style="padding-right: 36px;color: red;"
                                                                                                *ngIf="data.status=='Provisioning-Failure'  || data.status=='Provisioning-Status-Failed' || data.status=='Provisioning-Failed'">
                                                                                                Provisioning Failed,
                                                                                                Please Contact
                                                                                                Administrator [Emailid]
                                                                                            </span> -->

                                              <span
                                                style="
                                                  padding-right: 36px;
                                                  color: red;
                                                "
                                                *ngIf="
                                                  data.status ==
                                                    'Provision-Fail' ||
                                                  data.status ==
                                                    'Provision-VNF-Status-Fail' ||
                                                  data.status ==
                                                    'Provision-VNF-Timeout-Fail' ||
                                                  data.status ==
                                                    'Provision-Status-Fail' ||
                                                  data.status ==
                                                    'Provision-Status-Timeout-Fail' ||
                                                  data.status ==
                                                    'Provision-Config-Status-Fail' ||
                                                  data.status ==
                                                    'Provision-Config-Timeout-Fail'
                                                "
                                              >
                                                Your order is taking more than
                                                expected time , We are working
                                                on it and will notify you
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>

                              <div *ngIf="currentTabname == 'Connecters'">
                                <div>
                                  <input
                                    type="text"
                                    placeholder="search by orderid"
                                    [(ngModel)]="orderidz3"
                                    class="search-btn"
                                  />
                                  <i
                                    class="fa fa-search le-search-icon le-search-icon-mat"
                                    aria-hidden="true"
                                    style="
                                      color: #ea7926;
                                      position: absolute;
                                      left: 52.1%;
                                      bottom: 35.3%;
                                    "
                                  ></i>
                                </div>
                                <div class="pt-2 table-responsive">
                                  <table class="table default_font">
                                    <thead class="table-header">
                                      <tr class="tableHeader text-nowrap">
                                        <th
                                          *ngFor="
                                            let header of connecterheaders
                                          "
                                        >
                                          {{ header }}
                                        </th>
                                        <th></th>
                                        <!-- <th></th> -->
                                      </tr>
                                    </thead>
                                    <tbody
                                      class="text-nowrap table-hover"
                                      *ngFor="
                                        let data of edgeArrayList
                                          | orderIdFilter : orderidz3;
                                        let i = index
                                      "
                                    >
                                      <tr
                                        class="text-nowrap"
                                        *ngIf="data.orderId"
                                      >
                                        <td>{{ data.orderId }}</td>
                                        <td>{{ data.serviceId }}</td>
                                        <td>{{ data.location | titlecase }}</td>
                                        <td>
                                          {{
                                            data.bandWidthValue
                                              ? data.bandWidthValue
                                              : ""
                                          }}
                                          {{
                                            data.bandWidthUnit
                                              ? data.bandWidthUnit
                                              : ""
                                          }}
                                        </td>
                                        <td>
                                          {{
                                            data.aliasName ? data.aliasName : ""
                                          }}
                                          <br />
                                          <br />
                                          <button
                                            *ngIf="
                                              data.tunnel1Status ==
                                                'committed' ||
                                              (data.tunnel1Status ==
                                                'confirmed' &&
                                                data.tunnel2Status ===
                                                  'committed')
                                            "
                                            (click)="
                                              ondownloadConfig(
                                                data.serviceId,
                                                data.tunnelNumber
                                              )
                                            "
                                            class="btn active-btn"
                                            style="
                                              color: #ea7926;
                                              background-color: transparent;
                                              border: 1px solid
                                                rgb(234, 121, 38);
                                            "
                                          >
                                            DOWNLOAD CONFIGURATION
                                          </button>
                                        </td>
                                        <!-- (click)="ondownloadConfig(data.serviceId, data.tunnelNumber)" -->
                                        <!-- *ngIf="data.tunnel1Status == 'committed' || data.tunnel2Status === 'committed'" -->

                                        <td *ngIf="data.status !== null">
                                          {{ data.status }}
                                          <br /><br />
                                          <button
                                            class="btn active-btn"
                                            *ngIf="
                                              (data.tunnel1Status ===
                                                'committed' &&
                                                data.tunnelOneisdownloaded ===
                                                  'yes') ||
                                              (data.tunnel2Status ===
                                                'committed' &&
                                                data.tunnelTwoisdownloaded ===
                                                  'yes')
                                            "
                                            (click)="
                                              openPopupConfirmConfig(
                                                data.serviceId,
                                                data.tunnelNumber
                                              )
                                            "
                                            style="
                                              color: #ea7926;
                                              background-color: transparent;
                                              border: 1px solid
                                                rgb(234, 121, 38);
                                            "
                                          >
                                            CONFIRM CONFIGURATION
                                          </button>
                                          <!-- (click)="openPopupConfirmConfig(data.serviceId, data.tunnelNumber)"
                                                                                         *ngIf="data.tunnel1Status === 'committed' || data.tunnel2Status === 'committed'  && data.tunnelOneisdownloaded === 'yes'" -->
                                          <!-- *ngIf="(data.tunnel1Status === 'committed' && data.tunnelOneisdownloaded === 'yes')|| (data.tunnel2Status === 'committed'&& data.tunnelTwoisdownloaded === 'yes')" -->
                                        </td>
                                        <td *ngIf="data.status === null">
                                          Not Initialized
                                          <br /><br />
                                          <button
                                            class="btn active-btn"
                                            *ngIf="
                                              data.tunnel1Status ===
                                                'committed' ||
                                              (data.tunnel2Status ===
                                                'committed' &&
                                                data.tunnelOneisdownloaded ===
                                                  'yes')
                                            "
                                            (click)="
                                              openPopupConfirmConfig(
                                                data.serviceId,
                                                data.tunnelNumber
                                              )
                                            "
                                            style="
                                              color: #ea7926;
                                              background-color: transparent;
                                              border: 1px solid
                                                rgb(234, 121, 38);
                                            "
                                          >
                                            CONFIRM CONFIGURATION
                                          </button>
                                          <!-- (click)="openPopupConfirmConfig(data.serviceId, data.tunnelNumber)" -->
                                          <!-- *ngIf="(data.tunnel1Status === 'committed' && data.tunnelOneisdownloaded === 'yes')|| (data.tunnel2Status === 'committed'&& data.tunnelTwoisdownloaded === 'yes')" -->
                                        </td>
                                        <td>{{ data.tunnel1Status }}</td>
                                        <td>{{ data.tunnel2Status }}</td>
                                        <!-- <td>-</td>
                                                                                <td>-</td> -->
                                        <td>
                                          <span
                                            *ngIf="customerLeId == data?.cuLeId"
                                            [ngClass]="{
                                              'col-md-3': true,
                                              editpencil:
                                                data.provisioningThrough !==
                                                'Middleware',
                                              'disable-fa-pencil':
                                                data.provisioningThrough ==
                                                'Middleware'
                                            }"
                                          >
                                            <i
                                              class="fa fa-pencil fa-md"
                                              (click)="
                                                data.provisioningThrough !==
                                                'Middleware'
                                                  ? Openpop()
                                                  : null
                                              "
                                              [(ngModel)]="editoption"
                                              style="
                                                color: #0e0d0d;
                                                margin-right: 10px;
                                              "
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                          <span
                                            *ngIf="customerLeId == data?.cuLeId"
                                            [ngClass]="{
                                              'col-md-3': true,
                                              editpencil:
                                                data.provisioningThrough !==
                                                'Middleware',
                                              'disable-fa-pencil':
                                                data.provisioningThrough ==
                                                'Middleware'
                                            }"
                                          >
                                            <i
                                              class="fa fa-trash fa-sm"
                                              (click)="
                                                data.provisioningThrough !==
                                                'Middleware'
                                                  ? Openpop()
                                                  : null
                                              "
                                              [(ngModel)]="editoption"
                                              style="
                                                color: #0e0d0d;
                                                margin-right: 10px;
                                              "
                                              aria-hidden="true"
                                            ></i>
                                          </span>
                                        </td>

                                        <!-- <td >
                                                                                    <div
                                                                                        [ngClass]="{'col-md-3': true, 'editpencil': data.provisioningThrough  !== 'Middleware', 'disable-fa-pencil':data.provisioningThrough  == 'Middleware'}">
                                                                                        <i class="fa fa-trash fa-sm "
                                                                                            (click)="data.provisioningThrough  !== 'Middleware' ? Openpop() : null"
                                                                                            [(ngModel)]='editoption'
                                                                                            style="color:#0e0d0d;margin-right:10px;"
                                                                                            aria-hidden="true"></i>
                                                                                    </div>
                                                                                </td> -->
                                      </tr>

                                      <tr
                                        *ngIf="
                                          data.provisioningThrough ==
                                            'Middleware' && data.orderId
                                        "
                                      >
                                        <td colspan="9">
                                          <div class="flowChart-section">
                                            <div class="aws-msgInfo">
                                              <!-- <span
                                                                                                style="padding-right: 36px;color: red;"
                                                                                                *ngIf="data.status=='Provisioning-Failure'  || data.status=='Provisioning-Status-Failed' || data.status=='Provisioning-Failed'">
                                                                                                Provisioning Failed,
                                                                                                Please Contact
                                                                                                Administrator [Emailid]
                                                                                            </span> -->
                                              <span
                                                style="
                                                  padding-right: 36px;
                                                  color: red;
                                                "
                                                *ngIf="
                                                  data.status ==
                                                    'Provision-Fail' ||
                                                  data.status ==
                                                    'Provision-VNF-Status-Fail' ||
                                                  data.status ==
                                                    'Provision-VNF-Timeout-Fail' ||
                                                  data.status ==
                                                    'Provision-Status-Fail' ||
                                                  data.status ==
                                                    'Provision-Status-Timeout-Fail' ||
                                                  data.status ==
                                                    'Provision-Config-Status-Fail' ||
                                                  data.status ==
                                                    'Provision-Config-Timeout-Fail'
                                                "
                                              >
                                                Your order is taking more than
                                                expected time , We are working
                                                on it and will notify you
                                              </span>
                                            </div>
                                          </div>
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- Side navigation bar -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Multi-Cloud Coverage Tab -->
                <div
                  class="tab-pane active show"
                  id="service-desc"
                  aria-labelledby="service-desc-tab"
                  *ngIf="currentStaus === 'Output'"
                >
                  <div class="service-desc-wrapper">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="row">
                          <div class="col-md-12">
                            <div class="mb-2">
                              <div style="margin-bottom: 42px">
                                <!-- [iconUrl]="m.icon" -->
                                <agm-map
                                  [latitude]="lat"
                                  [longitude]="lng"
                                  [zoom]="mapZoom"
                                  [disableDefaultUI]="true"
                                  [zoomControl]="true"
                                  [usePanning]="true"
                                  [streetViewControl]="true"
                                  (mapClick)="close_window()"
                                  [fullscreenControl]="false"
                                  [restrictionOptions]="restrictionOptions"
                                  minZoom="2"
                                  maxZoom="5"
                                  [fullscreenControl]="true"
                                  (mapClick)="close_window($event)"
                                >
                                  <agm-marker
                                    *ngFor="
                                      let m of finalMapList;
                                      let i = index
                                    "
                                    [latitude]="m.latitude"
                                    [longitude]="m.longitude"
                                    [markerDraggable]="m.draggable"
                                    [iconUrl]="
                                      m.country === userCountry
                                        ? iconUrlGreen.url
                                        : iconUrlGray.url
                                    "
                                    (mouseOver)="
                                      onMouseOver(m.city, infoWindow)
                                    "
                                  >
                                    <!-- (markerClick)="clickedMarker(m.location, i ,infoWindow)" -->
                                    <agm-info-window
                                      #infoWindow
                                      *ngIf="m.city === 'Chicago'"
                                      [isOpen]="true"
                                      [maxWidth]="320"
                                    >
                                      <div
                                        class="extra-block"
                                        style="
                                          position: relative;
                                          width: 100%;
                                          height: 60px;
                                        "
                                      >
                                        <p>
                                          select legal entities to establish
                                          cloud connections with ease.
                                        </p>
                                      </div>
                                      <div class="info-window">
                                        <div
                                          *ngIf="m.popStatus == 'Live'"
                                          style="display: flex"
                                        >
                                          <p class="location-name font-size">
                                            Chicago
                                          </p>
                                          <!-- <button class="add-btn "
                                                                                        [routerLink]="['/config', {location:locationName}]"
                                                                                        *ngIf="m.country === userCountry">+
                                                                                        Connection
                                                                                    </button> -->
                                          <button
                                            class="add-btn"
                                            *ngIf="m.country === userCountry"
                                            (click)="
                                              locationBasedNav(locationName)
                                            "
                                          >
                                            + Connection
                                          </button>
                                        </div>
                                      </div>
                                      <div
                                        *ngIf="m.country != userCountry"
                                        style="
                                          padding-left: 22px;
                                          line-height: 20px;
                                        "
                                      >
                                        <p class="font-size1">
                                          To Order here, customer needs to have
                                          Legal Entity in this location
                                        </p>
                                      </div>
                                      <div
                                        *ngIf="
                                          m.popStatus == 'ROADMAP' ||
                                          m.popStatus == 'WIP'
                                        "
                                      >
                                        <p class="location-name">Coming soon</p>
                                      </div>
                                    </agm-info-window>

                                    <agm-info-window
                                      #infoWindow
                                      *ngIf="m.city !== 'Chicago'"
                                      [isOpen]="show"
                                      [maxWidth]="320"
                                    >
                                      <div class="info-window">
                                        <div
                                          *ngIf="m.popStatus == 'Live'"
                                          style="display: flex"
                                        >
                                          <p class="location-name font-size">
                                            {{ m.city }}
                                          </p>
                                          <!-- <button class="add-btn "
                                                                                        [routerLink]="['/config', {location:locationName}]"
                                                                                        *ngIf="m.country === userCountry">+
                                                                                        Connection
                                                                                    </button> -->
                                          <button
                                            class="add-btn"
                                            *ngIf="m.country === userCountry"
                                            (click)="
                                              locationBasedNav(locationName)
                                            "
                                          >
                                            + Connection
                                          </button>
                                        </div>
                                      </div>
                                      <div
                                        *ngIf="m.country != userCountry"
                                        style="
                                          padding-left: 22px;
                                          line-height: 20px;
                                        "
                                      >
                                        <p class="font-size1">
                                          To Order here, customer needs to have
                                          Legal Entity in this location
                                        </p>
                                      </div>
                                      <div
                                        *ngIf="
                                          m.popStatus == 'ROADMAP' ||
                                          m.popStatus == 'WIP'
                                        "
                                      >
                                        <p class="location-name">Coming soon</p>
                                      </div>
                                    </agm-info-window>
                                  </agm-marker>
                                </agm-map>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div style="padding: 0 170px 58px 170px">
                      <div class="row">
                        <div>
                          <p class="region-info">
                            IZO<sup>TM</sup> Multi Cloud Connect Regions
                          </p>
                          <p class="region-sub-info">
                            Select a region to configure your cloud connection.
                          </p>
                        </div>
                      </div>
                      <div class="row">
                        <div
                          class="container1 col-xs-12 col-sm-12 col-md-3 col-3"
                          *ngFor="let listCountries of regionListCount"
                        >
                          <div
                            class="region-info-card"
                            (click)="regionFlow(listCountries.CountryName)"
                            (mouseover)="
                              onMouseOverImg(listCountries.CountryName)
                            "
                            (mouseout)="
                              onMouseOutImg(listCountries.CountryName)
                            "
                          >
                            <!-- [routerLink]="['/config', {region:listCountries.CountryName}  ]" -->
                            <div>
                              <div class="text-center">
                                <p
                                  style="
                                    font-size: 16px;
                                    font-weight: 700;
                                    padding: 10px;
                                    margin: 0;
                                  "
                                >
                                  {{ listCountries.CountryName }}
                                </p>

                                <div
                                  *ngIf="listCountries.CountryName == 'AMER'"
                                >
                                  <img
                                    src="assets/images/scp/amer1.svg"
                                    width="100px"
                                    height="100px"
                                    *ngIf="amerregionImage == false"
                                  />
                                  <!-- <p *ngIf="!amerregionImage">vignesh</p> -->
                                  <img
                                    src="assets/images/scp/amerh1.svg"
                                    width="100px"
                                    height="100px"
                                    *ngIf="amerregionImage == true"
                                  />
                                </div>

                                <div
                                  *ngIf="listCountries.CountryName == 'EMEA'"
                                >
                                  <img
                                    src="assets/images/scp/emer1.svg"
                                    width="100px"
                                    height="100px"
                                    *ngIf="emearegionImage == false"
                                  />

                                  <img
                                    src="assets/images/scp/emeah1.svg"
                                    width="100px"
                                    height="100px"
                                    *ngIf="emearegionImage == true"
                                  />
                                </div>

                                <div
                                  *ngIf="listCountries.CountryName == 'APAC'"
                                >
                                  <img
                                    src="assets/images/scp/apac1.svg"
                                    width="100px"
                                    height="100px"
                                    *ngIf="apacregionImage == false"
                                  />
                                  <img
                                    src="assets/images/scp/apach1.svg"
                                    width="100px"
                                    height="100px"
                                    *ngIf="apacregionImage == true"
                                  />
                                </div>
                                <div>
                                  <!-- <p style=" font-size: 13px;
                                                                padding-top : 4px">{{listCountries.city.length}}
                                                                            connections</p> -->
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </section>
</main>

<ng-template #Editing>
  <div id="shareModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div role="document">
      <div class="modal-content">
        <div
          class="modal-header d-flex justify-content-between align-items-center flex-row"
        >
          <h5 class="modal-title">Please Contact Account Manager</h5>
          <button
            type="button"
            class="close"
            (click)="Closepop()"
            data-dismiss="modal"
            aria-label="Close"
          >
            <i class="icon-close"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!--enable ipsec tunnel -->

<ng-template #enableipsec>
  <div id="shareModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="card ct-card card-wrapper-height">
      <div class="row pb-10" style="padding-left: 15px; padding-right: 15px">
        <div
          class="card-header card-header-wrapper"
          style="width: 100%; margin-left: 0rem; margin-right: 0 rem"
        >
          <div
            class="d-flex justify-content-between align-items-center"
            style="width: 100%"
          >
            <div class="w100">
              <div class="popupinfoIpsec">
                <div class="align-self-start">
                  <span class="text-uppercase fontStyle bold-text dfg"
                    >Configure IPSec</span
                  >
                </div>
                <div class="align-self-end">
                  <button
                    type="button"
                    class="close"
                    (click)="ipSecEnableClosepop()"
                    data-dismiss="modal"
                    aria-label="Close"
                  >
                    <i
                      class="icon-close align-self-right"
                      style="font-size: 20px; margin-right: 10px"
                    ></i>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grpby-content-wrapper" style="height: 290px">
      <div
        style="
          padding-left: 30px;
          padding-right: 30px;
          width: 100%;
          background-color: white;
          margin-bottom: 40px;
          margin-top: 10px;
        "
      >
        <div class="row" style="width: 100%">
          <div class="col-12">
            <div class="row">
              <div class="col" style="margin-bottom: 10px">
                You can build upto two tunnels
              </div>
            </div>
            <div class="row" style="margin-bottom: 15px">
              <div class="col bold-text">
                <h6>Tunnel {{ OrdertunnerNumerinfo + 1 }}</h6>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 bold-text">
                Remote CPE public IP <span style="color: red">*</span>
              </div>
              <div class="col-md-4 bold-text">
                Remote CPE side Tunnel IP <span style="color: red">*</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field style="width: 20px">
                  <input
                    matInput
                    type="text"
                    autocomplete="off"
                    [disabled]="ipsecflag"
                    [(ngModel)]="remoteCpePublicIp"
                    required
                    name="remotecpeip"
                    pattern="(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)"
                    placeholder="Enter IP"
                    #remotecpeip="ngModel"
                  />
                </mat-form-field>
                <div
                  [hidden]="remotecpeip.valid || remotecpeip.pristine"
                  style="
                    color: red;
                    margin-top: -9px;
                    margin-bottom: 4px;
                    font-size: 11px;
                    font-weight: 600;
                  "
                >
                  <div [hidden]="!remotecpeip.hasError('required')">
                    IP address is required
                  </div>
                  <div [hidden]="!remotecpeip.hasError('pattern')">
                    Invalid IP address
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    autocomplete="off"
                    [disabled]="ipsecflag"
                    [(ngModel)]="remoteTunnelIp"
                    required
                    name="remotetunnelip"
                    pattern="(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)"
                    placeholder="Enter IP"
                    #remotetunnelip="ngModel"
                  />
                </mat-form-field>
                <div
                  [hidden]="remotetunnelip.valid || remotetunnelip.pristine"
                  style="
                    color: red;
                    margin-top: -9px;
                    margin-bottom: 4px;
                    font-size: 11px;
                    font-weight: 600;
                  "
                >
                  <div [hidden]="!remotetunnelip.hasError('required')">
                    IP address is required
                  </div>
                  <div [hidden]="!remotetunnelip.hasError('pattern')">
                    Invalid IP address
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4 bold-text">
                VNF side Tunnel IP Address <span style="color: red">*</span>
              </div>
              <div class="col-md-4 bold-text">
                Remote CPE ASN <span style="color: red">*</span>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <mat-form-field style="margin-right: 60px">
                  <input
                    matInput
                    type="text"
                    autocomplete="off"
                    [(ngModel)]="tunnelIpAddress"
                    [disabled]="ipsecflag"
                    required
                    name="tunnelip"
                    pattern="(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?\/\d{1,3})"
                    placeholder="Enter IP"
                    #tunnelip="ngModel"
                  />
                </mat-form-field>
                <div
                  [hidden]="tunnelip.valid || tunnelip.pristine"
                  style="
                    color: red;
                    margin-top: -9px;
                    margin-bottom: 4px;
                    font-size: 11px;
                    font-weight: 600;
                  "
                >
                  <div [hidden]="!tunnelip.hasError('required')">
                    IP address is required
                  </div>
                  <div [hidden]="!tunnelip.hasError('pattern')">
                    Invalid IP address
                  </div>
                </div>
              </div>
              <div class="col-md-4">
                <mat-form-field>
                  <input
                    matInput
                    type="text"
                    autocomplete="off"
                    [disabled]="ipsecflag"
                    [(ngModel)]="remoteAsAumber"
                    [maxlength]="10"
                    required
                    name="remotenumber"
                    placeholder="Enter As Number"
                    #remotenumber="ngModel"
                  />
                </mat-form-field>
                <div
                  [hidden]="remotenumber.valid || remotenumber.pristine"
                  style="
                    color: red;
                    margin-top: -9px;
                    margin-bottom: 4px;
                    font-size: 11px;
                    font-weight: 600;
                  "
                >
                  <div [hidden]="!remotenumber.hasError('required')">
                    remote number is required
                  </div>
                  <div [hidden]="!remotenumber.hasError('pattern')">
                    Invalid remote number
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="margin-left: 700px">
            <button
              class="btn active-btn"
              style="
                background-color: transparent;
                color: #ea7926;
                border: 1px solid rgb(234, 121, 38);
                margin-right: 10px;
              "
              (click)="onAttributeValueChange()"
              [disabled]="
                !remotenumber.valid ||
                !tunnelip.valid ||
                !remotecpeip.valid ||
                !remotetunnelip.valid
              "
            >
              SAVE
            </button>
            <button
              class="btn active-btn"
              [disabled]="!deploydisable"
              (click)="deployEnableIpsec()"
            >
              DEPLOY
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>

<!-- confirm config popup -->

<ng-template #confirmconfig>
  <div id="shareModal" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="card ct-card card-wrapper-height">
      <div class="row pb-10" style="padding-left: 15px; padding-right: 15px">
        <div
          class="card-header card-header-wrapper"
          style="width: 100%; margin-left: 0rem; margin-right: 0 rem"
        >
          <div
            class="d-flex justify-content-between align-items-center"
            style="width: 100%"
          >
            <div class="w33">
              <div class="left">
                <span class="text-uppercase fontStyle bold-text dfg"
                  >CONFIRM CONFIGURATION</span
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="grpby-content-wrapper" style="height: 110px">
      <div
        style="
          padding-left: 30px;
          padding-right: 30px;
          width: 100%;
          background-color: white;
          margin-bottom: 40px;
          margin-top: 10px;
        "
      >
        <div class="row" style="width: 100%">
          <p>
            By clicking on ‘CONFIRM’, you confirm that you have configured the
            service as per the configurations file. We will initiate a status
            check once you confirm.
          </p>
          <div style="margin-left: 400px">
            <button
              class="btn active-btn"
              style="
                background-color: transparent;
                color: #ea7926;
                border: 1px solid rgb(234, 121, 38);
                margin-right: 20px;
              "
              (click)="Closepop()"
            >
              CANCEL
            </button>
            <button class="btn active-btn" (click)="ConfirmConfigpop()">
              CONFIRM
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
