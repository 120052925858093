/**
 * This file contains the SalesOrderComponent class.
 *
 * @author Sarath Kumar
 * @link http://www.tatacommunications.com/
 * @copyright 2014 Tata Communications Limited 
 */

import { Component, OnInit, TemplateRef, ViewChild, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { BsModalService } from 'ngx-bootstrap/modal';
import { BsModalRef } from 'ngx-bootstrap/modal/bs-modal-ref.service';
import { Router, ActivatedRoute } from '@angular/router';

// import { OrderFormService } from '../../../izomcc/order-form/order-form.service'
import { DataSharedSvc } from '../../../data-shared-svc';
// import { CommonBillingUserService } from '../../../common/service/common-billing-user.service';
import { UntypedFormControl } from '@angular/forms';

import * as _moment from 'moment';
import * as _ from 'lodash';
import { OrderFormService } from 'src/app/izomcc/order-form/order-form.service';
// import { WidgetDetails } from ;
// import { forEach } from '@angular/router/src/utils/collection';
// import { OrderFormService as mssOrderFormService } from '../../../managed-security-service/order-form/order-form.service';
// import { GetDataService as mssQuoteService } from '../../../managed-security-service/quote/quote.service';
// import {OrderFormService as iwanOrderFormService } from '../../../internet-wan/order-form/order-form.service';
// import {TemplateCofGenerationService} from '../../../template-cof-generation/template-cof-generation.service'

@Component({
    selector: '[common-app-sales-order]',
    templateUrl: './sales-order.component.html',
    host: {
        "(window:click)": "clickin($event)"
    }
})
export class CommonSalesOrderComponent implements OnInit {
    public isCommercialUser: boolean; /** validate then remove */
    public quoteisSiteBilling: boolean = false;
    public popUpMessage: string;
    public isSitewiseBillingbtnClicked: boolean;
    // previousLegalEntityId: any;
    isGVPNInternational: boolean = false;
    // sitewiseBillingInfo: any;
    isCrossConnect: boolean;
    isBulk: boolean;
    showInput: boolean = false;
    is_other_gst: any = ''
    // otherGstNumber: any;
    nonStandardBulk: boolean = false;
    dflag: boolean = false;
    customerLe: any;
    otherGstNumber: any;
    leowners: any;
    clickin() {
        this.showDropdown = true;
    }
    myControl = new UntypedFormControl();
    // @ViewChild('billingModal') billingModal;
    @ViewChild('leaglentityModal') legalModal;
    @Input('common-app-sales-order') commonProductName: any;
    @ViewChild('confirmGivenCrossBordeTax') confirmGivenCrossBordeTax: TemplateRef<any>;
    @ViewChild('errorHandle') errorHandleTemplate: TemplateRef<any>; // error Handle template reference
    @ViewChild('mailSent') mailSentTemplate: TemplateRef<any>; // error Handle template reference
    // @ViewChild('sitewiseBilling') siteWiseBillingTemplate: TemplateRef<any>;
    // @ViewChild('deleteConfirmation') deleteConfirmationTemplate: TemplateRef<any>;
    salesOrderData: any = {
        selectedSpleDetails: {},
        uploadSuccess: true,
        correspondingLegalEntityName: []

    }; // to get response from api
    /** izomcc */
    userDetail: any = {
        billingContactDetails: [],
        customerContactList: []
    }
    private fronturl;
    private backurl;
    public salesOrderParams: any = {
        'illSitesIds': [],
        'isTaxExemption': 'no',
        'numberOfEntity': 'single',

    }; // using for storing user selecting data while traverse this sales order
    // showloader: any = true;
    selectedAddress = []; // using for getting seleced address from list of illsites which user needs tax exemption
    modalRef: BsModalRef; // reference for modal
    showSalesOrder = true; // once RM notification is sent then needs to show notification content to user for that using this property
    // quoteObj = JSON.parse(localStorage.getItem('quoteObj'));
    quoteObj = JSON.parse(this.dataSharedSvc.getQuoteObjFromSession());
    customer = JSON.parse(this.dataSharedSvc.getCustomerLeFromSession());
    payloadStage = {
        stage: 'Checkout'
    }
    // loaderModalSettings: any = {};
    quoteCode;
    commonService;
    quoteService;
    showDropdown = true;
    isDocusign: boolean;
    flag: any = {
        salesLogin: false,
        orderFormSpinner: false,
        isUSLocation: false,
    }
    creditCheck: any = {
        creditCheckStatus: '',
        creditCheckError: '',
        creditCheckStatusFlag: false,
        preapprovedFlag: false
    }
    partnerGVPNOppIdDetails: any;
    // messageModalSettings: any = {};
    quoteCategory: string;
    isGSTForIndia: boolean;
    selectedLeGSTAndAddress: Array<object> = [];
    offeringName: string;
    uploadDetails: any = {
        'uploadSpinner': false
    }
    isTaxDocumentRequired: boolean;

    // sdwanStage = [];
    gstnInfoList: any;
    customerContractingAddressList: any;
    selectedCustomerLegalEntity: any;
    selectedCustomerContractingAddress: any;
    selectedLocationId: any;
    locationId: any;
    isLoggedInUserPartner: Boolean;
    selectedleOwner = {
        "ownerName": ""
    };
    siteWiseBilling = {
        'selectedState': '',
    };
    stateList = ["All"];
    public billingAddressList = [];
    selectedBillingAddress = '';
    searchBillingAddress = "";
    isSiteWiseBillingEnabled: boolean = false;
    billingAttr: any = [];
    // ipcCrossBorderTax: any;
    // taxInfo: any;
    // existingTaxPercent: any;
    // ipcCustomerContactList: any = [];
    // ipcSelectedCustomer: any;
    is_manual: boolean = false;
    manualContactList: any = []
    manualSelectdContact: any
    search: any = {
        searchCode: '',
        searchResponse: ''
    };
    is_sfdc_exists = false

    // showLegalEntitySelect = false;
    // public mssEntitySelected = false;
    selectedApiMethod: any;
    searchTextObj = {
        contractingAddress: '',
        contactEmail: '',
        billingEmail: '',
        billingAddress: ''
    };
    constructor(
        public router: Router, public modalService: BsModalService,
        public dataSharedSvc: DataSharedSvc,
        // public _izopcOrderFormService: izoOrderFormService,
        public bsModalService: BsModalService,
        public izomcc_orderFormService: OrderFormService,
        public activeRoute: ActivatedRoute,
        // public templateCofGenerationService: TemplateCofGenerationService,
    ) { }




    ngOnInit() {
        // alert(this.commonProductName)
        console.log('test+++++++++', this.commonProductName)
        this.siteWiseBilling.selectedState = this.stateList[0];
        switch (this.commonProductName = 'IZONE') {
            case 'IZONE':
                this.commonService = 'izomcc_orderFormService';
                this.fronturl = '/izomcc/orderForm/reviewaccept';
                this.backurl = '/izomcc/quote/getquote';
                break
        }
        switch (this.commonProductName = 'IZOMCC') {
            case 'IZOMCC':
                this.commonService = 'izomcc_orderFormService';
                this.fronturl = '/izomcc/orderForm/reviewaccept';
                this.backurl = '/izomcc/quote/getquote';
                break
        }
        /**for getting quote details */
        this.activeRoute.params.subscribe(params => {
            console.log(params)
            if (params['quoteId']) {
                this.dflag = true
                this.quoteObj = params
                this.dataSharedSvc.setQuoteObjInSession(JSON.stringify(this.quoteObj));
            }
        })
        if (this.dflag == true) {
            this.getuserDetails();
            this.updateStage();
            this.getQuoteDetailsById();
        } else {
            this.getuserDetails();
            this.updateStage();
            this.getQuoteDetailsById();
        }
        this.customerLe = this.dataSharedSvc.getCustomerLeFromSession()
    }
    onSearchClick(event) {
        event.stopPropagation();
    }

   

    private getQuoteDetailsById() {
        const isMacd = this.quoteObj.isMacd ? true : false;
        this.izomcc_orderFormService.getQuoteDetailsByQuoteId(this.quoteObj.quoteId, (successFn) => {
            if (successFn.responseCode == 200 && successFn.data != null) {
                this.isBulk = successFn.data.bulk;
                this.nonStandardBulk = successFn.data.nonStandard;
                // this.isBulk === (true && successFn.data.quoteCode.startsWith('IZONE')) ? this.selectedApiMethod = "getQuote" : this.selectedApiMethod = "getQuote"
                this.isBulk === (true && successFn.data.quoteCode.startsWith('IZOMCC')) ? this.selectedApiMethod = "getQuote" : this.selectedApiMethod = "getQuote"
                this[this.commonService][this.selectedApiMethod](this.quoteObj.quoteId, isMacd, (successData) => {
                    this.quoteCode = successData.data.quoteCode;
                    this.isDocusign = successData.data.isDocusign;
                    this.quoteCategory = successData.data.quoteCategory;
                    this.quoteisSiteBilling = successData.data.isSiteBilling;
                    this.isSiteWiseBillingEnabled = this.quoteisSiteBilling;
                    const data = { 'message': 'Please wait ...' };
                    this.dataSharedSvc.open(data);
                    const listOfSites = [];
                    const listOfSitesCCA = [];
                    this.salesOrderParams.quoteId = successData.data.quoteId;
                    this.salesOrderParams.quoteLeId = successData.data.legalEntities[0].quoteleId;
                    this.salesOrderParams.legalEntityId = successData.data.legalEntities[0].customerLegalEntityId;
                    if (this.is_manual) {
                        this.search.searchCode = successData.data.legalEntities[0].tpsSfdcOptyId;
                        if (this.search.searchCode) {
                            this.is_sfdc_exists = true;
                        }
                        if (successData.data.legalEntities[0].legalAttributes.find(e => e.displayValue == "GstOption")) {
                            this.is_other_gst = successData.data.legalEntities[0].legalAttributes.find(e => e.displayValue == "GstOption").attributeValue
                        }
                        this.manualSelectdContact = successData.data.legalEntities[0].legalAttributes.find(e => e.displayValue == "CONTACTNAME").attributeValue;
                        this.salesOrderParams.selectedCorrespondingLegalEntityName = successData.data.legalEntities[0].legalAttributes.find(e => e.displayValue == "correspondingLegalEntityName") ? successData.data.legalEntities[0].legalAttributes.find(e => e.displayValue == "correspondingLegalEntityName").attributeValue : "";
                        this.salesOrderParams.selectedCurrency = successData.data.legalEntities[0].legalAttributes.find(e => e.displayValue == "currency") ? successData.data.legalEntities[0].legalAttributes.find(e => e.displayValue == "currency").attributeValue : "";

                    }
                    let QUOTE_SITE_TYPE = successData.data.legalEntities[0].legalAttributes.find(e => e.displayValue === 'QUOTE_SITE_TYPE');
                    this.isGVPNInternational = QUOTE_SITE_TYPE ? QUOTE_SITE_TYPE.attributeValue.toLowerCase().indexOf('international') > -1 : false;;
                    this.salesOrderData.quote = successData.data;
                    // if (this.commonProductName === 'IZONE') {
                        if (this.commonProductName === 'IZOMCC') {
                            
                        this.salesOrderData.izoneCustomerLeId = successData.data.legalEntities[0].customerLegalEntityId ? successData.data.legalEntities[0].customerLegalEntityId : this.customerLe;
                        const filteredTaxExempted = successData.data.legalEntities[0].legalAttributes.filter(each => { return each.displayValue === 'isTaxExemption' })
                        // const temp = this.solutionDetailsObj.siteData.filter(e => e.isTaxExempted);
                        if (filteredTaxExempted.length > 0) {
                            this.salesOrderParams.isTaxExemption = filteredTaxExempted[0].attributeValue;
                        }
                        successData.data.legalEntities[0].legalAttributes.forEach(attr => {
                            if (attr.displayValue === 'Payment Currency' && attr.attributeValue.length) {
                                this.salesOrderData.updatedCurrency = attr.attributeValue;
                            } else if (attr.displayValue === 'Supplier Contracting Entity' && attr.attributeValue.length) {
                                this.salesOrderData.updatedSupplierId = successData.data.legalEntities[0]['supplierLegalEntityId'];
                            }
                        });
                        let taxDocument = _.find(successData.data.quoteSdwanAttributeValues, { 'displayValue': 'SP_LE_TAX_DOCUMENT_ATTACHMENT_ID' });
                        let attachmentName = _.find(successData.data.quoteSdwanAttributeValues, { 'displayValue': 'SP_LE_TAX_DOCUMENT_NAME' });
                        let isPureByon = _.find(successData.data.quoteSdwanAttributeValues, { 'displayValue': 'BYON100P' });
                        this.salesOrderData.isPureByon = (isPureByon && isPureByon.attributeValue === 'true') ? true : false;
                        this.salesOrderData.attachmentName = (attachmentName) ? attachmentName.attributeValue : '';
                        this.salesOrderData.attachmentId = (taxDocument) ? taxDocument.attributeValue : '';
                    }
                    this.salesOrderData.customerId = this.salesOrderData.quote.customerId;
                    this.salesOrderData.customerLeId = successData.data.legalEntities[0].customerLegalEntityId;
                    this.salesOrderData.offerings = [];
                    this.selectedleOwner = successData.data.leOwnerDetailsSfdc ? successData.data.leOwnerDetailsSfdc : {};
                    this.selectedleOwner['teamRole'] = (!this.selectedleOwner['teamRole'] || this.selectedleOwner['teamRole'].length == 0) ? null : this.selectedleOwner['teamRole'];
                    this.selectedleOwner['region'] = (!this.selectedleOwner['region'] || this.selectedleOwner['region'].length == 0) ? null : this.selectedleOwner['region'];
                    this.selectedCustomerLegalEntity = successData.data.legalEntities[0].customerLegalEntityId;
                    for (let i = 0; i < this.salesOrderData.quote.legalEntities.length; i++) {
                        for (let j = 0; j < this.salesOrderData.quote.legalEntities[i].productFamilies.length; j++) {
                            for (let k = 0; k < this.salesOrderData.quote.legalEntities[i].productFamilies[j].solutions.length; k++) {
                                this.salesOrderData.offerings.push(this.salesOrderData.quote.legalEntities[i].productFamilies[j].solutions[k]);
                            }
                        }
                    }
                    this.getCustomerContractingAddress();

                    successData.data.legalEntities[0].legalAttributes.forEach((legalAttribute) => {
                        if (legalAttribute.displayValue === 'Customer Contracting Entity') {
                            listOfSitesCCA.push(legalAttribute.attributeValue);
                        }
                    });
                    this.salesOrderData.sites = [];
                    // if (this.commonProductName === 'IZONE') {
                        if (this.commonProductName === 'IZOMCC') {
                        this.billingAddress();
                        this.getCustomerLegalEntity();
                        
                    }
                    this.isCrossConnect = this.salesOrderData.offerings.find(offering => offering.offeringName == 'MMR Cross Connect');
                }, (error) => { }, this.is_manual, this.commonProductName);
            }
        }, error => { });
    }

    getContractingInfoDetails() {
        this.izomcc_orderFormService.getLeContactInfo(this.salesOrderParams.customerLegalEntityId, (success) => {
            const contactData = success.data && success.data.contacts.length ? success.data.contacts[0] : {};
            this.userDetail.customerContactList = success.data.contacts;
            const savedDetails = this.userDetail.customerContactList.filter(x => x.contactId === Number(this.userDetail.savedContactId));
            this.userDetail.contactId = savedDetails.length ? savedDetails[0].contactId : contactData ?.contactId;
            this.getCustomerContact();
            if (this.is_manual) {
                this.manualContactList = success.data.contacts;
                this.manualSelectdContact = this.manualSelectdContact ? this.manualContactList.find(e => e.customerLeContactName == this.manualSelectdContact) ? this.manualContactList.find(e => e.customerLeContactName == this.manualSelectdContact).customerLeContactName : '' : this.manualContactList[0].customerLeContactName;
                this.userDetail.id = this.manualSelectdContact ? this.manualContactList.find(e => e.customerLeContactName == this.manualSelectdContact).contactId : this.manualContactList[0].contactId;
                this.userDetail.fname = this.manualSelectdContact ? this.manualContactList.find(e => e.customerLeContactName == this.manualSelectdContact).customerLeContactName.split(' ')[0] : this.manualContactList[0].customerLeContactName.split(' ')[0];
                this.userDetail.lname = this.manualSelectdContact ? this.manualContactList.find(e => e.customerLeContactName == this.manualSelectdContact).customerLeContactName.split(' ')[1] : this.manualContactList[0].customerLeContactName.split(' ')[1];
                this.userDetail.contact = this.manualSelectdContact ? this.manualContactList.find(e => e.customerLeContactName == this.manualSelectdContact).mobileNumber : this.manualContactList[0].mobileNumber;
                this.userDetail.email = this.manualSelectdContact ? this.manualContactList.find(e => e.customerLeContactName == this.manualSelectdContact).customerLeContactEmailid : this.manualContactList[0].customerLeContactEmailid;
                this.userDetail.desingn = this.manualSelectdContact ? this.manualContactList.find(e => e.customerLeContactName == this.manualSelectdContact).title : this.manualContactList[0].title;
              }
  
          }, (error) => { });
  
      }
  
      getCustomerContact(): void {
          const customer = this.userDetail.customerContactList.find(customer => customer.contactId === this.userDetail.contactId);
          this.userDetail.fname = customer?.customerLeContactName.split(' ')[0] || "" ;
          this.userDetail.lname = customer?.customerLeContactName.split(' ')[1] || "" ;
          this.userDetail.name = customer?.customerLeContactName ;
          this.userDetail.contact = customer?.mobileNumber;
          this.userDetail.email = customer?.customerLeContactEmailid;
          this.userDetail.desingn = customer?.title;
          this.userDetail.id = customer?.contactId;
          this.userDetail.savedContactId = customer?.contactId;
      }

    /** after user selects entity from a list needs to get name and corresponding tata communcations legal entity */
    setEntity(index, frmPartner?, fromPage?) {
        this.salesOrderParams.customerLegalEntityId = this.salesOrderParams.legalEntityId;
        let params: any = {};
        // if (this.commonProductName === 'IZONE') {
            if (this.commonProductName === 'IZOMCC') {
            this[this.commonService].getSupplierLegalEntityIzone(this.salesOrderParams.legalEntityId, this.quoteObj.quoteId, (supplierLegalEnitiy) => {
                console.log(supplierLegalEnitiy)
                this.salesOrderData.supplierLegalDetails = supplierLegalEnitiy.data.izosdwanSupplierBeans;
                this.salesOrderParams.supplierLegalEntityId = Number(supplierLegalEnitiy.data.serviceProviderId);
                this.salesOrderData.supplierLegalDetailsOrg = _.cloneDeep(supplierLegalEnitiy.data.izosdwanSupplierBeans);
                // this.salesOrderParams.correspondingLegalEntityName = supplierLegalEnitiy.data.sple;
                // this.salesOrderParams.currency = supplierLegalEnitiy.data.currency;
                params.attributeName = 'louRequired';
                params.attributeValue = supplierLegalEnitiy.data.louRequired;
                this.salesOrderData.quoteLeId = params.quoteToLe
                let supplierId = this.salesOrderData.updatedSupplierId ? this.salesOrderData.updatedSupplierId : this.salesOrderData.supplierLegalDetails[0].supplierId;
                this.isTaxDocumentRequired = (supplierLegalEnitiy.data.isTaxClearanceDocumentRequired) ? true : false;
                if (this.isTaxDocumentRequired) {
                    this.salesOrderData.uploadSuccess = false;
                    if (this.salesOrderData.attachmentId && this.salesOrderData.attachmentId.length) {
                        this.salesOrderData.uploadSuccess = true;
                    }
                }
                sessionStorage.setItem('withHoldTaxApplicable', supplierLegalEnitiy.data.isTaxClearanceDocumentRequired);
                // this[this.commonService].postLOU(params, (success) => { }, (error) => { });
            })
        }
    }

    proceedToNextPartnerCheck(template) {
        this.openLoaderModal({ message: "Please wait" });
        if (this.isLoggedInUserPartner) {
           
        } else {
            this.proceedToNext(template);
        }
    }
    /** Routing to next state */
    proceedToNext(template) {
        if (!this.salesOrderParams.GSTNumber && this.selectedLeGSTAndAddress.length) {
            this.modalRef = this.modalService.show(template, { class: "modal-sm" });
            return;
        }
        console.log(this.salesOrderParams)
        console.log(this.salesOrderData)
       
        let quoteLeId;
        if (this.quoteObj.quoteleId == null || this.quoteObj.quoteleId == undefined) {
            quoteLeId = this.quoteObj.legalEntities[0].quoteleId;
        }
        else {
            quoteLeId = this.quoteObj.quoteleId;
        }
        let selectedGstAddress;
        if (this.gstnInfoList &&this.gstnInfoList.length !=0) {
            selectedGstAddress = this.gstnInfoList.filter( each => {
                return each.gstn === this.salesOrderParams.GSTNumber
            })[0].address;
        }
        
        const params = {
            quoteToLe: quoteLeId,

            attributeDetails: [{
                'name': 'customerLegalEntityId',
                'value': this.salesOrderParams.customerLegalEntityId
            }, {
                'name': 'entityName',
                'value': this.salesOrderParams.numberOfEntity
            }, {
                'name': 'LESTATEGST',
                'value': this.salesOrderParams.leStateGstId
            }, {
                'name': 'BILLING_CONTACT_ID',
                'value': this.salesOrderData.billingInfoid
            },
            {
                'name': 'CUSTOMER_CODE',
                'value': this.salesOrderData.customerCode
            },
            {
                'name': 'CUSTOMER_LE_CODE',
                'value': this.salesOrderData.customerLeCode
            },
            {
                'name': 'LeStateGstAddress',
                'value': selectedGstAddress
            },
            {
                'name': 'LeStateGstNumber',
                'value': this.salesOrderParams.GSTNumber
            }]
        };
        
        params.attributeDetails.push({
            'name': 'CONTACTID',
            'value': this.userDetail.id
        }, {
                'name': 'CONTACTNAME',
                'value': `${this.userDetail.fname} ${this.userDetail.lname}`
            }, {
                'name': 'CONTACTEMAIL',
                'value': this.userDetail.email
            }, {
                'name': 'CONTACTNO',
                'value': this.userDetail.contact
            });
        // if (this.commonProductName === 'IZONE') {
            if (this.commonProductName === 'IZOMCC') {
            params.attributeDetails.push({
                'name': 'isTaxExemption',
                'value': this.salesOrderParams.isTaxExemption
            });
            // this[this.commonService].updateStage(this.quoteObj.quoteId, this.quoteObj.quoteleId, encodeURIComponent('Review & Accept'), response => {
            // }, error => { });
        }
        if (this.salesOrderParams.po_number != '' && this.salesOrderParams.po_date != '') {
            let date = this.salesOrderParams.po_date === '' ? '' : this.salesOrderParams.po_date.toLocaleDateString();
            date = _moment(date).format('DD-MM-YYYY');
            date = (date === "Invalid date") ? '-' : date;
            params.attributeDetails.push({
                'name': 'PO_NUMBER',
                'value': this.salesOrderParams.po_number
            }, {
                    'name': 'PO_DATE',
                    'value': date
                })
        }
        const quoteParams = {
            quoteId: this.salesOrderParams.quoteId,
            quoteLeId: this.salesOrderParams.quoteLeId
        }
        console.log(params)

        this[this.commonService].postStoreData(quoteParams, params, (success) => { }, (error) => {
            
         });
        this.salesOrderParams.quoteId = this.quoteObj.quoteId;
        this.salesOrderParams.quoteLeId = quoteLeId;
        this.salesOrderParams.familyName = this.commonProductName;
        this.salesOrderParams.customerId = this.salesOrderData.customerId;
        this.flag.orderFormSpinner = true;
        this.salesOrderParams['teamRoleSfdc'] = this.selectedleOwner['teamRole'];
        this.salesOrderParams['regionSfdc'] = this.selectedleOwner['region'];
        this.salesOrderParams['contactMobileSfdc'] = this.selectedleOwner['mobile'];
        

       
        console.log(quoteParams)
        console.log(this.salesOrderParams)

            if (this.commonProductName == 'IZOMCC') {
            this[this.commonService].postSalesOrderForIzomcc(quoteParams, this.salesOrderParams, (successData) => {
                if (successData.responseCode === 200) {
                    console.log(successData.data)
                    // this.creditCheck.creditCheckStatus = successData.data.creditCheckStatus;
                    // this.creditCheck.preapprovedFlag = successData.data.preapprovedFlag;
                    // this.creditCheck.creditCheckStatus != 'Positive' ? this.creditCheck.creditCheckStatusFlag = true : false;
                    // console.log(this.creditCheck)
                    // this.dataSharedSvc.setCreditStatus(this.creditCheck);
                    this.flag.orderFormSpinner = false;
                    this.router.navigateByUrl(this.fronturl);
                }
            }, (errorData) => {
                let ticket = errorData.error && errorData.error.ticket ? `, Ticket id:${errorData.error.ticket}` : '';
                if (this.quoteCategory && this.quoteCategory === 'ADD_SITE') {
                    const data = { 'message': `Something went wrong${ticket}` };
                    this.dataSharedSvc.open(data);
                } else {
                    this.creditCheck.creditCheckStatusFlag = true;
                    if (errorData.error && !errorData.error.message) {
                        const data = { 'message': `Something went wrong${ticket}` };
                        this.dataSharedSvc.open(data);
                    } 
                    // else {
                    //     if (!this.flag.salesLogin) {
                    //         this.creditCheck.creditCheckStatus = errorData.error.message;
                    //     } else {
                    //         this.creditCheck.creditCheckError = errorData.error.message;
                    //     }
                    //     this.dataSharedSvc.setCreditStatus(this.creditCheck);
                    // }
                }
            });
        } else {
            this[this.commonService].postSalesOrder(quoteParams, this.salesOrderParams, (successData) => {
                if (successData.responseCode === 200) {
                    // this.creditCheck.creditCheckStatus = successData.data.creditCheckStatus;
                    // this.creditCheck.preapprovedFlag = successData.data.preapprovedFlag;
                    // this.creditCheck.creditCheckStatus != 'Positive' ? this.creditCheck.creditCheckStatusFlag = true : false;
                    // this.dataSharedSvc.setCreditStatus(this.creditCheck);
                    this.flag.orderFormSpinner = false;
                    this.router.navigateByUrl(this.fronturl);
                  
                }
            },
                (errorData) => {
                    let ticket = errorData.error && errorData.error.ticket ? `, Ticket id:${errorData.error.ticket}` : '';
                    if (this.quoteCategory && this.quoteCategory === 'ADD_SITE') {
                        // this.openMessageModal({ message: `Something went wrong${ticket}` });
                        const data = { 'message': `Something went wrong${ticket}` };
                        this.dataSharedSvc.open(data);
                    } 
                    // else {
                    //     this.creditCheck.creditCheckStatusFlag = true;
                    //     if (errorData.error && !errorData.error.message) {
                    //         // this.openMessageModal({ message: `Something went wrong${ticket}` });
                    //         const data = { 'message': `Something went wrong${ticket}` };
                    //         this.dataSharedSvc.open(data);
                    //     } else {
                    //         if (!this.flag.salesLogin) {
                    //             this.creditCheck.creditCheckStatus = errorData.error.message;
                    //         } else {
                    //             this.creditCheck.creditCheckError = errorData.error.message;
                    //         }
                    //         this.dataSharedSvc.setCreditStatus(this.creditCheck);
                    //         this.router.navigateByUrl(this.fronturl);
                    //     }
                    // }
                });
        }
        this.dataSharedSvc.close();
    }

    /** Routing back to previous state */
    routeBack() {
        this.router.navigateByUrl(this.backurl);
    }

    public billingAddress(tolres?: any): void {
        console.log(tolres)
        if (tolres) {
          
            this.salesOrderData.defaultbillingInfoid = tolres.id.data.billingInfoId;
            this.salesOrderData.addnew = ""
            this.salesOrderData.billingInfoid = tolres.id.data.billingInfoId;
            this.salesOrderParams.billingAddress = tolres.id.data.billAddr;
        }
        this[this.commonService].getCustomerBillingAddress(this.salesOrderParams.legalEntityId, (success) => {
           
            console.log(success)
            this.salesOrderData.billingAddresses = success.data;
            // Removing Duplicate Addresses
            this.salesOrderData.uniqueAddressFromBillingAddresses = _.uniqBy(success.data || [], 'billAddr');
            // Removing Duplicate Emails
            this.salesOrderData.uniqueEmailsFromBillingAddresses = _.uniqBy(success.data || [], 'emailId');
            this.billingAddressList = success.data;
            if (this.salesOrderData.billingAddresses ?.length) {
              
                this.salesOrderData.customerCode = success.data[0].customerCode;
                this.salesOrderData.customerLeCode = success.data[0].customerLeCode;
            } else {
              
                this.salesOrderParams.billingAddress = '';
            }
            console.log(this.salesOrderData.defaultbillingInfoid)
            console.log(this.salesOrderData.billingAddresses[0].billingInfoid)

            if (this.salesOrderData.billingInfoid == '' ||this.salesOrderData.billingInfoid == undefined) {
               
                this.salesOrderData.addnew = ""
                this.salesOrderData.billingInfoid = this.salesOrderData.billingAddresses.length ? this.salesOrderData.billingAddresses[0].billingInfoid : '';
                this.salesOrderParams.billingAddress = this.salesOrderData.billingAddresses.length ? this.salesOrderData.billingAddresses[0].billAddr : '';
                console.log( this.salesOrderParams.billingAddress ,"592") 
            } else {
               
                this.salesOrderData.addnew = ""
                let savedBillingInfo = this.salesOrderData.billingAddresses.find(value => value.billingInfoid == Number(this.salesOrderData.defaultbillingInfoid));
                let value = savedBillingInfo && Object.keys(savedBillingInfo).length ? savedBillingInfo : this.salesOrderData.billingAddresses.length ? this.salesOrderData.billingAddresses[0] : {};
                this.salesOrderData.billingInfoid = value.billingInfoid;
                this.salesOrderParams.billingAddress = value.billAddr;
            }
            // add billing address changes
            if (this.salesOrderData.billingInfoid) {
               
                this.getbillingContactDetails();
            } else {
               
                this.userDetail.billingContactDetails = [];
                this.userDetail.billingContactDetails.push({ fname: '', lname: '', contactNumber: '', emailId: '' });
            }

            //end of billing add changes
        }, (error) => { });
    }
    // add billing address changes
    private getbillingContactDetails(): void {
        this[this.commonService].getbillingAddress(this.salesOrderParams.legalEntityId, this.salesOrderData.billingInfoid, (success) => {
           console.log(success)
            this.userDetail.billingContactDetails = [];
            let data = success.data;
            this.userDetail.billingContactDetails.push({ 'fname': data.fname, 'lname': data.lname, 'contactNumber': data.mobileNumber, 'emailId': data.emailId });
            // console.log(this.userDetail.billingContactDetails)
        }, (error) => { })
        console.log(this.salesOrderData.billingAddresses,"621")
        if (this.salesOrderData.billingAddresses ?.length) {
            let selectedBillingAddress = this.salesOrderData.billingAddresses.find(billAdd => billAdd.billingInfoid == Number(this.salesOrderData.billingInfoid)) ?.billAddr;
            console.log(selectedBillingAddress,"selectedBillingAddress")
            this.salesOrderParams.billingAddress=selectedBillingAddress // newly added
            this.isGSTForIndia = selectedBillingAddress ?.indexOf('India') > -1 ? true : false;
        }
    }

    getuserDetails() {
        this.izomcc_orderFormService.getUserDetails(response => {

            this.userDetail = {
                "id": response.data.id,
                "fname": response.data.firstName,
                "lname": response.data.lastName,
                "desingn": response.data.designation,
                "email": response.data.emailId,
                "contact": response.data.contactNo
            }
            
            this.legalModal.openEdit(response.data);
        }, (error) => { })
    }

    // private afterGetCustomerLegalEntity(): void {
    //     if (this.commonProductName === 'IZONE') { // && (!attributes.data.filter(e => e.displayValue === 'customerLegalEntityId' && e.attributeValue).length)
    //         // this.preFillLegalEntityForPartner();
    //         this.setEntity(0, true);
    //     }
    // }

    private getCustomerLegalEntity() {
        /** for getting list of legal entity */
        if (!(this.partnerGVPNOppIdDetails && this.partnerGVPNOppIdDetails.data.customerLeName) || this.commonProductName !== 'PARTNERIAS' && this.commonProductName !== 'PARTNERGVPN' && this.commonProductName !== 'PARTNERNPL') {
            this[this.commonService].getCustomerLegalEntity(this.salesOrderData.customerId, (customerLegalEntity) => {
                this.salesOrderData.listOfLegalEntity = customerLegalEntity.data;
                this.salesOrderData.listOfLegalEntity = this.salesOrderData.listOfLegalEntity.filter(e => e.isVerified == 'Y');
                if (this.quoteisSiteBilling) {
                    // this.getSavedSitewiseBillingAttr();
                }
                if (!this.salesOrderData.listOfLegalEntity.length && this.isLoggedInUserPartner) {
                    let message = `Submit your documents to partnersupport@tatacommunications.com for following account details:<br> 
                Account name- ${this.salesOrderData.quote.customerName}<br>
                Optimus Quote ID- ${this.salesOrderData.quote.quoteCode}<br>
                for Account verification`
                    // this.openMessageModal({ message });
                    this.dataSharedSvc.close();
                    return;
                }
                this.salesOrderData.listOfLegalEntityOrg = JSON.stringify(this.salesOrderData.listOfLegalEntity);
                // if (this.commonProductName === 'IZONE') {
                    if (this.commonProductName === 'IZOMCC') {
                    this.salesOrderData.listOfLegalEntity.forEach(entity => {
                        if (entity.legalEntityId === this.salesOrderData.izosdwanCustomerLeId) {
                            this.salesOrderParams.legalEntityId = entity.legalEntityId;
                        }
                    });
                }

                this.afterGetCustomerLegalEntity();
                this.dataSharedSvc.close();
            }, (errorData) => {
                // this.errorHandling(errorData);
            }, this.isLoggedInUserPartner);
        } else {
            this.salesOrderData.listOfLegalEntity = [];
            this.afterGetCustomerLegalEntity();
        }
    }


    // /**get contracting address based on customer Legal Entity */
    private getCustomerContractingAddress() {
        this.izomcc_orderFormService.getCustomerContractingAddress(this.selectedCustomerLegalEntity, (success) => {
            let data = success.data;
            let updatedgstn;
            this.customerContractingAddressList = data.customerContractingAddressInfoList.reduce((prev, curr) => {
                let locations: any = {};
                var add = '';
                var currAddress = curr['address'];
                if ('addressLineOne' in currAddress) add = add + currAddress['addressLineOne'] + ' ';
                if ('addressLineTwo' in currAddress) add = add + currAddress['addressLineTwo'] + ' ';
                if ('locality' in currAddress) add = add + currAddress['locality'] + ' ';
                add = add + currAddress['city'] + ' ';
                add = add + currAddress['state'] + ' ';
                add = add + currAddress['country'] + ' ';
                add = add + currAddress['pincode'];

                locations.add = add;
                locations.locationId = curr['locationId'];
                locations.isDefault = curr['isDefault'];
                locations.gstnInfoList = curr['gstnInfoList'];

                this.isGSTForIndia = add.indexOf('India') > -1 ? true : false;
                if (locations.isDefault == 1 && !this.selectedCustomerContractingAddress) {
                    this.selectedCustomerContractingAddress = add;
                    updatedgstn = curr;
                    //this.onSelectOfContractingAddress(curr);
                }
                if (this.selectedLocationId == curr.locationId) {
                    updatedgstn = curr;
                }
                return [...prev, locations];
            }, []);
            if (!updatedgstn) {
                this.customerContractingAddressList.forEach((address) => {
                    if (address.isDefault)
                        updatedgstn = address;
                })
            }

            this.gstnInfoList = this.customerContractingAddressList.reduce((prev, curr) => {
                var gstnInfo = curr['gstnInfoList'];
                if (this.is_manual) {
                    gstnInfo.push({
                        address: "",
                        city: "",
                        gstn: "Others",
                        leStateGstId: '',
                        state: ""
                    })
                }
                return [...prev, gstnInfo];
            }, []);
            this.onSelectOfContractingAddress(updatedgstn);

        }, (error) => { });
    }

    /**Update CCA - Attribute call */
    onSelectOfContractingAddress(location) {
        this.gstnInfoList = location.gstnInfoList;
        if (this.gstnInfoList.length == 0) {
            this.salesOrderParams.GSTNumber = 'No Registered GST';
        }
        else
            this.salesOrderParams.GSTNumber = this.gstnInfoList['0']['gstn'];
        if (this.is_manual && this.salesOrderParams.GSTNumber == "Others") {
            this.showInput = true
        }
        let params: any = {};
        let quoteLeId;

        if (this.quoteObj.quoteleId == null || this.quoteObj.quoteleId == undefined) {
            quoteLeId = this.quoteObj.legalEntities[0].quoteleId;
            // console.log(params.quoteToLe)
        }
        else {
            quoteLeId = this.quoteObj.quoteleId;
        }
        // if (this.commonProductName != 'IZOPC') {

        //     if (this.gstnInfoList.length == 1)
        //         this.salesOrderParams.GSTNumber = this.gstnInfoList['0']['gstn'];


        //     params.quoteToLe = quoteLeId;
        //     params.quoteId = this.quoteObj.quoteId
        //     params.attributeName = 'Customer Contracting Entity';
        //     params.attributeValue = location.locationId;
        //     this[this.commonService].postCCA(params, (success) => { }, (error) => { });

        //     if (this.commonProductName === "GVPN") {
        //         console.log(this.selectedCustomerContractingAddress);
        //         var postObj = {
        //             "quoteToLe": this.quoteObj.quoteleId,
        //             "attributeDetails": [
        //                 { "name": "Customer Contracting Address", "value": this.selectedCustomerContractingAddress ? this.selectedCustomerContractingAddress : ' ' }
        //             ]
        //         }
        //         this[this.commonService].postStoreData(postObj, (response) => { console.log("Customer Contracting Address attr Updated"); }, (error) => { });
        //     }
        // }
        // else {
        //     params.quoteToLe = this.quoteObj.quoteleId;
        //     params.attributeDetails = [{
        //         name: "Customer Contracting Entity",
        //         value: location.locationId
        //     }]
        //     this[this.commonService].postCCA(params, (success) => { }, (error) => { });

        // }
    }

    updateStage() {
        let quoteLeId;
        if (this.quoteObj.quoteleId == null || this.quoteObj.quoteleId == undefined) {
            quoteLeId = this.quoteObj.legalEntities[0].quoteleId;
            // console.log(params.quoteToLe)
        }
        else {
            quoteLeId = this.quoteObj.quoteleId;
        }
        this.izomcc_orderFormService.updateQuoteStage(this.quoteObj.quoteId, quoteLeId, this.payloadStage, (res) => {
            console.log(res, 'res');

        }, (error) => {

        })
    }

    openLoaderModal(data) {
        this.dataSharedSvc.open(data);
    }

    afterGetCustomerLegalEntity() {
     
        this[this.commonService].getAttributesIZOMCC((attributes) => {
            let attr = attributes.data.filter(e => e.displayValue === 'entityName' && e.attributeValue);
            this.salesOrderParams.numberOfEntity = attr.length ? attr[0].attributeValue : 'single';
            attr = attributes.data.filter(e => e.displayValue === 'customerLegalEntityId' && e.attributeValue);
            this.userDetail.savedContactId = attributes.data.find(e => e.displayValue === 'CONTACTID' && e.attributeValue) ?.attributeValue;
            if (this.commonProductName === 'IZO SDWAN') {
                this.salesOrderParams.customerLegalEntityId = attr.length ? attr[0].attributeValue : this.salesOrderParams.legalEntityId;
            } else {
                this.salesOrderParams.customerLegalEntityId = this.salesOrderData.customerLeId;
            }

            attr = attributes.data.filter(e => e.displayValue === 'PO_NUMBER' && e.attributeValue);
            this.salesOrderParams.po_number = attr.length ? attr[0].attributeValue : '';

            attr = attributes.data.filter(e => e.displayValue === 'PO_DATE' && e.attributeValue);
            this.salesOrderParams.po_date = attr.length ? attr[0].attributeValue : '';
            let date = _moment(this.salesOrderParams.po_date, 'DD-MM-YYYY')
            this.salesOrderParams.po_date = new Date(date.format('MM-DD-YYYY').split('-').join('/'));

            attr = attributes.data.filter(e => e.displayValue === 'BILLING_CONTACT_ID' && e.attributeValue);
            console.log(attr)
            this.salesOrderData.defaultbillingInfoid = attr.length ? attr[0].attributeValue : '';
            this.salesOrderData.billingInfoid = this.quoteisSiteBilling ? this.salesOrderData.billingInfoid : attr.length ? attr[0].attributeValue : this.salesOrderData.billingInfoid;
            attributes.data.forEach(atr => {
                if (atr.displayValue === "LeStateGstNumber") {
                    this.salesOrderParams.GSTNumber = atr.attributeValue;
                    if (this.is_manual && this.is_other_gst == 'Others') {
                        this.otherGstNumber = atr.attributeValue
                    }
                }
                if (atr.displayValue === "LeStateGstAddress") {
                    this.salesOrderParams.LeStateGstAddress = atr.attributeValue;
                }
            });

            if (this.is_manual && this.is_other_gst == 'Others') {
                this.salesOrderParams.GSTNumber = this.is_other_gst;
                this.otherGstNumber = this.otherGstNumber
                this.showInput = true
            }


            if (this.is_manual) {
                this.selectedleOwner.ownerName = attributes.data.find(e => e.displayValue === 'SUPPLIER_LE_OWNER') ? attributes.data.find(e => e.displayValue === 'SUPPLIER_LE_OWNER').attributeValue : '';
            }
            this.salesOrderParams.legalEntityId = this.salesOrderParams.customerLegalEntityId;
            this.salesOrderParams.legalEntityId = Number(this.salesOrderParams.legalEntityId);
            // this.getbillingContactDetails();


            this.salesOrderData.listOfLegalEntity.forEach((legalEntity, key) => {
                if (legalEntity.legalEntityId === Number(this.salesOrderParams.customerLegalEntityId)) {
                    // if (this.commonProductName != 'GVPN' || this.commonProductName != 'IZOPC' || this.commonProductName != 'PARTNERGVPN' || this.commonProductName != 'IAS' || this.commonProductName != 'IZO Internet WAN' || this.commonProductName != 'IZONE') {
                        if (this.commonProductName != 'GVPN' && this.commonProductName != 'IZOPC'&& this.commonProductName != 'PARTNERGVPN' && this.commonProductName != 'IAS' && this.commonProductName != 'IZO Internet WAN' && this.commonProductName != 'IZOMCC') {
                                 
                            this.setEntity(key);
                    }
                    // if (this.commonProductName == 'GVPN' || this.commonProductName === 'IZOPC' || this.commonProductName === 'PARTNERGVPN' || this.commonProductName === 'IAS' || this.commonProductName === 'IZO Internet WAN' || this.commonProductName == 'IZONE') {
                        if (this.commonProductName == 'GVPN' || this.commonProductName === 'IZOPC' || this.commonProductName === 'PARTNERGVPN' || this.commonProductName === 'IAS' || this.commonProductName === 'IZO Internet WAN' || this.commonProductName == 'IZOMCC') {
                            this.checkLegalIntity(key);
                    }
                }
                setTimeout(() => {
                    this.dataSharedSvc.close();
                }, 1000);
            });

            setTimeout(() => {
                this.dataSharedSvc.close();
            }, 1000);
        }, (error) => { });
    }

    checkLegalIntity(index, fromPage?) {
        var obj = {
            customerLegalEntityId: this.salesOrderParams.legalEntityId,
            productName: this.commonProductName,
            siteCountry: []
        }
        this.salesOrderData.listOfLegalEntityList = JSON.parse(this.salesOrderData.listOfLegalEntityOrg);
        // console.log( this.salesOrderData.listOfLegalEntityList)
        //others
        // if (this.commonProductName == "IZO Internet WAN" || this.commonProductName == "IAS" || this.commonProductName == "GVPN") {
            var supplierCountry = this.salesOrderData.listOfLegalEntityList[index].country.toString();
            var siteObj = {
                country: supplierCountry
            }
            console.log(supplierCountry);
            obj.siteCountry.push(siteObj);
            // console.log(siteObj)
        // }
        // this.salesOrderData.listOfSites.forEach(site => {
        //     var siteObj = {
        //         siteId: site.locationId,
        //         country: site.userAddress.country
        //     }
        //     obj.siteCountry.push(siteObj);
        // })


        this[this.commonService].checkIntity(response => {
           
            // if ((this.commonProductName === 'IZOPC' || this.commonProductName === 'GVPN' || this.commonProductName === 'PARTNERGVPN' || this.commonProductName === 'IAS' || this.commonProductName === 'IZO Internet WAN' || this.commonProductName == "IZONE") && response.data.exception) {
                if ((this.commonProductName === 'IZOPC' || this.commonProductName === 'GVPN' || this.commonProductName === 'PARTNERGVPN' || this.commonProductName === 'IAS' || this.commonProductName === 'IZO Internet WAN' || this.commonProductName == "IZOMCC") && response.data.exception) {
                this.openModal(this.mailSentTemplate, 'modal-sm');
                return;
            }
            //else {
            var response = response.data;
           
            if (!this.quoteisSiteBilling) {
               
                this.salesOrderParams.entityName = this.salesOrderData.listOfLegalEntity[index].legalEntityName;
                this.salesOrderData.billingAddress = this.salesOrderData.listOfLegalEntity[index].billingAddresses;
                // this.salesOrderParams.leStateGstId = this.salesOrderData.listOfLegalEntity[index].billingAddresses[0] ? this.salesOrderData.listOfLegalEntity[index].billingAddresses[0].leStateGstId : '';
                this.salesOrderParams.billingAddress = this.salesOrderData.listOfLegalEntity[index].billingAddresses[0] ? this.salesOrderData.listOfLegalEntity[index].billingAddresses[0].address : this.salesOrderParams.billingAddress;
                // console.log(this.salesOrderData.listOfLegalEntity[index])
                // console.log(this.salesOrderData.listOfLegalEntity)
                // console.log(index)
                // console.log(this.salesOrderData.listOfLegalEntity[index].billingAddresses[0].address)
            }
           
            this.salesOrderParams.customerLegalEntityId = this.salesOrderParams.legalEntityId;

            this.salesOrderParams.correspondingLegalEntityName = response.sple;
            this.salesOrderParams.currency = response.currency;
            this.salesOrderParams.supplierLegalEntityId = response.serviceProviderId;
            if(this.is_manual){
                this.salesOrderData.correspondingLegalEntityName.push({ supplierName:response.sple });
                this.salesOrderParams.selectedCorrespondingLegalEntityName = this.salesOrderData.correspondingLegalEntityName[0].supplierName;
                this.salesOrderParams.supplierLegalEntityId = this.salesOrderData.correspondingLegalEntityName[0].serviceProviderId;
            }
            //post call
            // this.updatePaymentCurrency();   // to be enabled after currency conversion
            // add billing address changes
            console.log(this.salesOrderData)
            
            if (this.salesOrderData.billingInfoid != '') {
                this.getbillingContactDetails();
            }
            console.log(this.salesOrderParams)

                     
        }, (error) => {
            if (error.error.status == 'FAILURE' && (error.error.message == '0XMSE08' || error.error.message == '0XSLEE09' || error.error.message == 'OMCE10')) {
                this.openModal(this.mailSentTemplate, 'modal-sm');
            }
        }, obj);
        console.log(this.salesOrderParams,"946")
        this.getContractingInfoDetails();
        // this.getLeOwners();
    }
     /** for opening modal */
     openModal(template: TemplateRef<any>, type) {
        this.modalRef = this.modalService.show(template, Object.assign({},
            { class: 'modal-dialog-centered ' + type, ignoreBackdropClick: true }));

    }
    getLeOwners() {
        //     const data = { 'message': 'Please wait ...' };
        //    this.dataSharedSvc.open(data);
        this.salesOrderParams.legalEntityId = this.salesOrderParams.legalEntityId == undefined ? '' : this.salesOrderParams.legalEntityId;
        this[this.commonService].getLeOwners(this.salesOrderData.customerId, this.quoteObj.quoteId, this.salesOrderParams.legalEntityId, (response) => {
            this.leowners = response.data;
            if(this.leowners.length && (!this.selectedleOwner || !this.selectedleOwner.ownerName)){
                this.selectedleOwner = this.leowners[0];
            }
            // setTimeout(()=>{
            //     this.dataSharedSvc.close();
            // })
        }, (error) => {

        })
    }

    /**Get Billing Address id */
    getBilling(index, billingAddress?, emailOrAddress?: 'email' | 'address') {
       
        this.salesOrderData.addnew = "";
        const emailId = billingAddress.emailId;
        this.salesOrderParams.billingAddress = billingAddress.billAddr;
        this.salesOrderParams.billingInfoId = billingAddress.billingInfoid;
        
      
        if (emailOrAddress === 'address') {
            // Filter the list of Billing Addresses with same address and reitirate the Email dropdown
            this.salesOrderData.uniqueEmailsFromBillingAddresses = this.salesOrderData.billingAddresses.filter(data => data.billAddr === this.salesOrderParams.billingAddress)
        } else {
            // Filter the list of Billing Addresses with same emails and reitirate the Address dropdown
            this.salesOrderData.uniqueAddressFromBillingAddresses = this.salesOrderData.billingAddresses.filter(data => data.emailId === emailId)
        }

        this.getbillingContactDetails();
        // end of billing add changes

        // const params = {
        //     quoteToLe: this.quoteObj.quoteleId,
        //     attributeDetails: [{
        //         'name': 'BILLING_CONTACT_ID',
        //         'value': this.salesOrderData.billingInfoid
        //     }]
        // };
        // this[this.commonService].postStoreData(pgetarams, (success) => { }, (error) => {

        // });
        // this.salesOrderParams.GSTNumber = this.salesOrderData.billingAddress[index].gstn
    }

    onChangeGSTSelection(data, flag?): void {
        this.selectedLeGSTAndAddress.forEach(e => e['isSelected'] = e['gstn'] === data['gstn']);
        this.salesOrderParams.GSTNumber = data['gstn'];
        if( data.isSelected || flag == 'clicked') {
            for(let index = this.gstnInfoList.length-1; index >=0; index -- ) {
                if(this.gstnInfoList[index]['gstn'] == data['gstn']) {
                    this.gstnInfoList.splice(index,1);
                }
            }
            this.gstnInfoList.unshift(data);
        }
        this.salesOrderParams.LeStateGstAddress = `${data['address']}, ${data['state']}`;
        this.salesOrderParams.leStateGstId = data['leStateGstId'];
    }
}

