<main>
  <section class="" style="width: 98%">
    <section class="container-header-scp">
      <div class="row">
        <div class="col-md-8 align-self-start">
          <h3>
            <i class="icon icon-back" (click)="goBack()"></i> Design your cloud
            solution
          </h3>
        </div>
        <div class="col-md-4 align-self-start">
          <div *ngIf="true" style="padding-right: 50px">
            <button
              tourAnchor="tour-53"
              type="button"
              class="btn active-btn"
              (click)="validationCall()"
            >
              GET QUOTE
            </button>
            <!-- <button type="button" class="btn active-btn" (click)="printval()"
                            >Print Value</button> -->
          </div>
        </div>
      </div>
    </section>
    <section style="margin: 0px 51px; border-top: 1px solid #667586">
      <!-- Read mode -->
      <div class="stepper-horizontal" id="stepper1">
        <div class="step step0 done">
          <div class="step-circle"><span>1</span></div>
          <div class="step-title">Location</div>
          <div class="step-bar-left"></div>
          <div class="step-bar-right"></div>
        </div>
        <div class="step step1 editing">
          <div class="step-circle"><span>2</span></div>
          <div class="step-title">Contract Term</div>
          <div class="step-bar-left"></div>
          <div class="step-bar-right"></div>
        </div>
        <div class="step step2">
          <div class="step-circle"><span>3</span></div>
          <div class="step-title">Connector</div>
          <!-- <div class="step-optional">Optional</div> -->
          <div class="step-bar-left"></div>
          <div class="step-bar-right"></div>
        </div>
        <div class="step step3">
          <div class="step-circle"><span>4</span></div>
          <div class="step-title">VNF Information</div>
          <!-- <div class="step-optional">Optional</div> -->
          <div class="step-bar-left"></div>
          <div class="step-bar-right"></div>
        </div>
        <div class="step step4">
          <div class="step-circle"><span>5</span></div>
          <div class="step-title">Cloud Information</div>
          <!-- <div class="step-optional">Optional</div> -->
          <div class="step-bar-left"></div>
          <div class="step-bar-right"></div>
        </div>
      </div>

      <div class="card" style="margin-top: 25px" *ngIf="hideSolutionDetails">
        <div
          class="w-100 card-header card-higlight d-flex justify-content-between align-items-center flex-row"
        >
          <div class="title">Solution details</div>
          <div *ngIf="hideSolutionDetails">
            <i
              class="fa fa-pencil alignpen"
              aria-hidden="true"
              (click)="editSolutionDetails()"
            ></i>
          </div>
        </div>
        <div id="custom-scroll" class="card-body">
          <div>
            <div class="row pb-4">
              <div class="col-md-2">
                <label class="input-label">Solution alias name</label>
                <div class="input-value">
                  {{ solutionForm.get("solution_alias_name").value }}
                </div>
              </div>

              <div class="col-md-2">
                <label class="input-label">Contract term</label>
                <div
                  class="input-value"
                  *ngIf="solutionForm.get('term_in_months').value != '1 Month'"
                >
                  {{ solutionForm.get("term_in_months").value }}
                </div>
                <div
                  class="input-value"
                  *ngIf="solutionForm.get('term_in_months').value == '1 Month'"
                >
                  Non-committed term – Daily Rate plan
                </div>
              </div>
            </div>
            <div class="title-readmode">Location details</div>
            <div class="row pb-4">
              <div class="col-md-2">
                <label class="input-label">Region</label>
                <div class="input-value">
                  {{ locationForm.get("region").value }}
                </div>
              </div>

              <div class="col-md-2">
                <label class="input-label">City</label>
                <div class="input-value">
                  {{ locationForm.get("city").value }}
                </div>
              </div>
            </div>
            <div class="title-readmode">Connector details</div>
            <div class="row pb-4">
              <div class="col-md-2">
                <label class="input-label">Connection type</label>
                <div class="input-value">
                  {{ connectorForm.get("connType").value }}
                </div>
              </div>

              <div class="col-md-2">
                <label class="input-label">Bandwidth</label>
                <div class="input-value">
                  {{ connectorForm.get("bandwidth").value }}
                </div>
              </div>
              <div class="col-md-2">
                <label class="input-label">Link type</label>
                <div class="input-value">
                  {{ connectorForm.get("linkType").value }}
                </div>
              </div>

              <div class="col-md-2">
                <label class="input-label">Alias name</label>
                <div class="input-value">
                  {{ connectorForm.get("aliasName").value }}
                </div>
              </div>
            </div>
            <div class="title-readmode">VNF service details</div>
            <div class="row pb-4">
              <div class="col-md-2">
                <label class="input-label">Router preference</label>
                <div class="input-value">
                  {{ vnfForm.get("routerPreference").value }}
                </div>
              </div>

              <div class="col-md-2">
                <label class="input-label">Core preference</label>

                <div class="input-value">
                  {{ vnfForm.get("corePreference").value }}
                </div>
              </div>
              <div class="col-md-2">
                <label class="input-label">Memory</label>
                <div class="input-value">{{ vnfForm.get("memory").value }}</div>
              </div>

              <div class="col-md-2">
                <label class="input-label">Alias name</label>
                <div class="input-value">
                  {{ vnfForm.get("aliasName").value }}
                </div>
              </div>
            </div>
            <div class="title-readmode">Cloud connect details</div>
            <div class="row pb-4">
              <div class="col-md-2">
                <label class="input-label">Cloud provider</label>
                <div class="input-value">
                  {{ cloudConnectForm.get("cloudProvider").value }}
                </div>
                <!-- <div class="input-value">
                                    <label class="pl-3 pr-3 pt-1 cloudbtn mr-3"
                                        *ngIf="cloudConnectForm.get('cloudProvider').value == 'Amazon Web Services'">
                                        <input type="radio" name="test" value="Amazon Web Services" checked
                                            [disabled]="awsImage">
                                        <div>
                                            <i class="fa fa-solid fa-cloud"
                                                style="font-size: 35px; color: #f79919;"></i></div>
                                        <div class="text-center f-s-10">AWS</div>
                                    </label>
                                    <label class="pl-3 pr-3 pt-1 cloudbtn"
                                        *ngIf="cloudConnectForm.get('cloudProvider').value == 'Microsoft Azure'">
                                        <input type="radio" name="test" value="Microsoft Azure" [disabled]="azureImage">
                                        <div><i class="fa fa-solid fa-cloud"
                                                style="font-size: 35px; color: #00b6ea;"></i></div>
                                        <div class="text-center f-s-10">AZURE</div>
                                    </label>
                                </div> -->
              </div>

              <div class="col-md-2">
                <label class="input-label">Interconnect location</label>
                <div class="input-value">
                  {{ cloudConnectForm.get("location").value }}
                </div>
              </div>
              <div class="col-md-2">
                <label class="input-label">Bandwidth</label>
                <div class="input-value">
                  {{ cloudConnectForm.get("bandwidth").value }}
                </div>
              </div>
              <div class="col-md-2">
                <label class="input-label">Link type</label>
                <div class="input-value">
                  {{ cloudConnectForm.get("linkType").value }}
                </div>
              </div>
              <div class="col-md-2">
                <label class="input-label">Alias name</label>
                <div class="input-value">
                  {{ cloudConnectForm.get("aliasName").value }}
                </div>
              </div>
            </div>
            <div class="row pb-4" *ngIf="showSecondaryForm">
              <div class="col-md-2">
                <label class="input-label">Cloud provider</label>
                <div class="input-value">
                  {{ secondcloudConnectForm.get("cloudProvider").value }}
                </div>
                <!-- <div class="input-value">
                                    <label class="pl-3 pr-3 pt-1 cloudbtn mr-3"
                                        *ngIf="secondcloudConnectForm.get('cloudProvider').value == 'Amazon Web Services'">
                                        <input type="radio" name="test" value="Amazon Web Services" checked
                                            [disabled]="awsImage">
                                        <div>
                                            <i class="fa fa-solid fa-cloud"
                                                style="font-size: 35px; color: #f79919;"></i></div>
                                        <div class="text-center f-s-10">AWS</div>
                                    </label>
                                    <label class="pl-3 pr-3 pt-1 cloudbtn"
                                        *ngIf="secondcloudConnectForm.get('cloudProvider').value == 'Microsoft Azure'">
                                        <input type="radio" name="test" value="Microsoft Azure" [disabled]="azureImage">
                                        <div><i class="fa fa-solid fa-cloud"
                                                style="font-size: 35px; color: #00b6ea;"></i></div>
                                        <div class="text-center f-s-10">AZURE</div>
                                    </label>
                                </div> -->
              </div>

              <div class="col-md-2">
                <label class="input-label">Interconnect location</label>
                <div class="input-value">
                  {{ secondcloudConnectForm.get("location").value }}
                </div>
              </div>
              <div class="col-md-2">
                <label class="input-label">Bandwidth</label>
                <div class="input-value">
                  {{ secondcloudConnectForm.get("bandwidth").value }}
                </div>
              </div>
              <div class="col-md-2">
                <label class="input-label">Link type</label>
                <div class="input-value">
                  {{ secondcloudConnectForm.get("linkType").value }}
                </div>
              </div>
              <div class="col-md-2">
                <label class="input-label">Alias name</label>
                <div class="input-value">
                  {{ secondcloudConnectForm.get("aliasName").value }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Read mode ends -->
      <div class="card" style="margin-top: 25px" *ngIf="!hideSolutionDetails">
        <div id="custom-scroll" class="card-body">
          <div *ngIf="showStep1">
            <i
              class="fa fa-pencil alignpen"
              aria-hidden="true"
              (click)="editStep1()"
            ></i>
          </div>
          <div [formGroup]="solutionForm">
            <div class="row">
              <div class="col-md-3" tourAnchor="tour-1">
                Solution alias name
                <div class="" *ngIf="!showStep1">
                  <mat-form-field>
                    <input
                      matInput
                      type="text"
                      formControlName="solution_alias_name"
                      [disabled]="false"
                      (blur)="onSolnSumbit()"
                      required
                    />
                  </mat-form-field>
                </div>
              </div>

              <div class="col-md-3" tourAnchor="tour-2">
                Contract term
                <div class="select-style" *ngIf="!showStep1">
                  <mat-form-field>
                    <mat-select
                      #tour2
                      (selectionChange)="onSolnSumbit()"
                      formControlName="term_in_months"
                      required
                    >
                      <mat-option
                        *ngFor="let item of contractTermList"
                        [value]="item.attributeValue"
                      >
                        {{ item.displayValue }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="card" style="margin-top: 7px" *ngIf="!hideSolutionDetails">
        <div
          class="w-100 card-header card-higlight d-flex justify-content-between align-items-center flex-row"
        >
          <div class="title">
            Add location <span class="mandatory-symbol">*</span>
          </div>
          <div *ngIf="showStep2">
            <i
              class="fa fa-pencil alignpen"
              aria-hidden="true"
              (click)="editStep2()"
            ></i>
          </div>
        </div>
        <div id="custom-scroll" class="card-body">
          <div [formGroup]="locationForm">
            <div class="row">
              <div class="col-md-3" tourAnchor="tour-3">
                Region
                <div class="select-style" *ngIf="!showStep2">
                  <mat-form-field>
                    <mat-select
                      formControlName="region"
                      [disabled]="diableRegion"
                      (selectionChange)="onSelectionRegion(); onLocSumbit()"
                      required
                    >
                      <mat-option
                        *ngFor="let item of locationObj"
                        [value]="item.region"
                      >
                        {{ item.region }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>

              <div class="col-md-3" tourAnchor="tour-4">
                City
                <div class="select-style" *ngIf="!showStep2">
                  <mat-form-field>
                    <mat-select
                      formControlName="city"
                      [disabled]="disableLocation"
                      (selectionChange)="onSelectionLocation(); onLocSumbit()"
                      required
                    >
                      <mat-option
                        *ngFor="let item of locationObj"
                        [value]="item.city"
                      >
                        {{ item.city }}
                      </mat-option>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ng-container>
        <div class="card" style="margin-top: 7px" *ngIf="!hideSolutionDetails">
          <div
            class="w-100 card-header card-higlight d-flex justify-content-between align-items-center flex-row"
          >
            <div class="title">
              Add connector <span class="mandatory-symbol">*</span>
              <a
                class="pointer"
                matTooltip="Connect your virtual device (VNF) to Tata Communications’ s Internet"
              >
                <span class="icon icon-info fs-11"></span>
              </a>
            </div>
            <div *ngIf="hideConnector">
              <i
                class="fa fa-pencil alignpen"
                aria-hidden="true"
                (click)="editConnector(el, i)"
              ></i>
            </div>
          </div>
          <div id="custom-scroll" class="card-body">
            <div>
              <ng-container *ngFor="let el of connectorList; let i = index">
                <div class="row" [formGroup]="connectorForm">
                  <div class="col-md-3" tourAnchor="tour-5">
                    <label class="">Connection type</label>
                    <div class="select-style" *ngIf="!hideConnector">
                      <mat-form-field>
                        <mat-select
                          #tour5
                          (selectionChange)="connectorSave(el)"
                          formControlName="connType"
                          required
                        >
                          <div *ngFor="let opt of conntrConnTypes">
                            <mat-option [value]="opt.attributeValue">
                              {{ opt.attributeValue }}
                            </mat-option>
                          </div>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-3" tourAnchor="tour-6">
                    <label class="">Bandwidth</label>
                    <div class="select-style" *ngIf="!hideConnector">
                      <mat-form-field>
                        <mat-select
                          #tour6
                          (selectionChange)="connectorSave(el)"
                          formControlName="bandwidth"
                          required
                        >
                          <mat-option
                            [value]="opt"
                            *ngFor="let opt of bandwidthList"
                          >
                            {{ opt }}
                          </mat-option>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-3" tourAnchor="tour-7">
                    <label class="">Link type</label>
                    <div class="row" *ngIf="!hideConnector">
                      <label
                        class="radio-box small-font ml-4"
                        style="margin-top: 20px; padding-left: 20px"
                        *ngFor="
                          let link of conntrLinkTypes
                            | sort : 'desc' : 'attributeValue'
                        "
                      >
                        <div
                          style="color: grey"
                          *ngIf="link.attributeValue == 'Dual'"
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ link.attributeValue }}"
                            formControlName="linkType"
                          />

                          <span class="checkmark"></span>
                          {{ link.attributeValue }}
                        </div>

                        <div *ngIf="link.attributeValue != 'Dual'">
                          <input
                            type="radio"
                            name=""
                            value="{{ link.attributeValue }}"
                            formControlName="linkType"
                          />

                          <span class="checkmark"></span>
                          {{ link.attributeValue }}
                        </div>
                      </label>
                    </div>
                  </div>
                  <div class="col-md-3" tourAnchor="tour-8">
                    <label class="">Alias name</label>
                    <div class="" *ngIf="!hideConnector">
                      <mat-form-field>
                        <input
                          matInput
                          type="text"
                          [disabled]="false"
                          (blur)="connectorSave(el, i)"
                          formControlName="aliasName"
                          required
                        />
                      </mat-form-field>
                    </div>
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="card" style="margin-top: 7px" *ngIf="!hideSolutionDetails">
          <div
            class="w-100 card-header card-higlight d-flex justify-content-between align-items-center flex-row"
          >
            <div class="title">
              Add VNF service <span class="mandatory-symbol">*</span>
              <a
                class="pointer"
                matTooltip="VNF is a router per tenant that an enterprise can deploy as a virtual machine in a provider-hosted cloud with low-latency multi-cloud access for a variety of applications."
              >
                <span class="icon icon-info fs-11"></span>
              </a>
            </div>
            <div *ngIf="hideVNF">
              <i
                class="fa fa-pencil alignpen"
                aria-hidden="true"
                (click)="editVNF()"
              ></i>
            </div>
          </div>
          <div id="custom-scroll" class="card-body">
            <div [formGroup]="vnfForm">
              <ng-container>
                <div class="row">
                  <div class="col-md-3" tourAnchor="tour-9">
                    <div class="">Router preference</div>
                    <div class="select-style" *ngIf="!hideVNF">
                      <mat-form-field>
                        <mat-select
                          #tour9
                          (selectionChange)="VNFSave()"
                          formControlName="routerPreference"
                          required
                        >
                          <div
                            *ngFor="
                              let device of deviceAttr
                                | uniqueBy : 'deviceName';
                              let i = index
                            "
                          >
                            <mat-option
                              *ngIf="
                                device.deviceName ===
                                'Cisco Catalyst 8000v Router'
                              "
                              [value]="device.deviceName"
                            >
                              {{ device.deviceName }}
                            </mat-option>
                            <mat-option
                              *ngIf="
                                device.deviceName !=
                                'Cisco Catalyst 8000v Router'
                              "
                              [disabled]="true"
                              [value]="device.deviceName"
                            >
                              {{ device.deviceName }}
                            </mat-option>
                          </div>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-3" tourAnchor="tour-10">
                    <div class="">
                      Core preference
                      <span
                        class="icon-info-cust-cls icon-info fs-11"
                        customToolTip
                        [contentTemplate]="template"
                      ></span>
                      <ng-template #template>
                        <span>
                          VNF Provisioning Guideline:<br />
                          2vCPU support Upto X Gbps <br />
                          4vCPU support Upto Y Gbps <br />
                          6vCPU support Upto T Gbps<br />
                          8vCPU support Upto Z Gbps</span
                        >
                      </ng-template>
                    </div>
                    <div class="select-style" *ngIf="!hideVNF">
                      <mat-form-field>
                        <mat-select
                          #tour10
                          (selectionChange)="VNFSave()"
                          formControlName="corePreference"
                          required
                        >
                          <div
                            *ngFor="
                              let device of deviceAttr | uniqueBy : 'size';
                              let i = index
                            "
                          >
                            <mat-option [value]="device.size">
                              {{ device.size }}
                            </mat-option>
                          </div>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-3" tourAnchor="tour-11">
                    <div class="">Memory</div>
                    <div class="select-style" *ngIf="!hideVNF">
                      <mat-form-field>
                        <mat-select
                          #tour11
                          (selectionChange)="VNFSave()"
                          formControlName="memory"
                          required
                        >
                          <div *ngFor="let device of deviceAttr; let i = index">
                            <mat-option
                              [value]="device.memory"
                              *ngIf="
                                device.size ==
                                this.vnfForm.get('corePreference').value
                              "
                            >
                              {{ device.memory }}
                            </mat-option>
                          </div>
                        </mat-select>
                      </mat-form-field>
                    </div>
                  </div>
                  <div class="col-md-3" tourAnchor="tour-12">
                    <div class="">Alias name</div>
                    <div class="" *ngIf="!hideVNF">
                      <mat-form-field>
                        <input
                          matInput
                          type="text"
                          [disabled]="false"
                          (blur)="VNFSave()"
                          formControlName="aliasName"
                          required
                        />
                      </mat-form-field>
                    </div>
                  </div>

                  <!-- <div
                class="custom-options"
                style="margin: 10px 20px; margin-bottom: 40px"
              >
                <div class="row"> -->
                  <div class="col-md-3">
                    <label class="">Licensing</label>
                    <div class="row">
                      <label
                        class="radio-box small-font ml-4"
                        style="margin-top: 20px; padding-left: 20px"
                        *ngFor="
                          let link of [
                            'Tata Subscription',
                            'Bring Your Own License'
                          ]
                        "
                      >
                        <div
                          style="color: grey"
                          *ngIf="link == 'Tata Subscription'"
                        >
                          <input
                            type="radio"
                            name=""
                            checked
                            value="{{ link }}"
                          />

                          <span class="checkmark"></span>
                          {{ link }}
                        </div>

                        <div *ngIf="link != 'Tata Subscription'">
                          <input type="radio" name="" value="{{ link }}" />

                          <span class="checkmark"></span>
                          {{ link }}
                        </div>
                      </label>
                    </div>
                  </div>

                  <!-- <div class="col-md-3">
                    <label class="">Device Configuration</label>
                    <div class="row">
                      <label
                        class="radio-box small-font ml-4"
                        style="margin-top: 20px; padding-left: 20px"
                        *ngFor="
                          let link of ['Tata - Configured', 'Self Configured']
                        "
                      >
                        <div
                          style="color: grey"
                          *ngIf="link == 'Self Configured'"
                        >
                          <input type="radio" name="" value="{{ link }}" />

                          <span class="checkmark"></span>
                          {{ link }}
                        </div>

                        <div *ngIf="link != 'Self Configured'">
                          <input
                            type="radio"
                            name=""
                            checked
                            value="{{ link }}"
                          />

                          <span class="checkmark"></span>
                          {{ link }}
                        </div>
                      </label>
                    </div>
                  </div>

                  <div class="col-md-3">
                    <label class="">Scope of Management</label>
                    <div class="row">
                      <label
                        class="radio-box small-font ml-4"
                        style="margin-top: 20px; padding-left: 20px"
                        *ngFor="let link of ['Fully Managed', 'Unmanaged']"
                      >
                        <div
                          style="color: grey"
                          *ngIf="link == 'Fully Managed'"
                        >
                          <input
                            type="radio"
                            name=""
                            checked
                            value="{{ link }}"
                          />

                          <span class="checkmark"></span>
                          {{ link }}
                        </div>

                        <div *ngIf="link == 'Unmanaged'">
                          <input type="radio" name="" value="{{ link }}" />

                          <span class="checkmark"></span>
                          {{ link }}
                        </div>
                      </label>
                    </div>
                  </div> -->
                  <!-- </div>
              </div> -->
                </div>
              </ng-container>
            </div>
          </div>
        </div>

        <div class="card" style="margin-top: 7px" *ngIf="!hideSolutionDetails">
          <div
            class="w-100 card-header card-higlight d-flex justify-content-between align-items-center flex-row"
          >
            <div class="title">
              Add cloud connect <span class="mandatory-symbol">*</span>
              <a
                class="pointer"
                matTooltip="Public cloud service providers wherein customer can host their applications / DBs"
              >
                <span class="icon icon-info fs-11"></span>
              </a>
            </div>
            <div *ngIf="hideCloud">
              <i
                class="fa fa-pencil alignpen"
                aria-hidden="true"
                (click)="editCloud(el, i)"
              ></i>
            </div>
          </div>
          <div id="custom-scroll" class="card-body">
            <ng-container>
              <!-- primary cloud form -->
              <div class="row pl-3 pb-3" [formGroup]="cloudConnectForm">
                <div class="col-md-3" tourAnchor="tour-13">
                  <div class="">Cloud provider</div>
                  <div class="" *ngIf="!hideCloud">
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      [class.active]="
                        cloudConnectForm.get('cloudProvider').value ==
                        'Amazon Web Services'
                      "
                    >
                      <input
                        type="radio"
                        name="cloudProvider"
                        value="Amazon Web Services"
                        checked
                        (click)="selectCloud('Amazon Web Services')"
                        (selectionChange)="cloudSave(el, i)"
                        [disabled]="awsImage"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #f79919"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AWS</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      [class.active]="
                        cloudConnectForm.get('cloudProvider').value ==
                        'Microsoft Azure'
                      "
                    >
                      <input
                        type="radio"
                        name="cloudProvider"
                        value="Microsoft Azure"
                        (click)="selectCloud('Microsoft Azure')"
                        [disabled]="azureImage"
                        (selectionChange)="cloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #00b6ea"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AZURE</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      [class.active]="
                        cloudConnectForm.get('cloudProvider').value ==
                        'Google Cloud Provider'
                      "
                    >
                      <input
                        type="radio"
                        name="cloudProvider"
                        value="Google Cloud Provider"
                        (click)="selectCloud('Google Cloud Provider')"
                        (selectionChange)="cloudSave(el, i)"
                        [disabled]="awsImage"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #f4b400"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">GCP</div>
                    </label>

                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      [class.active]="
                        cloudConnectForm.get('cloudProvider').value == 'IBM'
                      "
                    >
                      <input
                        type="radio"
                        name="cloudProvider"
                        value="IBM"
                        (click)="selectCloud('IBM')"
                        [disabled]="azureImage"
                        (selectionChange)="cloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #1b88c1"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">IBM</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      [class.active]="
                        cloudConnectForm.get('cloudProvider').value == 'Oracle'
                      "
                    >
                      <input
                        type="radio"
                        name="cloudProvider"
                        value="Oracle"
                        (click)="selectCloud('Oracle')"
                        (selectionChange)="cloudSave(el, i)"
                        [disabled]="awsImage"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #ef262c"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">Oracle</div>
                    </label>
                  </div>
                  <div class="" *ngIf="hideCloud">
                    <label
                      class="pl-3 pr-3 pt-1 cloudbtn mr-3"
                      *ngIf="
                        cloudConnectForm.get('cloudProvider').value ==
                        'Amazon Web Services'
                      "
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Amazon Web Services"
                        checked
                        [disabled]="awsImage"
                        required
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #ef262c"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AWS</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="
                        cloudConnectForm.get('cloudProvider').value ==
                        'Microsoft Azure'
                      "
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Microsoft Azure"
                        [disabled]="azureImage"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #00b6ea"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AZURE</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="
                        cloudConnectForm.get('cloudProvider').value ==
                        'Google Cloud Provider'
                      "
                    >
                      <input
                        type="radio"
                        name="cloudProvider"
                        value="Google Cloud Provider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #f4b400"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">GCP</div>
                    </label>
                  </div>
                </div>
                <div class="col-md-2" tourAnchor="tour-14">
                  <div class="">Interconnect location</div>
                  <div class="select-style" *ngIf="!hideCloud">
                    <mat-form-field>
                      <mat-select
                        (selectionChange)="cloudSave(el)"
                        formControlName="location"
                        disabled
                      >
                        <mat-option
                          [value]="loc.attributeValue"
                          *ngFor="let loc of intrConntLocation"
                        >
                          {{ loc.attributeValue }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-2" tourAnchor="tour-15">
                  <div class="">Bandwidth</div>
                  <div class="select-style" *ngIf="!hideCloud">
                    <mat-form-field>
                      <mat-select
                        #tour15
                        (selectionChange)="cloudSave(el)"
                        formControlName="bandwidth"
                        required
                      >
                        <mat-option
                          [value]="opt"
                          *ngFor="let opt of bandwidthList"
                        >
                          {{ opt }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                </div>
                <div class="col-md-2 pl-5" tourAnchor="tour-16">
                  <div class="">Link type</div>
                  <div class="row pl-2" *ngIf="!hideCloud">
                    <label
                      class="radio-box small-font ml-4"
                      style="margin-top: 20px; padding-left: 20px"
                      *ngFor="
                        let link of cloudLinkTypes
                          | sort : 'desc' : 'attributeValue'
                      "
                    >
                      <div
                        style="color: grey"
                        *ngIf="
                          link.attributeValue == 'Single' &&
                          cloudConnectForm.get('cloudProvider').value ==
                            'Microsoft Azure'
                        "
                      >
                        <input
                          type="radio"
                          name=""
                          value="{{ link.attributeValue }}"
                          formControlName="linkType"
                          disabled
                          required
                        />
                        <span class="checkmark"></span>{{ link.attributeValue }}
                      </div>
                      <div
                        *ngIf="
                          link.attributeValue == 'Dual' &&
                          cloudConnectForm.get('cloudProvider').value ==
                            'Microsoft Azure'
                        "
                      >
                        <input
                          type="radio"
                          name=""
                          value="{{ link.attributeValue }}"
                          formControlName="linkType"
                          disabled
                          required
                        />
                        <span class="checkmark"></span>{{ link.attributeValue }}
                      </div>
                      <div
                        style="color: grey"
                        *ngIf="
                          link.attributeValue == 'Dual' &&
                          cloudConnectForm.get('cloudProvider').value ==
                            'Amazon Web Services'
                        "
                      >
                        <input
                          type="radio"
                          name=""
                          value="{{ link.attributeValue }}"
                          formControlName="linkType"
                          disabled
                          required
                        />
                        <span class="checkmark"></span>{{ link.attributeValue }}
                      </div>
                      <div
                        *ngIf="
                          link.attributeValue == 'Single' &&
                          cloudConnectForm.get('cloudProvider').value ==
                            'Amazon Web Services'
                        "
                      >
                        <input
                          type="radio"
                          name=""
                          value="{{ link.attributeValue }}"
                          formControlName="linkType"
                          disabled
                          required
                        />
                        <span class="checkmark"></span>{{ link.attributeValue }}
                      </div>

                      <div
                        style="color: grey"
                        *ngIf="
                          link.attributeValue == 'Dual' &&
                          cloudConnectForm.get('cloudProvider').value ==
                            'Google Cloud Provider'
                        "
                      >
                        <input
                          type="radio"
                          name=""
                          value="{{ link.attributeValue }}"
                          formControlName="linkType"
                          disabled
                          required
                        />
                        <span class="checkmark"></span>{{ link.attributeValue }}
                      </div>
                      <div
                        *ngIf="
                          link.attributeValue == 'Single' &&
                          cloudConnectForm.get('cloudProvider').value ==
                            'Google Cloud Provider'
                        "
                      >
                        <input
                          type="radio"
                          name=""
                          value="{{ link.attributeValue }}"
                          formControlName="linkType"
                          disabled
                          required
                        />
                        <span class="checkmark"></span>{{ link.attributeValue }}
                      </div>
                    </label>
                  </div>
                </div>
                <div class="col-md-2" tourAnchor="tour-17">
                  <div class="">Alias name</div>
                  <div class="" *ngIf="!hideCloud">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        [disabled]="false"
                        (blur)="cloudSave(el, i)"
                        formControlName="aliasName"
                        required
                      />
                    </mat-form-field>
                  </div>
                </div>
              </div>

              <!-- second cloud form -->
              <div
                class="row pl-3 pb-3"
                [formGroup]="secondcloudConnectForm"
                *ngIf="showSecondaryForm"
              >
                <div class="col-md-3" tourAnchor="tour-19">
                  <div class="">Cloud provider</div>
                  <div class="">
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="isdisableCP2 != 'Amazon Web Services'"
                      [class.active]="
                        secondcloudConnectForm.get('cloudProvider').value ==
                        'Amazon Web Services'
                      "
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Amazon Web Services"
                        checked
                        [disabled]="true"
                        (click)="secondselectCloud('Amazon Web Services')"
                        (selectionChange)="secdcloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #f79919"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AWS</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      style="color: grey"
                      *ngIf="isdisableCP2 == 'Amazon Web Services'"
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Amazon Web Services"
                        disabled
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #f79919"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AWS</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="isdisableCP2 != 'Microsoft Azure'"
                      [class.active]="
                        secondcloudConnectForm.get('cloudProvider').value ==
                        'Microsoft Azure'
                      "
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Microsoft Azure"
                        (click)="secondselectCloud('Microsoft Azure')"
                        (selectionChange)="secdcloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #00b6ea"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AZURE</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      style="color: grey"
                      *ngIf="isdisableCP2 == 'Microsoft Azure'"
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Microsoft Azure"
                        disabled
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #00b6ea"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AZURE</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="isdisableCP2 != 'Google Cloud Provider'"
                      [ngClass]="{
                        active:
                          secondcloudConnectForm.get('cloudProvider').value ===
                          'Google Cloud Provider'
                      }"
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Google Cloud Provider"
                        (click)="secondselectCloud('Google Cloud Provider')"
                        (selectionChange)="secdcloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #f4b400"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">
                        GCP
                        {{
                          secondcloudConnectForm.get("cloudProvider").value ==
                            "Google Cloud Provider"
                        }}
                      </div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      style="color: grey"
                      id="gcpactive"
                      *ngIf="isdisableCP2 == 'Google Cloud Provider'"
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Google Cloud Provider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #f4b400"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">GCP</div>
                    </label>

                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="isdisableCP2 != 'IBM'"
                      [ngClass]="{
                        active:
                          secondcloudConnectForm.get('cloudProvider').value ===
                          'IBM'
                      }"
                    >
                      <input
                        type="radio"
                        name="test"
                        value="IBM"
                        (click)="secondselectCloud('IBM')"
                        (selectionChange)="secdcloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #1b88c1"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">IBM</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      style="color: grey"
                      id="gcpactive"
                      *ngIf="isdisableCP2 == 'IBM'"
                    >
                      <input type="radio" name="test" value="IBM" />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #1b88c1"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">IBM</div>
                    </label>

                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="isdisableCP2 != 'Oracle'"
                      [ngClass]="{
                        active:
                          secondcloudConnectForm.get('cloudProvider').value ===
                          'Oracle'
                      }"
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Oracle"
                        (click)="secondselectCloud('Oracle')"
                        (selectionChange)="secdcloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #ef262c"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">Oracle</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      style="color: grey"
                      id="gcpactive"
                      *ngIf="isdisableCP2 == 'Oracle'"
                    >
                      <input type="radio" name="test" value="Oracle" />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #ef262c"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">Oracle</div>
                    </label>
                  </div>
                </div>
                <div class="col-md-2" tourAnchor="tour-20">
                  <div class="">Interconnect location</div>
                  <div class="select-style" *ngIf="!secdhideCloud">
                    <mat-form-field>
                      <mat-select
                        (selectionChange)="secdcloudSave(el)"
                        formControlName="location"
                        disabled
                      >
                        <mat-option
                          [value]="loc.attributeValue"
                          *ngFor="let loc of intrConntLocation"
                        >
                          {{ loc.attributeValue }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="secdhideCloud">
                    {{ secondcloudConnectForm.get("location").value }}
                  </div>
                </div>
                <div class="col-md-2" tourAnchor="tour-21">
                  <div class="">Bandwidth</div>
                  <div class="select-style" *ngIf="!secdhideCloud">
                    <mat-form-field>
                      <mat-select
                        #tour21
                        (selectionChange)="secdcloudSave(el)"
                        formControlName="bandwidth"
                        required
                      >
                        <mat-option
                          [value]="opt"
                          *ngFor="let opt of bandwidthList"
                        >
                          {{ opt }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="secdhideCloud">
                    {{ secondcloudConnectForm.get("bandwidth").value }}
                  </div>
                </div>
                <div class="col-md-2 pl-5" tourAnchor="tour-22">
                  <div class="">Link type</div>
                  <div class="row pl-2" *ngIf="!secdhideCloud">
                    <div
                      *ngFor="
                        let opt of cloudLinkTypes
                          | sort : 'desc' : 'attributeValue'
                      "
                    >
                      <label
                        class="radio-box small-font ml-4"
                        style="margin-top: 20px; padding-left: 20px"
                      >
                        <div
                          style="color: grey"
                          *ngIf="
                            opt.attributeValue == 'Single' &&
                            secondcloudConnectForm.get('cloudProvider').value ==
                              'Microsoft Azure'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                        <div
                          *ngIf="
                            opt.attributeValue == 'Dual' &&
                            secondcloudConnectForm.get('cloudProvider').value ==
                              'Microsoft Azure'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                        <div
                          style="color: grey"
                          *ngIf="
                            opt.attributeValue == 'Dual' &&
                            secondcloudConnectForm.get('cloudProvider').value ==
                              'Amazon Web Services'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                        <div
                          *ngIf="
                            opt.attributeValue == 'Single' &&
                            secondcloudConnectForm.get('cloudProvider').value ==
                              'Amazon Web Services'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                        <div
                          style="color: grey"
                          *ngIf="
                            opt.attributeValue == 'Dual' &&
                            secondcloudConnectForm.get('cloudProvider').value ==
                              'Google Cloud Provider'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                            required
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                        <div
                          *ngIf="
                            opt.attributeValue == 'Single' &&
                            secondcloudConnectForm.get('cloudProvider').value ==
                              'Google Cloud Provider'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                            required
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="secdhideCloud">
                    {{ secondcloudConnectForm.get("linkType").value }}
                  </div>
                </div>
                <div class="col-md-2" tourAnchor="tour-23">
                  <div class="">Alias name</div>
                  <div class="" *ngIf="!secdhideCloud">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        [disabled]="false"
                        (blur)="secdcloudSave(el, i)"
                        formControlName="aliasName"
                        required
                      />
                    </mat-form-field>
                  </div>
                  <div *ngIf="secdhideCloud">
                    {{ secondcloudConnectForm.get("aliasName").value }}
                  </div>
                </div>
                <div
                  class="col-md pt-4 text-center f-s-19"
                  *ngIf="!secdhideCloud"
                >
                  <i class="fa fa-trash fa-md" (click)="removeCloud()"></i>
                </div>
              </div>

              <!-- third cloud form -->
              <div
                class="row pl-3 pb-3"
                [formGroup]="thirdcloudConnectForm"
                *ngIf="showThirdForm"
              >
                <div class="col-md-3" tourAnchor="tour-24">
                  <div class="">Cloud provider</div>
                  <div class="">
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="
                        isdisableCP2 != 'Amazon Web Services' &&
                        isdisableCP3 != 'Amazon Web Services'
                      "
                      [class.active]="
                        thirdcloudConnectForm.get('cloudProvider').value ==
                        'Amazon Web Services'
                      "
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Amazon Web Services"
                        checked
                        (selectionChange)="cloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #f79919"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AWS</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      style="color: grey"
                      id="awsactive"
                      *ngIf="
                        isdisableCP2 == 'Amazon Web Services' ||
                        isdisableCP3 == 'Amazon Web Services'
                      "
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Amazon Web Services"
                        disabled
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #f79919"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AWS</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="
                        isdisableCP2 != 'Microsoft Azure' &&
                        isdisableCP3 != 'Microsoft Azure'
                      "
                      [class.active]="
                        thirdcloudConnectForm.get('cloudProvider').value ==
                        'Microsoft Azure'
                      "
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Microsoft Azure"
                        (selectionChange)="thirdcloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #00b6ea"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AZURE</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      style="color: grey"
                      *ngIf="
                        isdisableCP2 == 'Microsoft Azure' ||
                        isdisableCP3 == 'Microsoft Azure'
                      "
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Microsoft Azure"
                        disabled
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #00b6ea"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">AZURE</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="
                        isdisableCP2 != 'Google Cloud Provider' &&
                        isdisableCP3 != 'Google Cloud Provider'
                      "
                      [class.active]="
                        thirdcloudConnectForm.get('cloudProvider').value ==
                        'Google Cloud Provider'
                      "
                    >
                      <input
                        type="radio"
                        name="cloudProvider"
                        value="Google Cloud Provider"
                        (selectionChange)="thirdcloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #f4b400"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">GCP</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      style="color: grey"
                      *ngIf="
                        isdisableCP2 == 'Google Cloud Provider' ||
                        isdisableCP3 == 'Google Cloud Provider'
                      "
                    >
                      <input
                        type="radio"
                        name="test"
                        value="Google Cloud Provider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #f4b400"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">GCP</div>
                    </label>

                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="isdisableCP2 != 'IBM' && isdisableCP3 != 'IBM'"
                      [class.active]="
                        thirdcloudConnectForm.get('cloudProvider').value ==
                        'IBM'
                      "
                    >
                      <input
                        type="radio"
                        name="cloudProvider"
                        value="IBM"
                        (selectionChange)="thirdcloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #1b88c1"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">IBM Cloud</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      style="color: grey"
                      *ngIf="isdisableCP2 == 'IBM' || isdisableCP3 == 'IBM'"
                    >
                      <input type="radio" name="test" value="IBM" />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #1b88c1"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">IBM Cloud</div>
                    </label>

                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      *ngIf="
                        isdisableCP2 != 'Oracle' && isdisableCP3 != 'Oracle'
                      "
                      [class.active]="
                        thirdcloudConnectForm.get('cloudProvider').value ==
                        'Oracle'
                      "
                    >
                      <input
                        type="radio"
                        name="cloudProvider"
                        value="Oracle"
                        (selectionChange)="thirdcloudSave(el, i)"
                        formControlName="cloudProvider"
                      />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #ef262c"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">Oracle</div>
                    </label>
                    <label
                      class="pl-3 pr-3 pt-1 mr-3 cloudbtn"
                      style="color: grey"
                      *ngIf="
                        isdisableCP2 == 'Oracle' || isdisableCP3 == 'Oracle'
                      "
                    >
                      <input type="radio" name="test" value="Oracle" />
                      <div>
                        <i
                          class="fa fa-solid fa-cloud"
                          style="font-size: 35px; color: #ef262c"
                        ></i>
                      </div>
                      <div class="text-center f-s-12">Oracle</div>
                    </label>
                  </div>
                </div>
                <div class="col-md-2" tourAnchor="tour-25">
                  <div class="">Interconnect location</div>
                  <div class="select-style" *ngIf="!secdhideCloud">
                    <mat-form-field>
                      <mat-select
                        (selectionChange)="thirdcloudSave(el)"
                        formControlName="location"
                        disabled
                      >
                        <mat-option
                          [value]="loc.attributeValue"
                          *ngFor="let loc of intrConntLocation"
                        >
                          {{ loc.attributeValue }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="secdhideCloud">
                    {{ thirdcloudConnectForm.get("location").value }}
                  </div>
                </div>
                <div class="col-md-2" tourAnchor="tour-26">
                  <div class="">Bandwidth</div>
                  <div class="select-style" *ngIf="!secdhideCloud">
                    <mat-form-field>
                      <mat-select
                        #tour26
                        (selectionChange)="thirdcloudSave(el)"
                        formControlName="bandwidth"
                        required
                      >
                        <mat-option
                          [value]="opt"
                          *ngFor="let opt of bandwidthList"
                        >
                          {{ opt }}
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>
                  <div *ngIf="secdhideCloud">
                    {{ thirdcloudConnectForm.get("bandwidth").value }}
                  </div>
                </div>
                <div class="col-md-2 pl-5" tourAnchor="tour-27">
                  <div class="">Link type</div>
                  <div class="row pl-2" *ngIf="!secdhideCloud">
                    <div
                      *ngFor="
                        let opt of cloudLinkTypes
                          | sort : 'desc' : 'attributeValue'
                      "
                    >
                      <label
                        class="radio-box small-font ml-4"
                        style="margin-top: 20px; padding-left: 20px"
                      >
                        <div
                          style="color: grey"
                          *ngIf="
                            opt.attributeValue == 'Single' &&
                            thirdcloudConnectForm.get('cloudProvider').value ==
                              'Microsoft Azure'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                        <div
                          *ngIf="
                            opt.attributeValue == 'Dual' &&
                            thirdcloudConnectForm.get('cloudProvider').value ==
                              'Microsoft Azure'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                        <div
                          style="color: grey"
                          *ngIf="
                            opt.attributeValue == 'Dual' &&
                            thirdcloudConnectForm.get('cloudProvider').value ==
                              'Amazon Web Services'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                        <div
                          *ngIf="
                            opt.attributeValue == 'Single' &&
                            thirdcloudConnectForm.get('cloudProvider').value ==
                              'Amazon Web Services'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                        <div
                          style="color: grey"
                          *ngIf="
                            opt.attributeValue == 'Dual' &&
                            thirdcloudConnectForm.get('cloudProvider').value ==
                              'Google Cloud Provider'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                            required
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                        <div
                          *ngIf="
                            opt.attributeValue == 'Single' &&
                            thirdcloudConnectForm.get('cloudProvider').value ==
                              'Google Cloud Provider'
                          "
                        >
                          <input
                            type="radio"
                            name=""
                            value="{{ opt.attributeValue }}"
                            formControlName="linkType"
                            disabled
                            required
                          />
                          <span class="checkmark"></span
                          >{{ opt.attributeValue }}
                        </div>
                      </label>
                    </div>
                  </div>
                  <div *ngIf="secdhideCloud">
                    {{ thirdcloudConnectForm.get("linkType").value }}
                  </div>
                </div>
                <div class="col-md-2" tourAnchor="tour-28">
                  <div class="">Alias name</div>
                  <div class="" *ngIf="!secdhideCloud">
                    <mat-form-field>
                      <input
                        matInput
                        type="text"
                        [disabled]="false"
                        (blur)="thirdcloudSave(el, i)"
                        formControlName="aliasName"
                        required
                      />
                    </mat-form-field>
                  </div>
                  <div *ngIf="secdhideCloud">
                    {{ thirdcloudConnectForm.get("aliasName").value }}
                  </div>
                </div>
                <div
                  class="col-md pt-4 text-center f-s-19"
                  *ngIf="!secdhideCloud"
                >
                  <i class="fa fa-trash fa-md" (click)="removeCloud2()"></i>
                </div>
              </div>

              <div class="col-md-12" *ngIf="!hideCloud">
                <button
                  (click)="addCloud(el, i)"
                  tourAnchor="tour-18"
                  id="addCloud"
                  type="button"
                  class="btn add-btn"
                  [disabled]="isaddBtndisable"
                >
                  + Cloud
                </button>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="card" style="margin-top: 10px" *ngIf="emptyaccordian">
          <div
            class="w-100 card-header card-higlight d-flex justify-content-between align-items-center flex-row"
          >
            <div class="title">
              Configuration details <span class="mandatory-symbol">*</span
              ><br />
              <span class="pointer dclmrMsg">
                <span
                  class="icon icon-info fs-11"
                  style="margin: 0px !important"
                ></span>
                For successful configuration, Customer to fill up attribute
                values accurately. Any support required, Please reach out to
                mcc.taskforce&#64;tatacommunications.com
              </span>
            </div>
            <div>
              <i
                class="fa fa-pencil alignpen"
                aria-hidden="true"
                (click)="editConfigDetails()"
              ></i>
            </div>
          </div>
          <div id="custom-scroll" class="card-body">
            <div>
              <div class="pb-4 service-title">VNF service details</div>
              <mat-accordion *ngIf="!hideConfigDetails">
                <mat-expansion-panel
                  [expanded]="true"
                  (opened)="openPanel(item, i)"
                  (closed)="closePanel(item, i)"
                  style="margin-bottom: 10px"
                >
                  <mat-expansion-panel-header
                    [collapsedHeight]="'48px'"
                    [expandedHeight]="'48px'"
                  >
                    <div class="row" style="width: 100%">
                      <div class="col-12">
                        <mat-panel-title class="accHeaders">
                          <div>
                            <span>
                              <i
                                class="fa fa-microchip pr-3"
                                style="font-size: 20px; color: #617c8f"
                              ></i>
                            </span>

                            <span>Cisco</span>
                          </div>
                        </mat-panel-title>
                      </div>
                      <!-- <div class="col-6">
                                                <mat-panel-title class="accHeaders">

                                                </mat-panel-title>
                                            </div> -->
                    </div>
                  </mat-expansion-panel-header>
                  <div
                    class="row w-100 accordin-sec"
                    *ngIf="!panelflag"
                    id="custom-scroll"
                    [formGroup]="vnfServiceForm"
                    style="height: auto"
                  >
                    <div class="col-md-4" tourAnchor="tour-29">
                      <div class="input-label">
                        AS number (local)
                        <a
                          class="pointer"
                          matTooltip="Local Refers to Customer’s Device (VNF)"
                        >
                          <span class="icon icon-info fs-11"></span>
                        </a>
                      </div>
                      <div class="row input-value" *ngIf="!savedData">
                        <div class="pl-3" *ngFor="let obj of vnfServiceConfig">
                          <mat-form-field>
                            <input
                              matInput
                              autocomplete="off"
                              [maxlength]="10"
                              formControlName="{{ obj.formcontrol }}"
                              required
                            />
                          </mat-form-field>
                          <p
                            class="error"
                            *ngIf="
                              !vnfServiceForm.valid &&
                              vnfServiceForm.get('asNumber').value != ''
                            "
                          >
                            Provide valid {{ obj.displayName }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </mat-expansion-panel>
              </mat-accordion>

              <div class="" *ngIf="hideConfigDetails">
                <div class="mb-4 border-bottom pb-2">
                  <span
                    ><i
                      class="fa fa-microchip pr-3"
                      style="font-size: 20px; color: #617c8f"
                    ></i
                  ></span>
                  <span>Cisco</span>
                </div>
                <div class="row">
                  <div
                    class="col-md-4 pb-4 pl-3"
                    *ngFor="let data of vnfServiceConfig"
                  >
                    <div class="input-label">AS number (local)</div>
                    <div class="input-value">
                      {{ vnfServiceForm.get("asNumber").value }}
                    </div>
                  </div>
                </div>
              </div>

              <div class="service-title pb-4 pt-4">Cloud service details</div>
              <div>
                <!-- {{cloudConfiguration | json }} -->
                <mat-accordion
                  *ngFor="let item of cloudConfiguration; let i = index"
                >
                  <mat-expansion-panel
                    [expanded]="true"
                    (opened)="openPanel(item, i)"
                    (closed)="closePanel(item, i)"
                    style="margin-bottom: 10px"
                  >
                    <mat-expansion-panel-header
                      [collapsedHeight]="'48px'"
                      [expandedHeight]="'48px'"
                    >
                      <div class="row" style="width: 100%">
                        <div class="col-12">
                          <mat-panel-title class="accHeaders">
                            <div>
                              <span
                                *ngIf="
                                  item.cloudConfgName == 'Amazon Web Services'
                                "
                              >
                                <i
                                  class="fa fa-solid fa-cloud pr-3"
                                  style="font-size: 20px; color: #f79919"
                                ></i>
                              </span>

                              <span
                                *ngIf="
                                  item.cloudConfgName ==
                                    'Microsoft Azure Primary' ||
                                  item.cloudConfgName ==
                                    'Microsoft Azure Secondary'
                                "
                              >
                                <i
                                  class="fa fa-solid fa-cloud pr-3"
                                  style="font-size: 20px; color: #00b6ea"
                                ></i
                              ></span>

                              <span>{{ item.cloudConfgName }}</span>

                              <a
                                class="pointer"
                                *ngIf="
                                  item.cloudConfgName ==
                                  'Microsoft Azure Primary'
                                "
                              >
                                <span
                                  class="icon icon-info fs-11"
                                  matTooltipClass="custom-tooltip"
                                  matTooltip="Please enter BGP peering information, VLAN and service key"
                                ></span>
                              </a>
                              <a
                                class="pointer"
                                *ngIf="
                                  item.cloudConfgName ==
                                  'Microsoft Azure Secondary'
                                "
                                matTooltipClass="custom-tooltip"
                                matTooltip="Please enter only IP address for interface and neighbour IP"
                              >
                                <span class="icon icon-info fs-11"></span>
                              </a>
                              <a
                                class="pointer"
                                *ngIf="
                                  item.cloudConfgName == 'Amazon Web Services'
                                "
                                matTooltipClass="custom-tooltip"
                                matTooltip="Please enter BGP peering information and account id"
                              >
                                <span class="icon icon-info fs-11"></span>
                              </a>
                            </div>
                          </mat-panel-title>
                        </div>
                        <!-- <div class="col-6">
                                                    <mat-panel-title class="accHeaders">

                                                    </mat-panel-title>
                                                </div> -->
                      </div>
                    </mat-expansion-panel-header>
                    <div
                      class="row w-100 accordin-sec"
                      *ngIf="!panelflag"
                      id="custom-scroll"
                    >
                      <div
                        class="col-md-3 input-label"
                        *ngFor="let obj of item.attributes; let j = index"
                      >
                        {{ obj.displayName }}
                        <a
                          class="pointer"
                          *ngIf="
                            obj.type == 'input' &&
                            obj.displayName == 'IP address for Interface'
                          "
                          matTooltip="Local IP address"
                        >
                          <span class="icon icon-info fs-11"></span>
                        </a>
                        <a
                          class="pointer"
                          *ngIf="
                            obj.type == 'input' &&
                            obj.displayName == 'Neighbour IP' &&
                            item.cloudConfgName == 'Amazon Web Services'
                          "
                          matTooltip="AWS IP address "
                        >
                          <span class="icon icon-info fs-11"></span>
                        </a>
                        <a
                          class="pointer"
                          *ngIf="
                            obj.type == 'input' &&
                            obj.displayName == 'Neighbour IP' &&
                            item.cloudConfgName != 'Amazon Web Services'
                          "
                          matTooltip="Azure IP address"
                        >
                          <span class="icon icon-info fs-11"></span>
                        </a>
                        <a
                          class="pointer"
                          *ngIf="
                            obj.type == 'input' &&
                            obj.displayName == 'AS Number(remote)' &&
                            item.cloudConfgName == 'Amazon Web Services'
                          "
                          matTooltip="AWS ASN"
                        >
                          <span class="icon icon-info fs-11"></span>
                        </a>
                        <a
                          class="pointer"
                          *ngIf="
                            obj.type == 'input' &&
                            obj.displayName == 'AS Number(remote)' &&
                            item.cloudConfgName != 'Amazon Web Services'
                          "
                          matTooltip="Azure ASN"
                        >
                          <span class="icon icon-info fs-11"></span>
                        </a>
                        <a
                          class="pointer"
                          *ngIf="
                            obj.type == 'input' && obj.displayName == 'BGP Key'
                          "
                          matTooltip="BGP authentication key"
                        >
                          <span class="icon icon-info fs-11"></span>
                        </a>
                        <div class="row input-value" *ngIf="!savedData">
                          <div
                            *ngIf="
                              obj.type == 'input' &&
                              obj.displayName != 'AS Number(remote)' &&
                              obj.displayName != 'AS Number(local)' &&
                              obj.displayName != 'Neighbour IP' &&
                              obj.displayName != 'IP address for Interface' &&
                              obj.displayName != 'BGP Key' &&
                              obj.displayName != 'Subnet' &&
                              obj.displayName != 'Account ID' &&
                              obj.displayName != 'VLAN' &&
                              obj.displayName != 'Service Key'
                            "
                          >
                            <div class="col-md-6">
                              <mat-form-field
                                tourAnchor="{{
                                  i === 0
                                    ? 'tour-' + (30 + j)
                                    : i === 1
                                    ? 'tour-' + (38 + j)
                                    : i === 3
                                    ? 'tour-' + (47 + j)
                                    : 'tour-' + (46 + j)
                                }}"
                              >
                                <input
                                  matInput
                                  type="text"
                                  autocomplete="off"
                                  (blur)="populateAzureSecondaryconfig()"
                                  (keyup)="validateAddiConfig()"
                                  [(ngModel)]="obj.selectedValue"
                                  required
                                />
                              </mat-form-field>
                            </div>
                            <p
                              class="error pl-3"
                              *ngIf="
                                obj.showRequiredError && !obj.selectedValue
                              "
                            >
                              Provide required values
                            </p>
                            <p class="error pl-3" *ngIf="obj.isNameValidation">
                              Provide valid {{ obj.displayName }}
                            </p>
                          </div>

                          <div
                            *ngIf="
                              obj.type == 'input' &&
                              (obj.displayName == 'Neighbour IP' ||
                                obj.displayName == 'IP address for Interface')
                            "
                          >
                            <div class="col-md-6">
                              <mat-form-field
                                tourAnchor="{{
                                  i === 0
                                    ? 'tour-' + (30 + j)
                                    : i === 1
                                    ? 'tour-' + (38 + j)
                                    : i === 3
                                    ? 'tour-' + (47 + j)
                                    : 'tour-' + (46 + j)
                                }}"
                              >
                                <input
                                  matInput
                                  type="text"
                                  autocomplete="off"
                                  (blur)="populateAzureSecondaryconfig()"
                                  (keyup)="validateAddiConfig()"
                                  [(ngModel)]="obj.selectedValue"
                                  required
                                  autocomplete="off"
                                  name="ipaddress"
                                  pattern="(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)"
                                  #ipaddress="ngModel"
                                />
                              </mat-form-field>
                            </div>
                            <p
                              class="error"
                              *ngIf="obj.isNameValidation && obj.selectedValue"
                              class="error pl-3 f-s-10"
                            >
                              Provide valid {{ obj.displayName }}
                            </p>
                          </div>

                          <div
                            *ngIf="
                              obj.type == 'input' &&
                              obj.displayName == 'BGP Key'
                            "
                          >
                            <div class="col-md-6">
                              <mat-form-field
                                tourAnchor="{{
                                  i === 0
                                    ? 'tour-' + (30 + j)
                                    : i === 1
                                    ? 'tour-' + (38 + j)
                                    : i === 3
                                    ? 'tour-' + (47 + j)
                                    : 'tour-' + (46 + j)
                                }}"
                              >
                                <input
                                  matInput
                                  type="text"
                                  autocomplete="off"
                                  (blur)="
                                    populateAzureSecondaryconfig();
                                    validateAddiConfig()
                                  "
                                  [(ngModel)]="obj.selectedValue"
                                  required
                                  autocomplete="off"
                                  name="bgpkey"
                                  pattern="(^[A-Za-z0-9]*$)"
                                  #bgpkey="ngModel"
                                />
                              </mat-form-field>
                            </div>
                            <p
                              class="error pl-3"
                              *ngIf="obj.isNameValidation"
                              class="error f-s-10"
                            >
                              Provide valid {{ obj.displayName }}
                            </p>
                          </div>

                          <div
                            *ngIf="
                              obj.type == 'input' && obj.displayName == 'Subnet'
                            "
                          >
                            <div class="col-md-6">
                              <mat-form-field
                                tourAnchor="{{
                                  i === 0
                                    ? 'tour-' + (30 + j)
                                    : i === 1
                                    ? 'tour-' + (38 + j)
                                    : i === 3
                                    ? 'tour-' + (47 + j)
                                    : 'tour-' + (46 + j)
                                }}"
                              >
                                <input
                                  matInput
                                  type="text"
                                  autocomplete="off"
                                  (blur)="populateAzureSecondaryconfig()"
                                  (keyup)="validateAddiConfig()"
                                  [(ngModel)]="obj.selectedValue"
                                  required
                                  autocomplete="off"
                                  name="Subnet"
                                  pattern="(\/\d{1,3})"
                                  #Subnet="ngModel"
                                />
                              </mat-form-field>
                            </div>
                            <p
                              class="error pl-3 f-s-10"
                              *ngIf="obj.isNameValidation"
                            >
                              Provide valid {{ obj.displayName }}
                            </p>
                          </div>

                          <div
                            *ngIf="
                              obj.type == 'input' &&
                              obj.displayName == 'Account ID'
                            "
                          >
                            <div class="col-md-6">
                              <mat-form-field
                                tourAnchor="{{
                                  i === 0
                                    ? 'tour-' + (30 + j)
                                    : i === 1
                                    ? 'tour-' + (38 + j)
                                    : i === 3
                                    ? 'tour-' + (47 + j)
                                    : 'tour-' + (46 + j)
                                }}"
                              >
                                <input
                                  matInput
                                  type="text"
                                  autocomplete="off"
                                  (keyup)="validateAddiConfig()"
                                  [(ngModel)]="obj.selectedValue"
                                  required
                                  autocomplete="off"
                                  name="accountId"
                                  [maxlength]="12"
                                  #accountId="ngModel"
                                />
                              </mat-form-field>
                            </div>
                            <p
                              class="error f-s-10 pl-3"
                              *ngIf="obj.isNameValidation"
                            >
                              Provide valid {{ obj.displayName }}
                            </p>
                          </div>

                          <div
                            *ngIf="
                              obj.type == 'input' && obj.displayName == 'VLAN'
                            "
                          >
                            <div class="col-md-6">
                              <mat-form-field
                                tourAnchor="{{
                                  i === 0
                                    ? 'tour-' + (30 + j)
                                    : i === 1
                                    ? 'tour-' + (38 + j)
                                    : i === 3
                                    ? 'tour-' + (47 + j)
                                    : 'tour-' + (46 + j)
                                }}"
                              >
                                <input
                                  matInput
                                  type="text"
                                  autocomplete="off"
                                  (blur)="populateAzureSecondaryconfig()"
                                  (keyup)="validateAddiConfig()"
                                  [(ngModel)]="obj.selectedValue"
                                  required
                                  autocomplete="off"
                                  name="vlan"
                                  pattern="(^(409[0-6]|(40[0-8]|[1-3]\d\d|[1-9]\d|[1-9])\d|[1-9])$)"
                                  #vlan="ngModel"
                                />
                              </mat-form-field>
                            </div>
                            <p
                              class="error pl-3 f-s-10"
                              *ngIf="obj.isNameValidation"
                            >
                              Provide valid {{ obj.displayName }}
                            </p>
                          </div>

                          <div
                            *ngIf="
                              obj.type == 'input' &&
                              obj.displayName == 'Service Key'
                            "
                          >
                            <div class="col-md-6">
                              <mat-form-field
                                tourAnchor="{{
                                  i === 0
                                    ? 'tour-' + (30 + j)
                                    : i === 1
                                    ? 'tour-' + (38 + j)
                                    : i === 3
                                    ? 'tour-' + (47 + j)
                                    : 'tour-' + (46 + j)
                                }}"
                              >
                                <input
                                  matInput
                                  type="text"
                                  autocomplete="off"
                                  (blur)="populateAzureSecondaryconfig()"
                                  [(ngModel)]="obj.selectedValue"
                                  required
                                  autocomplete="off"
                                  name="serviceKey"
                                  pattern="(^[A-Za-z0-9-]*$)"
                                  #serviceKey="ngModel"
                                />
                              </mat-form-field>
                            </div>
                            <p
                              class="error pl-3"
                              *ngIf="obj.isNameValidation"
                              class="error f-s-10"
                            >
                              Provide valid {{ obj.displayName }}
                            </p>
                          </div>
                          <div
                            *ngIf="
                              obj.type == 'input' &&
                              obj.displayName == 'AS Number(remote)'
                            "
                          >
                            <div class="col-md-6">
                              <mat-form-field
                                tourAnchor="{{
                                  i === 0
                                    ? 'tour-' + (30 + j)
                                    : i === 1
                                    ? 'tour-' + (38 + j)
                                    : i === 3
                                    ? 'tour-' + (47 + j)
                                    : 'tour-' + (46 + j)
                                }}"
                              >
                                <input
                                  matInput
                                  type="text"
                                  autocomplete="off"
                                  (blur)="populateAzureSecondaryconfig()"
                                  (keyup)="validateAddiConfig()"
                                  [(ngModel)]="obj.selectedValue"
                                  required
                                  autocomplete="off"
                                  name="asNumberRemote"
                                  [maxlength]="10"
                                  #asNumberRemote="ngModel"
                                />
                              </mat-form-field>
                            </div>
                            <p
                              class="error pl-3 f-s-10"
                              *ngIf="obj.isNameValidation"
                            >
                              Provide valid {{ obj.displayName }}
                            </p>
                          </div>
                          <div
                            class="pl-3 select-style"
                            *ngIf="obj.type == 'select'"
                          >
                            <mat-form-field
                              tourAnchor="{{
                                i === 0
                                  ? 'tour-' + (30 + j)
                                  : i === 1
                                  ? 'tour-' + (38 + j)
                                  : i === 3
                                  ? 'tour-' + (47 + j)
                                  : 'tour-' + (46 + j)
                              }}"
                            >
                              <mat-select
                                [(ngModel)]="obj.selectedValue"
                                (blur)="populateAzureSecondaryconfig()"
                              >
                                <mat-option
                                  *ngFor="let item of obj.displayData"
                                  [value]="item.value"
                                >
                                  {{ item.value }}
                                </mat-option>
                              </mat-select>
                            </mat-form-field>
                            <p
                              class="error pl-3"
                              *ngIf="obj.isNameValidation"
                              class="error f-s-10"
                            >
                              Provide valid {{ obj.displayName }}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </mat-expansion-panel>
                </mat-accordion>
              </div>

              <!-- Form saved mode -->
              <div *ngIf="hideConfigDetails">
                <div *ngFor="let item of cloudConfiguration; let i = index">
                  <div class="mb-4 border-bottom pb-2">
                    <span *ngIf="item.cloudConfgName == 'Amazon Web Services'">
                      <i
                        class="fa fa-solid fa-cloud pr-3"
                        style="font-size: 20px; color: #f79919"
                      ></i>
                    </span>
                    <span
                      *ngIf="
                        item.cloudConfgName == 'Microsoft Azure Primary' ||
                        item.cloudConfgName == 'Microsoft Azure Secondary'
                      "
                    >
                      <i
                        class="fa fa-solid fa-cloud pr-3"
                        style="font-size: 20px; color: #00b6ea"
                      ></i
                    ></span>
                    <span>{{ item.cloudConfgName }}</span>
                  </div>
                  <div class="row">
                    <div
                      class="col-md-4 pb-4 pl-3"
                      *ngFor="let obj of item.attributes"
                    >
                      <div class="input-label">{{ obj.displayName }}</div>
                      <div class="input-value">{{ obj.selectedValue }}</div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row pt-5">
                <div class="col-md-12">
                  <button
                    type="button"
                    class="btn active-btn"
                    (click)="cloudConfigSave()"
                    [disabled]="isSubmitDisable"
                  >
                    SAVE
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div
          class="card"
          style="margin-top: 10px; height: 80px"
          *ngIf="!emptyaccordian"
        >
          <div id="custom-scroll" class="card-body">
            <div class="title color-change1">
              Configuration details <span class="mandatory-symbol">*</span
              ><br />
              <span class="pointer dclmrMsg">
                <span
                  class="icon icon-info fs-11"
                  style="margin: 0px !important"
                ></span>
                For successful configuration, Customer to fill up attribute
                values accurately. Any support required, Please reach out to
                mcc.taskforce&#64;tatacommunications.com
              </span>
            </div>
          </div>
        </div>
      </ng-container>
    </section>
  </section>
  <tour-step-template>
    <ng-template let-step="step">
      <div class="tour-pointer"></div>
      <div class="tour">
        <p class="ngxp-title">{{ step?.title }}</p>
        <p class="ngxp-content">{{ step?.content }}</p>
        <div class="tour-step-navigation">
          <button
            [disabled]="!tourService.hasPrev(step)"
            class="ngxp-btn"
            (click)="tourService.prev()"
          >
            « prev
          </button>

          <button class="ngxp-btn" (click)="handleNextButtonClick(step)">
            next »
          </button>
          <!-- <button class="ngxp-btn" (click)="tourService.end();setValuesOnNext(step?.anchorId)">end</button> -->
        </div>
      </div>
    </ng-template>
  </tour-step-template>
</main>
