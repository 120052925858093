/**
 * This file contains the ReviewacceptComponent class.
 *
 * @author <author name>
 * @Modified by Sarath Kumar
 * @link http://www.tatacommunications.com/
 * @copyright 2014 Tata Communications Limited
 */

import { Component, OnInit,AfterViewChecked,OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppService } from './app.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { NgxPermissionsService } from 'ngx-permissions';
import { NgxRolesService } from 'ngx-permissions';
import { ISubscription } from 'rxjs/Subscription';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    host: {
        '(window:resize)': 'onResize($event)'
    }
})

export class AppComponent {

    constructor(private router: Router, private appService: AppService,
        private modalService: BsModalService, private permissionsService: NgxPermissionsService, private ngxRolesService: NgxRolesService) {
        }
        }
