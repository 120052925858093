import { Component, OnInit, Input, ViewChild, TemplateRef } from '@angular/core';
import { BsModalService } from "ngx-bootstrap/modal";

@Component({
    selector: '[confirmModal]',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.css']
})

export class ConfirmModalComponent implements OnInit {
    constructor(private bsModalService: BsModalService) {

    }
    @Input('confirmModal') confirmSettings: any;
    @ViewChild("confirmModal") confirmModal: TemplateRef<any>;
    confirmModalData: any = {
        title: "",
        message: "",
        modalRef: {}
    }

    closeModal() {
        this.confirmSettings.close();
    }

    ngOnInit() {

        this.confirmSettings.open = (data) => {
            this.confirmModalData.message = data.message;
            this.confirmModalData.title = data.title;
            var buttons = [];
            for(var btn in data.buttons){
                if(data.buttons[btn].name == 'Close'){

                }
                buttons.push({name: data.buttons[btn].name,method: data.buttons[btn].callBackFn});
            }
            this.confirmModalData.buttons = buttons;            
            this.confirmModalData.modalRef = this.bsModalService.show(this.confirmModal,
                { class: "modal-sm", backdrop: this.confirmSettings.backdrop ? this.confirmSettings.backdrop : true }
            );
        }

        this.confirmSettings.close = () => {
            this.confirmSettings.showSpinner = false;
            this.confirmModalData.modalRef.hide();
        }
    }
}