
import { ElementRef } from '@angular/core';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-widget',
  templateUrl: './loading-widget.component.html',
  styleUrls: ['./loading-widget.component.css']
})
export class LoadingWidgetComponent implements OnInit {

  constructor(private element:ElementRef) { }

  ngOnInit() {
    // console.log(document.getElementById('loading-widget').parentElement.style.position)
    // document.getElementById('loading-widget').parentElement.style.position='relative';
    // console.log(document.getElementById('loading-widget').parentElement.style.position)
  }
  ngAfterViewInit(){
  // console.log(this.element.nativeElement.parentElement.style.position)
  this.element.nativeElement.parentElement.style.position='relative';
    // console.log(this.element.nativeElement.parentElement.style.position)
}
}
