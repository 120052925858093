import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { IzoneComponent } from './izo-ne.component';
import { DesigncanvasComponent } from './components/designcanvas/designcanvas.component';
import { IzomccScpComponent } from './izomcc-scp.component';
import { LandingpageComponent } from '../izomcc-scp/components/landingpage/landingpage.component';
import { GetquoteComponent } from './components/getquote/getquote.component';
import { ViewOrderDetailsComponent } from './components/view-order-details/view-order-details.component';
import { ScpSalesOrderComponent } from './components/scp-sales-order/scp-sales-order.component';
import { ScpOrderReviewComponent } from './components/scp-order-review/scp-order-review.component';
const routes: Routes = [];

export const IzomccScpRoutes: Routes = [
    {
        // path: '', component: IzomccScpComponent,
        // children: [
        //     {path: 'design-canvas', component: DesigncanvasComponent}
        // ]
        path: 'config', component: DesigncanvasComponent
    },
    { path: 'config/:country', component: DesigncanvasComponent, data: { "stage": "Select Configuration" }},
    { path: 'config/:quoteId/:le/:quoteleId', component: DesigncanvasComponent, data: { "stage": "Select Configuration" }},
    // { path: 'landingPage', component: LandingpageComponent},
    { path: 'landingPage', component: LandingpageComponent},

    { path: 'getquote', component: GetquoteComponent},
    { path: 'getquote/:quoteId/:le/:quoteleId', component: GetquoteComponent, data: { "stage": "Get Quote" } },
    { path: 'thankyou', component: ViewOrderDetailsComponent},
    { path: 'checkout', component: ScpSalesOrderComponent },
    { path: 'checkout/:quoteId/:le/:quoteleId', component: ScpSalesOrderComponent, data: { "stage": "Checkout Config" } },    
    { path: 'reviewaccept', component: ScpOrderReviewComponent },
    { path: 'reviewaccept/:quoteId/:le/:quoteleId', component: ScpOrderReviewComponent, data: { "stage": "Review & Accept", permissions: { except: ['OPT_L2O_VIEW_ALL_FEASIBILITIES'], redirectTo: '/forbidden' } }}


]