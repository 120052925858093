import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute, Router } from '@angular/router';
import { IzomccScpService } from '../../izomcc-scp.service';
import { DataSharedSvc } from 'src/app/data-shared-svc';
import {
  MatDialog,
  MatDialogRef,
  MatDialogActions,
  MatDialogClose,
  MatDialogTitle,
  MatDialogContent,
} from '@angular/material/dialog';

@Component({
  selector: 'app-view-order-details',
  templateUrl: './view-order-details.component.html',
  styleUrls: ['./view-order-details.component.css']
})
export class ViewOrderDetailsComponent implements OnInit {
  displaytyo = false;
  serviceid: any;
  siteAaddress: any;
  siteBaddress: any;
  BCB: any;
  BOD: any;
  UpgradedBandwidth: any;
  startdateandtime: any;
  today = new Date()
currentDate = new Date();

// Add 3 days to the current date

  enddateandtime: any = "2021-07-30T04:53:24.000+00:00";
  ordertype: any;
  category: any;
  productname: any;
  orderId: any;
  orderDetails: any;
  vnfDetails: any = [
    {attributes: [{}]}
  ]
  cloudDetails: any = [
    {
      attributes: [
        {
          name: 'cloud provider',
          value: 'Microsoft Azure'
        },
        {
          name: 'interconnect location',
          value: 'Chicago'
        },
        {
          name: 'cloud bandwidth',
          value: '50Mbps'
        },
        {
          name: 'link type',
          value: 'Dual'
        },
        {
          name: 'cloud alias name',
          value: 'AZURE_50Mbps_Dual-Link'
        }
      ]
    },

    {
      attributes: [
        {
          name: 'cloud provider',
          value: 'Google Cloud Provider'
        },
        {
          name: 'interconnect location',
          value: 'Chicago'
        },
        {
          name: 'cloud bandwidth',
          value: '50Mbps'
        },
        {
          name: 'link type',
          value: 'single'
        },
        {
          name: 'cloud alias name',
          value: 'GCP_50Mbps_Dual-Link'
        }
      ]
    },

    {
      attributes: [
        {
          name: 'cloud provider',
          value: 'Amazon Web Services'
        },
        {
          name: 'interconnect location',
          value: 'Chicago'
        },
        {
          name: 'cloud bandwidth',
          value: '50Mbps'
        },
        {
          name: 'link type',
          value: 'Single'
        },
        {
          name: 'cloud alias name',
          value: 'AWS_50Mbps_Dual-Link'
        }
      ]
    }

  ]
  connectorDetails: any = []
  vnfComponent: any = []
  cloudComponent: any = []
  connectorComponent: any = []
  orderObj: any = {};
  orderCode: any;
  configurationDetails: any = [];
  location: any;
  orderDate: any;
  region: any;
  connection_alias_name: any;
  customerLe: any;
  customerObj: any;
  showCloudDetails: any = [{
    attributes: []
  }];
  constructor(public dialog: MatDialog, public activatedRoute: ActivatedRoute, private service: IzomccScpService, public router: Router, public datasharedSvc: DataSharedSvc) {
    this.currentDate.setDate(this.currentDate.getDate() + 3);
   }

  ngOnInit(): void {
    // this.orderObj = JSON.parse(this.datasharedSvc.getOrderObjFromSession());
    // this.orderId = this.orderObj.orderId;
    // this.customerLe = JSON.parse(this.datasharedSvc.getCustomerLeFromSession());
    // console.log(this.customerLe)
    // this.customerObj = JSON.parse(this.datasharedSvc.getCustomerFromSession());
    // console.log(this.customerObj)
    // this.getOrderDetails()
  }

  getOrderDetails() {
    this.service.orderDetails(this.orderId, (response) => {
      console.log(response)
      this.orderDetails = response.data.orderToLeBeans[0]
      this.orderCode = response.data.orderCode
      this.location = response.data.orderToLeBeans[0].orderToLeProductFamilyBeans[0].orderProductSolutions[0].orderConfigurations[0].locationName;
      this.region = response.data.orderToLeBeans[0].legalAttributes.find(ele => ele.displayValue == "REGION") ? response.data.orderToLeBeans[0].legalAttributes.find(ele => ele.displayValue == "REGION").attributeValue : '---'
      this.orderDate = response.data.createdTime
      // this.connection_alias_name = response.data.orderToLeBeans[0].orderToLeProductFamilyBeans[0].orderProductSolutions[0].solutionAliasName
      this.connection_alias_name = response.data.orderToLeBeans[0].legalAttributes.filter(function (el) {
        return el.displayValue == "SOLUTION ALIAS NAME";
      });
      console.log(this.connection_alias_name, ' this.connection_alias_name ');

      this.configurationDetails = response.data.orderToLeBeans[0].orderToLeProductFamilyBeans[0].orderProductSolutions[0].orderConfigurations[0].components
      console.log(this.configurationDetails)
      this.vnfDetails = response.data.orderToLeBeans[0].orderToLeProductFamilyBeans[0].orderProductSolutions[0].orderConfigurations[0].components.filter(item => item.name == "Edge Device");
      this.vnfDetails[0].attributes.splice(0, 1)
      this.vnfDetails[0].attributes.forEach(ele => {
        ele.name = ele.name.toLowerCase()
      });
      this.vnfDetails.forEach(ele => {
        let index1 = ele.attributes.findIndex(item => item.name == "link type")
        ele.attributes.splice(index1, 1)
        let index2 = ele.attributes.findIndex(item => item.name == "component order type")
        ele.attributes.splice(index2, 1)
        let index3 = ele.attributes.findIndex(item => item.name == "Device OEM")
        ele.attributes.splice(index3, 1)
        let index4 = ele.attributes.findIndex(item => item.name == "Device Type")
        ele.attributes.splice(index4, 1)
        let index5 = ele.attributes.findIndex(item => item.name == "Device Category")
        ele.attributes.splice(index5, 1)
        let index6 = ele.attributes.findIndex(item => item.name == "size")
        ele.attributes.splice(index6, 1)
        let index7 = ele.attributes.findIndex(item => item.name == "License Type")
        ele.attributes.splice(index7, 1)
        let index8 = ele.attributes.findIndex(item => item.name == "CPE Basis Chasis")
        ele.attributes.splice(index8, 1)

      })

      console.log(this.vnfDetails)
      this.cloudDetails = response.data.orderToLeBeans[0].orderToLeProductFamilyBeans[0].orderProductSolutions[0].orderConfigurations[0].components.filter(item => item.name == "Cloud");
      this.cloudDetails.forEach((ele, index) => {
        ele["selectedCloud"] = ele.attributes.find(item => item.name == "Cloud Provider").value
      });
      this.cloudDetails.forEach(ele => {
        ele.attributes.forEach(item => item.name = item.name.toLowerCase());
      });
      this.cloudDetails.forEach(ele => {
        if (ele.selectedCloud.trim() == "Amazon Web Services") {
          let index1 = ele.attributes.findIndex(item => item.name == "primary service key")
          ele.attributes.splice(index1, 1)
          let index2 = ele.attributes.findIndex(item => item.name == "primary peer type")
          ele.attributes.splice(index2, 1)
          let index5 = ele.attributes.findIndex(item => item.name == "primary vlan")
          ele.attributes.splice(index5, 1)
        }
        else if (ele.selectedCloud.trim() == "Microsoft Azure") {
          let index6 = ele.attributes.findIndex(item => item.name == "primary account id")
          ele.attributes.splice(index6, 1)
          let index7 = ele.attributes.findIndex(item => item.name == "secondary account id")
          ele.attributes.splice(index7, 1)
        }
      })
      console.log(this.showCloudDetails)
      this.connectorDetails = response.data.orderToLeBeans[0].orderToLeProductFamilyBeans[0].orderProductSolutions[0].orderConfigurations[0].components.filter(item => item.name == "Edge Connectivity")
      this.connectorDetails[0].attributes.forEach(ele => {
        ele.name = ele.name.toLowerCase()
      });
      this.connectorDetails.forEach(ele => {
        let index1 = ele.attributes.findIndex(item => item.name == "component order type")
        ele.attributes.splice(index1, 1)
        ele.attributes.push(ele.attributes.shift())
      })
      console.log(this.connectorDetails)
    }, (error) => {
    })
    //mock
  }


  openDialog(enterAnimationDuration: string, exitAnimationDuration: string): void {
    this.dialog.open(congratulations, {
      width: '250px',
      enterAnimationDuration,
      exitAnimationDuration,
    });
  }

  public trackYourOrder(): void {
    // let url = 'landingPage'
    // console.log(url)
    // this.datasharedSvc.removeQuoteObjFromSession();
    // this.router.navigate([url]).then(() => { window.location.reload(); });
    this.displaytyo = true;

    setTimeout(()=>{
      document.getElementById("scheduled").classList.remove('current');
      document.getElementById("activation").classList.add('current')
    }, 15000)

    setTimeout(()=>{
      document.getElementById("activation").classList.remove('current');
      document.getElementById("deactivation").classList.add('current')
    }, 30000)
    setTimeout(()=>{
      document.getElementById("deactivation").classList.remove('current');
      this.openDialog('0ms', '0ms')
      // document.getElementById("activation").classList.add('current')
    }, 45000)
  }

  public exitOrder(): void {
    this.router.navigate(['/landingPage']);
    // this.datasharedSvc.removeQuoteObjFromSession();
    // let url = environment.devUrl + environment.base_path + '/products';
    // window.open(url, '_self');
    //
  }


}



@Component({
  selector: 'app-congratulations',
  templateUrl: 'congratulations.component.html',
})
export class congratulations {
  constructor(public dialogRef: MatDialogRef<congratulations>) {}
}

