import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'commaSeperator'
})
export class CommaSeperatorPipe implements PipeTransform {

  transform(value: number, currenyType: string, decimalPoints: number): any {
    // To handle GSC condition in case of GLOBAL OUTBOUND;
    if (value === 0 && currenyType) {
      return 0;
    }
    if (value && currenyType) {
      let tempValue = value.toString().split('.');
      tempValue[0] = Number(tempValue[0]).toLocaleString(`en-${currenyType.slice(0, 2)}`);
      return decimalPoints === 0 || tempValue.length === 1 ? tempValue[0] : `${tempValue[0]}.${tempValue[1].slice(0, decimalPoints)}`;
    }
  }

}
