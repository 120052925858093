import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'gscnamechange'
})
export class GscNameChangePipe implements PipeTransform {

  transform(value: String): any {
    var productName = value;
    if (value === 'GSIP') {
      productName = 'GSC';
    }

    if (value === 'gsip') {
      productName = 'gsc';
    }

    return productName;

  }

}
