import {
  NgModule,
  CUSTOM_ELEMENTS_SCHEMA,
  NO_ERRORS_SCHEMA,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { LandingpageComponent } from './components/landingpage/landingpage.component';
import { IzomccScpComponent } from './izomcc-scp.component';
import { DesigncanvasComponent } from './components/designcanvas/designcanvas.component';
import { ConnConfigComponent } from './components/conn-config/conn-config.component';
import { GetquoteComponent } from './components/getquote/getquote.component';

import { ScpSalesOrderComponent } from './components/scp-sales-order/scp-sales-order.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MaterialModule } from '../common/material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IzomccScpRoutes } from './izomcc-scp.route';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { AgmCoreModule } from '@agm/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {
  ViewOrderDetailsComponent,
  congratulations,
} from './components/view-order-details/view-order-details.component';
import { BrowserModule } from '@angular/platform-browser';
import { SearchPipe } from './search-pipe/search.pipe';
import { CommonModules } from '../common/common.module';
import { SortPipe } from './search-pipe/sort.pipe';
import { ScpOrderReviewComponent } from './components/scp-order-review/scp-order-review.component';
import { CustomTooltipComponent } from './search-pipe/custom-tooltip/custom-tooltip.component';
import { ToolTipRendererDirective } from './search-pipe/tool-tip-renderer.directive';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { TopologyComponent } from './components/topology/topology.component';
import { VisualComponent } from './components/visual/visual.component';
import { UniqueByPipe } from '../common/pipes/attr-order-pipe';

@NgModule({
  declarations: [
    UniqueByPipe,
    IzomccScpComponent,
    LandingpageComponent,
    DesigncanvasComponent,
    ConnConfigComponent,
    GetquoteComponent,
    congratulations,
    ViewOrderDetailsComponent,
    ScpSalesOrderComponent,
    ScpOrderReviewComponent,
    SearchPipe,
    SortPipe,
    CustomTooltipComponent,
    ToolTipRendererDirective,
    TopologyComponent,
    VisualComponent,
  ],

  imports: [
    CommonModules,
    CommonModule,
    MatFormFieldModule,
    MatTabsModule,
    MatSelectModule,
    MatTooltipModule,
    MatExpansionModule,
    PopoverModule,
    TooltipModule,
    MatDatepickerModule,
    MaterialModule,
    ReactiveFormsModule,
    FormsModule,
    RouterModule.forChild(IzomccScpRoutes),
    MatInputModule,
    MatCardModule,
    MatCheckboxModule,
    TabsModule,
    FormsModule,
    MaterialModule,

    TourMatMenuModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyABsK46nzZQTt5N_zEM6vRONgpwYZypdhA',
      libraries: ['places'],
    }),
  ],
  bootstrap: [IzomccScpComponent],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA, NO_ERRORS_SCHEMA],
})
export class IzomccScpModule {}
