import { Component, OnInit, Input, ViewChild, TemplateRef,ElementRef } from '@angular/core';

@Component({
    selector: '[optimusSpinner]',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.css']
})

export class SpinnerComponent implements OnInit {
    constructor() {

    }
    spinnerObj : any = {

    }
    @ViewChild('spinner') spinner : ElementRef;
    @Input('optimusSpinner') optimusSpinnerSettings: any;

    ngOnInit() {
        if(this.optimusSpinnerSettings.faSize && this.spinner){
            this.spinner.nativeElement.classList.add(this.optimusSpinnerSettings.faSize)
        }        
    }
}