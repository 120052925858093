<ng-template #confirmModal>
<div class="service-details-modal" id="" tabindex="-1" role="dialog" aria-hidden="true">
  <div class="" role="document">
    <div class="modal-content">
      <div class="modal-header d-flex justify-content-between align-items-center flex-row">
        <h5 class="modal-title">{{confirmModalData.title}}</h5>
        <button type="button" (click)="closeModal()" class="close" data-dismiss="modal" aria-label="Close">
          <!-- <i class="icon-close"></i>  -->
        </button>
      </div>
      <div class="modal-body">

        <h4>{{confirmModalData.message}}</h4>

      </div>
      <div class="modal-footer">
        <ng-container *ngFor="let btn of confirmModalData.buttons">
            <span *ngIf="confirmSettings.showSpinner && confirmSettings.showSpinnerOn === btn.name" [optimusSpinner]="{faSize: 'fa-lg'}"></span>
            <button type="button" (click)="btn.method()" class="close" data-dismiss="modal" [ngClass]="{'secondary-dark-outline-btn' : btn.name.indexOf('Cancel') > -1}" class="btn active-btn">{{btn.name}}</button>
        </ng-container>        
      </div>
    </div>
  </div>
</div>
</ng-template>