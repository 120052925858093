import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IzomccScpService } from '../../izomcc-scp.service';
import { DataSharedSvc } from 'src/app/data-shared-svc';
import { BsModalService } from 'ngx-bootstrap/modal';
import { MatSnackBar } from '@angular/material/snack-bar';


@Component({
  selector: 'app-landingpage',
  templateUrl: './landingpage.component.html',
  styleUrls: ['./landingpage.component.css'],

})
export class LandingpageComponent implements OnInit {
  @ViewChild('Editing') Editing: TemplateRef<any>;
  @ViewChild('enableipsec') enableipsec: TemplateRef<any>;
  @ViewChild('confirmconfig') confirmconfig: TemplateRef<any>;

  amerregionImage: boolean = false;
  emearegionImage: boolean = false;
  apacregionImage: boolean = false;
  userCountry: any = '';
  currentStaus: any = 'Output';
  orderidz = "";
  locationName: any = '';
  zoom: number = 100;
  infoWindowOpened = null
  previous_info_window: any
  lat: number = 28.0320508;
  lng: number = 26.2443901;

  show: boolean = false;
  mcLocationList: any = []
  currentTabname: string;
  cspconnheaders = ['Order Id', 'Service Id', 'Cloud Name', 'Location', 'Bandwidth', 'Connection Type', 'Status'];
  sidemenunames = ['Virtual Devices', 'CSP Connections', 'Connecters'];
  virtualDevicesheaders = ['Order Id', 'Service Id', 'Device Name', 'Location', 'Operational Status'];
  connecterheaders = ['Order Id', 'Service Id', 'Location', 'Bandwidth', 'Alias Name', 'Status', 'IPSEC Tunnel1 status', 'IPSEC Tunnel2 status'];
  deviceArrayList = []
  edgeArrayList: any = []
  cloudArrayList: any = []
  inventorymaplist: any = []
  salesOrderInfo: any;
  edidel: any = {};
  vnfServiceIdIpsec: any;
  regionList: any = []
  regionListCount: any = []
  finalMapList: any;
  tab: any;
  customerInfo: any;
  customerid: any;
  customerLeId: any;
  customerCountry: any
  previousUrl: string = null;
  currentUrl: string = null;
  valid_country: boolean = false;
  valid_region: boolean = false;
  remoteCpePublicIp: any;
  remoteTunnelIp: any;
  tunnelIpAddress: any;
  remoteAsAumber: any;
  ipsecflag: boolean = false;
  OrdertunnerNumerinfo: any;
  localAsNumber:any;
  deploydisable: boolean = false;
  ipsecpayloadInfo: any;
  postipsecresponse: any;
  postipsecstatus: any;
  downloadconfiginfo: any;
  downloadconfigflag: boolean = false;
  downloadtunnelnumber: number;
  msg: any;
  mapZoom = 2;
  restrictionOptions: any = {
    latLngBounds: {
      north: 15,
      south: -185,
      east: 280,
      west: -280
    },
    strictBounds: true
  };
  selectedTunnelNumberInfo: any;
  selectedSserviceID: any;
  postConfig: any;
  iconUrlGray = {
    url: './assets/images/scp/Greyicon.png',
  };
  iconUrlGreen = {
    url: './assets/images/scp/Greenicon.png',
  };
  locationNameMyInventory: any
  previous_info_window1: any;
  infoWindowOpened1: any;
  CheckCustomerIds:any;
  constructor(
    public router: Router,
    private _snackBar: MatSnackBar,
    private service: IzomccScpService,
    public datasharedSvc: DataSharedSvc,
    public bsModalService: BsModalService,
    public activatedroute: ActivatedRoute
  ) {
    // this.datasharedSvc.sharedData.subscribe((data: any) => {
    //   this.customerid = data.customerId
    // })
  }

  ngOnInit(): void {
    this.currentTabname = 'Virtual Devices';
    this.zoom = 1;
       this.datasharedSvc.customerChangeobj.subscribe((data: any) => {
        this.checkUstomerId();
        // this.datasharedSvc.customerChangeobj.complete();
      })
  }
  checkUstomerId(){
    this.userCountry = "UNITED STATES".toLocaleUpperCase();

      this.getMapLocations()
    }
  currentTab(event) {
    if (event == 'Input') {
      this.currentStaus = event
      this.previous_info_window = null
    } else if (event == 'Output') {
      this.currentStaus = event
    }
  }

  close_window() {
    if (this.previous_info_window != null) {
      this.previous_info_window.close()
    }
  }

  opentab(tabname: string) {
    this.tab = tabname;
    if (tabname == 'Virtual Devices') {
      this.currentTabname = tabname;
    }
    else if (tabname == 'CSP Connections') {
      this.currentTabname = tabname;
    }
    else if (tabname == 'Connecters') {
      this.currentTabname = tabname;
    }

  }


  onMouseOver(label: any, infoWindow: any, ) {
    infoWindow.open();
    if (this.previous_info_window == null) {
      this.previous_info_window = infoWindow;
      this.locationName = label
    }
    else {
      this.infoWindowOpened = infoWindow
      this.locationName = label
      this.previous_info_window.close()
    }
    this.previous_info_window = infoWindow

    this.locationName = label
  }
  onMouseOutLocationName(infoWindow: any) {
    infoWindow.close();
  }

  onMouseOver1(label: any, infoWindow: any, ) {
    infoWindow.open();
    if (this.previous_info_window1 == null) {
      this.previous_info_window1 = infoWindow;
      this.locationNameMyInventory = label
    }
    else {
      this.infoWindowOpened1 = infoWindow
      this.locationNameMyInventory = label
      this.previous_info_window1.close()
    }
    this.previous_info_window1 = infoWindow

    this.locationNameMyInventory = label
  }
  getMapLocations() {
    const countryinfo =[
      {
          city: "Amsterdam",
          country: "NETHERLANDS",
          region: "EMEA",
          popStatus: "Live",
          latitude: "52.3031732618186",
          longitude: "4.93831828657571",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "AV2",
          siteAddress: "COLO AT EQUINIX (NETHERLANDS) BV, EQUINIX AM7,TATA COMMUNICATIONS (NETHERLANDS) B.V.  KUIPERBERGWEG 13,1101 AE  AMSTERDAM,THE NETHERLANDS",
          siteType: null
      },
      {
          city: "Ashburn",
          country: "UNITED STATES",
          region: "AMER",
          popStatus: "Live",
          latitude: "39.0437",
          longitude: "-77.4875",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "ASH",
          siteAddress: "COLO AT EQUINIX (ASHBURN)  EQUINIX DC2, 21715 Filigree Court, Ashburn, VA 20147, US",
          siteType: null
      },
      {
          city: "Chicago",
          country: "UNITED STATES",
          region: "AMER",
          popStatus: "Live",
          latitude: "41.8781",
          longitude: "-87.6298",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "CHI",
          siteAddress: "EQUINIX CH3, 1905 LUNT AVENUE, ELK GROVE VILLAGE, IL 60007, USA",
          siteType: null
      },
        {
          "city": "Geneva",
          "country": "SWITZERLAND",
          "region": "EMEA",
          "popStatus": "Pre Ordering",
          "latitude": "46.2044",
          "longitude": "6.1432",
          "draggable": "false",
          "icon": "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          "siteCode": "",
          "siteAddress": "Geneva, Switzerland",
          "siteType": "Equinix"
        },
        {
          "city": "Perth",
          "country": "AUSTRALIA",
          "region": "APAC",
          "popStatus": "Pre Ordering",
          "latitude": "-31.9505",
          "longitude": "115.8605",
          "draggable": "false",
          "icon": "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          "siteCode": "",
          "siteAddress": "Perth, Australia",
          "siteType": "Equinix"
        },
        {
          "city": "Copenhagen",
          "country": "DENMARK",
          "region": "EMEA",
          "popStatus": "Pre Ordering",
          "latitude": "55.6761",
          "longitude": "12.5683",
          "draggable": "false",
          "icon": "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          "siteCode": "",
          "siteAddress": "Copenhagen, Denmark",
          "siteType": "AWS"
        },
        {
          "city": "Melbourne",
          "country": "AUSTRALIA",
          "region": "APAC",
          "popStatus": "Pre Ordering",
          "latitude": "-37.8136",
          "longitude": "144.9631",
          "draggable": "false",
          "icon": "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          "siteCode": "",
          "siteAddress": "Melbourne, Australia",
          "siteType": "Equinix"
        },
        {
          "city": "Milan",
          "country": "ITALY",
          "region": "EMEA",
          "popStatus": "Pre Ordering",
          "latitude": "45.4642",
          "longitude": "9.1900",
          "draggable": "false",
          "icon": "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          "siteCode": "",
          "siteAddress": "Milan, Italy",
          "siteType": "Equinix"
        },
        {
          "city": "Osaka",
          "country": "JAPAN",
          "region": "APAC",
          "popStatus": "Pre Ordering",
          "latitude": "34.6937",
          "longitude": "135.5023",
          "draggable": "false",
          "icon": "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          "siteCode": "",
          "siteAddress": "Osaka, Japan",
          "siteType": "Equinix"
        },
        {
          "city": "Warsaw",
          "country": "POLAND",
          "region": "EMEA",
          "popStatus": "Pre Ordering",
          "latitude": "52.2297",
          "longitude": "21.0122",
          "draggable": "false",
          "icon": "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          "siteCode": "",
          "siteAddress": "Warsaw, Poland",
          "siteType": "Equinix"
        },
        {
          "city": "Zurich",
          "country": "SWITZERLAND",
          "region": "EMEA",
          "popStatus": "Pre Ordering",
          "latitude": "47.3769",
          "longitude": "8.5417",
          "draggable": "false",
          "icon": "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          "siteCode": "",
          "siteAddress": "Zurich, Switzerland",
          "siteType": "Equinix"
        },
        {
          "city": "Taipei",
          "country": "TAIWAN",
          "region": "APAC",
          "popStatus": "Pre Ordering",
          "latitude": "25.0330",
          "longitude": "121.5654",
          "draggable": "false",
          "icon": "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          "siteCode": "",
          "siteAddress": "Taipei, Taiwan",
          "siteType": "Equinix"
        },

      {
          city: "Dallas",
          country: "UNITED STATES",
          region: "AMER",
          popStatus: "Live",
          latitude: "32.7767",
          longitude: "-96.7970",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "DAL",
          siteAddress: "EQUINIX DA1, 1950 N STEMMONS FREEWAY, DALLAS, TX 75207, USA",
          siteType: null
      },
      {
          city: "Dublin",
          country: "IRELAND",
          region: "EMEA",
          popStatus: "Live",
          latitude: "53.3498",
          longitude: "-6.2603",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "DB1",
          siteAddress: "EQUINIX DB1, UNIT 5, KILSHANE ROAD, DUBLIN 15, IRELAND",
          siteType: null
      },
      {
          city: "Frankfurt",
          country: "GERMANY",
          region: "EMEA",
          popStatus: "Live",
          latitude: "50.1109",
          longitude: "8.6821",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "FR1",
          siteAddress: "EQUINIX FRANKFURT II (FR2), LICHTENBERGER STRASSE 2, FRANKFURT  66386, GERMANY",
          siteType: null
      },
      {
          city: "Hong Kong",
          country: "CHINA",
          region: "APAC",
          popStatus: "Live",
          latitude: "22.3193",
          longitude: "114.1694",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "HK1",
          siteAddress: "EQUINIX HK1, 15/F, BIG WAVE BAY, TKO GATEWAY DATA CENTER, HONG KONG, CHINA",
          siteType: null
      },
      {
          city: "London",
          country: "UNITED KINGDOM",
          region: "EMEA",
          popStatus: "Live",
          latitude: "51.5074",
          longitude: "-0.1278",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "LD6",
          siteAddress: "EQUINIX LD6, 8/9 HARBOUR EXCHANGE, LONDON  E14 9GE, UNITED KINGDOM",
          siteType: null
      },
      {
          city: "Los Angeles",
          country: "UNITED STATES",
          region: "AMER",
          popStatus: "Live",
          latitude: "34.0522",
          longitude: "-118.2437",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "LA1",
          siteAddress: "EQUINIX LA1, 624 S GRAND AVENUE, LOS ANGELES, CA 90017, USA",
          siteType: null
      },
      {
          city: "Madrid",
          country: "SPAIN",
          region: "EMEA",
          popStatus: "Live",
          latitude: "40.4168",
          longitude: "-3.7038",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "MD1",
          siteAddress: "EQUINIX MADRID (MD1), CALLE ACANTO, 8, SAN FERNANDO DE HENARES  28830, SPAIN",
          siteType: null
      },
      {
          city: "Miami",
          country: "UNITED STATES",
          region: "AMER",
          popStatus: "Live",
          latitude: "25.7617",
          longitude: "-80.1918",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "MI1",
          siteAddress: "EQUINIX MI1, 50 NE 9TH STREET, MIAMI, FL 33132, USA",
          siteType: null
      },
      {
          city: "Mumbai",
          country: "INDIA",
          region: "APAC",
          popStatus: "Live",
          latitude: "19.0760",
          longitude: "72.8777",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "MUM",
          siteAddress: "EQUINIX MUM 1, KURLA MUMBAI  400 070, INDIA",
          siteType: null
      },
      {
        city: "Chennai",
        country: "INDIA",
        region: "APAC",
        popStatus: "Live",
        latitude: "13.0827",
        longitude: "80.2707",
        draggable: "false",
        icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
        siteCode: "CHN",
        siteAddress: "EQUINIX MAA1, MAHAJAN TOWERS, NO. 45, WEST CIT NAGAR, NANDANAM, CHENNAI, TAMIL NADU 600 035, INDIA",
        siteType: null
      },
      {
        city: "Delhi",
        country: "INDIA",
        region: "APAC",
        popStatus: "Live",
        latitude: "28.7041",
        longitude: "77.1025",
        draggable: "false",
        icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
        siteCode: "DEL",
        siteAddress: "EQUINIX NDC DEL 1B, PLOT NO 6, NEW DELHI CHATTARPUR ENCLAVE, PHASE 3, NEW DELHI, INDIA",
        siteType: null
      },
      {
          city: "New York",
          country: "UNITED STATES",
          region: "AMER",
          popStatus: "Live",
          latitude: "40.7128",
          longitude: "-74.0060",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "NY5",
          siteAddress: "EQUINIX NY5, 800 SEC AVE, NEW YORK, NY 10017, USA",
          siteType: null
      },
      {
          city: "San Jose",
          country: "UNITED STATES",
          region: "AMER",
          popStatus: "Live",
          latitude: "37.3382",
          longitude: "-121.8863",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "SV8",
          siteAddress: "EQUINIX SV8, 529 BRYANT STREET, PALO ALTO, CA 94301, USA",
          siteType: null
      },
      {
          city: "Seattle",
          country: "UNITED STATES",
          region: "AMER",
          popStatus: "Live",
          latitude: "47.6062",
          longitude: "-122.3321",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "SE2",
          siteAddress: "EQUINIX SE2, 2020 5TH AVENUE, SEATTLE, WA 98121, USA",
          siteType: null
      },
      {
          city: "Singapore",
          country: "SINGAPORE",
          region: "APAC",
          popStatus: "Live",
          latitude: "1.3521",
          longitude: "103.8198",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "SG1",
          siteAddress: "EQUINIX SG1, 26A AYER RAJAH CRESCENT, SINGAPORE 139963, SINGAPORE",
          siteType: null
      },
      {
          city: "Stockholm",
          country: "SWEDEN",
          region: "EMEA",
          popStatus: "Live",
          latitude: "59.3293",
          longitude: "18.0686",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "STO",
          siteAddress: "EQUINIX SK2, OLOF PALMES GATA 14, 114 37 STOCKHOLM, SWEDEN",
          siteType: null
      },
      {
          city: "Sydney",
          country: "AUSTRALIA",
          region: "APAC",
          popStatus: "Live",
          latitude: "-33.8688",
          longitude: "151.2093",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "SY3",
          siteAddress: "EQUINIX SY3, 47 BOURKE ROAD, ALEXANDRIA NSW 2015, AUSTRALIA",
          siteType: null
      },
      {
          city: "Tokyo",
          country: "JAPAN",
          region: "APAC",
          popStatus: "Live",
          latitude: "35.6895",
          longitude: "139.6917",
          draggable: "false",
          icon: "https://chart.apis.google.com/chart?chst=d_map_pin_letter&chld=%E2%80%A2|FF0000",
          siteCode: "TY1",
          siteAddress: "EQUINIX TY1, KINSHICHO GATE TOWER, 2-2-1, WAKAKUSAGI, SUMIDA-KU, TOKYO  130 0013, JAPAN",
          siteType: null
      },

  ];
    this.mcLocationList = countryinfo;
      this.finalMapList = this.mcLocationList;
      this.regionList = [...new Set(this.mcLocationList.map((item: any) => item.region))]
      this.regionList.forEach((city: any) => {
        let objs = {
          CountryName: city,
          city: this.mcLocationList.filter((obj: { region: any; }) => obj.region === city)
        }
        this.regionListCount.push(objs)
  });
  }


  Openpop() {
    this.edidel.shareModalRef = this.bsModalService.show(this.Editing, { class: "modal-sm" });
  }

  Closepop() {
    this.edidel.shareModalRef.hide();
  }

  customerorderdetails() {

    this.service.customerorders(this.customerid, this.customerLeId, (res) => {
      if (res.status == "SUCCESS" || res.responseCode == 200) {
        this.salesOrderDetails()
        // this.datasharedSvc.close()
        this.customerInfo = res.data;
        // this.deviceArrayList = this.customerInfo.izomccScpDeviceInventoryBean;
        // this.cloudArrayList = this.customerInfo.izomccScpCloudInventoryBean;
        // this.edgeArrayList = this.customerInfo.izomccScpEdgeInventoryBean;


      //   const abc = {
      //     "responseCode": 200,
      //     "message": "OK",
      //     "status": "SUCCESS",
      //     "ticket": "WWE64K9IFGBZJUOOZPDKNS",
      //     "data": {
      //         "izomccScpCloudInventoryBean": [],
      //         "izomccScpDeviceInventoryBean": [
      //             {
      //                 "serviceId": "001ASHBA0F8SWDZB0TD",
      //                 "orderId": "IZOMCC230823BVII",
      //                 "status": "Active",
      //                 "offerringName": "CiscoLarge (6core)-Device-1 Ashburn",
      //                 "location": "Ashburn",
      //                 "connectionType": "SINGLE",
      //                 "provisioningThrough": null
      //             },
      //             {
      //                 "serviceId": "001ASHBA0BTAPJZNYT9",
      //                 "orderId": "IZOMCC091023U3XZ",
      //                 "status": "Active",
      //                 "offerringName": "Cisco2-Device-1 Ashburn",
      //                 "location": "Ashburn",
      //                 "connectionType": "SINGLE",
      //                 "provisioningThrough": null
      //             }
      //         ],
      //         "izomccScpEdgeInventoryBean": [],
      //         "izomccServiceInventoryMapBean": []
      //     }
      // }
      //   this.salesOrderInfo = abc.data

        // this.inventorymaplist=this.salesOrderInfo.izomccServiceInventoryMapBean;
        // if (this.salesOrderInfo && this.salesOrderInfo.izomccScpDeviceInventoryBean) {
        //   this.salesOrderInfo.izomccScpDeviceInventoryBean.map((ele: any) => {
        //     ele['isSalesOrder'] = "yes"
        //     this.deviceArrayList.push(ele)
        //   });
        // }

        // console.log(this.deviceArrayList ,'this.deviceArrayList' );

        // if (this.salesOrderInfo && this.salesOrderInfo.izomccScpCloudInventoryBean) {
        //   this.salesOrderInfo.izomccScpCloudInventoryBean.map((ele: any) => {
        //     this.cloudArrayList.push(ele)
        //   });
        // }
        // ;

        // if (this.salesOrderInfo && this.salesOrderInfo.izomccScpEdgeInventoryBean) {
        //   this.salesOrderInfo.izomccScpEdgeInventoryBean.map((ele: any) => {
        //     this.edgeArrayList.push(ele)
        //   });
        // }

        // if (this.salesOrderInfo && this.salesOrderInfo.izomccServiceInventoryMapBean) {
        //   this.salesOrderInfo.izomccServiceInventoryMapBean.map((ele: any) => {
        //     this.inventorymaplist.push(ele)
        //   });
        // }

        for (let devname in this.deviceArrayList) {
          this.deviceArrayList[devname].offerringName = this.deviceArrayList[devname].offerringName.match(/[A-Z][a-z]+/g)[0]
        }
      }
    }
      , (error) => {
        console.log(error)
        this.salesOrderDetails();
        // this.datasharedSvc.close()
      })
  }

mergeSalesAndCustomerResponce(){
  if (this.salesOrderInfo && this.salesOrderInfo.izomccScpDeviceInventoryBean) {
    this.salesOrderInfo.izomccScpDeviceInventoryBean.map((ele: any) => {
      ele['isSalesOrder'] = "yes"
      this.deviceArrayList.push(ele)
    });
  }

  if (this.salesOrderInfo && this.salesOrderInfo.izomccScpCloudInventoryBean) {
    this.salesOrderInfo.izomccScpCloudInventoryBean.map((ele: any) => {
      this.cloudArrayList.push(ele)
    });
  }
  ;

  if (this.salesOrderInfo && this.salesOrderInfo.izomccScpEdgeInventoryBean) {
    this.salesOrderInfo.izomccScpEdgeInventoryBean.map((ele: any) => {
      this.edgeArrayList.push(ele)
    });
  }
  // if (this.salesOrderInfo && this.salesOrderInfo.izomccServiceInventoryMapBean) {
  //   this.salesOrderInfo.izomccServiceInventoryMapBean.map((ele: any) => {
  //     this.inventorymaplist.push(ele)
  //   });
  // }
}

  openLoaderModal(data) {
    this.datasharedSvc.open(data);
  }
  salesOrderDetails() {

    this.service.salesOrderDetailsInventory(this.customerid, this.customerLeId, (res) => {

      if (res.status == "SUCCESS" || res.responseCode == 200) {
        // this.datasharedSvc.close()

        this.salesOrderInfo = res.data;

        this.deviceArrayList = this.salesOrderInfo.izomccScpDeviceInventoryBean;
        this.cloudArrayList = this.salesOrderInfo.izomccScpCloudInventoryBean;
        this.edgeArrayList = this.salesOrderInfo.izomccScpEdgeInventoryBean;
        // this.inventorymaplist = this.salesOrderInfo.izomccServiceInventoryMapBean;

        if (this.customerInfo && this.customerInfo.izomccScpDeviceInventoryBean) {
          this.customerInfo.izomccScpDeviceInventoryBean.map((ele: any) => {
            this.deviceArrayList.push(ele)
          });
        }


        if (this.customerInfo && this.customerInfo.izomccScpCloudInventoryBean) {
          this.customerInfo.izomccScpCloudInventoryBean.map((ele: any) => {
            this.cloudArrayList.push(ele)
          });
        }
        ;

        if (this.customerInfo && this.customerInfo.izomccScpEdgeInventoryBean) {
          this.customerInfo.izomccScpEdgeInventoryBean.map((ele: any) => {
            this.edgeArrayList.push(ele)
          });
        }

        for (let devname in this.deviceArrayList) {
          this.deviceArrayList[devname].offerringName = this.deviceArrayList[devname].offerringName.match(/[A-Z][a-z]+/g)[0]
        }
        this.deviceArrayList.reverse();
        this.cloudArrayList.reverse();
        this.edgeArrayList.reverse();
      }

    }
      , (error) => {
        // this.datasharedSvc.close()
        console.log(error)
      })

  }

  regionFlow(data: any) {
    this.valid_region = false;
    this.regionListCount.filter((ele: any) => {
      if (ele.CountryName == data) {
        ele.city.filter((res: any) => {
          if (res.country == this.userCountry) {
            this.valid_region = true
          }
        })
      }

    })
    if (this.valid_region) {
      // this.router.navigate(['/config', { country: this.userCountry }]);
      this.datasharedSvc.removeLocationInSession();
      this.router.navigate(['/config']);
    }
    else {
      this.openSnackBar('To Order here, Customer needs to have Legal Entity in this location');
    }

  }
  onMouseOverImg(data: any) {
    if (data == 'AMER') {
      this.amerregionImage = true
    } else if (data == 'EMEA') {
      this.emearegionImage = true
    } else if (data == 'APAC') {
      this.apacregionImage = true
    }
  }
  onMouseOutImg(data: any) {
    if (data == 'AMER') {
      this.amerregionImage = false
    } else if (data == 'EMEA') {
      this.emearegionImage = false
    } else if (data == 'APAC') {
      this.apacregionImage = false
    }
  }
  openSnackBar(msg: any) {
    this._snackBar.open(msg, '', {
      panelClass: ['warning-snackbar'],
      duration: 3000,
      verticalPosition: 'bottom',
      horizontalPosition: 'center',
    });
  }


  procedToNex() {
    this.valid_country = false;
    this.regionListCount.filter((ele: any) => {
      ele.city.filter((res: any) => {
        if (res.country === this.userCountry) {
          this.valid_country = true;
        }
      });
    })
    if (this.valid_country) {
      // this.router.navigate(['/config', { country: this.userCountry }]);
      this.datasharedSvc.removeLocationInSession();
      this.router.navigate(['/config']);
    }
    else {
      this.openSnackBar('To Order here, Customer needs to have Legal Entity in this location');
    }
  }

  ipSecEnablepopup(vnfserviceid, OrdertunnerNumerinfo, localAsNumber) {
    this.deploydisable = false;
    this.localAsNumber=localAsNumber;
    this.OrdertunnerNumerinfo = OrdertunnerNumerinfo
    this.vnfServiceIdIpsec = vnfserviceid
    this.ipsecflag = false;
    this.remoteCpePublicIp = '';
    this.remoteTunnelIp = '';
    this.tunnelIpAddress = '';
    this.remoteAsAumber = '';

    this.edidel.shareModalRef2 = this.bsModalService.show(this.enableipsec, { class: "modal-lg" });
  }
  ipSecEnableClosepop() {
    this.edidel.shareModalRef2.hide();
  }
  onAttributeValueChange() {

    if(this.remoteAsAumber!=this.localAsNumber && this.remoteAsAumber<=4294967294)
    {
      this.ipsecflag = true;
      this.ipsecpayloadInfo = {
      remoteCpePublicIp: this.remoteCpePublicIp,
      remoteTunnelIp: this.remoteTunnelIp,
      tunnelIpAddress: this.tunnelIpAddress.substring(0, this.tunnelIpAddress.indexOf('/')),
      remoteAsNumber: this.remoteAsAumber,
      tunnelNumber: this.OrdertunnerNumerinfo + 1,
      tunnelIpPrefix: this.tunnelIpAddress.substring(this.tunnelIpAddress.indexOf('/') + 1),
      previousTunnelId: this.OrdertunnerNumerinfo == 0 ? '' : this.OrdertunnerNumerinfo,
      vnfServiceId: this.vnfServiceIdIpsec,
    }
    console.log(this.ipsecpayloadInfo, 'this.ipsecpayloadInfo')
    this.deploydisable = true;
  }
  else if(this.remoteAsAumber==this.localAsNumber){
    this.ipsecflag = false;
    this.openSnackBar('Remote CPE ASN:Ensure this value is not equal to localAsNumber.');
    this.remoteAsAumber = '';
  }
  else if(this.remoteAsAumber>4294967294)
  {
    this.ipsecflag = false;
  this.openSnackBar('Remote CPE ASN:Ensure this value is less than or equal to 4294967294.');
  this.remoteAsAumber = '';
  }
  }

  deployEnableIpsec() {

    this.openLoaderModal({ message: "Please wait " })
    this.service.postEnableIpSec(this.ipsecpayloadInfo, (response) => {
      if (response.status == "SUCCESS" || response.responseCode == 200) {
        this.postipsecresponse = response.responseCode;
        this.postipsecstatus = response.status
        this.customerorderdetails()
        this.datasharedSvc.close()
        this.ipSecEnableClosepop();
      }

    }, (error) => {
      this.datasharedSvc.close()
      console.log(error)
    })
  }
  ondownloadConfig(serviceid, tunnelNumber) {
    this.openLoaderModal({ message: "Please Wait..." })
    this.downloadtunnelnumber = parseInt(tunnelNumber);
    console.log(this.downloadtunnelnumber);
    console.log(tunnelNumber);
    this.service.downloadconfigpdf(serviceid, this.downloadtunnelnumber, (res) => {
      console.log(res);
      this.confirmDownload(serviceid, tunnelNumber)
      var blobData = new Blob([res], { type: 'application/pdf' });
      var link = document.createElement('a');
      link.href = window.URL.createObjectURL(blobData);
      link.download = "Edge" + serviceid + ".pdf";
      link.click();
      link.remove();

      this.msg = "configuration Document Downloaded Successfully"

    }
      , (error) => {
        console.log(error);
        this.openLoaderModal({ message: "Something Went Wrong" })

        setTimeout(() => {
          this.datasharedSvc.close();
        }, 5000);
      })

  }



  confirmDownload(serviceid, tunnelNumber) {
    this.downloadconfiginfo = {
      "tunnelNumber": tunnelNumber,
      "tunnelOneisdownloaded": tunnelNumber == 1 ? "yes" : "",
      "tunnelTwoisdownloaded": tunnelNumber == 2 ? "yes" : "",
      "edgeServiceId": serviceid
    }
    this.service.downloadPdfPost(this.downloadconfiginfo, (response) => {
      if (response.status == "SUCCESS" || response.responseCode == 200) {
        this.customerorderdetails()
        this.datasharedSvc.close();
      }
    }, (error) => {
      this.datasharedSvc.close();
      console.log(error)
    })
  }

  openPopupConfirmConfig(serviceid, tunnelNumber) {
    this.selectedTunnelNumberInfo = tunnelNumber
    this.selectedSserviceID = serviceid
    this.edidel.shareModalRef = this.bsModalService.show(this.confirmconfig);
  }

  ConfirmConfigpop() {
    this.openLoaderModal({ message: "Please Wait..." })
    this.postConfig = {
      "tunnelNumber": this.selectedTunnelNumberInfo,
      "tunnelOneisconfirmed": this.selectedTunnelNumberInfo == 1 ? "yes" : "",
      "tunnelTwoisconfirmed": this.selectedTunnelNumberInfo == 2 ? "yes" : "",
      "tunnel1Status": this.selectedTunnelNumberInfo == 1 ? "Active" : "",
      "tunnel2Status": this.selectedTunnelNumberInfo == 2 ? "Active" : "",
      "edgeServiceId": this.selectedSserviceID
    }
    this.service.downloadPdfPost(this.postConfig, (response) => {
      if (response.status == "SUCCESS" || response.responseCode == 200) {
        this.customerorderdetails()
        this.datasharedSvc.close();
        this.Closepop()
      }
    }, (error) => {
      this.datasharedSvc.close();
      console.log(error)
    })
  }

  locationBasedNav(locationName) {
    console.log("locationBasedNav ", locationName);
    this.datasharedSvc.setLocationInSession(locationName);
    this.router.navigate(['/config']);
  }
}
