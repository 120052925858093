import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { IStepOption, TourService } from 'ngx-ui-tour-md-menu';
@Component({
  selector: 'app-productselect',
  templateUrl: './productselect.component.html',
  styleUrls: ['./productselect.component.css']
})
export class ProductselectComponent implements OnInit {


  constructor(private router:Router, private tourService: TourService) { }

  ngOnInit(): void {
    const tourSteps:any = [
      {
        anchorId: 'tour-1', // Solution Alias Name
        content: 'Please click here to view all cloud connect variants.', // Updated content
        title: 'Cloud Connect Variants', // Updated title
        placement: 'top'
    }
    ]

    this.tourService.initialize(tourSteps, {
      enableBackdrop: false,
      backdropConfig: {
        //@ts-ignore
        offset: 10,
        closeOnBackdropClick: false,
      },
    });

    setTimeout(()=>{
      this.tourService.start();
    },200)
  }

  setValuesOnNext(step:any){


    if(step==='tour-1'){
      this.router.navigate(['/variant'])
    }
  }

  handleNextButtonClick(step: any) {
    if (this.tourService.hasNext(step)) {
        this.tourService.next();
        this.setValuesOnNext(step?.anchorId);
    } else {
        this.tourService.end();
        this.setValuesOnNext(step?.anchorId);
    }
}

  navigatetovariant(){
    this.router.navigate(['/variant'])
  }
}
