import { Injectable, TemplateRef } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { AppService } from './../../app.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DataSharedSvc } from '../../data-shared-svc';
import * as _moment from 'moment'


@Injectable()
export class CommonValidationService {
    userName;
    groupName;
    invalidKeywords = ['test', 'n/a', 'none', 'personal', 'student', 'self', 'person', 'retired', 'dfg', 'dfad', 'df', 'dasdsa', 'dsf', 'not available', 'none', 'na', 'testing', 'unknown', 'here', 'mine', 'asd', 'sdf'];
    consecutiveRegex: any = new RegExp(/([a-zA-Z()''!"".:;-\s])\1{2,}/);
    specialCharcterRegex: any = new RegExp(/([`+=%@*[?$<>~^!_}{[\]])/);
    specialCharcterDQRuleRegex: any = new RegExp(/[^a-zA-Z.-]+/);

    constructor(private appService: AppService,
        private modalService: BsModalService, private dataSharedService: DataSharedSvc) {
    }

    scrollToTop() {
        window.scrollTo(0, 0);
    }

    transformURL(url) {
        // const quoteObj = JSON.parse(localStorage.getItem('quoteObj'));
        // const orderObj = JSON.parse(localStorage.getItem('orderObj'));
        const quoteObj = JSON.parse(this.dataSharedService.getQuoteObjFromSession());
        const orderObj = JSON.parse(this.dataSharedService.getOrderObjFromSession());
        // tslint:disable-next-line:max-line-length
        if (quoteObj) {
            url = url.replace(/\{\{quoteId\}\}/g, quoteObj['quoteId']).replace(/\{\{quoteleId\}\}/g, quoteObj['quoteleId']);
        }
        if (orderObj) {
            url = url.replace(/\{\{orderId\}\}/g, orderObj['orderId']).replace(/\{\{orderleId\}\}/g, orderObj['orderLeIds'][0]);
        }
        return url;
    }

    dqRuleValidationOnName(value){
        if(value) {
            if (this.invalidKeywords.indexOf(value.toLowerCase()) > -1) {
                return true;
            } else if (this.consecutiveRegex.test(value.toLowerCase().trim())) {
                return true;
            } else if (this.specialCharcterDQRuleRegex.test(value.toLowerCase().trim())) {
                return true;
            } else {
                return false;
            }
        } else {
            return true;
        }
    }

    dqValidation(fieldValue) {
        if (fieldValue) {
            if (this.invalidKeywords.indexOf(fieldValue.toLowerCase()) > -1) {
                return true;
            } else if (this.consecutiveRegex.test(fieldValue.toLowerCase().trim())) {
                return true;
            } else if (this.specialCharcterRegex.test(fieldValue.toLowerCase().trim())) {
                return true;
            } else {
                return false;
            }
        }
    }

    removeQuoteLocalStorage() {
        //localStorage.removeItem('quoteObj');
    }

    removeOrderLocalStorage() {
        //localStorage.removeItem('orderObj');
    }

    //Transform URL for services used both in & out of ISV. 
    transFormIsvUrl(url): string {
        let userRoles = this.dataSharedService.getListOfActions(); //this.appService.roles;
        if (userRoles.includes('OPT_OMS_VIEW_QUOTES') || userRoles.includes('OPT_OMS_VIEW_ORDERS')) {
            url = url.replace(/\{\{urlRouteParam\}\}/g, 'isv');
        } else {
            url = url.replace(/\{\{urlRouteParam\}\}\//g, '');
        }
        return url;
    }

    // Date filter : Array : Master array data, Status: Asc - true: Desc - false, field : Search Field    
    dateFilter(array: Array<any>, status: Boolean, field: string) {
        return array.sort((value1: any, value2: any) => {
            let date1: any = new Date(value1[field]);
            let date2: any = new Date(value2[field]);
            return status ? date1 - date2 : date2 - date1;
        });
    }

    /**Bootstrap Modal */
    openModal(template: TemplateRef<any>, userClass: string) {
        return this.modalService.show(template, { class: userClass });
    }
    getuserName() {
        this.dataSharedService.sharedData.subscribe(data => {
            this.userName = data['username'];
        }, (error) => {
        })
        return this.userName;
    }
    getUserGrps() {
        let userGrps = [];
        this.dataSharedService.sharedData.subscribe(data => {
            if (data['userGroups']) {
                userGrps = data['userGroups'];
            }

        }, (error) => {
        })
        return userGrps;
    } 

    getUserGroup() {
        
        // this.getuserName();
        // switch (this.userName) {
        //     case 'optimus_regus':
        //         this.groupName = 'optimus_regus'
        //         break
        //     case 'bop@legomail.com':
        //         this.groupName = 'BOP'
        //         break

        //     case 'field_ops_user@legomail.com':
        //     case 'field_ops_user1@legomail.com':
        //     case 'field_ops_user2@legomail.com':
        //         this.groupName = 'FIELD-OPS'
        //         break

        //     case 'sales_assist_user@legomail.com':
        //     case 'sales_assist_user1@legomail.com':
        //     case 'sales_assist_user2@legomail.com':
        //         this.groupName = 'SALES_ASSIST'
        //         break;
        //     case 'osp_user@legomail.com':
        //         this.groupName = 'OSP'
        //         break;
        //     case 'bond_user@legomail.com':
        //         this.groupName = 'BOND'
        //         break;
        //     case 'product_commercial_user@legomail.com':
        //     this.groupName='PRODUCT_COMMERCIAL'
        //     break;
        //     case 'scm_ml_user@legomail.com':
        //     this.groupName='SCM_M_L'
        //     break;
        //     case 'rf_sd_user@legomail.com':
        //     this.groupName='RF_SD'
        //     break;
        //     case 'sd_noc_ip_user@legomail.com':
        //     this.groupName='SD_NOC_IP'
        //     break;
        //     case 'sd_noc_tx_user@legomail.com':
        //     this.groupName='SD_NOC_TX'
        //     break;
        //     case 'casd_user@legomail.com':
        //     this.groupName='CASD'
        //     break;
        //     case 'pm_user@legomail.com':
        //     this.groupName='PM'
        //     break;
        //     case 'asp_user@legomail.com':
        //     this.groupName='ASP'
        //     break;
        //     case 'cid_user@legomail.com':
        //     this.groupName='CID'
        //     break;

        // }
        if (!sessionStorage.getItem('groupName')) {
            this.groupName = []
            this.dataSharedService.sharedData.subscribe(data => {
                // console.log(data['userGroups'][0])
                if (data['userGroups']) {
                    data['userGroups'][length] && data['userGroups'].forEach(element => {
                        if(element.groupType=='SERVICE_DELIVERY'){
                        this.groupName.push(element.groupName)
                        
                        }
                        // if(element.groupType=='COMMERCIAL' || element.groupType=='MANUAL_FEASIBILITY'){
                        //     this.groupName.push(element.groupName)
                            
                        //     }
                    });

                    sessionStorage.setItem('groupName', this.groupName[0])

                }

            }, (error) => {
            })
        }
   
        return sessionStorage.getItem('groupName')!='undefined'&&sessionStorage.getItem('groupName')?sessionStorage.getItem('groupName'):null;
        // return sessionStorage.getItem('groupName');
    }
    getLastSixMonthsList() {
        var lastSixMonths = [];
        var monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

        var today = new Date();
        var d;
        var month;

        for (var i = 1; i <= 6; i++) {
            d = new Date(today.getFullYear(), today.getMonth() - i, 1);
            month = monthNames[d.getMonth()];
            // lastSixMonths.push({key :  _moment().month(month).format("MM") + '-' + d.getFullYear(),value: month + ' ' + d.getFullYear()});
            lastSixMonths.push(month + ' ' + d.getFullYear());
        }

        return lastSixMonths;
    }

    monthApiFormat(month){
        let currentMonth = month.split(' ');
        var param = _moment().month(currentMonth[0]).format("M") + '-' +  _moment().year(currentMonth[1]).format("YY");
        return param;
    }

    monthApiFormatEx(month){
        let currentMonth = month.split('-');
        currentMonth[0] = currentMonth[0] < 10 && currentMonth[0].length === 1 ? '0' + currentMonth[0] : currentMonth[0]; 
        var param = currentMonth[0] + '-' +  "20" + currentMonth[1];
        return param;
    }

    monthApiFormatFromDate(date){
        var dateObj = new Date(date);
        return _moment(dateObj).format('M-YY');
    }

    monthNameHypenYearFormat(date){
        var value = date.split('-');
        var covertedMonth =  _moment().month(Number(value[0]) - 1).format("MMM"); 
        return covertedMonth.toUpperCase() + '-' + value[1];
    }

    monthNameHypenYearFormatEx(date){
        var value = date.split('-');
        var covertedMonth =  _moment().month(value[0]).format("MM"); 
        return covertedMonth + '-' + value[1];
    }

    isIndiaIntlGvpn(quote){
        var sites = [];
        quote.productFamilies[0].solutions.forEach(e=> sites = e.sites.concat(sites));

        var isIndiaIntlGvpn = sites.every(e => e.address.country.toLowerCase() === 'india');

        return !isIndiaIntlGvpn;
        // return true;
    }

    getMfUserGroup() {
        return this.getUserGrps().find(e => e.groupType === 'MANUAL_FEASIBILITY').groupName;
    } 
    
}
