import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-izomcc-scp',
  templateUrl: './izomcc-scp.component.html',
  styleUrls: ['./izomcc-scp.component.css']
})
export class IzomccScpComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
