export const IzoWidgetLabels = [
    {
        'Name': 'Quote',
        'Step': 1,
        'SubCategory': ['Select Configuration', 'Review Config','Get Quote', 'Checkout Config']
    },
    {
        'Name': 'Order Form',
        'Step': 2,
        'SubCategory': ['Generate Order Form','Review & Accept']
    },
    {
        'Name': 'Order Enrichment',
        'Step': 3,
        'SubCategory': ['Solution Details', 'Congratulations']
    }
];