import { NgModule, APP_INITIALIZER, Inject, NO_ERRORS_SCHEMA, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CommonModules } from './common/common.module';
import { AppComponent } from './app.component';
import { RouterModule, PreloadAllModules, Router } from '@angular/router';
import { AppRoutes } from './app.routes';
import { AppService } from './app.service';
import { MobileViewGuard } from './common/route-guard/mobile-view-guard-service';

import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { HttpModule, RequestOptions, XHRBackend } from "@angular/http";


import { NgxPermissionsModule } from 'ngx-permissions';
import { TrumbowygNgxModule } from 'trumbowyg-ngx';
import { IzomccScpModule } from './izomcc-scp/izomcc-scp.module';
import { ReactiveFormsModule } from '@angular/forms';
import { DataSharedSvc } from './data-shared-svc';
import { LoginComponent } from './login/login.component';
import { ProductselectComponent } from './productselect/productselect.component';
import { VariantselectComponent } from './variantselect/variantselect.component';

/* import { ApmService } from '@elastic/apm-rum-angular'
import { ApmErrorHandler } from '@elastic/apm-rum-angular' */

// export function initializeApp(appService: AppService) {
//     return (): Promise<any> => {
//         return appService.appInit();
//     }

// }
@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        ProductselectComponent,
        VariantselectComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        TourMatMenuModule,
        RouterModule.forRoot(AppRoutes, { useHash: false }), //preloadingStrategy: PreloadAllModules
        CommonModules,
        BrowserAnimationsModule,
        NgxPermissionsModule.forRoot(),
        TrumbowygNgxModule.withConfig({
            lang: 'hu',
            svgPath: '/assets/ui/icons.svg',
            removeformatPasted: true,
            autogrow: true,
            btns: [
                ['formatting'],
                ['strong', 'em', 'del'],
                ['superscript', 'subscript'],
                ['link'],
                ['justifyLeft', 'justifyCenter', 'justifyRight', 'justifyFull'],
                ['unorderedList', 'orderedList'],
                ['horizontalRule'],
                ['removeformat'],
                ['fullscreen']
            ]
        }),
        IzomccScpModule,
        ReactiveFormsModule
    ],
    exports: [
        RouterModule
    ],
    providers: [
        AppService,
        DataSharedSvc

    ],
    bootstrap: [AppComponent],

})

// export class AppModule {
//     constructor(@Inject(ApmService) service: ApmService) {
//       // API is exposed through this apm instance
//       const apm = service.init({
//         serviceName: 'optimus-ui',
//         serverUrl: window.location.origin,
//         active: true
//       });

//       apm.setUserContext({
//         'username': JSON.parse(sessionStorage.getItem('loggedUserName'))
//       });
//     }
//   }

export class AppModule {}
