import { Injectable } from '@angular/core';
import { appConstant } from './app.constants';
import { DataSharedSvc } from './data-shared-svc';
import { BehaviorSubject ,  Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';


@Injectable()

export class AppService {
    beObject: BehaviorSubject<any>;
    constructor( private dataSharedSvc: DataSharedSvc, private ngxPermissionsService:NgxPermissionsService) {
        this.beObject=new BehaviorSubject([])
    }
    httpService:any;

    AppServiceConst = {
        getSinglePage: './assets/types/getSinglePage.json',
        getServiceList: appConstant.userManagementService + '/users/le/engagement',
		customer: appConstant.omsService + '/api/v1/users/getcustomerdetails?limited=true',
		getQuotes: appConstant.solutionandSite + '/api/v1/dashboard/quotes',
		getSitesDetail: appConstant.omsService + '/api/v1/',
		getLocationDetails: appConstant.locationUrl + '/api/v1/locations/details',
		quoteTaskDetail: appConstant.l2oworkflow+'/api/v1/task/mf/',
		taskDetail: appConstant.l2oworkflow + '/api/v1/task/mp/details/oms?quoteId=',
        docusignStatus: appConstant.omsService + '/api/v1/quotes/',
        fpDetails: appConstant.omsService + '/api/v1/quotes/sites/',
        programTrackDetails: appConstant.customerService + '/api/v1/partners/',
        downloadUrl : appConstant.omsService + '/api/v1/dashboard/common/document',
        // api/v1/dashboard/common/document?
        searchTask: appConstant.l2oworkflow + '/api/v1/task/mf/dashboard/tasklist',
        taskDetails: appConstant.l2oworkflow + '/api/v1/task/detail/dashboard/task',
        sdwanServiceSchedule: appConstant.omsService + '/api/v1/izosdwan/quotes/SSdownload?qc=',
        sdwanSpaedServiceSchedule: appConstant.optimusSdwanService + '/api/v1/izosdwan/lite/SS/download?qc=',
        nplFpQuoteDetails: appConstant.omsService + '/api/v1/npl/quotes/links/',
        iwanServiceSchedule: appConstant.omsService + '/api/v1/iwan/quotes/SSdownload',
        getCustomerConfigurationList: appConstant.serviceInventory + '/api/v1/si/sdwan/configure/sdwanendpoints/all?',
        saveConfigurationDetails: appConstant.serviceInventory + '/api/v1/si/sdwan/configure/sdwanendpoints',
        deleteConfigurationDetails: appConstant.serviceInventory + '/api/v1/si/sdwan/configure/sdwanendpoints/statuschange',
        updateConfigurationDetails: appConstant.serviceInventory + '/api/v1/si/sdwan/configure/editsdwanendpoints',

    }
    roles: any;
    cutomerInfo:any;
    userListOfActions:any[];
    setAccessControll() {
        this.dataSharedSvc.sharedData.subscribe(data => {

            this.roles = data['roles'];
            this.cutomerInfo = data;
        })
    }
    getAccessControll() {
        return this.roles;
    }

    getloggedInUserInfo(){
        return this.cutomerInfo;
    }

    getUserActions(){
        return this.userListOfActions;
    }

    getIntialData(succsessFn, errorFn) {
       this.httpService.getHttp(appConstant.authenticationUrl + '/authentication/users/details', succsessFn, errorFn);
       // this.httpService.getHttp(appConstant.userManagement + '/api/users/data', succsessFn, errorFn);

    }

    getCheckDelegateUser(params, succsessFn, errorFn) {
        this.httpService.getHttp(appConstant.delegationUrl + '/api/v1/quotes/delegatedusers', succsessFn, errorFn);
    }
    logoutToken(succsessFn, errorFn) {
        this.httpService.getHttp(appConstant.authenticationUrl + '/authentication/token/logout', succsessFn, errorFn);
    }

    getSideMenu(successFn, cbFailureFn) {
        this.httpService.getHttp(this.AppServiceConst.getSinglePage, successFn, cbFailureFn);
    }

    getServiceList(successFn, errorFn) {
        this.httpService.getHttp(this.AppServiceConst.getServiceList, successFn, errorFn);
	}

	getQuoteList(pageNumber, pageSize, successFn, errorFn) {
        this.httpService.getHttp(this.AppServiceConst.getQuotes + '?page=' + pageNumber + '&size=' + pageSize, successFn, errorFn);
	}
	quoteDetails(successFn, errorFn, id,productName) {
        let url=productName=='NPL'|| productName=='NDE'? this.AppServiceConst.getSitesDetail +'npl/quotes/':this.AppServiceConst.getSitesDetail+'quotes/'
        return this.httpService.getHttp(url + id + '?feasiblesites=all', successFn, errorFn);
	}
	getLocationDetails(params, successFn, errorFn) {
        return this.httpService.postHttp(this.AppServiceConst.getLocationDetails, params, successFn, errorFn);
	}
	getQuoteTaskDetail(siteCodes,productName,successFn,errorFn){
        let url=productName=='NPL'||productName=="NDE"?this.AppServiceConst.quoteTaskDetail+'npl/details/oms':this.AppServiceConst.quoteTaskDetail+'details/oms'
        return this.httpService.postHttp(url, siteCodes, successFn, errorFn);
	}
	getTaskDetail(quoteId,successFn,errorFn){
        return this.httpService.getHttp(this.AppServiceConst.taskDetail + quoteId, successFn, errorFn);
	}
	getDocusignStatus(successFn, errorFn, quoteId) {
		return this.httpService.getHttp(this.AppServiceConst.docusignStatus + quoteId + '/docusign/status', successFn, errorFn);
	}
    getInvoiceFilter():Observable<any>{
        return this.beObject.asObservable();
    }
    setInvoiceFilter(invoice){
        this.beObject.next(invoice)
    }

    getProgramTrack(partnerId, successFn, errorFn) {
        this.httpService.getHttp(this.AppServiceConst.programTrackDetails + partnerId + '/profile', successFn, errorFn);
    }

    downloadDocument(file, successFn, errorFn) {
        this.httpService.getHttp(this.AppServiceConst.downloadUrl + '?path=optimus-partner-document&fileName=' + file, successFn, errorFn);
    }

    downloadDocumentWithUrl(url) {
        return this.httpService.getHttpObservableAsPDF(url);
    }

    getCustomer(successFn, errorFn) {
        this.httpService.getHttp(this.AppServiceConst.customer, successFn, errorFn);
    }
    fpDetail(successFn, errorFn, siteId,productName) {
        productName=='NPL'||productName=='NDE'? this.httpService.getHttp(this.AppServiceConst.nplFpQuoteDetails + siteId + '/fpdetails', successFn, errorFn):
        this.httpService.getHttp(this.AppServiceConst.fpDetails + siteId + '/fpdetails', successFn, errorFn);
    }
    getTaskSearchResult(username, successFn, errorFn) {
        this.httpService.getHttp(this.AppServiceConst.searchTask + '?userName=' + username , successFn, errorFn);
      }
    getTaskDetails(taskId, successFn, errorFn){
        this.httpService.getHttp(this.AppServiceConst.taskDetails + '?taskId=' + taskId , successFn, errorFn);
    }
    appInit() {
        return new Promise<void>((resolve, reject) => {
            console.log('AppInit Started');
            this.getIntialData((data) => {
                sessionStorage.setItem('loggedUserName', JSON.stringify(data.data['username']));
                appConstant.isSandboxURL = data.data['platform'] === 'PREPROD';
                appConstant.userName = data.data['username'];
                console.log('AppInit Finished');
                data.data.clientWidth = window.innerWidth;
                data.data.isMobileView = window['mobilecheck']();
                this.dataSharedSvc.changeData(data);


                const listOfActions = [];
                const listOfModules = [];
                for (const key in data.data.userActions) {
                    data.data.userActions[key].forEach(mod => {
                        listOfModules.push(mod.moduleName);
                        mod.actions.forEach(action => {
                            listOfActions.push(action.actionCode);
                        });
                    });
                }
                data.data.actions = listOfActions;
                data.data.modules = listOfModules;
                let temp = listOfActions.concat(listOfModules);

                // Temp - start
                // data.data.pmiro = true;
                // data.data.pmi = true;
                // Temp - end
                if(data.data.pmiro && data.data.pmi) {
                    temp = temp.concat('CSM_PMI_USER');
                }
                if (data.data.userType.toLowerCase() !== 'customer') {
                    temp = temp.concat('CAN_ACCESS_MODULES');
                }
                this.dataSharedSvc.setListOfActions(temp);
                this.userListOfActions = temp;
                this.ngxPermissionsService.addPermission(temp);
                resolve();
            }, () => {
                // window.open("https://customer.tatacommunications.com/optimus-deliverystatus/forbidden","_self");
                resolve();
            })
        });



    }

}
